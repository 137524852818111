import React, { ReactElement } from 'react';

import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import Button from 'snap-ui/Button';
import Drawer from 'snap-ui/Drawer';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { Detection } from 'module/Detection/Detection.type';
import { RedMarkerCreationPayload, RedMarkerUpdatePayload, Session } from 'module/Session/Session.type';

import { Ident } from 'types/common';
import { BlueMarker, CombinedCompositeMarker, RedMarker, RedMarkerExtended } from 'types/marker';
import { NodeDataType } from 'types/progressGraph';

import DefaultSidebar from './DefaultSidebar';
import NodeSidebar from './NodeSidebar';

type Props = {
  selectedNode?: NodeDataType;
  createAnalytic: (rowId: string, hostname: string) => void;
  onMarkerSave: (payload: RedMarkerCreationPayload, nodeData: NodeDataType) => Promise<void>;
  visible: boolean;
  setVisible: (value: boolean) => void;
  url: string;
  onMarkerUpdate: (marker: RedMarkerExtended, payload: RedMarkerUpdatePayload) => Promise<void>;
  onMarkerDelete: (markerId: Ident, nodeData: NodeDataType) => Promise<void>;
  session: Session;
  hostname: string;
  composite: CombinedCompositeMarker;
  detection: Detection;
  setTime: (min: number, max: number) => void;
  onAttackClick: (attack: RedMarker) => void;
  onAnalyticClick: (analytic: BlueMarker) => void;
};

const StyledDrawer = styled(Drawer)`
  .link {
    padding: 8px 12px;
  }
`;

const GraphSidebar = ({
  selectedNode,
  createAnalytic,
  visible,
  setVisible,
  onMarkerSave,
  url,
  onMarkerUpdate,
  onMarkerDelete,
  session,
  hostname,
  composite,
  detection,
  setTime,
  onAttackClick,
  onAnalyticClick
}: Props): ReactElement => {
  if (!visible) {
    return (
      <Drawer
        variant='persistent'
        anchor='right'
        open={!visible}
        PaperProps={{
          sx: {
            position: 'absolute',
            width: '40px',
            padding: '0 0 0 3px',
            overflowX: 'hidden',
            backgroundColor: '#3d3d3d',
            zIndex: 1049 // Userback widget fab is at 1050 and this ordinarily covers it
          }
        }}
      >
        <Button
          sx={{
            justifyContent: 'flex-start'
          }}
          id='sidebar-collapsed'
          variant='text'
          color='inherit'
          onClick={(): void => setVisible(!visible)}
        >
          <Icon icon={faArrowLeft} size={'xl'} />
        </Button>
      </Drawer>
    );
  }

  return (
    <StyledDrawer
      variant='persistent'
      anchor='right'
      open={visible}
      PaperProps={{
        sx: {
          position: 'absolute',
          width: '510px',
          padding: '10px 15px',
          overflowX: 'hidden',
          backgroundColor: '#3d3d3d'
        }
      }}
    >
      <Button
        sx={{
          justifyContent: 'flex-start'
        }}
        variant='text'
        color='inherit'
        className='link'
        onClick={(): void => setVisible(!visible)}
      >
        <Icon icon={faArrowRight} size={'2xl'} />
      </Button>
      {selectedNode?.process_name !== hostname ? (
        <NodeSidebar
          createAnalytic={createAnalytic}
          selectedNode={selectedNode}
          composite={composite}
          detection={detection}
          url={url}
          onMarkerUpdate={onMarkerUpdate}
          onMarkerDelete={onMarkerDelete}
          session={session}
          hostname={hostname}
          onMarkerSave={onMarkerSave}
          setTime={setTime}
        />
      ) : (
        <DefaultSidebar
          session={session}
          composite={composite}
          hostname={hostname}
          onAttackClick={onAttackClick}
          onAnalyticClick={onAnalyticClick}
        />
      )}
    </StyledDrawer>
  );
};

export default GraphSidebar;
