import React from 'react';

import { useField } from 'formik';

import FormControl, { FormHelperText } from 'snap-ui/FormControl';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormLabel from 'snap-ui/FormLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';

import { StrictReactNode } from 'types/core';

type RadioGroupFormik = {
  className?: string;
  disabled?: boolean;
  label?: StrictReactNode;
  name: string;
  options: Array<{ label: string; value: string }>;
  row?: boolean;
};

export default function RadioGroupFormik({
  className,
  disabled,
  label,
  name,
  options,
  row
}: RadioGroupFormik): JSX.Element {
  const [field, meta, helpers] = useField(name);
  const hasError = !!(meta.touched && meta.error);

  function handleChange(_e: React.ChangeEvent<HTMLInputElement>, value: string): void {
    helpers.setValue(value);
    helpers.setTouched(true, false);
  }

  return (
    <FormControl disabled={disabled} className={className} error={hasError}>
      {label && <FormLabel id={`${name}-label`}>{label}</FormLabel>}
      <RadioGroup aria-labelledby={`${name}-label`} name={name} onChange={handleChange} row={row} value={field.value}>
        {options.map(option => (
          <FormControlLabel key={option.value} control={<Radio />} label={option.label} value={option.value} />
        ))}
      </RadioGroup>
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
