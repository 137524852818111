import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { ARTIFACT_SCORE_OPTIONS } from 'constants/common';

import { FilterAugment, FilterControl } from 'module/GlobalFilter';
import Badge from 'module/Widgets/Badge';

import { ArtifactScore, ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue, fromQueryNoop } from '../GlobalFilter.util';

type AnalyticSeverityKeys = {
  severity: ArtifactScore[];
};

type AnalyticSeverityFilterProps = {
  onChange(values: AnalyticSeverityKeys): void;
  values: AnalyticSeverityKeys;
};

function AnalyticSeverityFilter({ onChange, values }: AnalyticSeverityFilterProps): React.ReactElement {
  return (
    <FilterControl>
      <FormLabel id='rank-toggle-button-group-label' className='title-tooltip'>
        By Severity
        <Tooltip title='Measure of impact caused by the threat' placement='right' arrow wrap>
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='rank-toggle-button-group-label'>
        {ARTIFACT_SCORE_OPTIONS.map(option => (
          <FormControlLabel
            key={option.key}
            control={
              <Checkbox onChange={handleChange} value={option.value} checked={values.severity.includes(option.value)} />
            }
            label={<Badge badgeSignature={option.value} badgeName='SEVERITY' />}
          />
        ))}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as ArtifactScore;
    const severity = addOrRemoveValue(checked, values.severity, v);
    onChange({ severity });
  }
}

function checkForCompleteSet(values: ArtifactScore[]): boolean {
  return !values?.length || Object.values(ArtifactScore).every(score => values?.includes(score));
}

function toAnalyticQuery(values: AnalyticSeverityKeys, { orgId }: FilterAugment): Query {
  if (checkForCompleteSet(values.severity)) return;
  const value = values.severity;
  return {
    op: Ops.for_each,
    items: [
      {
        field: 'severities.severity_name',
        op: Ops.in,
        value
      },
      {
        field: 'severities.organization_id',
        op: Ops.equals,
        value: orgId
      }
    ]
  };
}

function toQuery(values: AnalyticSeverityKeys): Query {
  if (checkForCompleteSet(values.severity)) return;
  const value = values.severity;
  return {
    field: 'severity_name',
    op: Ops.in,
    value
  };
}

const fromAnalyticQuery = zod
  .object({
    op: zod.literal(Ops.for_each),
    items: zod.array(
      zod.union([
        zod
          .object({
            field: zod.literal('severities.organization_id')
          })
          .transform((): void => undefined),
        zod
          .object({
            field: zod.literal('severities.severity_name'),
            op: zod.literal(Ops.in),
            value: zod.array(zod.union([zod.nativeEnum(ArtifactScore), zod.literal(null)]))
          })
          .transform(query => query.value.map(v => v || ArtifactScore.UNKNOWN))
      ])
    )
  })
  .transform(query => ({
    severity: query.items.filter(Boolean).flat()
  }));

const fromQuery = zod
  .object({
    field: zod.literal('severity_name'),
    op: zod.literal(Ops.in),
    value: zod.array(zod.union([zod.nativeEnum(ArtifactScore), zod.literal(null)]))
  })
  .transform(query => ({
    severity: query.value.map(v => v || ArtifactScore.UNKNOWN)
  }));

const AnalyticSeverityFilterConfig: FilterConfig<AnalyticSeverityKeys> = {
  defaults: { default: () => ({ severity: Object.values(ArtifactScore) }) },
  supportedTopics: [ArtifactType.Analytic, ArtifactType.Marker, ArtifactType.Session, ArtifactType.AttackScript],
  component: AnalyticSeverityFilter,
  toQuery: {
    [ArtifactType.Analytic]: toAnalyticQuery,
    [ArtifactType.Session]: toQuery,
    [ArtifactType.Marker]: () => null,
    [ArtifactType.AttackScript]: toQuery
  },
  fromQuery: {
    [ArtifactType.Analytic]: fromAnalyticQuery,
    [ArtifactType.Session]: fromQuery,
    [ArtifactType.Marker]: fromQueryNoop({
      field: zod.union([zod.literal('severity_name'), zod.literal('severities.severity_name')]),
      value: zod.array(zod.string())
    }),
    [ArtifactType.AttackScript]: fromQuery
  }
};
export default AnalyticSeverityFilterConfig;
