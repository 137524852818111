import React from 'react';

import z from 'zod';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import { Option } from 'snap-ui/Autocomplete';
import DateTimePicker from 'snap-ui/DateTimePicker';
import FormDialog from 'snap-ui/Dialog/FormDialog';
import { FieldsLayout } from 'snap-ui/Layout';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { ARTIFACT_SCORE_OPTIONS } from 'constants/common';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { Discriminator, TagAutocompleteFormik } from 'module/Tag';

import { BASJobState } from 'types/bas';

import { BASManualTest, OSName } from '../BAS.type';

export const Container = styled('div')`
  .container {
    margin: 0px;
    padding: 0px;
  }
  margin: 0px;
  padding: 0px;
`;

export const StyledFormDialog = styled(FormDialog)`
  margin: 0;
  padding: 0;

  .FieldsLayout {
    margin: 0px;
    margin-bottom: ${p => p.theme.spacing(3)};
    padding: 0px;
  }

  .auxiliary-actions {
    display: flex;
    justify-content: space-between;
  }
`;

const STATE: Partial<Record<BASJobState, string>> = {
  [BASJobState.Pending]: 'Pending',
  [BASJobState.InProgress]: 'In Progress',
  [BASJobState.Completed]: 'Completed',
  [BASJobState.MissingPrereq]: 'Missing Prereq',
  [BASJobState.Failed]: 'Failed',
  [BASJobState.TimedOut]: 'Time Out'
} as const;

type AddTestFormDialogProps = {
  open: boolean;
  onClose: () => void;
  handleSubmit: (v: BASManualTest) => void;
  isLoading: boolean;
  apiError?: string;
};

export const OPERATING_SYSTEM_OPTIONS = Object.entries(OSName).map(([key, value]) => ({
  key,
  content: key,
  value
}));

export const STATE_OPTIONS: Option[] = Object.entries(BASJobState).map(([key, value]) => {
  return {
    key,
    content: STATE[value],
    value
  };
});

function AddTestFormDialog({ open, onClose, handleSubmit, isLoading, apiError }: AddTestFormDialogProps) {
  const [startDateTime, setStartDateTime] = React.useState(null);
  const [endDateTime, setEndDateTime] = React.useState(null);

  return (
    <Container>
      <StyledFormDialog
        className='container'
        DialogProps={{ open: open, onClose: onClose }}
        FormikConfig={{
          validateOnChange: true,
          enableReinitialize: true,
          initialValues: {
            name: '',
            description: '',
            severity: '',
            attack_names: [],
            state: '',
            victim_hostname: '',
            victim_ip: '',
            victim_operating_system: '',
            attacker_hostname: '',
            attacker_ip: '',
            start_time: '',
            end_time: '',
            command_input: '',
            command_output: ''
          },
          onSubmit: (v: BASManualTest) => {
            const body = { ...v, start_time: startDateTime, end_time: endDateTime };
            handleSubmit(body);
          },
          zodSchema: z.object({
            name: z.string().nonempty('Please enter a name'),
            severity: z.string().nonempty('Please enter a severity'),
            attack_names: z.array(z.string()).nonempty('At least one MITRE ATT&CK is needed'),
            state: z.string().nonempty('Please enter a state'),
            victim_hostname: z.string().nonempty('Please enter victims hostname')
          })
        }}
        SubmitProps={{
          children: isLoading ? 'Saving...' : 'Save',
          disabled: isLoading
        }}
        title='Add Manual Test Case'
      >
        <FieldsLayout>
          <TextFieldFormik label='Name' name='name' required />
          <TextFieldFormik label='Description' name='description' />
          <AutocompleteFormik
            label='Severity'
            name='severity'
            options={ARTIFACT_SCORE_OPTIONS}
            disableUserAdditions
            required
          />
          <TagAutocompleteFormik name='attack_names' multiple discriminator={Discriminator.Attack} required />
          <AutocompleteFormik label='State' name='state' options={STATE_OPTIONS} disableUserAdditions required />

          <Typography variant='h4'>Attack Details Section</Typography>
          <TextFieldFormik label='Victim Host Name' name='victim_hostname' required />
          <TextFieldFormik label='Victim IP' name='victim_ip' />
          <AutocompleteFormik
            label='Victim Operating System'
            name='victim_operating_system'
            options={OPERATING_SYSTEM_OPTIONS}
            disableUserAdditions
          />
          <TextFieldFormik label='Attacker Hostname' name='attacker_hostname' />
          <TextFieldFormik label='Attacker IP' name='attacker_ip' />
          <TextFieldFormik label='Command Input' name='command_input' multiline rows={2} />
          <TextFieldFormik label='Command Output' name='command_output' multiline rows={2} />
          <DateTimePicker
            label='Start Date/Time'
            value={startDateTime}
            onChange={newValue => {
              setStartDateTime(newValue);
            }}
          />
          <DateTimePicker
            label='Stop Date/Time'
            value={endDateTime}
            onChange={newValue => {
              setEndDateTime(newValue);
            }}
          />
          {!!apiError && (
            <Alert severity='error'>
              <AlertTitle>Oops! Something went wrong.</AlertTitle>
              {apiError}
            </Alert>
          )}
        </FieldsLayout>
      </StyledFormDialog>
    </Container>
  );
}

export default AddTestFormDialog;
