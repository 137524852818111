import React from 'react';

import zod from 'zod';

import FormControlLabel from 'snap-ui/FormControlLabel';
import FormLabel from 'snap-ui/FormLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';

import { ArtifactType, BooleanString, isBooleanString } from 'types/common';
import { Ops } from 'types/filter';

import { FilterControl, FilterComponentProps, FilterConfig } from '../';

export const FIELD = 'is_native';

export type NativeDetectionKeys = {
  native: BooleanString | 'ALL';
};

function NativeDetectionFilter({ onChange, values }: FilterComponentProps<NativeDetectionKeys>): JSX.Element {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>, value: string) {
    if (isBooleanString(value)) {
      onChange({ native: value });
    } else {
      onChange({ native: undefined });
    }
  }

  return (
    <FilterControl className='TimeRestrictionFilter'>
      <FormLabel id='compatibilityLabel'>By Compatibility</FormLabel>
      <RadioGroup
        aria-labelledby='compatibilityLabel'
        name='compatibilityGroup'
        value={values.native}
        onChange={handleChange}
      >
        <FormControlLabel value='ALL' control={<Radio />} label='All Detections' />
        <FormControlLabel value={BooleanString.True} control={<Radio />} label='Only Native' />
        <FormControlLabel value={BooleanString.False} control={<Radio />} label='Only Sigma' />
      </RadioGroup>
    </FilterControl>
  );
}

function toQuery({ native }: NativeDetectionKeys) {
  if (!isBooleanString(native)) return;
  return {
    field: FIELD,
    op: Ops.equals,
    value: native
  };
}

const fromQuery = zod
  .object({
    field: zod.literal(FIELD),
    op: zod.literal(Ops.equals),
    value: zod.enum([BooleanString.True, BooleanString.False])
  })
  .transform(query => ({
    native: query.value
  }));

const NativeDetectionFilterConfig: FilterConfig<NativeDetectionKeys> = {
  defaults: { default: () => ({ native: 'ALL' }) },
  supportedTopics: [ArtifactType.Analytic],
  component: NativeDetectionFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};

export default NativeDetectionFilterConfig;
