import React from 'react';

import { faPen, faSave, faTrash, faX } from '@fortawesome/pro-solid-svg-icons';
import isEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from 'uuid';

import Badge from 'snap-ui/Badge';
import { ActionIconButton } from 'snap-ui/Button';
import {
  DataGrid,
  FooterPropsOverrides,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModes,
  GridRowModesModel
} from 'snap-ui/DataGrid';
import { FormDialog } from 'snap-ui/Dialog';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { CommonEvent, Engage, Fingerprint } from 'lib/Engagement';

import { standardFormikBaseProps } from 'module/Form';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { JsonSchema4 } from 'module/JsonView';

import { Control } from './Control';
import { MetadataFieldInput, MetadataSchema, Migration, Operation } from './Metadata.type';
import { buildSchemasFromInput, convertNameToIdentifier, sortControlOptions } from './Metadata.util';
import { AppliedToFormik, TypeFormik } from './Metadata.widgets';
import { MetadataEditorFooter } from './MetadataEditorFooter';
import { MetadataPreview } from './MetadataPreview';

const Dialog = styled(FormDialog)`
  .MuiDialogTitle-root {
    text-transform: capitalize;
  }
` as typeof FormDialog;

const Content = styled('div')`
  max-width: 100%;
  padding: ${p => p.theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(6)};

  .Content-badge {
    padding-right: ${p => p.theme.spacing(2)};
  }

  .Content-appliedPreview {
    margin-top: ${p => p.theme.spacing(5)};
    display: flex;
    gap: ${p => p.theme.spacing(4)};
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .Content-applied {
    flex: 0.45;
    margin-top: ${p => p.theme.spacing(3)};
    fieldset {
      width: 100%;
    }
  }

  .Control-openselect,
  .Control-select,
  .Control-multiselect {
    min-width: 200px;
  }

  .group-layout {
    padding: ${p => p.theme.spacing(5)};
    position: absolute;
    top: 0;
    left: 55%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiDataGrid-root {
    max-height: 350px;
  }

  .MuiDataGrid-footerContainer {
    padding: ${p => p.theme.spacing(0, 4)};
    display: flex;
    justify-content: space-between;
  }
`;

type Option = { id: string; initial: string; option: string; isNew?: boolean };

function sorter(a: Option, b: Option) {
  return sortControlOptions(a.option, b.option);
}

type _MetadataEditor = {
  blacklist: string[];
  field: JsonSchema4;
  name: string;
  onSubmit(payload: MetadataSchema, property: string): void;
  onClose(): void;
};

function _MetadataEditor(props: _MetadataEditor) {
  const { blacklist, field, name, onSubmit, onClose } = props;
  const [migrations, setMigrations] = React.useState<Migration[]>([]);
  const addMigration = (mig: Migration, next?: Migration[]) => setMigrations(m => [...(next || m), mig]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleSubmit = (values: MetadataFieldInput) => {
    const payload = {
      ...buildSchemasFromInput({
        ...values,
        options: values.options.map(o => (typeof o === 'object' ? o.option : undefined))
      }),
      migrations
    };
    Engage.track(
      Fingerprint.of(Path.Meta).withCommon(CommonEvent.Submit).withQualifier('edit field control').withData({
        name,
        schema: payload
      })
    );

    onSubmit(payload, name);
  };

  const handleFieldRename = (v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fieldName = v.target.value;
    const next = migrations.filter(m => m.operation !== Operation.FieldRename);
    if (fieldName === field.title) setMigrations(next);
    else
      setMigrations([
        ...next,
        {
          id: field.title,
          operation: Operation.FieldRename,
          field: name,
          value: convertNameToIdentifier(fieldName)
        }
      ]);
  };

  const handleAppliesToChange = (v: string[]) => {
    const next = migrations.filter(m => m.operation !== Operation.UIPropertyChange);
    const equals = isEqual(v.sort(sortControlOptions), field.properties.appliesTo.enum.sort(sortControlOptions));
    if (equals) setMigrations(next);
    else
      setMigrations([
        ...next,
        {
          id: 'appliesTo',
          operation: Operation.UIPropertyChange,
          field: 'appliesTo'
        }
      ]);
  };

  React.useEffect(() => {
    Engage.track(
      Fingerprint.of(Path.Meta)
        .withQualifier('edit field dialog')
        .withData({
          blacklist,
          field,
          name
        })
        .pageLoad()
    );
  }, [blacklist, field, name]);

  const options = React.useMemo(
    () =>
      [...(field.enum || field.items?.['enum'] || [])].sort(sorter).map((m, index) => ({
        id: `${index}-${m}`,
        initial: m,
        option: m
      })),
    [field.enum, field.items]
  );

  return (
    <Dialog
      title={`Metadata - Edit Field - ${name}`}
      DialogProps={{
        open: true,
        onClose,
        disableEscapeKeyDown: true,
        disablePortal: true
      }}
      SubmitProps={{ children: 'Save', disabled: migrations.length <= 0 || Object.keys(rowModesModel).length > 0 }}
      FormikConfig={{
        ...standardFormikBaseProps,
        validateOnChange: true,
        initialValues: {
          label: field.title,
          description: field.description,
          type: field.properties.control.title,
          applied: field.properties.appliesTo.enum,
          options,
          _blacklist: blacklist.filter(v => v !== name)
        },
        zodSchema: MetadataFieldInput,
        onSubmit: handleSubmit
      }}
    >
      {({ values, setFieldValue, touched, errors, setTouched, setErrors }) => {
        const options = values.options as Option[];

        const handleRowUpdate = (next: GridRowModel, prev: GridRowModel) => {
          const updatedRow = { ...next, isNew: false };
          setFieldValue('options', options.map(o => (o.id === next.id ? updatedRow : o)).sort(sorter));
          setTouched({ ...touched, options: true });
          if (next.initial === next.option) setMigrations(mig => mig.filter(m => m.id !== next.id));
          else {
            addMigration({
              id: prev.id,
              operation: Operation.SelectionRename,
              field: values.label,
              selection: prev.option,
              value: next.option
            });
          }
          return updatedRow;
        };

        const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => setRowModesModel(newRowModesModel);

        const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
          if (params.reason === GridRowEditStopReasons.rowFocusOut) event.defaultMuiPrevented = true;
        };

        const handleAddNewOption = (id: string) => {
          const option = `option ${options.length + 1}`;
          setFieldValue('options', [...options, { id, option, initial: option, isNew: true }], true);
          setTouched({ ...touched, options: true });
          addMigration({
            id,
            operation: Operation.SelectionAdd,
            field: values.label,
            selection: option
          });
        };

        const handleEditOption = (row: GridRowModel) => {
          setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.Edit } });
        };

        const handleSaveOption = (row: GridRowModel) => {
          setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.View } });
        };

        const handleCancelOption = (row: GridRowModel) => {
          setRowModesModel({
            ...rowModesModel,
            [row.id]: { mode: GridRowModes.View, ignoreModifications: true }
          });

          const editedRow = options.find(r => r.id === row.id);
          if (editedRow?.isNew) {
            setFieldValue('options', [...options.filter(o => o.id !== row.id)]);
          }
        };

        const handleDeleteOption = (row: GridRowModel) => {
          const opts = options.filter(o => o.id !== row.id);
          setFieldValue('options', opts);
          setTouched({ ...touched, options: true });
          const next = migrations.filter(m => m.id !== row.id);
          addMigration(
            {
              id: row.id,
              operation: Operation.SelectionDelete,
              field: values.label,
              selection: row.option
            },
            next
          );
          if (opts.length === 0) {
            const id = uuidv4();
            opts.push({ id, option: 'option 1', initial: 'option 1', isNew: true });
            setErrors({
              ...errors,
              options: ['At least one option is required']
            });
            addMigration({
              id,
              operation: Operation.SelectionAdd,
              field: values.label,
              selection: 'option 1'
            });
          }
          // purge migration sequence of new option added then deleted
          if (row.isNew) setMigrations(mig => mig.filter(m => m.id !== row.id));
        };
        return (
          <Content>
            <TypeFormik
              disabled
              onChange={t => {
                setFieldValue('type', t);
                // When supporting changing of types, sort out migration model
                if (t === Control.Select || t === Control.MultiSelect) setFieldValue('options', options, true);
                else setFieldValue('options', [], true);
              }}
            />
            <TextFieldFormik
              name='label'
              label='Field Name'
              required
              helperText='A name like FooBar or Foo Bar'
              disabled={!values.type}
              onChange={handleFieldRename}
            />
            <div className='Content-appliedPreview'>
              <AppliedToFormik name='applied' onChange={handleAppliesToChange} />
              {options.length > 0 ? (
                <DataGrid
                  editMode='row'
                  disableColumnMenu
                  disableColumnResize
                  disableRowSelectionOnClick
                  disableMultipleRowSelection
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={handleRowUpdate}
                  columns={[
                    {
                      field: 'option',
                      headerName: 'Options',
                      editable: true,
                      flex: 1,
                      renderCell: ({ value }) =>
                        migrations.find(m => m.value === value) ? (
                          <Badge color='warning' variant='dot'>
                            <div className='Content-badge'>{value}</div>
                          </Badge>
                        ) : (
                          value
                        )
                    },
                    {
                      field: 'actions',
                      type: 'actions',
                      headerName: 'Actions',
                      cellClassName: 'actions',
                      getActions: ({ row }) => {
                        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
                        return isInEditMode
                          ? [
                              <Tooltip key='save' enterNextDelay={500} title='Save' placement='top' arrow>
                                <ActionIconButton
                                  aria-label={`save ${row.option} control`}
                                  icon={faSave}
                                  onClick={() => handleSaveOption(row)}
                                />
                              </Tooltip>,
                              <Tooltip key='cancel' enterNextDelay={500} title='Cancel' placement='top' arrow>
                                <ActionIconButton
                                  aria-label={`cancel ${row.option} control`}
                                  icon={faX}
                                  onClick={() => handleCancelOption(row)}
                                />
                              </Tooltip>
                            ]
                          : [
                              <Tooltip key='edit' enterNextDelay={500} title='Edit' placement='top' arrow>
                                <ActionIconButton
                                  aria-label={`edit ${row.option} control`}
                                  icon={faPen}
                                  onClick={() => handleEditOption(row)}
                                />
                              </Tooltip>,
                              <Tooltip key='delete' enterNextDelay={500} title='Delete' placement='top' arrow>
                                <ActionIconButton
                                  aria-label={`delete ${row.option} control`}
                                  icon={faTrash}
                                  onClick={() => handleDeleteOption(row)}
                                />
                              </Tooltip>
                            ];
                      }
                    }
                  ]}
                  rows={options}
                  slots={{
                    footer: MetadataEditorFooter
                  }}
                  slotProps={{
                    footer: {
                      isEditing: Object.keys(rowModesModel).length > 0,
                      onAddNewOption: handleAddNewOption,
                      setRowModesModel
                    } as FooterPropsOverrides
                  }}
                />
              ) : (
                <MetadataPreview key={`${values.type}-${values.label}`} />
              )}
            </div>
          </Content>
        );
      }}
    </Dialog>
  );
}

export type MetadataEditor = _MetadataEditor & { isOpen: boolean };
export function MetadataEditor(props: MetadataEditor) {
  if (!props.isOpen) return null;
  return <_MetadataEditor {...props} />;
}
