import { FilterValues } from 'module/GlobalFilter';
import { AttackType } from 'module/Tag';

import { ArtifactType } from 'types/common';

import { downloadFile } from 'utilities/FileUtils';
import { formatQueryString, parseAnyQueryString } from 'utilities/SearchParam';

import { matrixToAttackNavigation } from './Matrix.service';
import { AttackNode, Display, Matrix, Palette, Statistic } from './Matrix.type';

export function nodeTotal(node: Statistic) {
  return (node?.analytics ?? 0) + (node?.sessions ?? 0) + (node?.intelligence ?? 0);
}

export function getMitreID(name: AttackNode['name']) {
  return name.split(': ')[0];
}
// expectation is that technique and subtechnique have the fully qualified name
// Tactic: Resource Development
// Technique: T1583: Acquire Infrastructure
// Subtechnique: T1583.005: Acquire Infrastructure: Botnet
export function getNodeSpecificName(node: AttackNode) {
  switch (node.type) {
    case AttackType.Tactic:
      return node.name;
    case AttackType.Technique:
      return node.name.split(': ')[1];
    case AttackType.Subtechnique:
      return node.name.split(': ')[2];
  }
}

export function exportMatrixToAttackNavigation(
  matrix: Matrix,
  meta: Omit<Display, 'showDrawer'>,
  topic: ArtifactType,
  palette?: Palette
) {
  const flat = matrixToAttackNavigation(matrix, meta, topic, palette);
  const json = JSON.stringify(flat, null, 2);
  downloadFile(new Blob([json]), `SnapAttack-ATT&CK-Navigator_${Date.now()}.json`);
}

export function getFilterValuesOnlyQueryString(filter: FilterValues, search: string) {
  const _filter = filter || {};
  const subject = parseAnyQueryString(search);
  const filterOnlyValues = Object.keys(subject).reduce((result: Record<string, string | string[]>, key) => {
    if (key in _filter) result[key] = subject[key];
    return result;
  }, {});
  return formatQueryString(filterOnlyValues);
}
