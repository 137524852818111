import React from 'react';

import { select } from 'd3';

import { Detection } from 'module/Detection/Detection.type';

import { CombinedCompositeMarker } from 'types/marker';
import { NodeDataType } from 'types/progressGraph';

import NodeLabel from './NodeLabel';

type NodeProps = {
  nodeData: NodeDataType;
  nodeSize: { x: number; y: number };
  onNodeClick: (node: NodeDataType) => void;
  onExpandClick: (node: NodeDataType) => void;
  composite: CombinedCompositeMarker;
  detection: Detection;
  subscriptions?: unknown;
  orientation?: string;
};

export default class Node extends React.Component<NodeProps, Record<string, unknown>> {
  state = {
    transform: this.setTransform(this.props.nodeData, 'horizontal', true),
    initialStyle: {
      opacity: 0
    }
  };
  node: SVGGElement = null;

  componentDidMount() {
    this.commitTransform();
  }

  componentDidUpdate() {
    this.commitTransform();
  }

  shouldComponentUpdate(nextProps: NodeProps) {
    return this.shouldNodeTransform(this.props, nextProps);
  }

  shouldNodeTransform = (ownProps: NodeProps, nextProps: NodeProps) =>
    nextProps.subscriptions !== ownProps.subscriptions ||
    nextProps.nodeData.x !== ownProps.nodeData.x ||
    nextProps.nodeData.y !== ownProps.nodeData.y ||
    nextProps.orientation !== ownProps.orientation;

  setTransform(nodeData: NodeDataType, orientation: string, shouldTranslateToOrigin = false) {
    const { x, y, parent } = nodeData;
    if (shouldTranslateToOrigin) {
      const hasParent = typeof parent === 'object';
      const originX = hasParent ? parent.x : 0;
      const originY = hasParent ? parent.y : 0;
      return orientation === 'horizontal' ? `translate(${originY},${originX})` : `translate(${originX},${originY})`;
    }
    return orientation === 'horizontal' ? `translate(${y},${x})` : `translate(${x},${y})`;
  }

  applyTransform(transform: string, transitionDuration: number, opacity = 1, done = () => {}) {
    if (transitionDuration === 0) {
      select(this.node).attr('transform', transform).style('opacity', opacity);
      done();
    } else {
      select(this.node)
        .transition()
        .duration(transitionDuration)
        .attr('transform', transform)
        .style('opacity', opacity)
        .each(done);
    }
  }

  commitTransform() {
    const { nodeData } = this.props;
    const transform = this.setTransform(nodeData, 'horizontal');

    this.applyTransform(transform, 0);
  }

  componentWillLeave(done: () => void) {
    const { nodeData } = this.props;
    const transform = this.setTransform(nodeData, 'horizontal', true);
    this.applyTransform(transform, 0, 0, done);
  }

  render() {
    const { nodeData, nodeSize } = this.props;
    return (
      <g
        id={nodeData.id}
        ref={n => {
          this.node = n;
        }}
        style={this.state.initialStyle}
        className={nodeData._children ? 'nodeBase' : 'leafNodeBase'}
        transform={this.state.transform}
      >
        <rect width={nodeSize.x} x={200} />
        <foreignObject width={nodeSize.x} height={nodeSize.y} x={0} y={-70}>
          <NodeLabel
            nodeData={nodeData}
            onNodeClick={this.props.onNodeClick}
            onExpandClick={this.props.onExpandClick}
            composite={this.props.composite}
            detection={this.props.detection}
          />
        </foreignObject>
      </g>
    );
  }
}
