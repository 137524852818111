import { BASJob, OSName } from 'module/BAS/BAS.type';

import { Permitted } from './auth';
import { Guid, Tracked } from './common';

export enum BASProductName {
  SnapAttack = 'SnapAttack',
  AtomicRedTeam = 'Atomic Red Team'
}

export type BASProductNameKey = keyof typeof BASProductName;

export type BASScript = {
  id: string;
  name: string;
  description: string;
  guid: Guid;
  product_id: string;
  product_name: BASProductName;
  product_version?: string;
  script: string;
  validation: string;
  platforms: OSName[];
  simulated: boolean;
  directly_attached: boolean;
};

export type BASAgent = Permitted & {
  id: number;
  organization_id: number;
  guid: Guid;
  bas_agent_version: string;
  description: string;
  host_id: string;
  host_metadata: Record<string, string>;
  is_online: boolean;
  last_checkin_time: string;
  name: string;
  os_name: OSName;
};

export type BASAgentEditableFields = Pick<BASAgent, 'name' | 'description'>;

export type SessionBASScriptResponse = {
  script: BASScript;
  agents: BASAgent[];
};

export type AnalyticBASScriptResponse = {
  scripts: BASScript[];
  agents: BASAgent[];
};

export type AtomicRedYaml = {
  attack_technique: string;
  display_name: string;
  atomic_tests: {
    name: string;
  }[];
};

export type LaunchBASFormValues = {
  agent_id: string;
  script_id: string;
};

export type BulkLaunchBASFormValues = {
  name?: string;
  agents: Partial<Record<OSName, Guid>>;
};

export enum BASJobResultStatus {
  Success = 'success',
  Warning = 'warning',
  Failure = 'failure'
}

export type BASJobDependencyResult = {
  name: string;
  exit_code: number;
  output?: string;
};

export type BASJobResult = {
  command: string;
  error?: string;
  exit_code?: string;
  output?: string;
  dependency_results: BASJobDependencyResult[];
};

export type BASJobSession = {
  guid: string;
  name: string;
  description: string;
};

export const BASJobState = {
  Pending: 'pending',
  InProgress: 'in_progress',
  Completed: 'completed',
  MissingPrereq: 'missing_prereq',
  Failed: 'failed',
  TimedOut: 'timed_out',
  Canceled: 'canceled'
} as const;

export type BASJobState = (typeof BASJobState)[keyof typeof BASJobState];

export enum JobOutcome {
  NeedsReview = 'Needs Review',
  NotTested = 'Not Tested',
  NoAction = 'No Action',
  Logged = 'Logged',
  Detected = 'Detected',
  Prevented = 'Prevented'
}

export const JOB_OUTCOME_ORDER = [
  JobOutcome.NeedsReview,
  JobOutcome.NotTested,
  JobOutcome.NoAction,
  JobOutcome.Logged,
  JobOutcome.Detected,
  JobOutcome.Prevented
] as const;

export type BASTool = string;
export type BASJobOutcome = {
  bas_job_id?: number;
  detections?: Tracked[];
  outcome: JobOutcome;
  tool: BASTool;
  notes?: string;
  job?: BASJob;
};

export type BASJobOutcomePayload = Omit<BASJobOutcome, 'detections'> & {
  detections?: Guid[];
};

export type ExecutionErrors = Record<string, string | Record<string, string>>;

export type BASJobPayload = Omit<BASJob, 'outcomes'> & {
  outcomes?: BASJobOutcomePayload[];
};

export type JobHit = {
  /* the analytic that hit */
  guid: Guid;
  /* the analytic's name */
  name: string;
  /* total number of hits for the analytic */
  count: number;
  /* timestamp of the latest hit */
  last_hit: number;
};
