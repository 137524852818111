import React from 'react';

import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { cleanHTML } from 'module/Card/Card.util';

import { CategoryResultItem } from './type';

const Container = styled('div')`
  .title span,
  .aliases span {
    color: ${p => p.theme.palette.primary.main};
  }
`;

const WrappedContainer = styled(Container)`
  flex: 1 1 auto;
  max-width: 395px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  padding-right: 10px;
`;

function sortHighlightsThenAlphabetically(a: string, b: string) {
  const aHasSpan = a.includes('<span>');
  const bHasSpan = b.includes('<span>');

  if (aHasSpan && !bHasSpan) return -1; // a comes first if it contains <span> and b doesn't
  if (!aHasSpan && bHasSpan) return 1; // b comes first if it contains <span> and a doesn't

  return a.localeCompare(b); // alphabetically sort if both have <span> or neither
}

export function NameWithAliases(props: { item: CategoryResultItem }) {
  const { preview, title: _title } = props.item;
  const [title, ...aliases] = _title;

  const aliasString = aliases.sort(sortHighlightsThenAlphabetically).join(', ');

  return (
    <Tooltip
      title={
        <Container>
          <Content title={title} aliases={aliasString} />
        </Container>
      }
      placement='left'
      arrow
    >
      <WrappedContainer className={preview ? 'result-title locked' : 'result-title'}>
        <Content title={title} aliases={aliasString} />
      </WrappedContainer>
    </Tooltip>
  );
}

function Content({ title, aliases }: { title: string; aliases: string }) {
  return (
    <>
      <span className='title' dangerouslySetInnerHTML={{ __html: cleanHTML(title) }} />
      {aliases && (
        <>
          {' '}
          (<span className='aliases' dangerouslySetInnerHTML={{ __html: cleanHTML(aliases) }} />)
        </>
      )}
    </>
  );
}
