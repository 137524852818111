import React from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import Button from 'snap-ui/Button';
import Checkbox from 'snap-ui/Checkbox';
import CircularProgress from 'snap-ui/CircularProgress';
import FormControlLabel from 'snap-ui/FormControlLabel';
import Icon from 'snap-ui/Icon';
import Pagination from 'snap-ui/Pagination';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { CancelToken } from 'apis';

import Path from 'constants/paths';

import useDebounce from 'hooks/useDebounce';

import { CommonEvent, Engage, Fingerprint } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';
import { mapIntegrationToOption } from 'module/Integration/Integration.util';
import IntegrationAutocomplete from 'module/Integration/IntegrationAutocomplete';
import { huntInterfaceFactory, JobType } from 'module/Job';
import { SyntaxViewer, SyntaxViewerPlaceholder } from 'module/Widgets/SyntaxEditor';

import { useIntegrationCatalog } from 'provider';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { createIOC } from '../IOC.api';
import {
  CategorizedIOCs,
  IOC,
  IOCTranslation,
  IOCTypeCategory,
  iocTypeCategory,
  IOCTypeCategoryChecked,
  SupportedIOC
} from '../IOC.type';
import IOCList from './IOCList';
import { IOCTranslatorContainer } from './IOCParser.style';
import { categorizeIocs, getKeywords, highlight, INITIAL_CATEGORIES, mapIocType, pluralize } from './IOCParser.util';
import useTranslateIOC from './useTranslateIOC';

const [IOCHuntInterface, IOCHuntButton, IOCHuntDialog] = huntInterfaceFactory('IOCHuntContext');

type IOCTranslatorProps = {
  iocs: IOC[];
  isLoading?: boolean;
  title: string;
  displayDefanged?: boolean;
  iocsWithWarnings?: IOC[];
};

const INITIAL_CHECKBOXES: IOCTypeCategoryChecked = {
  [IOCTypeCategory.Hash]: true,
  [IOCTypeCategory.IP_Address]: true,
  [IOCTypeCategory.Domain]: true,
  [IOCTypeCategory.URL]: true
};

export default function IOCTranslator({
  iocs,
  isLoading = false,
  title,
  displayDefanged,
  iocsWithWarnings
}: IOCTranslatorProps): JSX.Element {
  const { integrations } = useIntegrationCatalog();
  const { data: translations, translate, reset, status } = useTranslateIOC();
  const translationLoading = Status.pending === status;
  const [checkboxValues, setCheckboxValues] = React.useState<IOCTypeCategoryChecked>(INITIAL_CHECKBOXES);
  const [selectedIntegrationOptionValue, setSelectedIntegrationOptionValue] = React.useState<string>('');

  const selectedIntegration = React.useMemo(
    () => integrations.all.find(i => i.id.toString() === selectedIntegrationOptionValue),
    [integrations.all, selectedIntegrationOptionValue]
  );

  const integrationOptions = React.useMemo(
    () =>
      [...integrations.huntable, ...integrations.preferredOrgSearchable]
        .filter(i => i.type !== 'CustomerManaged')
        .filter(i => i?.guid)
        .map(i => mapIntegrationToOption(i)),
    [integrations.huntable, integrations.preferredOrgSearchable]
  );

  const filteredIOCs: SupportedIOC[] = React.useMemo(() => {
    if (isEmpty(iocs)) return [];
    const compatibilityMappedIOCs: SupportedIOC[] = iocs.map(ioc => ({
      ...ioc,
      hasTemplate: !!selectedIntegration?.extra_information?.[`${ioc.type.toLowerCase()}_template`]
    }));
    const categorizedIocs = categorizeIocs(compatibilityMappedIOCs);

    return Object.keys(checkboxValues)
      .filter((category: keyof IOCTypeCategoryChecked) => checkboxValues[category])
      .flatMap((category: keyof IOCTypeCategoryChecked) => categorizedIocs[category]);
  }, [iocs, selectedIntegration, checkboxValues]);

  const filteredIOCsWithWarnings = filteredIOCs.map(ioc => ({
    ...ioc,
    misp_warning_lists:
      iocsWithWarnings?.find(warning => warning.name === ioc.name)?.misp_warning_lists || ioc.misp_warning_lists
  }));

  const [page, setPage] = React.useState(0);
  const [selectedTranslation, setSelectedTranslation] = React.useState<IOCTranslation>();
  const [selectedIOCs, setSelectedIOCs] = React.useState<IOC[]>([]);

  const {
    data: createdIOCGuids,
    setData: setCreatedIOCGuids,
    task: createIOCTask,
    status: createIOCStatus,
    errorProps: createIOCErrorProps
  } = useAsync<Guid[]>([]);

  React.useEffect(() => {
    setSelectedTranslation(isEmpty(translations) ? null : translations[page]);
  }, [page, selectedTranslation, translations]);

  React.useEffect(() => {
    setSelectedIOCs(iocs || []);
  }, [iocs, selectedIntegration, setSelectedIOCs]);

  React.useEffect(() => {
    if (!isEmpty(filteredIOCs)) {
      setSelectedIOCs(currentSelectedIOCs =>
        currentSelectedIOCs.filter(selectedIOC =>
          filteredIOCs.find(filteredIOC => filteredIOC.hasTemplate && selectedIOC.name === filteredIOC.name)
        )
      );
    }
  }, [filteredIOCs]);

  React.useEffect(() => {
    if (!selectedIntegrationOptionValue) {
      setSelectedIntegrationOptionValue(integrationOptions[0]?.value);
    }
  }, [integrationOptions, selectedIntegrationOptionValue]);

  const categorizedSelectedIOCs = React.useMemo(() => categorizeIocs(selectedIOCs), [selectedIOCs]);
  const debouncedCategorizedSelectedIOCs: CategorizedIOCs<IOC> = useDebounce(categorizedSelectedIOCs, 1000);

  React.useEffect(() => {
    // translate on change
    const cancelSource = CancelToken.source();
    if (selectedIntegration && !isEqual(debouncedCategorizedSelectedIOCs, INITIAL_CATEGORIES)) {
      const iocs = Object.keys(checkboxValues)
        .filter((category: keyof IOCTypeCategoryChecked) => checkboxValues[category])
        .flatMap((category: keyof IOCTypeCategoryChecked) =>
          debouncedCategorizedSelectedIOCs[category].map(({ type, name }) => ({ type, name }))
        );

      if (iocs.length) translate(selectedIntegration.guid, iocs, cancelSource);
    } else {
      reset();
    }
    return () => cancelSource.cancel();
  }, [debouncedCategorizedSelectedIOCs, checkboxValues, selectedIntegration, translate, reset]);

  function handleChangeCheckbox(name: keyof IOCTypeCategoryChecked) {
    return () => {
      const selectingIOCs = !checkboxValues[name];
      if (selectingIOCs) {
        const iocsToAdd = iocs.filter(i => {
          return mapIocType(i.type) === name;
        });
        if (iocsToAdd.length > 0) {
          setSelectedIOCs(currentSelectedIOCs => {
            return [...currentSelectedIOCs, ...iocsToAdd];
          });
        }
      } else {
        setSelectedIOCs(currentSelectedIOCs => currentSelectedIOCs.filter(i => mapIocType(i.type) !== name));
      }

      setCheckboxValues({
        ...checkboxValues,
        [name]: !checkboxValues[name]
      });
    };
  }

  function trackSearch() {
    Engage.track(Fingerprint.of(Path.IOC).withCommon(CommonEvent.Click).withQualifier('search'));
  }

  function handleHuntButtonClick(onClick: () => void): void {
    createIOCTask(createIOC(selectedIOCs.map(ioc => omit(ioc, 'tags', 'hasTemplate')))).then(createdIOCGuids => {
      setCreatedIOCGuids(createdIOCGuids.map(tracked => tracked.guid));
      onClick();
    });
  }

  return (
    <IOCHuntInterface jobType={JobType.IOC}>
      <IOCTranslatorContainer className='IOCTranslator'>
        <Typography variant='h2'>{title}</Typography>
        <div className='IOCTranslator-toolbar'>
          <div className='IOCTranslator-inputs'>
            <div className='IOCTranslator-integration'>
              <IntegrationAutocomplete
                className='IOCTranslator-selector'
                label='Integration'
                onChange={setSelectedIntegrationOptionValue}
                name='integrationGuid'
                value={selectedIntegrationOptionValue}
                disableClearable
                disableUserAdditions
                options={integrationOptions}
              />

              <Tooltip
                arrow
                title="Don't see what you're looking for? Only integrations configured to Hunt or Search will be displayed here. Custom Integrations are not compatible with this feature."
                wrap
              >
                <Icon.Info />
              </Tooltip>
            </div>
            <div className='IOCTranslator-actions'>
              <IOCHuntButton>
                {({ onClick, disabled }) => (
                  <Button
                    onClick={() => handleHuntButtonClick(onClick)}
                    disabled={
                      disabled ||
                      !selectedIntegration?.ioc_huntable ||
                      createIOCStatus === Status.pending ||
                      isEmpty(selectedIOCs)
                    }
                  >
                    {createIOCStatus === Status.pending ? <CircularProgress size={25} /> : 'Hunt'}
                  </Button>
                )}
              </IOCHuntButton>

              {!isEmpty(selectedIntegration?.search_targets) && (
                <Button
                  component='a'
                  className='IOCTranslator-search'
                  disabled={!selectedTranslation?.url || translationLoading}
                  href={selectedTranslation?.url}
                  onClick={trackSearch}
                  rel='noopener noreferrer'
                  startIcon={<Icon icon={faSearch} />}
                  target='_blank'
                  variant='outlined'
                >
                  Search
                </Button>
              )}
            </div>
          </div>
          <div className='IOCTranslator-checks'>
            {iocTypeCategory.map(cat => (
              <FormControlLabel
                key={cat}
                name={cat}
                control={
                  <Checkbox
                    disabled={isEmpty(iocs)}
                    onChange={handleChangeCheckbox(cat)}
                    checked={checkboxValues[cat]}
                  />
                }
                label={
                  <div className={classnames('IOCTranslator-checkbox-label', cat, { disabled: isEmpty(iocs) })}>
                    {categorizedSelectedIOCs[cat]?.length ?? 0} {pluralize(cat)}
                  </div>
                }
              />
            ))}
          </div>
        </div>
        <ApiError {...createIOCErrorProps} />

        {selectedTranslation?.raw ? (
          <div className='IOCTranslator-query'>
            <SyntaxViewer value={selectedTranslation.raw} highlight={highlight(getKeywords(categorizeIocs(iocs)))} />
          </div>
        ) : selectedTranslation?.error ? (
          <ApiError messages={[selectedTranslation.error]} />
        ) : isEmpty(selectedTranslation) && Status.resolved === status ? (
          <ApiError messages={['No Translations Available']} />
        ) : (
          <SyntaxViewerPlaceholder rows={4} animate={isLoading || translationLoading} />
        )}

        {translations.length > 1 && (
          <Pagination
            className='IOCTranslator-pagination'
            zeroIndex
            changePage={(next: number) => setPage(next)}
            numPages={translations.length}
            page={page}
          />
        )}

        <IOCList
          iocs={filteredIOCsWithWarnings}
          isLoading={isLoading}
          selectedIOCs={selectedIOCs}
          setSelectedIOCs={setSelectedIOCs}
          displayDefanged={displayDefanged}
        />
        <IOCHuntDialog
          jobName='IOC Hunt'
          selections={createdIOCGuids}
          selectedIntegrations={selectedIntegration ? [selectedIntegration.guid] : []}
        />
      </IOCTranslatorContainer>
    </IOCHuntInterface>
  );
}
