import React from 'react';

import pick from 'lodash/pick';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { useHistory } from 'react-router-dom';

import { Option, getDisplayValue } from 'snap-ui/Autocomplete';
import Placeholder from 'snap-ui/Placeholder';
import { useTheme } from 'snap-ui/util';

import useRawTranslation from 'aso/useRawTranslation';

import Path from 'constants/paths';

import { IDETranslationStateProvider } from 'module/IDE';
import {
  getSyntheticSnapattackIntegration,
  useIntegrationOptions,
  useLanguageOptions,
  useLanguagePlatforms
} from 'module/Integration';
import { IntegrationLanguageOption, IntegrationOption } from 'module/Integration/Integration.type';
import { mapIntegrationToOption } from 'module/Integration/Integration.util';
import { Page } from 'module/Layout/Styled';
import {
  StyledAutocomplete,
  StyledAutocompletePaper,
  StyledIntegrationAutocomplete
} from 'module/TranslateAnalytic/TranslateAnalytic.helper';
import { getIntegrationValueFromLanguageId } from 'module/TranslateAnalytic/TranslateAnalytic.util';

import { useAuth, useIntegrationCatalog, usePushSnack } from 'provider';

import { Status } from 'storage';

import { CompilationTargetId, DeploymentIntegration } from 'types/analytic';
import { Ident } from 'types/common';

import { getQueryParam } from 'utilities/SearchParam';
import { STANDARD, formatCustomTimestamp } from 'utilities/TimeUtils';

import { StyledToolbar, VersionDeployButton, VersionDiff } from '../Analytic.style';
import { AnalyticRouterProps, AnalyticVersion } from '../Analytic.type';
import { useAnalyticLanguageCatalog } from '../AnalyticLanguages';
import AnalyticVersionList from '../AnalyticVersion/AnalyticVersionList';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import { useAnalyticVersionCatalog } from '../core/AnalyticVersionProvider';
import { DeployIntegrationButton } from '../core/MetadataPanel/Deployment';
import { getCompatibleIntegration } from '../core/MetadataPanel/util';
import { AnalyticDiffSelector } from './AnalyticDiffSelector';

export function AnalyticDiff(props: AnalyticRouterProps) {
  const { push } = useHistory();
  const pushSnack = usePushSnack();
  const { palette } = useTheme();
  const deployedEnvironment = getQueryParam(location.search, 'deployedEnvironment');
  const leftId = props.match.params.versionId;
  const rightId = props.match.params.otherId;
  const { defaultLanguageId, defaultOrgId } = useAuth();
  const [{ analytic, analyticStatus, supplemental, supplementalRefresh }] = useAnalyticCatalog();

  const { integrations, status: integrationsStatus } = useIntegrationCatalog();
  const { status: versionStatus, versions: allVersion } = useAnalyticVersionCatalog();

  const { getPlatformDetails, supportedPlatforms: snapattackSupportedPlatforms } = useLanguagePlatforms();
  const { getIntegrationOptionsFromPlatformEntries, integrationOptions: sigmaDetectionIntegrationOptions } =
    useIntegrationOptions(true, analytic.analytic_compilation_targets);

  const {
    data: languageList,
    allLanguages,
    status: languageListStatus,
    getLanguageById
  } = useAnalyticLanguageCatalog();

  const [selectedLanguageId, setSelectedLanguageId] = React.useState<number>();
  const [selectedIntegrationOptionValue, setSelectedIntegrationOptionValue] = React.useState<string>('');
  const [isTranslated, setIsTranslated] = React.useState<boolean>(false);

  const previousLanguageId = React.useRef<number>();
  const previousLeftId = React.useRef<string>();
  const previousRightId = React.useRef<string>();

  React.useEffect(() => {
    previousLanguageId.current = selectedLanguageId;
  });

  React.useEffect(() => {
    previousLeftId.current = leftId;
  });

  React.useEffect(() => {
    previousRightId.current = rightId;
  });

  const isVersionNative = React.useCallback(
    (version: AnalyticVersion) => {
      if (version?.guid === supplemental?.parent?.guid) {
        return supplemental?.parent?.source_compilation_target_id !== CompilationTargetId.Sigma;
      }
      return supplemental?.source_analytic_compilation_target_id !== CompilationTargetId.Sigma;
    },
    [
      supplemental?.parent?.guid,
      supplemental?.parent?.source_compilation_target_id,
      supplemental?.source_analytic_compilation_target_id
    ]
  );

  const isVersionAParentNative = React.useCallback(
    (version: AnalyticVersion) => {
      if (
        version?.guid !== supplemental?.parent?.guid &&
        supplemental?.parent?.source_compilation_target_id !== CompilationTargetId.Sigma
      ) {
        return true;
      }
      return false;
    },
    [supplemental?.parent?.guid, supplemental?.parent?.source_compilation_target_id]
  );

  const leftVersion = allVersion?.find(version => version.id.toString() === leftId);
  const rightVersion = allVersion?.find(version => version.id.toString() === rightId);
  const orgDeployments = supplemental?.deployments?.filter(d => d.organization_id === defaultOrgId);

  const deployableIntegrations: DeploymentIntegration[] = integrations.deployable.map(integration => {
    return {
      ...integration,
      deployment: orgDeployments?.find(dep => dep.integrations?.some(i => i.guid === integration.guid)),
      compatible: getCompatibleIntegration(supplemental, integration)
    };
  });
  const deployedIntegration = deployableIntegrations.find(integration => integration.guid === deployedEnvironment);

  const isNative = analytic.is_native;

  const shouldTranslateLeft = React.useMemo(() => {
    if (isVersionNative(leftVersion) && isVersionAParentNative(leftVersion)) {
      return false;
    }
    if (!isVersionNative(leftVersion) && !isVersionAParentNative(leftVersion)) {
      return true;
    }
    if (selectedLanguageId !== previousLanguageId.current) return true;
    if (leftId !== previousLeftId.current && leftVersion?.raw) return true;
  }, [isVersionNative, leftVersion, isVersionAParentNative, selectedLanguageId, leftId]);

  const shouldTranslateRight = React.useMemo(() => {
    if (isVersionNative(rightVersion) && isVersionAParentNative(rightVersion)) return false;
    if (!isVersionNative(rightVersion) && !isVersionAParentNative(rightVersion)) return true;
    if (selectedLanguageId !== previousLanguageId.current) return true;
    if (rightId !== previousRightId.current && rightVersion?.raw) return true;
  }, [isVersionNative, rightVersion, isVersionAParentNative, selectedLanguageId, rightId]);

  const {
    translation: leftTranslation,
    status: leftTranslationStatus,
    error: leftTranslationError
  } = useRawTranslation(shouldTranslateLeft, leftVersion?.raw, null, selectedLanguageId);
  const {
    translation: rightTranslation,
    status: rightTranslationStatus,
    error: rightTranslationError
  } = useRawTranslation(shouldTranslateRight, rightVersion?.raw, null, selectedLanguageId);

  const isPending =
    [languageListStatus, integrationsStatus, analyticStatus].some(s => s === Status.pending) && !!supplemental;

  const analyticLanguageId = supplemental?.source_analytic_compilation_target_id;

  const getLanguageName = React.useCallback(
    (id: Ident): string => {
      return languageList.find(l => l.id === id)?.name ?? 'Unknown Language';
    },
    [languageList]
  );

  const integrationOptions: IntegrationOption[] = React.useMemo(() => {
    if (isNative) {
      const detectionSupportedPlatforms = getPlatformDetails(supplemental?.source_analytic_compilation_target_id);
      const integrationOptions = detectionSupportedPlatforms?.platforms
        .filter(p => !!p.integration)
        .map(p => mapIntegrationToOption(p.integration));
      // we have at least one integration that supports this detection. show the integrations
      if (integrationOptions?.length) return integrationOptions;
      if (detectionSupportedPlatforms?.platforms.length) {
        // user doesn't have integrations but snapattack has some that suppoort the language
        return getIntegrationOptionsFromPlatformEntries(
          Object.entries(
            pick(
              snapattackSupportedPlatforms,
              detectionSupportedPlatforms.platforms.map(p => p.integrationType)
            )
          ),
          true
        );
      } else {
        // native detection with no integration support within SnapAttack
        const id = supplemental?.source_analytic_compilation_target_id;
        const name = getLanguageName(id);
        return [
          {
            label: name,
            type: name,
            value: `language-${id}`
          }
        ];
      }
      return [];
    } else {
      // sigma detection
      return sigmaDetectionIntegrationOptions;
    }
  }, [
    isNative,
    getPlatformDetails,
    supplemental?.source_analytic_compilation_target_id,
    getIntegrationOptionsFromPlatformEntries,
    snapattackSupportedPlatforms,
    getLanguageName,
    sigmaDetectionIntegrationOptions
  ]);

  const languages = useLanguageOptions(
    selectedIntegrationOptionValue,
    analytic.analytic_compilation_targets,
    analytic.recompile_in_progress
  );

  const languageOptions = React.useMemo(() => {
    if (isNative) {
      return allLanguages
        .filter(l => l.id === supplemental?.source_analytic_compilation_target_id)
        .map(l => ({
          value: l.id.toString(),
          content: l.name,
          label: l.name,
          warning: false
        })) as IntegrationLanguageOption[];
    } else {
      return languages.sort((a, b) => -b.warning - -a.warning);
    }
  }, [isNative, allLanguages, supplemental?.source_analytic_compilation_target_id, languages]);
  const selectedLanguage = getLanguageById(selectedLanguageId);

  const shouldShowLanguageOptions = React.useMemo(() => {
    return !isNative || (!isNative && analyticLanguageId !== CompilationTargetId.Sigma);
  }, [isNative, analyticLanguageId]);

  const getDefaultIntegration = React.useCallback(() => {
    const syntheticIntegration = getSyntheticSnapattackIntegration(allLanguages);
    const integrationsWithSynthetic = [...integrations.all, syntheticIntegration];
    const defaultIntegrationValue = `language-${CompilationTargetId.Sigma.toString()}`;

    if (analyticLanguageId !== CompilationTargetId.Sigma) {
      return getIntegrationValueFromLanguageId(
        analyticLanguageId,
        integrationOptions,
        integrationsWithSynthetic,
        snapattackSupportedPlatforms
      );
    }

    const isDefaultLanguageIncluded = analytic?.analytic_compilation_targets.includes(defaultLanguageId);
    return isDefaultLanguageIncluded
      ? getIntegrationValueFromLanguageId(
          defaultLanguageId,
          integrationOptions,
          integrationsWithSynthetic,
          snapattackSupportedPlatforms
        )
      : defaultIntegrationValue;
  }, [
    analyticLanguageId,
    defaultLanguageId,
    analytic?.analytic_compilation_targets,
    integrationOptions,
    integrations.all,
    allLanguages,
    snapattackSupportedPlatforms
  ]);

  const getDefaultLanguageId = React.useCallback(() => {
    // Handle native language cases
    if (isNative) {
      const matchingLanguage = languageOptions.find(option => option.value === analyticLanguageId?.toString());
      if (matchingLanguage) {
        return parseInt(matchingLanguage.value, 10);
      }
    }

    // Fallback logic
    const defaultLanguageOption = languageOptions.find(l => l.value === defaultLanguageId?.toString() && !l.warning);
    if (defaultLanguageOption) {
      return defaultLanguageId;
    }

    const validLanguage = languageOptions.find(language => !language.warning)?.value;
    return validLanguage ? parseInt(validLanguage, 10) : parseInt(languageOptions[0]?.value, 10);
  }, [isNative, analyticLanguageId, languageOptions, defaultLanguageId]);

  React.useEffect(() => {
    if (analytic.analytic_compilation_targets && analyticLanguageId) {
      setSelectedIntegrationOptionValue(getDefaultIntegration());
    }
  }, [
    analytic.analytic_compilation_targets,
    analyticLanguageId,
    defaultLanguageId,
    getDefaultIntegration,
    integrationOptions,
    isNative
  ]);

  React.useEffect(() => {
    if (languageOptions.length > 0) {
      const newLanguageId = getDefaultLanguageId();
      setSelectedLanguageId(newLanguageId);
    }
  }, [getDefaultLanguageId, languageOptions.length]);

  const disableIntegrationOption = integrationOptions.length <= 1;
  const disableLanguageOption = languageOptions.length <= 1;

  const leftContent = React.useMemo(() => {
    if (selectedLanguageId) {
      if (isTranslated || shouldTranslateLeft) {
        return leftTranslation || '';
      } else {
        return leftVersion?.raw || '';
      }
    }
  }, [isTranslated, leftTranslation, leftVersion?.raw, selectedLanguageId, shouldTranslateLeft]);

  const rightContent = React.useMemo(() => {
    if (selectedLanguageId) {
      if (isTranslated || shouldTranslateRight) {
        return rightTranslation || '';
      } else {
        return rightVersion?.raw || '';
      }
    }
  }, [isTranslated, rightTranslation, rightVersion?.raw, selectedLanguageId, shouldTranslateRight]);

  React.useEffect(() => {
    if (leftTranslationError) pushSnack(leftTranslationError, 'warning', 'center', 'bottom');
    if (rightTranslationError) pushSnack(rightTranslationError, 'warning', 'center', 'bottom');
  }, [leftTranslationError, rightTranslationError, pushSnack]);
  const existingAnalytic = allVersion.filter(v => v.guid === supplemental?.guid);
  const parentAnalytic = allVersion.filter(v => v.guid === supplemental?.parent?.guid);
  const isDeployable = allVersion?.filter(v => v.guid === supplemental?.guid)?.[0]?.id === rightVersion?.id;

  function filterByCurrentOrParentVersions(version?: AnalyticVersion): AnalyticVersion[] {
    if (existingAnalytic.some(c => c.id === version?.id)) {
      return existingAnalytic;
    } else {
      return parentAnalytic;
    }
  }

  function handleDeploySuccess() {
    supplementalRefresh();
    push(`${Path.Detection}/${supplemental?.guid}`);
  }

  function handleIntegrationChange(value: string) {
    setIsTranslated(true);
    setSelectedIntegrationOptionValue(value);
  }

  function handleLanguageChange(value: string | Option[]) {
    setIsTranslated(true);
    setSelectedLanguageId(Number.parseInt(value as string, 10));
  }

  return (
    <IDETranslationStateProvider>
      <VersionDiff>
        <AnalyticVersionList
          open={true}
          versions={allVersion}
          versionStatus={versionStatus}
          guid={supplemental?.guid}
          parentGuid={supplemental?.parent?.guid}
        />
        <div className='Version-content'>
          <Page className='Version-details'>
            <div className='Version-faux-table-header'>
              <AnalyticDiffSelector
                busy={leftTranslationStatus === Status.pending}
                title={
                  leftVersion ? (
                    `Version ${
                      filterByCurrentOrParentVersions(leftVersion).length -
                      filterByCurrentOrParentVersions(leftVersion).indexOf(leftVersion)
                    }`
                  ) : (
                    <Placeholder variant='text' width={50} />
                  )
                }
                subtitle={
                  leftVersion ? (
                    `${leftVersion?.created_by?.name} - ${formatCustomTimestamp(leftVersion?.creation, STANDARD)}`
                  ) : (
                    <Placeholder variant='text' width={100} />
                  )
                }
              />
              <AnalyticDiffSelector
                busy={rightTranslationStatus === Status.pending}
                title={
                  rightVersion ? (
                    `Version ${
                      filterByCurrentOrParentVersions(rightVersion).length -
                      filterByCurrentOrParentVersions(rightVersion).indexOf(rightVersion)
                    }`
                  ) : versionStatus !== Status.pending ? (
                    'Version ?'
                  ) : (
                    <Placeholder variant='text' width={50} />
                  )
                }
                subtitle={
                  rightVersion ? (
                    `${rightVersion?.created_by?.name} - ${formatCustomTimestamp(rightVersion?.creation, STANDARD)}`
                  ) : versionStatus !== Status.pending ? (
                    'make another version selection'
                  ) : (
                    <Placeholder variant='text' width={100} />
                  )
                }
              >
                <div className='Version-header-options'>
                  {isDeployable && !isPending && selectedLanguageId && (
                    <DeployIntegrationButton
                      guid={supplemental?.guid}
                      existingDeployment={orgDeployments?.[0]}
                      integration={deployedIntegration}
                      supplemental={supplemental}
                      onDeploy={handleDeploySuccess}
                      showIfDisabled
                    >
                      {({ disabled, handleClickDeploy }) => (
                        <VersionDeployButton disabled={disabled} onClick={handleClickDeploy}>
                          Deploy
                        </VersionDeployButton>
                      )}
                    </DeployIntegrationButton>
                  )}
                </div>
              </AnalyticDiffSelector>
            </div>
            <ReactDiffViewer
              oldValue={leftContent}
              newValue={rightContent}
              splitView={true}
              showDiffOnly={false}
              useDarkTheme
              styles={{
                variables: {
                  dark: {
                    diffViewerTitleBackground: palette.background.paper,
                    diffViewerTitleColor: palette.greyish.main,
                    gutterBackground: palette.surface.auxiliaryTab,
                    diffViewerBackground: palette.surface.auxiliaryTab,
                    emptyLineBackground: palette.background.default,
                    codeFoldBackground: palette.background.paper,
                    codeFoldGutterBackground: palette.background.paper,
                    codeFoldContentColor: palette.primary.main
                    // addedGutterBackground: palette.lime.main,
                    // addedBackground: palette.lime.main,
                    // wordAddedBackground: palette.lime.light,
                    // addedColor: palette.getContrastText(palette.lime.dark)
                  }
                }
                /** various styles can be overridden here as well.
                 * But we are limited in what we can target and what
                 * selectors are available. See Analytic.style VersionDiff
                 * to see how we can target with wildcards */
              }}
            />
          </Page>
          <StyledToolbar className='Language-diff-toolbar'>
            <div className='Version-header-options'>
              {shouldShowLanguageOptions && selectedLanguageId && (
                <>
                  <StyledAutocomplete
                    name='LanguageSelection'
                    onChange={handleLanguageChange}
                    options={languageOptions}
                    value={getDisplayValue(languageOptions, selectedLanguage?.id.toString())}
                    disabled={
                      leftTranslationStatus === Status.pending ||
                      rightTranslationStatus === Status.pending ||
                      disableLanguageOption
                    }
                    disableUserAdditions
                    disableGroupSelect
                    disableClearable
                    isOptionEqualToValue={(option: Option, value: Option) => option.value === value.value}
                    PaperComponent={StyledAutocompletePaper}
                  />
                  <StyledIntegrationAutocomplete
                    className='IntegrationSelection'
                    onChange={handleIntegrationChange}
                    name='IntegrationSelection'
                    value={selectedIntegrationOptionValue}
                    disabled={disableIntegrationOption}
                    disableGroupSelect
                    disableClearable
                    disableUserAdditions
                    options={integrationOptions}
                  />
                </>
              )}
            </div>
          </StyledToolbar>
        </div>
      </VersionDiff>
    </IDETranslationStateProvider>
  );
}
