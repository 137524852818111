import React from 'react';

import { useAlphaSetting } from './';

export function CanAlpha({
  children,
  feature
}: React.PropsWithChildren<{ feature: Parameters<typeof useAlphaSetting>[0] }>): JSX.Element | null {
  const { enableAlpha } = useAlphaSetting(feature);

  if (!enableAlpha) return null;
  return <>{children}</>;
}
