import React from 'react';

import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '@mui/material';
import { default as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import MuiDialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';

import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

export { DialogActions, DialogContentText } from '@mui/material';
export { default as FormDialog } from './FormDialog';

export type DialogProps = MuiDialogProps;
export type DialogTitleProps = MuiDialogTitleProps;

export const Dialog = MuiDialog;

const DialogContentContainer = styled('div')`
  padding-top: 8px;
`;

export function DialogContent({ children, ...props }: DialogContentProps): JSX.Element {
  // we have to wrap DialogContent to provide padding so form labels aren't cut off
  // we can't set the padding on DialogContent-root directly because DialogTitle overrides it
  // https://github.com/mui/snap-ui/issues/26795#issuecomment-873532990
  return (
    <MuiDialogContent {...props}>
      <DialogContentContainer>{children}</DialogContentContainer>
    </MuiDialogContent>
  );
}

const Subtitle = styled('p')`
  font-size: 75%;
  font-weight: normal;
`;

export function DialogTitle({
  children,
  onClose,
  subtitle
}: {
  children: DialogTitleProps['children'];
  onClose?: DialogProps['onClose'];
  subtitle?: string;
}): JSX.Element {
  function handleClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    onClose(event, 'backdropClick');
  }

  return (
    <MuiDialogTitle>
      {children}
      {onClose && (
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            color: theme => theme.palette.secondary.light,
            position: 'absolute',
            right: 8,
            top: 8,
            height: 40,
            width: 40
          }}
        >
          <Icon icon={faClose} />
        </IconButton>
      )}
      {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
    </MuiDialogTitle>
  );
}
