import React from 'react';

import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import Divider from 'snap-ui/Divider';
import { styled } from 'snap-ui/util';

import RjsfFormik from 'module/Form/RjsfFormik';

import { INTEGRATION_SCHEMA_TABS, IntegrationFormData } from '../Integration.type';
import { getTabIntegrationSchema } from '../Integration.util';
import IntegrationConfidenceTailoring from '../IntegrationConfidenceTailoring/IntegrationConfidenceTailoring';
import ApiFields from './ApiFields';
import DeploymentField from './DeploymentField';
import ImportField from './ImportField';
import MetadataFields from './MetadataFields';
import SearchFields from './SearchFields';
import { IntegrationFormFieldProps } from './TabFormFields';

const InlineFormFieldsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.spacing(4)};
  gap: ${p => p.theme.spacing(4)};
`;

function InlineFormFields({
  integrationGuid,
  jobSchema,
  integrationSchema,
  getLanguageOptions,
  setType
}: Pick<
  IntegrationFormFieldProps,
  'integrationSchema' | 'getLanguageOptions' | 'integrationGuid' | 'jobSchema' | 'setType'
>) {
  const { values } = useFormikContext<IntegrationFormData>();
  const languageOptionLists = getLanguageOptions(values.type);

  const tabIntegrationSchema = getTabIntegrationSchema(integrationSchema);

  return (
    <InlineFormFieldsContainer>
      <MetadataFields />
      <ApiFields integrationSchema={tabIntegrationSchema(INTEGRATION_SCHEMA_TABS.Configuration)} setType={setType} />
      <Divider textAlign='left'>Search</Divider>
      <SearchFields languageOptionLists={languageOptionLists} />
      <IntegrationConfidenceTailoring integration_guid={integrationGuid} jobSchema={jobSchema} />
      <Divider textAlign='left'>Deployment</Divider>
      <DeploymentField
        languageOptionLists={languageOptionLists}
        integrationSchema={tabIntegrationSchema(INTEGRATION_SCHEMA_TABS.Deployment)}
      />
      <ImportField integrationSchema={tabIntegrationSchema(INTEGRATION_SCHEMA_TABS.Import)} />
      {!isEmpty(integrationSchema) && (
        <>
          <Divider textAlign='left'>Indicators</Divider>
          <RjsfFormik
            name='extra_information'
            schema={tabIntegrationSchema(INTEGRATION_SCHEMA_TABS.Indicators)}
            preserve
          />
        </>
      )}
    </InlineFormFieldsContainer>
  );
}

export default InlineFormFields;
