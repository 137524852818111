import React from 'react';

import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { Stepper as MuiStepper, Step, StepButton, StepLabel } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import CircularProgress from './CircularProgress';
import Paper from './Paper';

export type StepDetail = {
  label: string;
  content?: JSX.Element;
};

type StepperProps = {
  buttons?: boolean;
  steps: StepDetail[];
  vertical?: boolean;
  nonLinear?: boolean;
  clickableSteps?: { [k: string]: boolean };
  onClick?(step: number): void;
  onFinish?(): void;
  savingStep?: boolean;
};

const StepContentContainer = styled(Paper, { label: 'StepContentContainer' })`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  flex-basis: 400px;
`;

const ActionsContainer = styled('div', { label: 'ActionButtonContainer' })`
  margin-top: ${p => p.theme.spacing(3)};
  display: flex;
  justify-content: flex-end;
  gap: ${p => p.theme.spacing(3)};
  /* TODO: these should be adjusted; we are running into the Userback feedback widget */
  margin-bottom: ${p => p.theme.spacing(4)};
  margin-right: 100px;

  .action-button {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
  }
`;

function Stepper({ buttons, nonLinear, steps, vertical, clickableSteps, onClick, onFinish, savingStep }: StepperProps) {
  const [activeStep, setActiveStep] = React.useState(0);
  const orientation = vertical ? 'vertical' : 'horizontal';

  const isLastStep = steps.length - 1 === activeStep;
  const handleStep = (step: number) => () => {
    onClick && onClick(activeStep);
    setActiveStep(step);
  };

  const handleNext = () => {
    onClick && onClick(activeStep);
    if (activeStep < steps.length - 1) setActiveStep(activeStep => activeStep + 1);
  };

  const handleBack = () => {
    onClick && onClick(activeStep);
    if (activeStep > 0) setActiveStep(activeStep => activeStep - 1);
  };

  if (isEmpty(steps)) return null;
  return (
    <>
      <MuiStepper className='Stepper' activeStep={activeStep} nonLinear={nonLinear} orientation={orientation}>
        {steps.map((step, index) => {
          const stepIsClickable = clickableSteps ? clickableSteps[step.label] : nonLinear;
          return (
            <Step key={`${step.label}_${index}`}>
              {stepIsClickable ? (
                <StepButton onClick={handleStep(index)}>{step.label}</StepButton>
              ) : (
                <StepLabel>{step.label}</StepLabel>
              )}
            </Step>
          );
        })}
      </MuiStepper>
      <StepContentContainer className='StepContent' elevation={2}>
        {steps[activeStep].content}
        <ActionsContainer>
          {buttons && (
            <>
              <Button className='action-button' onClick={handleBack} disabled={activeStep === 0} variant='outlined'>
                <Icon icon={faCaretLeft} />
                Back
              </Button>
              {!isLastStep && (
                <Button className='action-button' onClick={handleNext}>
                  {savingStep && activeStep !== 0 ? (
                    <>
                      Saving
                      <CircularProgress size={16} color='secondary' />
                    </>
                  ) : (
                    <>
                      Next
                      <Icon icon={faCaretRight} />
                    </>
                  )}
                </Button>
              )}
              {isLastStep && onFinish && (
                <Button className='action-button' onClick={() => onFinish()}>
                  Finish
                </Button>
              )}
            </>
          )}
        </ActionsContainer>
      </StepContentContainer>
    </>
  );
}

export default Stepper;
