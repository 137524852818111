import React, { ReactElement } from 'react';

import Checkbox from 'snap-ui/Checkbox';
import { FormControlLabel } from 'snap-ui/FormControl';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { Marker } from 'types/marker';

import FilterSlider from './FilterSlider';

type GraphFiltersProps = {
  maxTime: number;
  minTime: number;
  setTime: (min: number, max: number) => void;
  sessionStart: number;
  sessionEnd: number;
  marker: Marker[];
  collapseNodes: boolean;
  setCollapseNodes: (value: boolean) => void;
  totalNodes: number;
  unfilteredNodes: number;
};

const Container = styled('div')`
  padding: ${p => p.theme.spacing(2, 5)};

  .end-justified {
    display: flex;
    justify-content: flex-end;
  }
`;

const GraphFilters = ({
  maxTime,
  minTime,
  setTime,
  sessionStart,
  sessionEnd,
  collapseNodes,
  setCollapseNodes,
  totalNodes,
  unfilteredNodes
}: GraphFiltersProps): ReactElement => {
  const [range, setRange] = React.useState<number[]>([minTime, maxTime]);
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const handleChangeDebounce = (e: Event, value: number[]) => {
    clearTimeout(timeoutRef.current);
    setRange(value);
    timeoutRef.current = setTimeout(() => setTime(value[0], value[1]), 500);
  };

  return (
    <Container>
      <div className='end-justified'>
        <Typography variant='h4'>
          {unfilteredNodes}/{totalNodes} Nodes
        </Typography>
      </div>
      <FilterSlider
        label='Time'
        maxValue={sessionEnd}
        minValue={sessionStart}
        value={range}
        onChange={handleChangeDebounce}
      />
      <FormControlLabel
        control={<Checkbox onChange={(): void => setCollapseNodes(!collapseNodes)} checked={collapseNodes} />}
        label='Expand/Collapse nodes when filtered'
      />
    </Container>
  );
};

export default GraphFilters;
