import React, { ReactElement } from 'react';

import classnames from 'classnames';

import Card, { CardContent } from 'snap-ui/Card';
import Divider from 'snap-ui/Divider';
import { styled } from 'snap-ui/util';

import { insert } from 'utilities/ArrayUtils';

import { splitCondition, validateExpression } from './Detection.util';
import DragBox from './DragBox';
import Target from './Target';

const OPS = [
  { name: 'and', label: 'and', type: 'OP' },
  { name: 'or', label: 'or', type: 'OP' },
  { name: 'not', label: 'not', type: 'OP' },
  { name: 'openParen', label: '(', type: 'OP' },
  { name: 'closeParen', label: ')', type: 'OP' }
] as const;

export const OPERANDS = OPS.map(o => o.label);

type DragDropConditionProps = {
  sections: string[];
  condition: string;
  onChange: (condition: string) => void;
  disabled?: boolean;
};

const AbConditionBuilder = styled(Card)`
  margin-bottom: ${p => p.theme.spacing(6)};

  &.disabled {
    opacity: 0.95;

    .drag-target {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  div.drag-actions,
  div.drag-container {
    padding: ${p => p.theme.spacing(3)};
  }

  .drag-list {
    margin-bottom: ${p => p.theme.spacing(2)};
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .drag-container {
    display: flex;
    flex-direction: column;
  }

  .dragbox {
    display: inline-block;
    padding: ${p => p.theme.spacing(0, 2)};
    margin: ${p => p.theme.spacing(0, 1)};
    cursor: move;
    width: fit-content;
  }

  .drag-target {
    margin-top: ${p => p.theme.spacing(1)};
    margin-bottom: ${p => p.theme.spacing(1)};
    padding: ${p => p.theme.spacing(0, 2)};
    display: inline-flex;
    align-items: center;
    text-align: center;

    &.droppable-target {
      background-color: ${p => p.theme.palette.grey[700]};
      height: ${p => p.theme.spacing(9)};
      width: ${p => p.theme.spacing(9)};
      margin-left: ${p => p.theme.spacing(1)};
      margin-right: ${p => p.theme.spacing(1)};
      line-height: 1.25;
    }
  }

  .operand-box {
    background-color: ${p => p.theme.palette.primary.main};
    color: ${p => p.theme.palette.primary.contrastText};
  }

  .section-box {
    background-color: ${p => p.theme.palette.secondary.dark};
  }

  small.expression-error {
    color: ${p => p.theme.palette.error.main};
  }
`;

function DragDropCondition({ sections, condition, onChange, disabled }: DragDropConditionProps): ReactElement {
  const [droppedBoxNames, setDroppedBoxNames] = React.useState<string[]>([]);
  const [expressionError, setExpressionError] = React.useState<string>();

  React.useEffect(() => {
    setDroppedBoxNames(splitCondition(condition));
    const expressionError = validateExpression(condition);
    setExpressionError(expressionError);
  }, [condition]);

  function handleDrop(item: { name: string }, index: number): void {
    const { name } = item;
    const newDroppedBoxNames = droppedBoxNames.slice();
    if (name === '( )') {
      insert(newDroppedBoxNames, index, '(');
      insert(newDroppedBoxNames, index + 1, ')');
    } else {
      insert(newDroppedBoxNames, index, name);
    }
    setDroppedBoxNames(newDroppedBoxNames);
    handleChange(newDroppedBoxNames);
  }

  function handleChange(newDroppedBoxNames: string[]): void {
    let condition = '';
    newDroppedBoxNames.forEach(name => {
      if (name === '(') {
        condition = condition.concat(name);
      } else {
        condition = condition.concat(`${name} `);
      }
    });

    const expressionError = validateExpression(condition);
    setExpressionError(expressionError);

    onChange(condition);
  }

  function handleRemove(index: number): void {
    const newDroppedBoxNames = [...droppedBoxNames];
    newDroppedBoxNames.splice(index, 1);
    setDroppedBoxNames(newDroppedBoxNames);
    handleChange(newDroppedBoxNames);
  }

  return (
    <AbConditionBuilder className={classnames({ disabled })} elevation={6}>
      {!disabled && (
        <>
          <CardContent className='drag-actions'>
            <div className='drag-list'>
              Logic Operators:{' '}
              {OPS.map(({ label, type }, index) => (
                <DragBox key={index} name={label} disabled={disabled} type={type} />
              ))}
            </div>
            <div className='drag-list'>
              Sections:{' '}
              {sections.map((name, index) => (
                <DragBox key={index} name={name} disabled={disabled} type='SECTION' />
              ))}
            </div>
          </CardContent>
          <Divider />
        </>
      )}
      <CardContent className='drag-container'>
        <div className='drag-list'>
          Expression:{' '}
          <Target
            accept={['OP', 'SECTION']}
            onDrop={(item): void => handleDrop(item, 0)}
            droppedItem={null}
            isOperand={false}
          />
          {droppedBoxNames.map((droppedBoxName, index) => (
            <React.Fragment key={index}>
              <Target
                accept={[]}
                onDrop={(item): void => handleDrop(item, 0)}
                droppedItem={droppedBoxName}
                isOperand={OPERANDS.includes(droppedBoxName as (typeof OPERANDS)[number])}
                onClick={(): void => handleRemove(index)}
                disabled={disabled}
              />
              <Target
                accept={['OP', 'SECTION']}
                onDrop={(item): void => handleDrop(item, index + 1)}
                droppedItem={null}
                isOperand={false}
              />
            </React.Fragment>
          ))}
        </div>
        {expressionError && <small className='expression-error'>{expressionError}</small>}
      </CardContent>
    </AbConditionBuilder>
  );
}

export default DragDropCondition;
