import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';

import { FilterableCollections } from 'module/Collection/Collection.type';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { CheckboxControl, IconFormControlLabel } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue } from '../GlobalFilter.util';

type ContentTypeKeys = {
  contentType: FilterableCollections[];
};

type ContentTypeFilterProps = {
  onChange(values: Partial<ContentTypeKeys>): void;
  values: ContentTypeKeys;
};

function ContentTypeFilter({ onChange, values }: ContentTypeFilterProps): JSX.Element {
  function handleChange(value: FilterableCollections) {
    return function (e: React.SyntheticEvent<Element, Event>, checked: boolean) {
      const contentType = addOrRemoveValue(checked, values.contentType, value);
      onChange({ contentType });
    };
  }

  const { contentType } = values;

  return (
    <CheckboxControl>
      <FormLabel id='content-type-toggle-button-group-label'>By Content Type</FormLabel>
      <FormGroup aria-labelledby='content-type-toggle-button-group-label'>
        <IconFormControlLabel
          key={'contentTypeCollection'}
          control={
            <Checkbox
              onChange={handleChange(FilterableCollections.Static)}
              value={FilterableCollections.Static}
              checked={contentType.includes(FilterableCollections.Static)}
            />
          }
          label={
            <>
              <Icon.Collection width={16} /> Collection
            </>
          }
        />
        <IconFormControlLabel
          key={'contentTypeActor'}
          control={
            <Checkbox
              onChange={handleChange(FilterableCollections.Actor)}
              value={FilterableCollections.Actor}
              checked={contentType.includes(FilterableCollections.Actor)}
            />
          }
          label={
            <>
              <Icon.Actor width={16} /> Actor
            </>
          }
        />
        <IconFormControlLabel
          key={'contentTypeAttack'}
          control={
            <Checkbox
              onChange={handleChange(FilterableCollections.Attack)}
              value={FilterableCollections.Attack}
              checked={contentType.includes(FilterableCollections.Attack)}
            />
          }
          label={
            <>
              <Icon.Attack width={16} /> ATT&CK
            </>
          }
        />
        <IconFormControlLabel
          key={'contentTypeSoftware'}
          control={
            <Checkbox
              onChange={handleChange(FilterableCollections.Software)}
              value={FilterableCollections.Software}
              checked={contentType.includes(FilterableCollections.Software)}
            />
          }
          label={
            <>
              <Icon.Software width={16} /> Software
            </>
          }
        />
        <IconFormControlLabel
          key={'contentTypeVulnerability'}
          control={
            <Checkbox
              onChange={handleChange(FilterableCollections.Vulnerability)}
              value={FilterableCollections.Vulnerability}
              checked={contentType.includes(FilterableCollections.Vulnerability)}
            />
          }
          label={
            <>
              <Icon.Vulnerability width={16} /> Vulnerability
            </>
          }
        />
      </FormGroup>
    </CheckboxControl>
  );
}

function checkForCompleteSet(values: ContentTypeKeys): boolean {
  return (
    !values.contentType?.length ||
    Object.values(FilterableCollections).every(type => values.contentType?.includes(type))
  );
}

function toQuery(values: ContentTypeKeys): Query {
  if (checkForCompleteSet(values))
    return {
      field: 'type',
      op: Ops.not,
      value: ['datasource'],
      case_sensitive: false
    };

  return {
    field: 'type',
    op: Ops.in,
    value: values.contentType,
    case_sensitive: false
  };
}

const fromQuery = zod.union([
  zod
    .object({
      field: zod.literal('type'),
      op: zod.literal(Ops.not),
      value: zod.array(zod.literal('datasource'))
    })
    .transform(() => ({
      contentType: [] as FilterableCollections[]
    })),
  zod
    .object({
      field: zod.literal('type'),
      op: zod.nativeEnum(Ops),
      value: zod.array(zod.string())
    })
    .transform(query => ({
      contentType: query.value as FilterableCollections[]
    }))
]);

const ContentTypeFilterConfig: FilterConfig<ContentTypeKeys> = {
  defaults: { default: () => ({ contentType: Object.values(FilterableCollections) }) },
  supportedTopics: [ArtifactType.Collection],
  component: ContentTypeFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default ContentTypeFilterConfig;
