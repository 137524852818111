import axios, { CancelToken as AxCancelToken, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { EventSourcePolyfill } from 'event-source-polyfill';

import config from 'config/config';

import { formatQueryString } from 'utilities/SearchParam';

const snapattack = axios.create({
  baseURL: config.SNAPATTACK_API_URL,
  paramsSerializer(params) {
    // use slice to remove '?' from the front of the formatted string
    // because it's added later by axios
    return formatQueryString(params).slice(1);
  },
  validateStatus(status) {
    return status < 400;
  }
});

// eslint-disable-next-line
// @ts-ignore
const snapattackData = snapattack['create']({
  baseURL: config.SNAPATTACK_DATA_URL,
  headers: {
    ['Osd-Xsrf']: 'osd-fetch'
  }
});

const snapattackDocument = axios.create({
  baseURL: config.SNAPATTACK_DOC_URL
});

export { snapattack, snapattackData, snapattackDocument };

export function eventSource(channel?: string): EventSourcePolyfill {
  let url = `${config.BASE_HOST_URL}/stream`;
  if (channel) {
    url += `?channel=${channel}`;
  }

  return new EventSourcePolyfill(url, {
    heartbeatTimeout: 180000
  });
}
export const CancelToken = axios.CancelToken;
export type CancelTokenType = AxCancelToken;
export type CancelTokenSourceType = CancelTokenSource;
export type AxiosRequestConfigType = AxiosRequestConfig;

export type ApiErrorResponse = { detail?: string | string[] };

export type ValidationError = {
  detail: (string | JSX.Element)[];
};

export type ValidationResponse = void | ValidationError;

function wrapRawError(error: any) {
  let detail = 'Oops! ';

  if (error?.name === 'ZodError') {
    detail += 'That type was unexpected';
    console.error(error);
  } else detail += error?.message || 'This was unexpected';

  return { detail: [detail] };
}

export function asValidationError(error: any): ValidationError {
  // Look for detail and return otherwise, wrap it
  if (typeof error?.response?.data === 'object' && 'detail' in error.response.data) return error.response.data;
  if (typeof error?.response === 'object' && 'detail' in error.response) return error.response;
  if (typeof error === 'object' && 'detail' in error) return error;

  return wrapRawError(error);
}
