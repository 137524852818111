import { CancelTokenSource } from 'axios';

import { snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';
import { CapAttackZip, CapturePayload, CaptureStatus } from 'types/harbor';

const prefix = '/harbor/sessions/import/local/sessions';

export async function postCapture(
  { files, ...others }: CapturePayload,
  onUploadProgress: (event: ProgressEventInit) => void,
  cancelTokenSource: CancelTokenSource
): Promise<{ task_id: Guid }> {
  const fd = new FormData();

  fd.append('session_options', JSON.stringify(others));

  files?.forEach(file => {
    fd.append('session_files', file);
  });

  return snapattack
    .post(`${prefix}/`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress,
      cancelToken: cancelTokenSource.token
    })
    .then(r => r.data);
}

export async function getCapAttackZips(): Promise<CapAttackZip[]> {
  return snapattack.get(`${prefix}/capattack/`).then(zips => zips.data);
}

export async function deleteCapAttackZips(capAttackZip: CapAttackZip): Promise<CapAttackZip[]> {
  return snapattack
    .delete(`${prefix}/capattack/`, {
      data: capAttackZip.filename
    })
    .then(zips => zips.data);
}

export async function pingCaptureStatus(task_id: Guid): Promise<CaptureStatus> {
  return snapattack.get(`${prefix}/${task_id}/`).then(res => res.data);
}
