import React from 'react';

import useFieldOptions from 'module/Analytic/core/Detection/useFieldOptions';
import LogContent from 'module/MatchingLog/LogContent';
import { useReverseMapsForIntegration } from 'module/ReverseMap';

import { LogRecord } from 'types/ide';

import { caseInsensitiveStringEqual } from 'utilities/StringUtils';

import { useIDEState } from '../';

export function IDELogContent({ log }: { log: LogRecord }): JSX.Element {
  const mappedFields = React.useRef(new Map<string, string>());
  const { ideState, dispatch } = useIDEState();
  const [fieldOptions] = useFieldOptions();

  const { maps: reverseFieldMaps } = useReverseMapsForIntegration(log['metadata.snapattack_integration_guid']);

  function getSigmaFieldName(sourceFieldName: string) {
    if (!reverseFieldMaps.length) return sourceFieldName;
    if (!mappedFields.current.has(sourceFieldName)) {
      mappedFields.current.set(sourceFieldName, sourceFieldName);
      for (const reverseMap of reverseFieldMaps) {
        if (sourceFieldName in reverseMap) {
          let sigmaFieldName = reverseMap[sourceFieldName][0];
          for (const fieldOption of fieldOptions) {
            if (caseInsensitiveStringEqual(sigmaFieldName, fieldOption.field)) {
              sigmaFieldName = fieldOption.field;
            }
          }
          mappedFields.current.set(sourceFieldName, sigmaFieldName);
          break;
        }
      }
    }
    return mappedFields.current.get(sourceFieldName);
  }

  const disableLogClick = ideState.isNative
    ? true
    : !!(
        ideState.analyticForm.logsource?.type &&
        (ideState.analyticForm.logsource?.category || ideState.analyticForm.logsource?.service)
      );

  function getTooltip(key: string): string {
    if (ideState.isNative) return undefined;
    if (disableLogClick) return 'Select a log source to get started';
    const sigmaFieldName = getSigmaFieldName(key);
    if (sigmaFieldName !== key) return `Add ${key} to the detection logic as '${sigmaFieldName}'`;
    return `Add ${key} to the detection logic`;
  }

  function handleLogClick(key: string, value: string) {
    dispatch({ type: 'AddFromLogUpdate', addLog: { key: getSigmaFieldName(key), value } });
  }

  return (
    <LogContent
      getTooltip={getTooltip}
      highlightProcessor={ideState.highlightProcessor}
      log={log}
      onLogClick={disableLogClick ? undefined : handleLogClick}
    />
  );
}
