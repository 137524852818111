import React from 'react';

import { DuckedArtifact } from '../Curation.type';

export type EventTypeValue = Partial<DuckedArtifact>[];
export type EventType = {
  /** Selected items in right pane */
  selectedAttachedItems: EventTypeValue;
  /** All items in right pane */
  attachedItems: EventTypeValue;
  showIntel: boolean;
  showThreat: boolean;
  showDetection: boolean;
  showAttackScript: boolean;
};

export interface EventLineInterface {
  push(e: Event): void;
  pop(): void;
  current: Event;
}

const STARTING_EVENT_TYPE: EventType = {
  attachedItems: [],
  selectedAttachedItems: [],
  showIntel: true,
  showThreat: true,
  showDetection: true,
  showAttackScript: true
};

export default function useEventLine() {
  const [pointer, setPointer] = React.useState(0);
  const [events, setEvents] = React.useState<EventType[]>([STARTING_EVENT_TYPE]);

  const push = React.useCallback(
    (event: EventType) => {
      if (pointer === events.length - 1) {
        const line = [...events, event];
        setPointer(line.length - 1);
        setEvents(line);
      } else {
        const line = [...events].slice(0, pointer + 1);
        line.push(event);
        setPointer(line.length - 1);
        setEvents(line);
      }
    },
    [events, pointer]
  );

  const pop = React.useCallback(() => {
    if (events.length > 1) {
      const line = events.slice(0, -1);
      setPointer(line.length - 1);
      setEvents(line);
    }
  }, [events]);

  const next = React.useCallback(() => {
    setPointer(Math.min(pointer + 1, events.length - 1));
  }, [events.length, pointer]);

  const prev = React.useCallback(() => {
    setPointer(Math.max(pointer - 1, 0));
  }, [pointer]);

  /**
   * Used to establish a pure line. Otherwise the first item is default empty
   */
  const initialize = React.useCallback((attachedItems: EventTypeValue) => {
    setEvents([
      {
        ...STARTING_EVENT_TYPE,
        attachedItems,
        showDetection: true,
        showIntel: true,
        showThreat: true,
        showAttackScript: true
      }
    ]);
    setPointer(0);
  }, []);

  return {
    current: events[pointer],
    push,
    pop,
    next,
    prev,
    initialize,
    floor: pointer === 0,
    ceiling: pointer === events.length - 1
  };
}
