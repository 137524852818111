import React from 'react';

import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';

import UploadFieldFormik from 'module/Form/UploadFieldFormik';
import { extractIndicatorsFromFile } from 'module/IOC/IOC.api';
import { ExtractIOCResponse, IOC } from 'module/IOC/IOC.type';

import { SetState } from 'types/core';

function getIocPayload(fileIocs: IOC[], selectedIocs: IOC[]) {
  return [
    ...selectedIocs,
    ...fileIocs.filter((ioc: IOC) => !selectedIocs.map(currentIoc => currentIoc.value).includes(ioc.value))
  ];
}

export function ScrapeIOCUploadFieldFormik({
  className,
  setIocPayload,
  setIsActing,
  setText,
  scrapeMax
}: {
  className?: string;
  setIocPayload: SetState<IOC[]>;
  setIsActing(acting: boolean): void;
  setText?: (text: string) => void;
  scrapeMax?: number;
}): JSX.Element {
  const [{ value: attachmentValue }] = useField('attachment');

  const [summarized, setSummarized] = React.useState<string[]>([]);

  React.useEffect(() => {
    const attachments = scrapeMax ? attachmentValue.slice(0, scrapeMax) : attachmentValue;

    const needsSummarization: File[] = attachments.filter(
      (file: File) => !summarized.includes(file.name) && file.name.toLowerCase().endsWith('.pdf')
    );

    if (!isEmpty(needsSummarization)) {
      setIsActing(true);
      const promises = needsSummarization.reduce((promises: Promise<ExtractIOCResponse>[], file) => {
        const promise = extractIndicatorsFromFile(file);
        return [...promises, promise];
      }, []);

      Promise.all(promises)
        .then((summaries: ExtractIOCResponse[]) => {
          const fileIocs = summaries.flatMap(summary => summary.indicators || []);
          setSummarized(summarized => [...summarized, ...needsSummarization.map(file => file.name)]);
          setIocPayload(iocPayload => getIocPayload(fileIocs, iocPayload));

          if (setText) {
            const text = summaries.flatMap(s => s.text).join('\n');
            setText(text);
          }
        })
        .finally(() => setIsActing(false));
    }
  }, [attachmentValue, scrapeMax, setIocPayload, setIsActing, setText, summarized]);

  return <UploadFieldFormik className={className} accept='.pdf' existingFilesName='existingFiles' name='attachment' />;
}
