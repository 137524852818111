import React from 'react';

import Button from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';

import { DetermineOutcomeLabel } from 'module/Widgets/StateLabel/DetermineOutcome';

import { usePushSnack } from 'provider';

import { Status, useAsync } from 'storage';

import { postDetermineOutcomes } from '../BAS.api';
import { OutcomesStatus } from '../BAS.type';
import DetermineOutcomesFormDialog from './DetermineOutcomesFormDialog';
import useDetermineOutcomesStatus from './useDetermineOutcomesStatus';

function DetermineOutcomesButton({ campaign_id, refreshPage }: { campaign_id: string; refreshPage(): void }) {
  const [openModal, setOpenModal] = React.useState(false);
  const { run, status, error } = useAsync();
  const pushSnack = usePushSnack();
  const { data, cycle } = useDetermineOutcomesStatus({ campaign_id, refreshPage });

  React.useEffect(() => {
    if (status === Status.resolved) {
      pushSnack('Determination in progress', 'info', 'center', 'bottom', 5000);
      setOpenModal(false);
      cycle(0);
      refreshPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const determineOutcomes = React.useCallback(
    (integrations: string[]) => {
      run(postDetermineOutcomes(campaign_id, { integrations }));
    },
    [run, campaign_id]
  );

  return (
    <>
      {data === null || [OutcomesStatus.Complete, OutcomesStatus.Failed].includes(data?.status as OutcomesStatus) ? (
        <Button onClick={() => setOpenModal(!openModal)}>Determine Outcomes</Button>
      ) : (
        <Tooltip title={`Outcomes are currently being completed. ${data?.current_detail}`} wrap placement='top' arrow>
          <DetermineOutcomeLabel value={data?.status} />
        </Tooltip>
      )}

      <DetermineOutcomesFormDialog
        isLoading={status === Status.pending}
        apiError={error}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        handleSubmit={handleSubmit}
      />
    </>
  );

  function handleSubmit(integrations: string[]) {
    determineOutcomes(integrations);
  }
}

export default DetermineOutcomesButton;
