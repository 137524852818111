import React from 'react';

import {
  faAngleRight,
  faArrowRotateLeft,
  faArrowRotateRight,
  faClose,
  faEraser,
  faSearch
} from '@fortawesome/pro-solid-svg-icons';
import isEqual from 'lodash/isEqual';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import BackdropLoader from 'snap-ui/BackdropLoader';
import Button, { RouterButton } from 'snap-ui/Button';
import Checkbox from 'snap-ui/Checkbox';
import FormControl from 'snap-ui/FormControl';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { ValidationError, asValidationError } from 'apis';

import Path from 'constants/paths';

import useDebounce from 'hooks/useDebounce';
import useTitle from 'hooks/useTitle';

import { CommonEvent, Engage, Fingerprint } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';
import { updateCollectionModifyItems } from 'module/Collection/Collection.api';
import { useMayI } from 'module/May';
import NotFound from 'module/Util/Fallback/NotFound';

import { Status } from 'storage';

import { ContentPermission, FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import useCollection from '../../Collection/useCollection';
import { buildAddToBulkList, buildRemoveFromBulkList } from '../Curation.service';
import { DuckedArtifact } from '../Curation.type';
import CurationConfirmDelete from '../CurationConfirmDelete';
import { PendingCount } from '../CurationCount';
import CurationGrid from '../CurationGrid';
import useCompositeSearch from '../useCompositeSearch';
import useExistingComposite from '../useExistingComposite';
import { Container } from './CurationAttach.style';
import useEventLine, { EventTypeValue } from './useEventLine';

const TOOLTIP_DELAY = 500;

const DoButton = styled(Button)`
  border: 1px solid ${p => p.theme.palette.grey[400]};
  color: ${p => p.theme.palette.grey[400]};

  &:hover {
    border: 1px solid ${p => p.theme.palette.grey[100]};
    color: ${p => p.theme.palette.grey[100]};
    background-color: hsl(0, 0%, 34%, 0.45);
  }
`;

export type CurationAttachProps = RouteComponentProps<{ guid: string }>;

export default function CurationAttach({ match }: CurationAttachProps) {
  const { replace } = useHistory();

  const guid = match.params.guid;
  const { collection, status, error } = useCollection(guid);
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  useTitle(`${collection.name || 'Collection Attach'} | SnapAttack`);

  const [allSearchedItemsCheckbox, setAllSearchedItemsCheckbox] = React.useState(false);
  const [allAttachedItemsCheckbox, setAllAttachedItemsCheckbox] = React.useState(false);

  const [search, setSearch] = React.useState('');
  const debouncedQuery = useDebounce(search);
  const { data: searched, submitQuery, isPending: isSearching } = useCompositeSearch();
  const { current, initialize, push, prev, next, floor, ceiling } = useEventLine();
  const { attachedItems, selectedAttachedItems, showIntel, showDetection, showThreat, showAttackScript } = current;

  const [selectedSearchItems, setSelectedSearchItems] = React.useState<DuckedArtifact[]>([]);
  const { existing, existingIsPending } = useExistingComposite(guid);
  const [isActing, setIsActing] = React.useState(false);
  const [actionError, setActionError] = React.useState<ValidationError>();

  React.useEffect(() => {
    initialize(existing);
  }, [existing, initialize]);

  React.useEffect(() => {
    if (collection.guid && collection.name) {
      Engage.track(
        Fingerprint.load(Path.CollectionAttach).withData({
          content: ContentPermission.Edit,
          guid: collection.guid,
          name: collection.name
        })
      );
      Engage.trackPersonIncrement(`view ${Path.CollectionAttach}`, 1);
    }
  }, [collection.guid, collection.name]);

  React.useEffect(() => {
    if (debouncedQuery.length > 0) {
      submitQuery(debouncedQuery);
      setAllSearchedItemsCheckbox(false);
      setSelectedSearchItems([]);
    }
  }, [debouncedQuery, submitQuery]);

  const visibleAttachedItems = React.useMemo(
    () =>
      attachedItems.filter(item =>
        shouldIncludeItemByTypeAndVisibility(item, showIntel, showThreat, showDetection, showAttackScript && isBasUser)
      ),
    [attachedItems, showIntel, showThreat, showDetection, showAttackScript, isBasUser]
  );

  const visibleSearchedItems = React.useMemo(
    () =>
      searched.filter(
        item =>
          !attachedItems.some(e => e.guid === item.guid) &&
          shouldIncludeItemByTypeAndVisibility(
            item as DuckedArtifact,
            showIntel,
            showThreat,
            showDetection,
            showAttackScript && isBasUser
          )
      ) as unknown as DuckedArtifact[],
    [searched, attachedItems, showIntel, showThreat, showDetection, showAttackScript, isBasUser]
  );

  const dataIsUnchanged = React.useMemo(() => {
    return isEqual(existing.sort(sortItem), attachedItems);
  }, [existing, attachedItems]);

  if (error) return <NotFound artifact={ArtifactType.Collection} error={error} />;
  const name = status === Status.pending ? 'Collection Attach' : collection.name || 'Collection Attach';

  return (
    <Container className='CurationAttach'>
      <div className='meta'>
        <Typography variant='h1'>{name}</Typography>
        <RouterButton
          variant='outlined'
          color='primary'
          to={{ pathname: `${Path.Collection}/${guid}`, state: collection }}
        >
          Cancel
        </RouterButton>
        <CurationConfirmDelete
          guid={guid}
          onDelete={() => {
            setActionError(null);
            setIsActing(true);
          }}
          onError={error => {
            setActionError(error);
            setIsActing(false);
          }}
        />
        <Button color='primary' onClick={handleSave} disabled={dataIsUnchanged}>
          Save
        </Button>
      </div>
      {actionError && <ApiError messages={actionError?.detail as string[]} />}
      <div className='transfer-meta'>
        <div className='fetch-control'>
          <TextField
            label='Search'
            className='search'
            onChange={handleSearchChange}
            InputProps={{ endAdornment: isSearching ? <Icon.SpinnerProgress /> : <Icon icon={faSearch} /> }}
          />
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Show or hide list items</FormLabel>
            <FormGroup row>
              <FormControlLabel
                className='artifact-select'
                control={<Checkbox checked={showIntel} onChange={handleToggleShowHideIntelItems} />}
                label={
                  <>
                    <Icon.Intel /> Intelligence
                  </>
                }
              />
              <FormControlLabel
                className='artifact-select'
                control={<Checkbox checked={showThreat} onChange={handleToggleShowHideThreatItems} />}
                label={
                  <>
                    <Icon.Session /> Threat
                  </>
                }
              />
              <FormControlLabel
                className='artifact-select'
                control={<Checkbox checked={showDetection} onChange={handleToggleShowHideDetectionItems} />}
                label={
                  <>
                    <Icon.Analytic /> Detection
                  </>
                }
              />
              {isBasUser && (
                <FormControlLabel
                  className='artifact-select'
                  control={<Checkbox checked={showAttackScript} onChange={handleToggleShowHideAttackScriptItems} />}
                  label={
                    <>
                      <Icon.AttackScript /> Attack Script
                    </>
                  }
                />
              )}
            </FormGroup>
          </FormControl>
        </div>
        <span className='existing-count'>
          <PendingCount active={existingIsPending} unavailable={!showIntel}>
            <Icon.Intel />
            {intelligenceSum(attachedItems)}
          </PendingCount>
          <PendingCount active={existingIsPending} unavailable={!showThreat}>
            <Icon.Session />
            {threatSum(attachedItems)}
          </PendingCount>
          <PendingCount active={existingIsPending} unavailable={!showDetection}>
            <Icon.Analytic />
            {detectionSum(attachedItems)}
          </PendingCount>
          {isBasUser && (
            <PendingCount active={existingIsPending} unavailable={!showAttackScript}>
              <Icon.AttackScript />
              {attackScriptSum(attachedItems)}
            </PendingCount>
          )}
        </span>
      </div>
      <div className='transfer-container'>
        <CurationGrid
          isPending={isSearching}
          selected={selectedSearchItems}
          items={visibleSearchedItems}
          onSelectChange={handleSearchCheckboxChange}
          placement='bottom-start'
          showExtraColumns
          columnHeaders={[
            <Checkbox
              key='check-all'
              onChange={handleToggleAllSearchCheckbox}
              checked={allSearchedItemsCheckbox}
              intermediate={
                selectedSearchItems.length > 0 && selectedSearchItems.length !== visibleSearchedItems.length
              }
            />,
            'Searched Items',
            'Organization'
          ]}
        />
        <div className='transfer-control'>
          <Tooltip
            title={selectedSearchItems.length === 0 ? 'Select search items to enable' : 'Add selected searched items'}
            arrow
            placement='right'
            wrap
            enterNextDelay={TOOLTIP_DELAY}
          >
            <Button
              variant='outlined'
              onClick={handleAddSelectedSearchItems}
              color='success'
              disabled={selectedSearchItems.length === 0}
            >
              <Icon icon={faAngleRight} />
            </Button>
          </Tooltip>
          <Tooltip
            title={selectedAttachedItems.length === 0 ? 'Select attached items to enable' : 'Remove attached items'}
            arrow
            placement='left'
            wrap
            enterNextDelay={TOOLTIP_DELAY}
          >
            <Button
              variant='outlined'
              onClick={handleRemoveSelectedQueuedItems}
              color='warning'
              disabled={selectedAttachedItems.length === 0}
            >
              <Icon icon={faEraser} />
            </Button>
          </Tooltip>
          <Tooltip title='Undo last change' arrow placement='left' wrap enterNextDelay={TOOLTIP_DELAY}>
            <DoButton
              aria-label='undo last change'
              variant='outlined'
              color='secondary'
              disabled={floor}
              onClick={() => prev()}
            >
              <Icon icon={faArrowRotateLeft} />
            </DoButton>
          </Tooltip>
          <Tooltip title='Redo last change' arrow placement='left' wrap enterNextDelay={TOOLTIP_DELAY * 2}>
            <DoButton
              aria-label='redo last change'
              variant='outlined'
              color='secondary'
              disabled={ceiling}
              onClick={() => next()}
            >
              <Icon icon={faArrowRotateRight} />
            </DoButton>
          </Tooltip>
          <Tooltip title='Reset to existing' arrow placement='left' wrap enterNextDelay={TOOLTIP_DELAY * 2}>
            <Button
              aria-label='reset to existing'
              variant='outlined'
              color='error'
              disabled={dataIsUnchanged}
              onClick={handleResetToExistingItems}
            >
              <Icon icon={faClose} />
            </Button>
          </Tooltip>
        </div>
        <CurationGrid
          isPending={existingIsPending}
          selected={selectedAttachedItems as DuckedArtifact[]}
          items={visibleAttachedItems as DuckedArtifact[]}
          placement='bottom-end'
          onSelectChange={handleAttachCheckboxChange}
          columnHeaders={[
            <Checkbox
              key='check-all'
              onChange={handleToggleAllQueuedCheckbox}
              checked={allAttachedItemsCheckbox}
              intermediate={
                selectedAttachedItems.length > 0 && selectedAttachedItems.length !== visibleAttachedItems.length
              }
            />,
            'Attached Items'
          ]}
        />
      </div>
      <BackdropLoader open={isActing} title='Saving...' fixed />
    </Container>
  );

  function handleToggleShowHideIntelItems(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
    setSelectedSearchItems(
      selectedSearchItems.filter(item =>
        shouldIncludeItemByTypeAndVisibility(item, checked, showThreat, showDetection, showAttackScript)
      )
    );

    push({
      ...current,
      selectedAttachedItems: selectedAttachedItems.filter(item =>
        shouldIncludeItemByTypeAndVisibility(item, checked, showThreat, showDetection, showAttackScript)
      ),
      showIntel: checked
    });
  }

  function handleToggleShowHideThreatItems(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
    setSelectedSearchItems(
      selectedSearchItems.filter(item =>
        shouldIncludeItemByTypeAndVisibility(item, showIntel, checked, showDetection, showAttackScript)
      )
    );

    push({
      ...current,
      selectedAttachedItems: selectedAttachedItems.filter(item =>
        shouldIncludeItemByTypeAndVisibility(item, showIntel, checked, showDetection, showAttackScript)
      ),
      showThreat: checked
    });
  }

  function handleToggleShowHideDetectionItems(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
    setSelectedSearchItems(
      selectedSearchItems.filter(item =>
        shouldIncludeItemByTypeAndVisibility(item, showIntel, showThreat, checked, showAttackScript)
      )
    );

    push({
      ...current,
      selectedAttachedItems: selectedAttachedItems.filter(item =>
        shouldIncludeItemByTypeAndVisibility(item, showIntel, showThreat, checked, showAttackScript)
      ),
      showDetection: checked
    });
  }

  function handleToggleShowHideAttackScriptItems(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
    setSelectedSearchItems(
      selectedSearchItems.filter(item =>
        shouldIncludeItemByTypeAndVisibility(item, showIntel, showThreat, showDetection, checked)
      )
    );

    push({
      ...current,
      selectedAttachedItems: selectedAttachedItems.filter(item =>
        shouldIncludeItemByTypeAndVisibility(item, showIntel, showThreat, showDetection, checked)
      ),
      showAttackScript: checked
    });
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setSearch(event.currentTarget.value);
  }

  function handleAttachCheckboxChange(isSelected: boolean, item: DuckedArtifact) {
    const newSelectedAttachItems = isSelected
      ? [...selectedAttachedItems, item].sort(sortItem)
      : selectedAttachedItems.filter(i => i.guid !== item.guid);

    push({
      ...current,
      selectedAttachedItems: newSelectedAttachItems
    });
    setAllAttachedItemsCheckbox(newSelectedAttachItems.length === visibleAttachedItems.length);
  }

  function handleSearchCheckboxChange(isSelected: boolean, item: DuckedArtifact) {
    const newSelectedSearchItems = isSelected
      ? [...selectedSearchItems, item]
      : selectedSearchItems.filter(s => s.guid !== item.guid);
    setSelectedSearchItems(newSelectedSearchItems);
    setAllSearchedItemsCheckbox(newSelectedSearchItems.length === visibleSearchedItems.length);
  }

  async function handleSave() {
    setActionError(null);
    setIsActing(true);

    const addIntel = buildAddToBulkList(ArtifactType.Intel, existing, attachedItems);
    const addThreat = buildAddToBulkList(ArtifactType.Session, existing, attachedItems);
    const addDetection = buildAddToBulkList(ArtifactType.Analytic, existing, attachedItems);
    const addAttackScript = buildAddToBulkList(ArtifactType.AttackScript, existing, attachedItems);
    const removeIntel = buildRemoveFromBulkList(ArtifactType.Intel, existing, attachedItems);
    const removeThreat = buildRemoveFromBulkList(ArtifactType.Session, existing, attachedItems);
    const removeDetection = buildRemoveFromBulkList(ArtifactType.Analytic, existing, attachedItems);
    const removeAttackScript = buildRemoveFromBulkList(ArtifactType.AttackScript, existing, attachedItems);

    const p: Promise<void>[] = [];
    if (addIntel.length + addThreat.length + addDetection.length + addAttackScript.length > 0)
      p.push(updateCollectionModifyItems(collection.guid, 'add', addIntel, addThreat, addDetection, addAttackScript));
    if (removeIntel.length + removeThreat.length + removeDetection.length + removeAttackScript.length > 0)
      p.push(
        updateCollectionModifyItems(
          collection.guid,
          'remove',
          removeIntel,
          removeThreat,
          removeDetection,
          removeAttackScript
        )
      );

    Promise.all(p)
      .then(() => {
        Engage.track(Fingerprint.of(Path.CollectionAttach).withCommon(CommonEvent.Save));
        // move to view page without state to force a natural refresh
        replace(`${Path.Collection}/${collection.guid}`);
      })
      .catch(e => {
        Engage.track(Fingerprint.error(Path.CollectionAttach).withContent(ContentPermission.Edit).withData(e));
        setActionError(asValidationError(e));
        setIsActing(false);
      });
  }

  function handleResetToExistingItems() {
    setSelectedSearchItems([]);
    setAllAttachedItemsCheckbox(false);
    setAllSearchedItemsCheckbox(false);
    initialize(existing);
  }

  function handleToggleAllSearchCheckbox(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
    setSelectedSearchItems(checked ? [...visibleSearchedItems] : []);
    setAllSearchedItemsCheckbox(checked);
  }

  function handleToggleAllQueuedCheckbox(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
    push({
      ...current,
      selectedAttachedItems: checked ? [...visibleAttachedItems] : []
    });
    setAllAttachedItemsCheckbox(checked);
  }

  function handleAddSelectedSearchItems() {
    push({
      ...current,
      attachedItems: [...attachedItems, ...selectedSearchItems.map(s => ({ ...s, new: true }))].sort(sortItem)
    });
    setSelectedSearchItems([]);
    setAllSearchedItemsCheckbox(false);
  }

  function handleRemoveSelectedQueuedItems() {
    push({
      ...current,
      attachedItems: attachedItems.filter(q => !selectedAttachedItems.some(s => s.guid === q.guid)).sort(sortItem),
      selectedAttachedItems: []
    });
    setAllAttachedItemsCheckbox(false);
  }
}

function sortItem(a: DuckedArtifact, b: DuckedArtifact) {
  return getFeedKindGroupName(a).localeCompare(getFeedKindGroupName(b));
}

function getFeedKindGroupName(item: DuckedArtifact) {
  return (item.duck === ArtifactType.Intel ? '0' : item.duck === ArtifactType.Session ? '1' : '2') + item.name;
}

function intelligenceSum(item: EventTypeValue) {
  return item.reduce((prev, curr) => (curr.duck === ArtifactType.Intel ? 1 : 0) + prev, 0);
}

function threatSum(item: EventTypeValue) {
  return item.reduce((prev, curr) => (curr.duck === ArtifactType.Session ? 1 : 0) + prev, 0);
}

function detectionSum(item: EventTypeValue) {
  return item.reduce((prev, curr) => (curr.duck === ArtifactType.Analytic ? 1 : 0) + prev, 0);
}

function attackScriptSum(item: EventTypeValue) {
  return item.reduce((prev, curr) => (curr.duck === ArtifactType.AttackScript ? 1 : 0) + prev, 0);
}

function shouldIncludeItemByTypeAndVisibility(
  item: Partial<DuckedArtifact>,
  showIntel: boolean,
  showThreat: boolean,
  showDetection: boolean,
  showAttackScript: boolean
) {
  return (
    (showIntel && item.duck === ArtifactType.Intel) ||
    (showThreat && item.duck === ArtifactType.Session) ||
    (showDetection && item.duck === ArtifactType.Analytic) ||
    (showAttackScript && item.duck === ArtifactType.AttackScript)
  );
}
