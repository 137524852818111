import { Group } from 'types/auth';

import { DEFAULT_ROLE, DEFAULT_ROLES, GROUP_TYPE, SORTED_DEFAULT_ROLES } from './organization';

const MAX_INDEX = Object.keys(DEFAULT_ROLES).length;

function getIndex(group: Group): number {
  return group.type === GROUP_TYPE.RoleAssignment
    ? Object.keys(DEFAULT_ROLES).findIndex((key: DEFAULT_ROLE) => DEFAULT_ROLES[key] === group.role)
    : MAX_INDEX;
}

export const sortGroupByRole = (groupA: Group, groupB: Group) => {
  return getIndex(groupA) - getIndex(groupB);
};

export function compareRolesDescending(roleA: DEFAULT_ROLE, roleB: DEFAULT_ROLE) {
  const rankA = SORTED_DEFAULT_ROLES[roleA];
  const rankB = SORTED_DEFAULT_ROLES[roleB];

  return rankA - rankB;
}
