import React from 'react';

import { useLocation } from 'react-router-dom';

import Box, { BoxProps } from 'snap-ui/Box';
import MuiList from 'snap-ui/List';
import { styled } from 'snap-ui/util';

import { isPageFlexible } from './Scaffold.service';

const Container = styled(Box, { shouldForwardProp: p => p !== 'flexible' })<BoxProps & { flexible: boolean }>`
  display: ${p => (p.flexible ? 'flex' : 'unset')};
  height: 100%;
`;

export function ScaffoldContainer(props: BoxProps) {
  const { pathname } = useLocation();
  const flexible = isPageFlexible(pathname);
  return <Container flexible={flexible} {...props} />;
}

export const List = styled(MuiList)`
  margin: ${p => p.theme.spacing(5, 0)};

  .filter-widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filter-widget .MuiFormControlLabel-root {
    height: 32px;
    min-height: 1rem;
  }

  .filter-widget:not(:last-child)::after {
    content: '';
    margin: ${p => p.theme.spacing(6, 0)};
    background: linear-gradient(0.25turn, hsla(0, 0%, 20%, 0.1), hsla(0, 0%, 40%, 0.3), hsla(0, 0%, 20%, 0.1));
    width: 100%;
    height: 2px;
  }

  margin-top: 0;
  padding-top: 0;
`;
