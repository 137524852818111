import React from 'react';

import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import zod from 'zod';

import { Autocomplete, getDisplayValue, Option } from 'snap-ui/Autocomplete';
import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { FilterAugment, FilterContextStore, FilterControl, useGlobalFilterContextValue } from 'module/GlobalFilter';
import { Integration } from 'module/Integration/Integration.type';
import { AutoCompleteOptionContent } from 'module/Integration/IntegrationAutocomplete';

import { useAuth, useIntegrationCatalog } from 'provider';

import { ArtifactType, Guid } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue } from '../GlobalFilter.util';

export type DeployedEnvironmentKeys = {
  deployedStatus: string[];
  deployedEnvironment: string[];
};

type DeployedEnvironmentFilterProps = {
  onChange(values: Partial<DeployedEnvironmentKeys>): void;
  values: DeployedEnvironmentKeys;
  filterContext?: FilterContextStore;
};

export const DetectionDeploymentStatus = {
  success: 'success',
  pending: 'pending',
  failed: 'failed',
  false: 'false',
  outdated: 'outdated'
} as const;

export type DetectionDeploymentStatus = (typeof DetectionDeploymentStatus)[keyof typeof DetectionDeploymentStatus];

const deploymentOptions = [
  { label: 'Deployed', value: DetectionDeploymentStatus.success },
  { label: 'Queued', value: DetectionDeploymentStatus.pending },
  { label: 'Error', value: DetectionDeploymentStatus.failed },
  { label: 'Not Deployed', value: DetectionDeploymentStatus.false },
  { label: 'Out of Date', value: DetectionDeploymentStatus.outdated }
];

const allDeploymentOptions = deploymentOptions.map(option => option.value);

function DeployedEnvironmentFilter({ onChange, values }: DeployedEnvironmentFilterProps): JSX.Element {
  const { isSubscriber } = useAuth();
  const { integrations } = useIntegrationCatalog();
  const [, setIntegrationLanguageContext] = useGlobalFilterContextValue('integrationLanguage');

  const optionMap = integrations.deployable.map(integration => ({
    content: <AutoCompleteOptionContent type={integration?.type} text={integration.name} />,
    label: integration.name,
    value: integration.guid
  }));
  const listValue = getDisplayValue(optionMap, values.deployedEnvironment) as Option;

  function handleListChange(option: Option[]): void {
    onChange({ deployedEnvironment: option.map(o => o.value) });
  }

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value;
    const deployedStatus = addOrRemoveValue(checked, values.deployedStatus, v);
    onChange({ deployedStatus });
  }

  React.useEffect(() => {
    setIntegrationLanguageContext(integrations.deployable);
  }, [integrations.deployable, setIntegrationLanguageContext]);

  return (
    <>
      <FilterControl disabled={!isSubscriber} className='DeployedStatusFilter'>
        <FormLabel id='deployed-status' className='title-tooltip'>
          By Deployed Status
          {!isSubscriber && (
            <Tooltip title='Deployments are only available to subscribers' placement='right' wrap arrow>
              <Icon.Info />
            </Tooltip>
          )}
        </FormLabel>

        {deploymentOptions.map(option => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                onChange={handleChange}
                value={option.value}
                checked={values.deployedStatus.includes(option.value)}
              />
            }
            label={option.label}
          />
        ))}
      </FilterControl>
      <FilterControl disabled={!isSubscriber} className='DeployedEnvironmentFilter'>
        <FormLabel id='deployed-environments' className='title-tooltip'>
          By Deployed Environment
          {!isSubscriber && (
            <Tooltip title='Deployments are only available to subscribers' placement='right' wrap arrow>
              <Icon.Info />
            </Tooltip>
          )}
        </FormLabel>

        <Autocomplete
          name='Environment Select'
          onChange={handleListChange}
          options={optionMap}
          value={listValue}
          multiple
          disableUserAdditions
        />
      </FilterControl>
    </>
  );
}

function getStatusFilter(status: DetectionDeploymentStatus, environments: Guid[], orgId: number): Query {
  if (isEmpty(environments)) {
    // relates to statusOnAnyOrg in `fromQuery`
    return {
      op: Ops.for_each,
      items: [
        {
          field: 'deployment_integration_filter.organization_id',
          op: Ops.equals,
          value: orgId
        },
        {
          field: `deployment_integration_filter.${status}`,
          op: Ops.not_equals,
          value: []
        }
      ]
    };
  } else {
    // relates to statusInEnvironment in `fromQuery
    return {
      field: `deployment_integration_filter.${status}`,
      op: Ops.in,
      value: environments
    };
  }
}

function getNoStatusFilter(environments: Guid[], orgId: number, filterContext: FilterContextStore) {
  const contextValue = filterContext?.['integrationLanguage'] as Integration[];
  const environmentLanguages = contextValue
    ?.filter(integration => environments.includes(integration.guid))
    .flatMap(integration =>
      uniq([
        ...integration.deployment_targets.map(target => target.id),
        ...integration.hunt_targets.map(target => target.id),
        ...integration.search_targets.map(target => target.id)
      ])
    );
  if (isEmpty(environments)) {
    // relates to noStatusAnyEnvironment in `fromQuery`
    return {
      op: Ops.or,
      items: [
        {
          field: 'deployment_filter.org_ids',
          op: Ops.not,
          value: [orgId]
        },
        {
          field: 'deployment_filter.org_ids',
          op: Ops.equals,
          value: null
        }
      ]
    };
  } else {
    // relates to noStatusInEnvironment in `fromQuery`
    return {
      op: Ops.and,
      items: [
        {
          op: Ops.or,
          items: [
            {
              field: 'deployment_integration_filter.integrations',
              op: Ops.not,
              value: environments
            },
            {
              field: 'deployment_integration_filter.integrations',
              op: Ops.equals,
              value: null
            }
          ]
        },
        {
          field: 'analytic_compilation_targets',
          op: Ops.any,
          value: environmentLanguages
        }
      ]
    };
  }
}

function toQuery(values: DeployedEnvironmentKeys, { orgId }: FilterAugment, filterContext: FilterContextStore) {
  if (isEmpty(values.deployedStatus) || allDeploymentOptions.every(option => values.deployedStatus?.includes(option))) {
    if (isEmpty(values.deployedEnvironment)) {
      return;
    } else {
      // relates to allStatusGivenOrgs in `fromQuery`
      return {
        field: 'deployment_integration_filter.integrations',
        op: Ops.in,
        value: values.deployedEnvironment
      };
    }
  }

  // orWrapper
  const filter: Query = {
    op: Ops.or,
    items: []
  };

  if (values.deployedStatus.includes(DetectionDeploymentStatus.success)) {
    filter.items.push(getStatusFilter(DetectionDeploymentStatus.success, values.deployedEnvironment, orgId));
  }

  if (values.deployedStatus.includes(DetectionDeploymentStatus.pending)) {
    filter.items.push(getStatusFilter(DetectionDeploymentStatus.pending, values.deployedEnvironment, orgId));
  }

  if (values.deployedStatus.includes(DetectionDeploymentStatus.failed)) {
    filter.items.push(getStatusFilter(DetectionDeploymentStatus.failed, values.deployedEnvironment, orgId));
  }

  if (values.deployedStatus.includes(DetectionDeploymentStatus.outdated)) {
    filter.items.push(getStatusFilter(DetectionDeploymentStatus.outdated, values.deployedEnvironment, orgId));
  }

  if (values.deployedStatus.includes(DetectionDeploymentStatus.false)) {
    filter.items.push(getNoStatusFilter(values.deployedEnvironment, orgId, filterContext));
  }

  return filter;
}

const deploymentIntegrationStatus = zod.union([
  zod.literal('deployment_integration_filter.success'),
  zod.literal('deployment_integration_filter.pending'),
  zod.literal('deployment_integration_filter.failed'),
  zod.literal('deployment_integration_filter.outdated')
]);

const allStatusGivenOrgs = zod
  .object({
    op: zod.literal(Ops.in),
    field: zod.literal('deployment_integration_filter.integrations'),
    value: zod.array(zod.string())
  })
  .transform(query => [
    {
      deployedStatus: [] as string[],
      deployedEnvironment: query.value
    }
  ]);

const statusInEnvironment = zod
  .object({
    op: zod.literal(Ops.in),
    field: deploymentIntegrationStatus,
    value: zod.array(zod.string())
  })
  .transform(query => {
    const deployedStatus = query.field.split('.')[1];

    return {
      deployedStatus: [deployedStatus],
      deployedEnvironment: query.value
    };
  });

const statusOnAnyOrg = zod
  .object({
    op: zod.literal(Ops.for_each),
    items: zod.array(
      zod.union([
        zod.object({
          op: zod.literal(Ops.equals),
          field: zod.literal('deployment_integration_filter.organization_id'),
          value: zod.number()
        }),
        zod.object({
          op: zod.literal(Ops.not_equals),
          field: deploymentIntegrationStatus,
          value: zod.tuple([])
        })
      ])
    )
  })
  .transform(query => {
    const fields = query.items.filter(query => query.op === Ops.not_equals);
    const deployedStatus = fields.map(({ field }) => field.split('.')[1]);

    return {
      deployedStatus,
      deployedEnvironment: [] as string[]
    };
  });

const noStatusAnyEnvironment = zod
  .object({
    op: zod.literal(Ops.or),
    items: zod.array(
      zod.union([
        zod.object({
          field: zod.literal('deployment_filter.org_ids'),
          op: zod.literal(Ops.not),
          value: zod.array(zod.number())
        }),
        zod.object({
          field: zod.literal('deployment_filter.org_ids'),
          op: zod.literal(Ops.equals),
          value: zod.null()
        })
      ])
    )
  })
  .transform(() => ({
    deployedStatus: [DetectionDeploymentStatus.false],
    deployedEnvironment: [] as string[]
  }));

const noStatusInEnvironment = zod
  .object({
    field: zod.literal('deployment_integration_filter.integrations'),
    op: zod.literal(Ops.not),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    deployedStatus: [DetectionDeploymentStatus.false],
    deployedEnvironment: query.value
  }));

const orWrapper = zod
  .object({
    op: zod.literal(Ops.or),
    items: zod.array(zod.union([statusOnAnyOrg, statusInEnvironment, noStatusAnyEnvironment, noStatusInEnvironment]))
  })
  .transform(result => result.items);

const fromQuery = zod.union([orWrapper, allStatusGivenOrgs]).transform(result => {
  return result.reduce(
    (result, current) => ({
      deployedStatus: [...new Set([...result.deployedStatus, ...current.deployedStatus])],
      deployedEnvironment: [...new Set([...result.deployedEnvironment, ...current.deployedEnvironment])]
    }),
    {
      deployedStatus: [],
      deployedEnvironment: []
    }
  );
});

const DeployedEnvironmentFilterConfig: FilterConfig<DeployedEnvironmentKeys> = {
  defaults: {
    default: () => ({
      deployedStatus: allDeploymentOptions,
      deployedEnvironment: []
    })
  },
  supportedTopics: [ArtifactType.Analytic],
  component: DeployedEnvironmentFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default DeployedEnvironmentFilterConfig;
