import React from 'react';

import startCase from 'lodash/startCase';
import zod from 'zod';

import { Certified } from 'snap-ui/Cert';
import Checkbox from 'snap-ui/Checkbox';
import { FormControlLabel } from 'snap-ui/FormControl';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { isRecommendationResponse, RecommendationType, Actionability } from 'module/Curation/Curation.type';
import { FilterContextStore, FilterControl } from 'module/GlobalFilter';
import { useMayI } from 'module/May';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType, Tracked } from 'types/common';
import { Ops, Query } from 'types/filter';

import { CheckboxControl, IconFormControlLabel } from '../GlobalFilter.style';
import { FilterAugment, FilterConfig } from '../GlobalFilter.type';
import { fromQueryNoop } from '../GlobalFilter.util';

const recommendationTypeStrings = ['true', ...(Object.values(RecommendationType) as string[])];

function getRecommendedLabel(type: RecommendationType | 'true' | 'false', index: number): string {
  const ActionabilityLabel: Record<RecommendationType, string> = {
    [RecommendationType.Deployment]: 'deployed',
    [RecommendationType.Hunt]: 'hunted',
    [RecommendationType.Validation]: 'validated'
  };
  const actionType = ActionabilityLabel[type as RecommendationType];

  const recommendedLabels = [
    `Un${actionType} Recommendations`,
    `${startCase(actionType)} Recommendations`,
    'All Recommendations'
  ];
  return recommendedLabels[index];
}

export type RecommendedDetectionKeys = {
  recommended: RecommendationType | 'true' | 'false';
  actionability?: Actionability;
};

type RecommendedDetectionFilterProps = {
  disabled?: boolean;
  onChange(values: RecommendedDetectionKeys): void;
  values: RecommendedDetectionKeys;
  topic: ArtifactType;
  filterContext?: FilterContextStore;
};

const StyledCheckboxControl = styled(CheckboxControl)`
  padding-left: ${p => p.theme.spacing(7)};
`;

function RecommendedDetectionFilter({
  values,
  onChange,
  disabled,
  topic,
  filterContext
}: RecommendedDetectionFilterProps): React.ReactElement {
  const hasPermission = useMayI(FunctionalPermission.RecommendationFeedFilter);
  const enableActionability =
    hasPermission &&
    isRecommendationResponse(filterContext?.recommendations) &&
    ([RecommendationType.Deployment, RecommendationType.Hunt] as RecommendedDetectionKeys['recommended'][]).includes(
      values?.recommended
    );
  // for analytic and attack script feeds, must have alpha permission
  if ([ArtifactType.Analytic, ArtifactType.AttackScript].includes(topic) && !hasPermission) return null;

  const objectName = topic === ArtifactType.AttackScript ? 'attack scripts' : 'detections';

  return (
    <>
      <FilterControl disabled={disabled} className='RecommendedDetectionFilter'>
        <FormLabel id='recommended-detection-toggle-button-group-label' className='title-tooltip'>
          By Recommendations
          <Tooltip title={`Recommended ${objectName} are based on your threat profile.`} placement='right' arrow wrap>
            <Icon.Info />
          </Tooltip>
        </FormLabel>
        <FormGroup>
          <IconFormControlLabel
            key='recommended'
            control={
              <Checkbox
                onChange={handleCheckChange}
                checked={recommendationTypeStrings.includes(values.recommended?.toLowerCase())}
                value='recommended'
              />
            }
            label={
              <>
                <Certified size={5} /> Only Recommended {startCase(objectName)}
              </>
            }
          />
        </FormGroup>
        {topic === ArtifactType.Analytic && (
          <StyledCheckboxControl>
            <RadioGroup
              aria-labelledby='recommended-radio-button-group-label'
              name='recommended-radio-button-group'
              value={values.recommended}
              onChange={handleRadioChange}
            >
              <>
                <FormControlLabel
                  key={RecommendationType.Deployment}
                  value={RecommendationType.Deployment}
                  control={<Radio />}
                  label='For Alerting / Deployment'
                />
                <FormControlLabel
                  key={RecommendationType.Hunt}
                  value={RecommendationType.Hunt}
                  control={<Radio />}
                  label='For Hunting'
                />
              </>
            </RadioGroup>
          </StyledCheckboxControl>
        )}
      </FilterControl>
      {enableActionability && (
        <FilterControl className='RecommendedDetectionActionability'>
          <FormLabel id='recommendedDetectionActionability'>By Actionability</FormLabel>
          <RadioGroup
            aria-labelledby='recommendedDetectionActionability'
            name='recommendedDetectionActionability'
            value={values.actionability}
            onChange={handleActionabilityChange}
          >
            {Object.values(Actionability).map((action, index) => (
              <FormControlLabel
                key={action}
                value={action}
                control={<Radio />}
                label={getRecommendedLabel(values?.recommended, index)}
              />
            ))}
          </RadioGroup>
        </FilterControl>
      )}
    </>
  );

  function handleCheckChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    onChange({
      recommended: checked ? (topic === ArtifactType.Analytic ? RecommendationType.Deployment : 'true') : 'false'
    });
  }

  function handleRadioChange(_event: React.ChangeEvent<HTMLInputElement>, value: string): void {
    onChange({ recommended: value as RecommendationType });
  }

  function handleActionabilityChange(_event: React.ChangeEvent<HTMLInputElement>, value: string): void {
    onChange({ recommended: values.recommended, actionability: value as Actionability });
  }
}

function getGuids(actionability: Actionability, allRecs: Tracked[], unactioned: Tracked[]) {
  let recs: Tracked[];
  switch (actionability) {
    case Actionability.ALL:
      recs = allRecs;
      break;
    case Actionability.ACTIONED:
      recs = allRecs.filter(r => !unactioned.some(ua => ua.guid === r.guid));
      break;
    case Actionability.UNACTIONED:
    default:
      recs = unactioned;
  }
  return recs.map(r => r.guid);
}

function toQueryAnalytic(
  { recommended, actionability }: RecommendedDetectionKeys,
  { orgId }: FilterAugment,
  filterContext: FilterContextStore
): Query {
  if (!recommended || recommended === 'false') return;
  const isHunt = recommended === RecommendationType.Hunt;
  const contextValue = filterContext?.['recommendations'];
  if (isRecommendationResponse(contextValue)) {
    const fieldBase = isHunt ? 'hunt' : 'deployment';
    return {
      field: 'guid',
      op: Ops.in,
      value: getGuids(
        actionability,
        contextValue[`all_${fieldBase}_recommendations`],
        contextValue[`${fieldBase}_recommendations`]
      )
    };
  } else {
    const field = isHunt ? 'recommendations.hunt_score' : 'recommendations.deploy_score';
    return {
      op: Ops.for_each,
      items: [
        {
          op: Ops.sort,
          field,
          value: 'desc'
        },
        {
          op: Ops.greater_than,
          field,
          value: 0
        },
        {
          op: Ops.equals,
          field: 'recommendations.organization_id',
          value: orgId
        }
      ]
    };
  }
}

function toQueryAttackScript(
  { recommended }: RecommendedDetectionKeys,
  { orgId }: FilterAugment,
  filterContext: FilterContextStore
): Query {
  if (!recommended || recommended === 'false') return;
  const contextValue = filterContext?.['recommendations'];
  if (isRecommendationResponse(contextValue)) {
    return {
      field: 'guid',
      op: Ops.in,
      value: contextValue.validations.map(r => r.guid)
    };
  } else {
    return {
      field: 'recommended_validation.organization_id',
      op: Ops.equals,
      value: orgId
    };
  }
}

const scoreUnion = zod.union([zod.literal('recommendations.hunt_score'), zod.literal('recommendations.deploy_score')]);

const fromQueryAnalytic = zod
  .object({
    op: zod.nativeEnum(Ops),
    items: zod.array(
      zod.union([
        zod.object({
          field: scoreUnion,
          op: zod.literal(Ops.sort),
          value: zod.string()
        }),
        zod.object({
          field: scoreUnion,
          op: zod.literal(Ops.greater_than),
          value: zod.literal(0)
        }),
        zod.object({
          field: zod.literal('recommendations.organization_id'),
          op: zod.literal(Ops.equals),
          value: zod.number()
        })
      ])
    )
  })
  .transform(query => {
    const recommendedItem = query.items.find(item => item.op === Ops.greater_than);
    const recommended =
      recommendedItem.field === 'recommendations.hunt_score' ? RecommendationType.Hunt : RecommendationType.Deployment;
    return { recommended };
  });

const fromQueryAttackScript = zod
  .object({
    field: zod.literal('recommended_validation.organization_id')
  })
  .transform(
    () =>
      ({
        recommended: 'true'
      } as const)
  );

// Form `Marker` toQuery and fromQuery are both "NOOP"
//     Recommended Detections are filtered in memory
//     so these functions are not necessary.
const RecommendedDetectionFilterConfig: FilterConfig<RecommendedDetectionKeys> = {
  defaults: {
    default: () => ({ recommended: 'false', actionability: Actionability.UNACTIONED })
  },
  supportedTopics: [ArtifactType.Marker, ArtifactType.Analytic, ArtifactType.AttackScript],
  component: RecommendedDetectionFilter,
  toQuery: {
    default: () => null,
    [ArtifactType.Analytic]: toQueryAnalytic,
    [ArtifactType.AttackScript]: toQueryAttackScript
  },
  fromQuery: {
    default: fromQueryNoop({
      field: zod.literal('recommended'),
      value: zod.array(zod.string())
    }),
    [ArtifactType.Analytic]: fromQueryAnalytic,
    [ArtifactType.AttackScript]: fromQueryAttackScript
  }
};
export default RecommendedDetectionFilterConfig;
