import React from 'react';

import { faX } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import { default as _TextField } from 'snap-ui/TextField';

import useDebounce from 'hooks/useDebounce';

import {
  BulkActionForm,
  InlineEditorForm,
  JsonSchema,
  useInlineEditor,
  withJsonFormsControlProps
} from 'module/JsonView';

import { SINGLE_VALUE_BULK_OPTIONS } from '../Metadata.const';
import { isMetaEmpty } from '../Metadata.util';
import { useBulkEditor } from '../MetadataBulkEditInterface';
import { Control, ControlProps } from './Control.type';
import { isControlApplied } from './Control.util';

function InlineText(props: { data: string; enabled: boolean; path: string; schema: JsonSchema }) {
  const { data, enabled, path, schema } = props;
  const editor = useInlineEditor(data);
  return (
    <InlineEditorForm
      data={data}
      editor={editor}
      enabled={enabled}
      path={path}
      title={schema.title}
      type={Control.Text}
    >
      <_TextField
        autoFocus
        value={editor.queue || ''}
        label={schema.title}
        helperText={schema.description}
        onChange={e => editor.setQueue(e.target.value)}
        className={`Control Control-${Control.Text}`}
        elevated
      />
    </InlineEditorForm>
  );
}

export function FilterText({
  data,
  onChange,
  path,
  schema
}: {
  data: string;
  onChange: ControlProps['handleChange'];
  path: string;
  schema: JsonSchema;
}) {
  const [value, setValue] = React.useState<string>(() => data || '');
  const queue = useDebounce(value, 500);

  React.useEffect(() => {
    onChange(path, queue);
  }, [onChange, path, queue]);

  return (
    <_TextField
      value={value}
      label={schema.title}
      helperText={schema.description}
      onChange={e => setValue(e.target.value)}
      className={`Control Control-${Control.Text}`}
      elevated
      InputProps={{
        endAdornment: (
          <ActionIconButton
            disabled={!queue}
            aria-label={`Clear ${schema.title}`}
            icon={faX}
            onClick={() => setValue('')}
          />
        )
      }}
    />
  );
}

function BulkActionText(props: { path: string; schema: JsonSchema }) {
  const { path, schema } = props;
  const editor = useBulkEditor(path);
  return (
    <BulkActionForm actions={SINGLE_VALUE_BULK_OPTIONS} editor={editor} title={schema.title}>
      <_TextField
        className={`Control Control-${Control.Text}`}
        elevated
        label='Value'
        onChange={e => editor.set(editor.operation, e.target.value ?? null)}
        value={editor.value}
      />
    </BulkActionForm>
  );
}

const _TextControl = (props: ControlProps) => {
  const { config, data, enabled, handleChange, path, schema } = props;

  if ((!config.asPreview && !isControlApplied(schema, config)) || (config.truncated && isMetaEmpty(data))) {
    return null;
  }

  if (config.asBulk) return <BulkActionText path={path} schema={schema} />;
  if (config.asInline) return <InlineText data={data} enabled={enabled} path={path} schema={schema} />;
  if (config.asFilter) return <FilterText data={data} onChange={handleChange} path={path} schema={schema} />;
  return (
    <_TextField
      value={data || ''}
      label={schema.title}
      helperText={schema.description}
      onChange={e => handleChange(path, e.target.value)}
      className={`Control Control-${Control.Text}`}
      disabled={!enabled}
      elevated
    />
  );
};
export const TextControl = withJsonFormsControlProps(_TextControl);
