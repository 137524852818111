import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';

type HasIndicatorsKeys = {
  hasIndicators: string;
};

type HasIndicatorsFilterProps = {
  onChange(values: HasIndicatorsKeys): void;
  values: HasIndicatorsKeys;
};

function HasIndicatorsFilter({ onChange, values }: HasIndicatorsFilterProps): React.ReactElement {
  const booleanValue = values.hasIndicators?.toLowerCase() === 'true';

  return (
    <FilterControl>
      <FormLabel id='has-indicators-toggle-button-group-label' className='title-tooltip'>
        Has Indicators
        <Tooltip title='Filters out content without associated indicators.' placement='right' arrow wrap>
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='has-indicators-toggle-button-group-label'>
        <FormControlLabel
          key='hasIndicators'
          control={<Checkbox onChange={handleChange} value='hasIndicators' checked={booleanValue} />}
          label='Show content with indicators'
        />
      </FormGroup>
    </FilterControl>
  );

  function handleChange(_e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    onChange({ hasIndicators: checked ? String(checked) : undefined });
  }
}

function toQuery(values: HasIndicatorsKeys): Query {
  if (values.hasIndicators?.toLowerCase() !== 'true') return;

  return {
    field: 'indicators.name',
    op: Ops.not_equals,
    value: null
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('indicators.name'),
    op: zod.literal(Ops.not_equals)
  })
  .transform(() => ({ hasIndicators: 'true' }));

const HasIndicatorsFilterConfig: FilterConfig<HasIndicatorsKeys> = {
  defaults: { default: () => ({ hasIndicators: 'false' }) },
  supportedTopics: [ArtifactType.Intel],
  // MATI_DELETION
  // supportedTopics: [ArtifactType.Intel, ArtifactType.Collection],
  component: HasIndicatorsFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default HasIndicatorsFilterConfig;
