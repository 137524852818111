import isEmpty from 'lodash/isEmpty';

export type SearchParamFactory = {
  byName(p: string, defaultValue?: string): string;
  allByName(p: string, defaultValue?: string[]): string[];
};

export function searchParamFactory(search: string): SearchParamFactory {
  const params = new URLSearchParams(search);

  return {
    byName: (p: string, defaultValue = '') => params.get(p) || defaultValue,
    allByName: (p: string, defaultValue: string[] = []) => {
      const result = params.getAll(p);
      return isEmpty(result) ? defaultValue : result;
    }
  };
}

export function formatQueryString(params: Record<string, string | number | boolean | string[] | number[]>): string {
  const arrayParams: Array<{ key: string; val: string[] }> = [];
  for (const [key, val] of Object.entries(params)) {
    if ([undefined, null, ''].includes(val as string)) delete params[key];
    if (Array.isArray(val)) {
      arrayParams.push(Object.assign({ key, val }));
      delete params[key];
    }
  }
  const searchParams = new URLSearchParams(params as Record<string, string>);
  for (const item of arrayParams) {
    item.val.forEach(value => searchParams.append(item.key, value));
  }
  // searchParams.size isn't compiling
  const result = searchParams.toString();
  return `${result.length > 0 ? '?' : ''}${result}`;
}

/**
 * Short cut to factory. If needing multiple params. Use the factory directly.
 *
 * ```ts
 * searchParamFactory(search).byName(param);
 * ```
 */
export function getQueryParam(search: string, param: string) {
  return searchParamFactory(search).byName(param);
}

/**
 * Short cut to factory. If needing multiple params. Use the factory directly.
 *
 * ```ts
 * searchParamFactory(search).byName(param);
 * ```
 */
export function getQueryParams(search: string, param: string) {
  return searchParamFactory(search).allByName(param);
}

/**
 * Parse a query string into a javascript object
 * @param queryString the query string (starts with "?")
 * @param schema schema of the expected result (fields can be String | Number | Array)
 * @returns javascript object with the provided schema
 */
export function parseQueryString(
  search: string,
  schema: Record<string, StringConstructor | NumberConstructor | ArrayConstructor>
) {
  const params = new URLSearchParams(search);
  return Array.from(params.keys()).reduce(
    (paramsObj, key: string) => ({
      ...paramsObj,
      [key]:
        key in schema
          ? schema[key] === Array
            ? params.getAll(key)
            : schema[key] === Number
            ? Number(params.get(key))
            : params.get(key)
          : undefined
    }),
    {}
  );
}

/**
 * Parse a query string into a javascript object while filtering against the schema
 * @param queryString the query string (starts with "?")
 * @param schema schema of the expected result (fields can be String | Number | Array)
 * @returns javascript object with the provided schema
 */
export function parseSchemaOnlyQueryString(
  search: string,
  schema: Record<string, StringConstructor | NumberConstructor | ArrayConstructor>
) {
  const params = new URLSearchParams(search);
  return Array.from(params.keys())
    .filter(p => p in schema)
    .reduce(
      (paramsObj, key: string) => ({
        ...paramsObj,
        [key]:
          schema[key] === Array
            ? params.getAll(key)
            : schema[key] === Number
            ? Number(params.get(key))
            : params.get(key)
      }),
      {}
    );
}

/**
 * Parse any kind of query string into a javascript object
 * @param queryString the query string (starts with "?")
 * @returns javascript object
 */
export function parseAnyQueryString(search: string): Record<string, string | string[]> {
  const params = new URLSearchParams(search);
  return Array.from(params.entries()).reduce((prev: Record<string, string | string[]>, [key, value]) => {
    let newValue: string | string[] = value;
    const existing = prev[key];
    if (existing && Array.isArray(existing)) {
      newValue = [...existing, value];
    } else if (existing && typeof existing === 'string') {
      newValue = [existing, value];
    }
    return {
      ...prev,
      [key]: newValue
    };
  }, {});
}
