import { RJSFSchema } from '@rjsf/utils';
import { FormikErrors } from 'formik';

import { COMMUNITY_ORGANIZATION } from 'constants/auth';

import { checkContentPermission } from 'services/authService';

import { CompilationTargetId, Language } from 'types/analytic';
import { ContentPermission } from 'types/auth';
import { Ident } from 'types/common';

import { TimeInSeconds } from 'utilities/TimeUtils';

import {
  HuntableIntegration,
  INTEGRATION_SCHEMA_TABS,
  Integration,
  IntegrationFormData,
  IntegrationRJSFSchema,
  IntegrationSchemaPropertiesMap,
  IntegrationTarget,
  JobIntegrationCatalogSchema,
  PlatformDetails,
  SchemaCatalog,
  SchemaSupported,
  SchemaSupportedLanguageDetails
} from './Integration.type';
import { IntegrationOption } from './IntegrationAutocomplete';

export function filterIntegrationsByPermission(data: Integration[], permission: ContentPermission) {
  return data.filter(integration => {
    const { organization_id } = integration;
    const isNotCommunityIntegration = organization_id !== COMMUNITY_ORGANIZATION.id;

    return checkContentPermission(integration, permission) && isNotCommunityIntegration;
  });
}

export function filterIntegrationsByOrg(data: Integration[], orgs: (string | number)[]) {
  return data.filter(integration => {
    const { organization_id } = integration;
    const isNotCommunityIntegration = organization_id !== COMMUNITY_ORGANIZATION.id;
    const matchesOrgId = orgs.some(org => org == organization_id);

    return isNotCommunityIntegration && matchesOrgId;
  });
}

export function getSpecificJobSchema(
  jobSchemaCatalog: JobIntegrationCatalogSchema,
  integrationType: string
): RJSFSchema {
  return jobSchemaCatalog[integrationType] ? jobSchemaCatalog[integrationType] : {};
}

export const getIntegrationTypeWithMostParameters = (
  integrations: HuntableIntegration[],
  catalog: JobIntegrationCatalogSchema
): string => {
  const huntableIntegrations = (integrations || []).filter(i => i.huntableDetections);

  if (huntableIntegrations.length === 0) return '';
  let maxProperties = -1;
  let integrationType = '';

  for (const integration of huntableIntegrations) {
    const currentCatalogEntry = catalog[integration.type];

    // Ensure the type from hunts exists in the catalog.
    if (currentCatalogEntry && currentCatalogEntry.properties) {
      const numOfKeys = Object.keys(currentCatalogEntry.properties).length;
      if (numOfKeys > maxProperties) {
        maxProperties = numOfKeys;
        integrationType = integration.type;
      }
    }
  }

  return integrationType;
};

export function validateSearchAndCronHasNoGap(
  cronString: string,
  searchWindow: string,
  setErrors: (errors: FormikErrors<any>) => void,
  errors: FormikErrors<any>
) {
  // Define a lookup object with regex and their corresponding time in seconds
  const cronPatterns: Record<string, { pattern: RegExp; time: number }> = {
    everyHour: { pattern: /^0 \* \* \* \*$/, time: TimeInSeconds.hour },
    everyDay: { pattern: /^0 0( \*){3}$|^0 0.+ \* \* \*$/, time: TimeInSeconds.day },
    everyWeek: {
      pattern:
        /^0 0 \* \* 0$|^0 0.+ \* \* 0$|^0 0 \* \* (\d+(-\d+)?(,\d+(-\d+)?)*|$)$|^0 \* \* \* 0.$|^0 [^ ]+ \* \* [^ ]+$/,
      time: TimeInSeconds.sevenDays
    },
    everyMonth: { pattern: /^0 0 1 \* \*$/, time: TimeInSeconds.thirtyDays }
  };

  // Iterate over the patterns and check if any matches
  for (const key in cronPatterns) {
    if (cronPatterns[key].pattern.test(cronString)) {
      const timeLimit = cronPatterns[key].time;
      // return timeLimit <= searchWindow ? true : false;
      const errorMessage =
        timeLimit <= parseInt(searchWindow)
          ? null
          : 'The max search window does not cover the entire frequency, and events outside that window will not be hunted.';

      errorMessage
        ? setErrors({
            ...errors,
            cron_trigger: errorMessage ? errorMessage : null
          })
        : setErrors({ ...errors, cron_trigger: null });

      break; // Exit loop if a match is found
    }
  }
}

export function getPlatformDetailsByLanguage(
  languageId: Ident,
  languages: Language[],
  supportedPlatforms: SchemaSupported,
  integrations: Integration[]
): PlatformDetails {
  const language = languages.find(language => language.id === languageId);
  const languageDetails = {
    languageId,
    languageName: language?.name,
    nativeKey: language?.native_key
  };

  const integrationPlatforms = integrations
    .filter(
      integration =>
        integration.type !== 'CustomerManaged' &&
        (hasLanguagMatch(integration.deployment_targets) ||
          hasLanguagMatch(integration.hunt_targets) ||
          hasLanguagMatch(integration.search_targets))
    )
    .map(integration => ({
      typeLabel: integration.type_label,
      integrationType: integration.type,
      hasVisibleIntegration: true,
      integration
    }));

  const SNAPATTACK_LANGS = [
    CompilationTargetId.SnapAttackCloud,
    CompilationTargetId.SnapAttackSplunk,
    CompilationTargetId.SnapAttackSplunkLinux,
    CompilationTargetId.SnapAttackWindows,
    CompilationTargetId.SnapAttackZeek
  ];

  const preferredSnapAttackPlatforms =
    languageId === CompilationTargetId.Sigma
      ? [
          {
            typeLabel: {
              short_name: 'Sigma',
              display_name: 'Sigma'
            },
            integrationType: 'Sigma',
            hasVisibleIntegration: false
          }
        ]
      : SNAPATTACK_LANGS.includes(languageId)
      ? [
          {
            typeLabel: {
              short_name: 'Splunk',
              display_name: 'Splunk'
            },
            integrationType: 'Splunk',
            hasVisibleIntegration: false
          }
        ]
      : [];

  const supportedPlatformEntries: [string, SchemaSupportedLanguageDetails][] = Object.entries(supportedPlatforms);
  const otherPlatforms = supportedPlatformEntries
    .filter(
      ([integrationType, platform]) =>
        !integrationPlatforms.some(p => p.integrationType === integrationType) &&
        (platform.deploy.includes(languageId) ||
          platform.hunt.includes(languageId) ||
          platform.search.includes(languageId))
    )
    .map(([integrationType, platform]) => ({
      typeLabel: platform.type_label,
      integrationType,
      hasVisibleIntegration: false
    }));

  return {
    ...languageDetails,
    platforms: [...preferredSnapAttackPlatforms, ...integrationPlatforms, ...otherPlatforms]
  };

  function hasLanguagMatch(targets: IntegrationTarget[]): boolean {
    return targets.some(t => t.id === languageId);
  }
}

export function hasSupportedMatch(targetIds: Ident[], supportedLanguageIds: Ident[]): boolean {
  return targetIds.some(t => supportedLanguageIds?.includes(t));
}

export function mapIntegrationToOption(
  integration: Integration,
  supportedLanguageIds?: Ident[],
  valueField: 'id' | 'guid' = 'id'
): IntegrationOption {
  return {
    label: integration.name,
    group: 'Integrations',
    type: integration.type,
    value: integration[valueField].toString(),
    warning: supportedLanguageIds
      ? !hasSupportedMatch(
          [
            ...integration.deployment_targets.map(t => t.id),
            ...integration.hunt_targets.map(t => t.id),
            ...integration.search_targets.map(t => t.id)
          ],
          supportedLanguageIds
        )
      : false
  };
}

export function cleanData(values: IntegrationFormData, catalog: SchemaCatalog['catalog']): IntegrationFormData {
  if (values?.extra_information?.private_key) {
    values = {
      ...values,
      extra_information: {
        ...values.extra_information,
        private_key: values?.extra_information?.private_key.replace(/\\n/g, '\n')
      }
    };
  }

  if (values.type in catalog === false) values.extra_information = null;

  return values;
}

export function getTabIntegrationSchema(schema: IntegrationRJSFSchema) {
  const groupedProperties: IntegrationSchemaPropertiesMap = Object.entries(schema?.properties ?? {}).reduce(
    (accumulated, [key, config]) => {
      const tab = config.tab ?? INTEGRATION_SCHEMA_TABS.Configuration;
      return { ...accumulated, [tab]: accumulated[tab] ? { ...accumulated[tab], [key]: config } : { [key]: config } };
    },
    {} as IntegrationSchemaPropertiesMap
  );
  return function (tab: INTEGRATION_SCHEMA_TABS): IntegrationRJSFSchema {
    return groupedProperties[tab]
      ? {
          ...schema,
          properties: groupedProperties[tab]
        }
      : undefined;
  };
}
