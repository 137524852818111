import React from 'react';

import { Area, AreaChart, ReferenceLine, ResponsiveContainer, YAxis, XAxis, Legend } from 'recharts';

import { useTheme } from 'snap-ui/util';

import { DoubleLineChartData } from 'module/Dashboard/SocDashboard/SocDashboard.type';

type Props = {
  data: DoubleLineChartData;
  threshold: number;
  hideLegend?: boolean;
};

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function DoubleLineChart({ data, threshold, hideLegend }: Props) {
  const { palette } = useTheme();
  const num = randomIntFromInterval(1, 1000);

  return (
    <ResponsiveContainer width='100%' height={400}>
      <AreaChart width={350} height={75} data={data}>
        <defs>
          <linearGradient id={`area-gradient-${num}`} x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor={palette.purple.main} stopOpacity={0.8} />
            <stop offset='95%' stopColor={palette.purple.main} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey='date' padding={{ left: 30, right: 30 }} />
        <YAxis />
        {!hideLegend && <Legend />}
        <ReferenceLine y={threshold} stroke={palette.red.main} isFront strokeWidth={2} ifOverflow='extendDomain' />
        <Area
          type='monotone'
          dataKey='value'
          stroke={palette.purple.main}
          fillOpacity={0.6}
          fill={`url(#area-gradient-${num})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default DoubleLineChart;
