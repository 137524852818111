import React from 'react';

import yaml from 'js-yaml';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation } from 'react-router-dom';

import Chip from 'snap-ui/Chip';
import { ConfirmDialog, DialogProgressContainer, DisplayDialog } from 'snap-ui/Dialog';
import Placeholder from 'snap-ui/Placeholder';
import Tabs, { TabLabel } from 'snap-ui/Tabs';
import Typography from 'snap-ui/Typography';

import useAnalyticEditor from 'aso/useAnalyticEditor';

import { URL_PARAMS_SCHEMA } from 'constants/ide';
import Path from 'constants/paths';

import useNoPermitCreateRedirect from 'hooks/useNoPermitCreateRedirect';
import useQueryString from 'hooks/useQueryString';

import { Engage, Fingerprint } from 'lib/Engagement';

import { checkAnalyticPermission } from 'module/Analytic/Analytic.util';
import { AnalyticLanguagesProvider } from 'module/Analytic/AnalyticLanguages';
import { AnalyticVersionProvider } from 'module/Analytic/core/AnalyticVersionProvider';
import IDEStatus, { IDEStatusState } from 'module/Analytic/core/IDE/IDEStatus';
import { WorkflowBadge } from 'module/Analytic/core/WorkflowState';
import { useAnalyticDetectionSummary } from 'module/Detection';
import { IntegrationOption } from 'module/Integration/IntegrationAutocomplete';
import MatchingLogs from 'module/MatchingLog';
import useSession from 'module/Session/useSession';
import { SyntaxCheckerProvider, useSyntaxCheckerContext } from 'module/SyntaxChecker';
import { useTagCatalog } from 'module/Tag';
import { IDEAnalyticTranslate } from 'module/TranslateAnalytic';
import NotFound from 'module/Util/Fallback/NotFound';
import DisabledButton from 'module/Widgets/DisabledButton';

import { useAuth, useIntegrationCatalog } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { Status } from 'storage';

import { AnalyticTabType } from 'types/analytic';
import { ContentPermission, FunctionalPermission } from 'types/auth';
import { ArtifactType, Guid, Ident } from 'types/common';
import { IDERouteGen } from 'types/route';

import { getLocation } from 'utilities/RouteGenUtils';
import { parseQueryString } from 'utilities/SearchParam';

import { IDEStateProvider, IDETranslationStateProvider, useIDEState } from './';
import { IDEPanel, IDERoot, IDEToolbar } from './IDE.style';
import AdvancedForm from './core/AdvancedForm';
import BuilderPanel from './core/BuilderPanel';
import BuilderToolbar from './core/BuilderToolbar';
import DetectionForm from './core/DetectionForm/DetectionForm';
import EditAsNativeDialog from './core/EditAsNativeDialog';
import MetadataForm from './core/MetadataForm';
import { PreviewLogs } from './core/PreviewLogs';
import RequiredFieldsModal from './core/RequiredFieldsModal';
import TargetLog from './core/TargetLog';
import useIDEReducer, { AddFromLogUpdate, validate } from './reducer';
import { useTestLogs } from './useTestLogs';

interface ActiveTabs {
  builder: AnalyticTabType;
  tester: number;
}

export function IDE(): React.ReactElement {
  const { push, replace } = useHistory();
  const { defaultOrgId, permission: organizations } = useAuth();
  const { search } = useLocation();
  const params: IDERouteGen['search'] = parseQueryString(search, URL_PARAMS_SCHEMA);
  const [selectedLogTab, setSelectedLogTab] = React.useState<'target-log' | 'matching-log'>('target-log');
  const { ideState, dispatch, tagOptions } = useIDEState();

  const {
    analytic,
    permissions,
    supplemental,
    fetchStatus,
    fetchError,
    save,
    saveError,
    saveStatus,
    publish,
    publishError,
    publishStatus,
    erase,
    eraseError,
    eraseStatus
  } = useAnalyticEditor(ideState, dispatch, tagOptions, params.detection);

  const [isCompiling, setIsCompiling] = React.useState(false);
  React.useEffect(() => {
    if (saveStatus !== Status.pending) setIsCompiling(false);
  }, [saveStatus]);

  const readonly =
    !!permissions?.permission?.length && !checkAnalyticPermission(permissions, supplemental, ContentPermission.Edit)[0];
  const { testLogs, testLogsStatus, cancelTest, getLabTest, getHuntTest } = useTestLogs();
  const [eagerTabSwitchNonce, setEagerTabSwitchNonce] = React.useState(false);
  const workflowState = analytic?.states?.find(state => state.organization_id === defaultOrgId)?.state;
  const [statusState, setStatusState] = React.useState(IDEStatusState.Ready);
  const [activeTabs, activeTabsDispatch] = React.useReducer(
    (state: ActiveTabs, action: Partial<ActiveTabs>) => ({ ...state, ...action }),
    {
      builder: AnalyticTabType.Detection,
      tester: 0
    } as ActiveTabs
  );
  const detection = useAnalyticDetectionSummary(ideState.guid);
  const { integrations } = useIntegrationCatalog();
  const { syntaxCheckerParams } = useSyntaxCheckerContext();

  const filterIntegrationTestOptions = React.useCallback(
    (option: IntegrationOption) => {
      const integration = integrations.all.find(i => i.id.toString() === option.value);
      if (!integration?.hunt_targets) return false;
      const organization = organizations.find(o => o.id === integration.organization_id);
      if (!checkTaskPermission([organization], FunctionalPermission.CreateAnalyticJob)) return false;
      if (ideState.isNative) return integration.hunt_targets.some(t => t.id === ideState.analyticForm.languageId);
      return true;
    },
    [ideState.analyticForm.languageId, ideState.isNative, integrations.all, organizations]
  );

  const updateParams = React.useCallback(
    (params: IDERouteGen['search']): void => {
      replace({
        ...getLocation({
          pathname: Path.IDE,
          search: {
            ...parseQueryString(search, URL_PARAMS_SCHEMA),
            ...params
          }
        }),
        state: { noScroll: true }
      });
    },
    [replace, search]
  );

  document.title = analytic?.name ? 'Edit ' + analytic?.name + ' | SnapAttack' : 'Detection IDE | SnapAttack';

  React.useEffect(() => {
    if (ideState.guid && params.detection !== ideState.guid) {
      updateParams({ detection: ideState.guid });
    }
  }, [ideState.guid, params.detection, updateParams]);

  React.useEffect(() => {
    // redirect to View page after successful publish
    if (ideState.guid && publishStatus === Status.resolved) {
      push(`${Path.Detection}/${ideState.guid}`);
    }
  }, [ideState.guid, publishStatus, push]);

  React.useEffect(() => {
    if (eraseStatus === Status.resolved) {
      push(
        getLocation({
          pathname: Path.Feed,
          search: {
            topic: ArtifactType.Analytic
          }
        })
      );
    }
  }, [eraseStatus, push]);

  React.useEffect(() => {
    if (saveStatus === Status.pending) {
      setStatusState(IDEStatusState.Saving);
    } else if (publishStatus === Status.pending) {
      setStatusState(IDEStatusState.Publishing);
    } else if (eraseStatus === Status.pending) {
      setStatusState(IDEStatusState.Deleting);
    } else if (testLogsStatus === Status.pending) {
      setStatusState(IDEStatusState.Testing);
    } else {
      setStatusState(IDEStatusState.Ready);
    }
  }, [saveStatus, publishStatus, eraseStatus, testLogsStatus]);

  const { session } = useSession(params.threat);

  const disableDetection = !!ideState.yamlParseError;
  const disableMetadata = ideState.yamlParseError && ideState.yamlParseError instanceof yaml.YAMLException;
  const disableSave = disableMetadata;

  React.useEffect(() => {
    // switch away from a disabled tab if it becomes disabled
    // this will switch to Advanced tab on load for analytics with unsupported detection logic
    if (disableDetection && activeTabs.builder === AnalyticTabType.Detection) {
      activeTabsDispatch({ builder: AnalyticTabType.Advanced });
    } else if (disableMetadata && activeTabs.builder === AnalyticTabType.Metadata) {
      activeTabsDispatch({ builder: AnalyticTabType.Advanced });
    }
  }, [activeTabs.builder, disableDetection, disableMetadata]);

  React.useEffect(() => {
    if (params.detection) {
      if (ideState.guid) {
        Engage.track(
          Fingerprint.load(Path.IDE).withData({
            guid: ideState.guid,
            artifact_organization_id: ideState.analyticForm.organization_id,
            artifact_organization_name: organizations?.find(o => o.id === analytic?.organization_id)?.name
          })
        );
        Engage.trackPersonIncrement(`view ${Path.IDE}`, 1);
      }
    } else {
      Engage.track(Fingerprint.load(Path.IDEReset));
      Engage.trackPersonIncrement(`view ${Path.IDEReset}`, 1);
    }
    // Disabled so we only capture once with the owning organization id
    // Changes here should be verified with engagement debugger enabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.detection, ideState.guid, organizations, analytic?.organization_id]);

  const [requiredFieldsModalAction, setRequiredFieldsModalAction] = React.useState<
    'save' | 'publish' | 'test' | 'hunt'
  >(null);

  const _validateAndSave = React.useCallback(() => {
    if (!isEmpty(validate({ ...ideState.analyticForm, raw: ideState.raw }))) {
      setRequiredFieldsModalAction('save');
      return;
    }
    save();
  }, [ideState.analyticForm, ideState.raw, save]);

  const validateAndSave = (): void => {
    // setTimeout is to try to avoid a race condition between form update from typing and
    // clicking the save button
    setTimeout(() => {
      _validateAndSave();
    }, 250);
  };

  const _validateAndPublish = React.useCallback(() => {
    if (!isEmpty(validate({ ...ideState.analyticForm, raw: ideState.raw }))) {
      setRequiredFieldsModalAction('publish');
      return;
    }
    publish();
  }, [ideState.analyticForm, ideState.raw, publish]);

  const validateAndPublish = (): void => {
    setTimeout(() => {
      _validateAndPublish();
    }, 250);
  };

  const _validateAndTest = React.useCallback(() => {
    if (!isEmpty(validate({ ...ideState.analyticForm, raw: ideState.raw }))) {
      setRequiredFieldsModalAction('test');
      return;
    }

    if (ideState.isModified) {
      setIsCompiling(true);
      save(true).then(({ guid }) => getLabTest(guid));
    } else {
      getLabTest(ideState.guid);
    }
  }, [ideState.analyticForm, ideState.isModified, ideState.guid, ideState.raw, save, getLabTest]);

  const validateAndTest = () => {
    setTimeout(() => {
      _validateAndTest();
    }, 250);
  };

  const [showHuntDialog, setShowHuntDialog] = React.useState(false);

  function handleOpenHunt() {
    setTimeout(() => {
      setShowHuntDialog(true);
    }, 250);
  }

  function handleDoHunt(raw: string, languageId: Ident, integrationId: string) {
    const integration = integrations.all.find(i => i.id.toString() === integrationId);
    const language = integration.hunt_targets.find(t => t.id === languageId);
    getHuntTest(raw, integration, language);
  }

  function handleDoNativeHunt() {
    const integration = integrations.all.find(i => i.id === syntaxCheckerParams?.integrationId);
    const language = integration.hunt_targets.find(t => t.id === syntaxCheckerParams?.languageId);
    getHuntTest(ideState.raw, integration, language);
  }

  const handleCloseModal = (): void => {
    setRequiredFieldsModalAction(null);
    dispatch({ type: 'FormUpdateAction', analyticForm: {}, refresh: true });
  };

  const handleStatusCancel = (): void => {
    setRequiredFieldsModalAction(null);
    cancelTest();
  };

  const handleSubmitFromModal = (): void => {
    if (requiredFieldsModalAction === 'save') save();
    else if (requiredFieldsModalAction === 'publish') publish();
    else if (requiredFieldsModalAction === 'test') {
      setIsCompiling(true);
      save(true).then(({ guid }) => getLabTest(guid));
    } else if (requiredFieldsModalAction === 'hunt') {
      setIsCompiling(true);
      save(true).then(() => setShowHuntDialog(true));
    }
    handleCloseModal();
  };

  const handlePinLog = (guid: Guid) => {
    const currentEvents = ideState.pinned_events || [];
    const newValues = currentEvents.includes(guid)
      ? [...currentEvents.filter(p => p !== guid)]
      : [...currentEvents, guid];

    dispatch({
      type: 'ModPinnedEventUpdate',
      pinned_events: newValues
    });
  };

  function handleLogClick(key: string, value: string) {
    const action: AddFromLogUpdate = { type: 'AddFromLogUpdate', addLog: { key, value } };

    dispatch(action);
  }

  const previewMatchingCount = testLogs?.length ?? 0;
  const logTabPanes = [
    {
      value: 'target-log',
      label: (
        <TabLabel>
          Target Logs <Chip label={ideState.pinned_events?.length || 0} />
        </TabLabel>
      ),
      content: (
        <div className='IDE-panelContent'>
          <TargetLog
            onPinLogChange={readonly ? undefined : handlePinLog}
            onTest={ideState.isNative ? undefined : validateAndTest}
          />
        </div>
      )
    },
    {
      value: 'matching-log',
      label: (
        <TabLabel>
          Test Results <Chip label={previewMatchingCount > 0 ? previewMatchingCount : detection.summary.total} />
        </TabLabel>
      ),
      content:
        previewMatchingCount > 0 ? (
          <div className='IDE-panelContent'>
            <PreviewLogs
              data={testLogs}
              status={statusState}
              pins={ideState.pinned_events}
              onPinLogChange={readonly ? undefined : handlePinLog}
            />
          </div>
        ) : (
          <div className='IDE-panelContent'>
            <MatchingLogs
              analyticGuid={ideState.guid}
              detectionSummary={detection}
              highlightProcessor={ideState.highlightProcessor}
              onLogClick={handleLogClick}
              onPinLogChange={readonly ? undefined : handlePinLog}
              onTest={ideState.isNative ? undefined : validateAndTest}
              pins={ideState.pinned_events}
            />
          </div>
        )
    }
  ];

  React.useEffect(() => {
    function beEager() {
      if (ideState.isNative || eagerTabSwitchNonce) return;
      if (fetchStatus !== Status.idle /* only IDLE and PENDING */) return;
      if (detection.status === Status.idle || detection.status === Status.pending) return;

      if (ideState.pinned_events?.length > 0) {
        setSelectedLogTab('target-log');
        setEagerTabSwitchNonce(true);
      } else if (previewMatchingCount > 0 || detection.summary.total > 0) {
        setSelectedLogTab('matching-log');
        setEagerTabSwitchNonce(true);
      }
    }

    beEager();
  }, [
    ideState.pinned_events,
    detection.summary.total,
    detection.status,
    eagerTabSwitchNonce,
    fetchStatus,
    testLogsStatus,
    previewMatchingCount,
    ideState.isModified,
    ideState.guid,
    getLabTest,
    ideState.isNative
  ]);

  if (fetchError) return <NotFound artifact={ArtifactType.Analytic} error={fetchError} />;

  return (
    <IDERoot>
      <div className='ide-header'>
        <Typography variant='h1'>
          {params.detection ? (
            <>
              <span>{analytic?.name || ideState.analyticForm.title || `Detection ${params.detection}`}</span>
              <WorkflowBadge state={workflowState} />
            </>
          ) : (
            'Detection Builder'
          )}
        </Typography>
        <div className='right-side'>
          <IDEStatus
            onCancelTest={handleStatusCancel}
            errors={saveError || publishError || eraseError}
            session={session}
            state={statusState}
          />
          {!readonly && (
            <DisabledButton
              ButtonProps={{
                id: 'CreateAnalyticPublishButton',
                'aria-label': 'Publish this detection',
                onClick: validateAndPublish
              }}
              disabled={fetchStatus === Status.pending}
              title='Publish this detection'
            >
              Publish Detection
            </DisabledButton>
          )}
        </div>
      </div>
      <div className='IDE-panelWrapper' key={params.detection}>
        <IDEPanel className='IDE-panel'>
          {params.detection === ideState.guid && (
            <BuilderPanel
              isFetching={fetchStatus === Status.pending}
              activeTab={activeTabs.builder}
              onTabChange={(tab): void => activeTabsDispatch({ builder: tab })}
              disableDetection={disableDetection}
              disableMetadata={disableMetadata}
              detectionComponent={
                <div className='IDE-panelContent'>
                  <DetectionForm readonly={readonly} />
                </div>
              }
              metadataComponent={
                <div className='IDE-panelContent'>
                  <MetadataForm readonly={readonly} />
                </div>
              }
              advancedComponent={
                !ideState.isNative && (
                  <div className='IDE-panelContent'>
                    <AdvancedForm syntaxError={ideState.yamlParseError} readonly={readonly} />
                  </div>
                )
              }
              toolbarComponent={
                <BuilderToolbar
                  emitChange={dispatch}
                  disableSave={disableSave}
                  handleDelete={erase}
                  handleHunt={handleOpenHunt}
                  handleSave={validateAndSave}
                  handlePreview={validateAndTest}
                  ideState={ideState}
                  isModified={ideState.isModified}
                  permissions={permissions}
                  supplemental={supplemental}
                  status={statusState}
                />
              }
            />
          )}
        </IDEPanel>
        <IDEPanel className='IDE-panel stacked'>
          <Tabs
            value={selectedLogTab}
            onChange={(e, v) => setSelectedLogTab(v)}
            nowrap
            tabs={logTabPanes}
            auxiliary={<IDEToolbar />}
          />
          <div className='IDE-translate'>
            <IDEAnalyticTranslate emitChange={dispatch} ideState={ideState} fetchStatus={fetchStatus} />
          </div>
        </IDEPanel>
      </div>
      {!!requiredFieldsModalAction && (
        <RequiredFieldsModal
          handleCancel={handleCloseModal}
          handleSubmit={handleSubmitFromModal}
          isOpen={!!requiredFieldsModalAction}
        />
      )}
      <EditAsNativeDialog
        disableSubmit={(language, integration) => {
          if (!language || !integration?.hunt_targets?.some(t => t?.id === language?.id)) {
            return 'Select a huntable language to test this detection';
          }
        }}
        filterIntegrationOptions={filterIntegrationTestOptions}
        isOpen={!ideState.isNative && showHuntDialog}
        onSubmit={handleDoHunt}
        onClose={() => setShowHuntDialog(false)}
        submitTitle={(l, integration) => (integration ? `Test in ${integration.name}` : 'Test')}
        title='Test this Detection in Your Integration'
      />
      <ConfirmDialog
        ConfirmProps={{ children: 'Run Test', onClick: handleDoNativeHunt }}
        DialogProps={{ open: ideState.isNative && showHuntDialog, onClose: () => setShowHuntDialog(false) }}
        title='Test Detection'
      >
        This will run the detection in {integrations.all.find(i => i.id === syntaxCheckerParams?.integrationId)?.name}
      </ConfirmDialog>
      <DisplayDialog
        DialogProps={{ open: isCompiling && saveStatus === Status.pending, onClose: () => setIsCompiling(false) }}
        title='Saving and Compiling...'
      >
        <DialogProgressContainer>
          <Placeholder variant='rectangular' height={10} animation='wave' />
        </DialogProgressContainer>
      </DisplayDialog>
    </IDERoot>
  );
}

export default function IDEWrapper(props: any) {
  useNoPermitCreateRedirect(ArtifactType.Analytic);
  const { getByKey } = useQueryString();
  const analyticGuid = getByKey('detection');
  const [ideState, dispatch] = useIDEReducer();
  const { data: tagOptions } = useTagCatalog();

  const ideStateValue = React.useMemo(() => ({ dispatch, ideState, tagOptions }), [dispatch, ideState, tagOptions]);

  return (
    <IDEStateProvider value={ideStateValue}>
      <SyntaxCheckerProvider>
        <AnalyticVersionProvider analyticGuid={analyticGuid}>
          <AnalyticLanguagesProvider guid={analyticGuid}>
            <IDETranslationStateProvider>
              <IDE {...props} />
            </IDETranslationStateProvider>
          </AnalyticLanguagesProvider>
        </AnalyticVersionProvider>
      </SyntaxCheckerProvider>
    </IDEStateProvider>
  );
}
