import { JsonSchema, JsonView } from 'module/JsonView';

export const Control: Record<string, string> = {
  Flag: 'flag',
  OpenSelect: 'openselect',
  Select: 'select',
  MultiSelect: 'multiselect',
  Number: 'number',
  Text: 'text',
  TextArea: 'textarea'
} as const;

export const ControlFriendlyName: Record<Control, string> = {
  [Control.Flag]: 'Boolean',
  [Control.OpenSelect]: 'Tag',
  [Control.Select]: 'Select',
  [Control.MultiSelect]: 'Multiple Select',
  [Control.Number]: 'Numeric Value',
  [Control.Text]: 'Single Line Text',
  [Control.TextArea]: 'Multiple Line Text'
} as const;

export type Control = (typeof Control)[keyof typeof Control];

export type TypeOption = {
  key: string;
  label: string;
  value: string;
};

export type ControlProps<T = string> = {
  config?: JsonView['config'];
  data: T;
  enabled: boolean;
  schema: JsonSchema;
  path: string; // Property path
  handleChange(path: string, value: T): void;
  className?: string;
};
