import React from 'react';

import { FormikErrors } from 'formik';
import get from 'lodash/get';

import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';

import { putOrg } from 'apis/resources/organization';

import { standardFormikBaseProps } from 'module/Form';

import { useAuth } from 'provider';

import { Preference } from 'types/auth';
import { ArtifactScore } from 'types/common';

import { DefaultThreshold } from './BulkConfidence.const';
import BulkConfidenceThresholdInput from './BulkConfidenceThresholdInput';

type Props = {
  className?: string;
  open: boolean;
  onClose: (close: boolean) => void;
  mandatory_preference?: Preference;
  refreshPreferredOrg: () => void;
};

type ThresholdParameter = Record<ArtifactScore, number>;

function ThresholdConfidence({ open, onClose, mandatory_preference, refreshPreferredOrg }: Props) {
  const { user } = useAuth();
  const [validForm, setValidForm] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);

  const highest = get(mandatory_preference, 'rank_threshold.Highest.max') || DefaultThreshold.Highest;
  const high = get(mandatory_preference, 'rank_threshold.High.max') || DefaultThreshold.High;
  const medium = get(mandatory_preference, 'rank_threshold.Medium.max') || DefaultThreshold.Medium;
  const low = get(mandatory_preference, 'rank_threshold.Low.max') || DefaultThreshold.Low;

  const initialValues = {
    [ArtifactScore.HIGHEST]: highest,
    [ArtifactScore.HIGH]: high,
    [ArtifactScore.MEDIUM]: medium,
    [ArtifactScore.LOW]: low
  };

  const handleSubmit = (formData: ThresholdParameter) => {
    setSubmitting(true);
    putOrg(user.preferred_organization.guid, {
      mandatory_preference: {
        rank_threshold: {
          Highest: {
            min: 0,
            max: formData.Highest,
            closed_min: true,
            closed_max: true
          },
          High: {
            min: formData.Highest,
            max: formData.High,
            closed_min: false,
            closed_max: true
          },
          Medium: {
            min: formData.High,
            max: formData.Medium,
            closed_min: false,
            closed_max: true
          },
          Low: {
            min: formData.Medium,
            max: formData.Low,
            closed_min: false,
            closed_max: true
          },
          Lowest: {
            min: formData.Low,
            closed_min: false,
            closed_max: true
          }
        }
      }
    }).then(() => {
      onClose(false);
      setSubmitting(false);
      refreshPreferredOrg();
    });
  };

  function checkValues(threshold: ThresholdParameter): FormikErrors<ThresholdParameter> {
    const ArtifactLevel = [
      ArtifactScore.HIGHEST,
      ArtifactScore.HIGH,
      ArtifactScore.MEDIUM,
      ArtifactScore.LOW,
      ArtifactScore.LOWEST
    ];

    for (let i = 1; i < ArtifactLevel.length; i++) {
      if (threshold[ArtifactLevel[i]] <= threshold[ArtifactLevel[i - 1]]) {
        setValidForm(false);
        return { [ArtifactLevel[i]]: `This number overlaps` };
      }
    }

    setValidForm(true);
    return null;
  }

  return (
    <FormDialog
      title='Set Confidence Thresholds'
      subtitle='Confidence tailoring measures detection noise in your organization. SnapAttack will measure the weekly hits over the last month in calculating the confidence value.'
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues,
        onSubmit: handleSubmit,
        validate: checkValues
      }}
      DialogProps={{
        open,
        onClose: () => onClose(false),
        maxWidth: 'md'
      }}
      SubmitProps={{
        children: submitting ? <CircularProgress color='primary' size={25} /> : 'Save',
        disabled: !validForm || submitting
      }}
    >
      {({ values }) => (
        <FieldsLayout className='field' maxWidth='md'>
          <BulkConfidenceThresholdInput priorThreshold={0} level={ArtifactScore.HIGHEST} />
          <BulkConfidenceThresholdInput priorThreshold={values[ArtifactScore.HIGHEST]} level={ArtifactScore.HIGH} />
          <BulkConfidenceThresholdInput priorThreshold={values[ArtifactScore.HIGH]} level={ArtifactScore.MEDIUM} />
          <BulkConfidenceThresholdInput priorThreshold={values[ArtifactScore.MEDIUM]} level={ArtifactScore.LOW} />
          <BulkConfidenceThresholdInput priorThreshold={values[ArtifactScore.LOW] + 1} level={ArtifactScore.LOWEST} />
        </FieldsLayout>
      )}
    </FormDialog>
  );
}

export default ThresholdConfidence;
