import React from 'react';

import { faRectangle, faSignalBars } from '@fortawesome/pro-regular-svg-icons';
import { faGrid } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Chip from 'snap-ui/Chip';
import { useGridApiRef } from 'snap-ui/DataGrid';
import Divider from 'snap-ui/Divider';
import Tabs from 'snap-ui/Tabs';

import { ValidationError } from 'apis';
import { CancelToken } from 'apis/snapattack';

import { BASParams, BulkLauncherButton, BulkLauncherDialog, BulkLauncherInterface } from 'module/BAS/Launcher';
import { Collection, CollectionDiscriminator } from 'module/Collection/Collection.type';
import useCollectionArtifacts from 'module/Collection/useCollectionArtifacts';
import { useDetectionCount } from 'module/Detection';
import {
  DetectionDeploymentDialog,
  DetectionDeploymentInterface,
  DetectionDeploymentMenuButton
} from 'module/DetectionDeployment';
import { useDetectionExportInterface, useRecommendedExportInterface } from 'module/Export';
import { DisplayKind } from 'module/Feed/Feed.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { JobType, huntInterfaceFactory } from 'module/Job';
import { HyperTag } from 'module/Landing/Landing.type';
import { BurgerClicker, Menu } from 'module/Layout/Artifact.widgets';
import { useMayI } from 'module/May';
import {
  MetadataBulkEditButton,
  MetadataBulkEditDialog,
  MetadataBulkEditInterface,
  useMetadataItems
} from 'module/Metadata';
import { useSupplementalItems } from 'module/Search';
import { bulkTaggingFactory } from 'module/Tag/BulkTag';

import { FeedExtraProvider, useUserConfig } from 'provider';
import { useRecommenderCatalog } from 'provider/Recommender/RecommenderProvider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType, Guid, isArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';

import { getQueryParam } from 'utilities/SearchParam';

import CollectionAuxiliaryAction from '../Collection/CollectionAuxiliaryAction';
import { addToCollectionInterfaceFactory } from './AddToCollection';
import { AddToCollection } from './AddToCollection/AddToCollection';
import { buildCuratedFilter } from './Curation.service';
import { TabType } from './Curation.type';
import { PendingCount } from './CurationCount';
import CurationTab from './CurationTab';
import CurationTabLabel from './CurationTabLabel';
import useArtifactCollectionCounts from './useCurationArtifactCounts';

const [SelectedATCInterface, SelectedATCButton, SelectedATCDialog] =
  addToCollectionInterfaceFactory('SelectedATCContext');
const [RecommendedATCInterface, RecommendedATCButton, RecommendedATCDialog] = addToCollectionInterfaceFactory(
  'AddRecommendedToCollectionContext'
);

const [AttackScriptATCInterface, AttackScriptATCButton, AttackScriptATCDialog] = addToCollectionInterfaceFactory(
  'AddAttackScriptToCollectionContext'
);

const [HuntInterface, HuntButton, HuntDialog] = huntInterfaceFactory('HuntContext');
const [BulkConfidenceInterface, BulkConfidenceButton, BulkConfidenceDialog] =
  huntInterfaceFactory('BulkConfidenceContext');
// const [IOCHuntInterface, IOCHuntButton, IOCHuntDialog] = huntInterfaceFactory('IOCHuntContext');
const [BulkTagInterface, BulkTagButton, BulkTagDialog] = bulkTaggingFactory('BulkTaggingContext');

export type CurationFeedProps = {
  children?: StrictReactNode;
  collection: Collection;
  hyperTag?: HyperTag;
  guid: Guid;
  infoContent: JSX.Element;
  infoLabel: string;
  onDelete?(): void;
  onClone?(): void;
  onError?(error: ValidationError): void;
  hideCheckboxes?: boolean;
};

export default function CurationFeed({ collection, ...others }: CurationFeedProps) {
  const cancelToken = React.useRef(CancelToken.source());
  const { guid } = collection;
  const { display, setDisplayKind } = useUserConfig();

  const { search, topic, toQuery, update } = useFilterRegistry(ArtifactType.Session);
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  // MATI_DELETION
  // const isIndicatorUser = useMayI(FunctionalPermission.LandingIndicators); // MATI_DELETION
  const isRecommendationUser = useMayI(FunctionalPermission.CalculateRecommendation);
  const gridApiRef = useGridApiRef();

  const [DetectionExportButton, DetectionExportDialog] = useDetectionExportInterface();
  const [RecommendedExportButton, RecommendedExportDialog] = useRecommendedExportInterface();

  const [page, setPage] = React.useState(1);
  const [selectedTab, setSelectedTab] = React.useState<TabType>('overview');
  // MATI_DELETION
  // const { intelPage, threatPage, detectionPage, validationPage, indicatorPage, getQueryPayload, ...feed } =
  const { intelPage, threatPage, detectionPage, validationPage, getQueryPayload, ...feed } = useCollectionArtifacts(
    collection,
    display === 'Card' ? 20 : 5000
  );
  const feedItems = feed[topic]?.data?.items || [];
  const [selectionModel, setSelectionModel] = React.useState<Guid[]>([]);

  const { detection: detectionCount, status: detectStatus } = useDetectionCount(topic, feedItems);
  const { supplemental, status: supStatus } = useSupplementalItems(topic, feedItems);
  const { metadata, status: metadataStatus, refresh: refreshMetadata } = useMetadataItems(topic, feedItems);
  const { getCounts, getCountsStatus } = useArtifactCollectionCounts(feedItems);

  const { status: recommenderCatalogStatus } = useRecommenderCatalog();

  const isRecommendedReady = recommenderCatalogStatus === Status.resolved || !isRecommendationUser;

  const handleChangePage = (
    page: number,
    callable: typeof intelPage | typeof threatPage | typeof detectionPage | typeof validationPage
    // MATI_DELETION
    // callable: typeof intelPage | typeof threatPage | typeof detectionPage | typeof validationPage | typeof indicatorPage
  ) => {
    if (cancelToken.current) cancelToken.current.cancel();
    cancelToken.current = CancelToken.source();
    setPage(page);
    callable(page, toQuery(), cancelToken.current);
  };

  const handleDisplayKindChange = (kind: DisplayKind) => {
    setPage(1);
    setDisplayKind(kind);
  };

  const urlTopic = getQueryParam(search, 'topic');
  React.useEffect(() => {
    if (isArtifactType(urlTopic)) setSelectedTab(urlTopic as TabType);
    else setSelectedTab('overview');
  }, [setSelectedTab, urlTopic]);

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: TabType) => {
    setSelectedTab(value);
    setPage(1);
    update({ topic: value === 'overview' ? '' : value });
  };

  let basParams: BASParams;
  if (collection.type === CollectionDiscriminator.Static) {
    basParams = { collectionGuid: guid, collectionName: collection.name };
  } else if (
    (
      [
        CollectionDiscriminator.Actor,
        CollectionDiscriminator.Attack,
        CollectionDiscriminator.Software,
        CollectionDiscriminator.Vulnerability
      ] as CollectionDiscriminator[]
    ).includes(collection.type)
  ) {
    basParams = { tagName: collection.name };
  } else if (others.hyperTag) {
    basParams = { tagName: collection.name };
  } else basParams = {};

  const criteria = React.useMemo(
    () => (selectionModel.length > 0 ? buildCuratedFilter(selectionModel) : getQueryPayload(topic, toQuery())),
    [getQueryPayload, selectionModel, topic, toQuery]
  );

  // Intel fetch/reset
  React.useEffect(() => {
    const cancelToken = CancelToken.source();
    if (guid) intelPage(1, toQuery(ArtifactType.Intel), cancelToken);
    return () => cancelToken.cancel();
  }, [guid, intelPage, toQuery]);

  const {
    intelligence: { reset: intelReset }
  } = feed;
  React.useEffect(() => {
    if (!guid) intelReset();
  }, [guid, intelReset]);

  // Threat fetch/reset
  React.useEffect(() => {
    const cancelToken = CancelToken.source();
    if (guid) threatPage(1, toQuery(ArtifactType.Session), cancelToken);
    return () => cancelToken.cancel();
  }, [guid, toQuery, threatPage]);

  const {
    threat: { reset: threatReset }
  } = feed;
  React.useEffect(() => {
    if (!guid) threatReset();
  }, [guid, threatReset]);

  // Detection fetch/reset
  React.useEffect(() => {
    const cancelToken = CancelToken.source();
    if (guid && isRecommendedReady) detectionPage(1, toQuery(ArtifactType.Analytic), cancelToken);
    return () => cancelToken.cancel();
  }, [guid, toQuery, detectionPage, isRecommendedReady]);

  const {
    detection: { reset: detectionReset }
  } = feed;
  React.useEffect(() => {
    if (!guid) detectionReset();
  }, [guid, detectionReset]);

  // MATI_DELETION BEGIN

  // IOC fetch/reset
  // React.useEffect(() => {
  //   const cancelToken = CancelToken.source();
  //   if (guid) indicatorPage(1, toQuery(ArtifactType.Indicator), cancelToken);
  //   return () => cancelToken.cancel();
  // }, [guid, toQuery, indicatorPage]);

  // const {
  //   indicator: { reset: indicatorReset }
  // } = feed;

  // React.useEffect(() => {
  //   if (!guid) indicatorReset();
  // }, [guid, indicatorReset]);

  // MATI_DELETION END

  // AttackScript fetch/reset
  React.useEffect(() => {
    const cancelToken = CancelToken.source();
    if (guid && isRecommendedReady) validationPage(1, toQuery(ArtifactType.AttackScript), cancelToken);
    return () => cancelToken.cancel();
  }, [guid, toQuery, validationPage, isRecommendedReady]);

  const {
    attack_script: { reset: validationReset }
  } = feed;
  React.useEffect(() => {
    if (!guid) validationReset();
  }, [guid, validationReset]);

  // Clear selection model if not grid
  React.useEffect(() => {
    if (display !== DisplayKind.Grid) setSelectionModel([]);
  }, [display, topic]);

  const tabs = [
    {
      value: 'overview',
      label: others.infoLabel,
      content: others.infoContent
    },
    {
      disabled: !collection.threat_intelligence_filter || feed.intelligence.data.total === 0,
      value: ArtifactType.Intel,
      content: (
        <CurationTab
          gridApiRef={gridApiRef}
          topic={ArtifactType.Intel}
          data={feed?.intelligence?.data}
          onChangePage={p => handleChangePage(p, intelPage)}
          isActive={feed?.intelligence?.status === Status.pending}
          page={page}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          display={display}
          hideCheckboxes={others.hideCheckboxes}
        />
      ),
      label: (
        <CurationTabLabel mode={ArtifactType.Intel} selectedTab={selectedTab}>
          Intelligence{' '}
          <PendingCount
            unavailable={!!collection.threat_intelligence_filter}
            active={feed.intelligence.status === Status.pending}
          >
            <Chip size='small' label={feed.intelligence.data.total} />
          </PendingCount>
        </CurationTabLabel>
      )
    },
    {
      disabled: !collection.session_filter || feed.threat.data.total === 0,
      value: ArtifactType.Session,
      content: (
        <CurationTab
          gridApiRef={gridApiRef}
          topic={ArtifactType.Session}
          data={feed.threat.data}
          onChangePage={p => handleChangePage(p, threatPage)}
          isActive={feed.threat.status === Status.pending}
          page={page}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          display={display}
          hideCheckboxes={others.hideCheckboxes}
        />
      ),
      label: (
        <CurationTabLabel mode={ArtifactType.Session} selectedTab={selectedTab}>
          Threats{' '}
          <PendingCount unavailable={!!collection.session_filter} active={feed.threat.status === Status.pending}>
            <Chip size='small' label={feed.threat.data.total} />
          </PendingCount>
        </CurationTabLabel>
      )
    },
    {
      disabled: !collection.analytic_filter || feed.detection.data.total == 0,
      value: ArtifactType.Analytic,
      content: (
        <CurationTab
          gridApiRef={gridApiRef}
          topic={ArtifactType.Analytic}
          data={feed.detection.data}
          onChangePage={p => handleChangePage(p, detectionPage)}
          isActive={feed.detection.status === Status.pending || !isRecommendedReady}
          page={page}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          display={display}
          hideCheckboxes={others.hideCheckboxes}
        />
      ),
      label: (
        <CurationTabLabel mode={ArtifactType.Analytic} selectedTab={selectedTab}>
          Detections{' '}
          <PendingCount
            unavailable={!!collection.analytic_filter}
            active={feed.detection.status === Status.pending || !isRecommendedReady}
          >
            <Chip size='small' label={feed.detection.data.total} />
          </PendingCount>
        </CurationTabLabel>
      )
    },
    // MATI_DELETION BEGIN
    // {
    //   // feed.ioc.data.total can be non-zero for a non-indicator user as a tease
    //   disabled: feed?.indicator?.data?.items?.length === 0 && feed.indicator.data.total === 0,
    //   value: ArtifactType.Indicator,
    //   content: isIndicatorUser ? (
    //     <CurationTab
    //       gridApiRef={gridApiRef}
    //       topic={ArtifactType.Indicator}
    //       data={feed.indicator.data}
    //       onChangePage={p => handleChangePage(p, indicatorPage)}
    //       isActive={feed.indicator.status === Status.pending}
    //       page={page}
    //       selectionModel={selectionModel}
    //       onSelectionModelChange={setSelectionModel}
    //       display={display}
    //       hideCheckboxes={others.hideCheckboxes}
    //     />
    //   ) : (
    //     <PaywallContainer>
    //       <PayWall isSubscriber />
    //     </PaywallContainer>
    //   ),
    //   label: (
    //     <CurationTabLabel mode={ArtifactType.Indicator} selectedTab={selectedTab}>
    //       Indicators{' '}
    //       <PendingCount unavailable={feed.indicator.data.total === 0} active={feed.indicator.status === Status.pending}>
    //         <Chip size='small' label={feed.indicator.data.total} />
    //       </PendingCount>
    //     </CurationTabLabel>
    //   )
    // },
    // MATI_DELETION END
    {
      disabled: !collection.bas_script_filter || feed.attack_script.data.total === 0,
      value: ArtifactType.AttackScript,
      content: (
        <CurationTab
          gridApiRef={gridApiRef}
          topic={ArtifactType.AttackScript}
          data={feed.attack_script.data}
          onChangePage={p => handleChangePage(p, validationPage)}
          isActive={feed.attack_script.status === Status.pending || !isRecommendedReady}
          page={page}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          display={display}
          hideCheckboxes={others.hideCheckboxes}
        />
      ),
      label: (
        <CurationTabLabel mode={ArtifactType.AttackScript} selectedTab={selectedTab}>
          Attack Scripts{' '}
          <PendingCount
            unavailable={!!collection.bas_script_filter}
            active={feed.attack_script.status === Status.pending || !isRecommendedReady}
          >
            <Chip size='small' label={feed.attack_script.data.total} />
          </PendingCount>
        </CurationTabLabel>
      )
    }
  ]
    // MATI_DELETION
    // .filter(tab => (tab.value === ArtifactType.Indicator ? isIOCCompatibleCollection(collection.type) : true))
    .filter(tab => (tab.value === ArtifactType.AttackScript ? isBasUser : true));

  if (!guid) return null;

  // MATI_DELETION BEGIN
  // function handleExportIOCs() {
  //   Engage.track(
  //     Fingerprint.of(Widget.CurationFeed)
  //       .withCommon(CommonEvent.Export)
  //       .withQualifier('indicators')
  //       .withData({
  //         [collection.type]: collection.name
  //       })
  //   );

  //   gridApiRef.current.exportDataAsCsv({
  //     fields: GridColumnsIOC.map(col => col.field),
  //     fileName: `${collection.name}_indicators_${formatCustomNow('YYYY_MM_DD')}`
  //   });
  // }
  // MATI_DELETION END

  return (
    <FeedExtraProvider
      detection={detectionCount}
      supplemental={supplemental}
      metadata={metadata}
      getCounts={getCounts}
      detectionPending={detectStatus === Status.pending}
      supplementalPending={supStatus === Status.pending}
      metadataPending={metadataStatus === Status.pending}
      getCountsStatus={getCountsStatus}
    >
      <div>
        <Tabs
          nowrap
          onChange={handleTabChange}
          value={selectedTab}
          tabs={tabs}
          auxiliary={
            <CollectionAuxiliaryAction>
              <SelectedATCInterface>
                <AttackScriptATCInterface>
                  <RecommendedATCInterface>
                    <BulkTagInterface>
                      <HuntInterface jobType={JobType.Hunt}>
                        <BulkConfidenceInterface jobType={JobType.Rank}>
                          {/* MATI_DELETION */}
                          {/* <IOCHuntInterface jobType={JobType.IOC}> */}
                          <DetectionDeploymentInterface>
                            <MetadataBulkEditInterface>
                              <BulkLauncherInterface topic={topic} criteria={criteria} basParams={basParams}>
                                <Menu size='small' disabled={selectedTab === 'overview'}>
                                  {ArtifactType.Analytic === selectedTab && <DetectionExportButton />}
                                  {ArtifactType.Session === selectedTab && <RecommendedExportButton />}
                                  {ArtifactType.Analytic === selectedTab && <DetectionDeploymentMenuButton />}
                                  {ArtifactType.Analytic === selectedTab && (
                                    <DetectionDeploymentMenuButton title='Undeploy Detections' undeploy />
                                  )}
                                  {ArtifactType.Analytic === selectedTab && <HuntButton />}
                                  {ArtifactType.Analytic === selectedTab && (
                                    <BulkConfidenceButton icon={faSignalBars} />
                                  )}
                                  {(
                                    [
                                      ArtifactType.Analytic,
                                      ArtifactType.Session,
                                      ArtifactType.AttackScript
                                    ] as TabType[]
                                  ).includes(selectedTab) && <BulkLauncherButton />}
                                  {(
                                    [
                                      ArtifactType.Intel,
                                      ArtifactType.Analytic,
                                      ArtifactType.Session,
                                      ArtifactType.AttackScript
                                    ] as TabType[]
                                  ).includes(selectedTab) && <SelectedATCButton />}
                                  {ArtifactType.Session === topic && (
                                    <RecommendedATCButton title='Add Recommended Detections to Collection' />
                                  )}
                                  {ArtifactType.Analytic === selectedTab && isBasUser && (
                                    <AttackScriptATCButton title='Add Linked Attack Scripts to Collection' />
                                  )}
                                  {/* MATI_DELETION BEGIN */}
                                  {/* {ArtifactType.Indicator === selectedTab && isIndicatorUser && (
                                      <BurgerClicker
                                        key='export_iocs'
                                        title='Export IOCs'
                                        icon={faFileCsv}
                                        onClick={handleExportIOCs}
                                        TooltipProps={{
                                          title: display === DisplayKind.Grid ? '' : 'Switch to grid view to export',
                                          placement: 'left'
                                        }}
                                        disabled={display === DisplayKind.Card}
                                      />
                                    )} */}
                                  {/* {ArtifactType.Indicator === selectedTab && isIndicatorUser && <IOCHuntButton />} */}
                                  {/* MATI_DELETION END */}
                                  {(
                                    [
                                      ArtifactType.Intel,
                                      ArtifactType.Analytic,
                                      ArtifactType.Session,
                                      ArtifactType.AttackScript
                                    ] as TabType[]
                                  ).includes(selectedTab) && (
                                    <BulkTagButton
                                      TooltipProps={{
                                        title: display === DisplayKind.Grid ? '' : 'Switch to grid view to edit tags',
                                        placement: 'left'
                                      }}
                                      disabled={display === DisplayKind.Card}
                                    />
                                  )}
                                  <MetadataBulkEditButton
                                    TooltipProps={{
                                      title:
                                        display === DisplayKind.Card
                                          ? 'Switch to grid view to bulk edit metadata'
                                          : isEmpty(selectionModel)
                                          ? 'Select items in the grid to continue'
                                          : '',
                                      placement: 'left'
                                    }}
                                    disabled={display === DisplayKind.Card || isEmpty(selectionModel)}
                                  />
                                  <Divider />
                                  <BurgerClicker
                                    key='card'
                                    title='Display as Card'
                                    icon={faRectangle}
                                    onClick={() => handleDisplayKindChange(DisplayKind.Card)}
                                    disabled={display === DisplayKind.Card}
                                  />
                                  <BurgerClicker
                                    key='grid'
                                    title='Display as Grid'
                                    icon={faGrid}
                                    onClick={() => handleDisplayKindChange(DisplayKind.Grid)}
                                    disabled={display === DisplayKind.Grid}
                                  />
                                </Menu>
                                <HuntDialog jobName={collection.name} query={criteria} selections={selectionModel} />
                                <BulkConfidenceDialog query={criteria} selections={selectionModel} />
                                {/* MATI_DELETION */}
                                {/* <IOCHuntDialog
                                    jobName={collection.name + ' IOC Hunt'}
                                    selections={
                                      display === DisplayKind.Grid && !isEmpty(selectionModel)
                                        ? selectionModel
                                        : feed.indicator.filteredItems.map(i => i.guid)
                                    }
                                  /> */}
                                <BulkLauncherDialog />
                                <DetectionDeploymentDialog
                                  criteria={criteria}
                                  onDeployed={() => detectionPage(page, toQuery(ArtifactType.Analytic))}
                                />
                                <DetectionExportDialog filter={criteria} />
                                <RecommendedExportDialog filter={criteria} />
                                <SelectedATCDialog
                                  type={topic as AddToCollection['type']}
                                  selectionModel={selectionModel}
                                  criteria={criteria}
                                  title='Add filtered items to a collection'
                                />
                                <AttackScriptATCDialog
                                  type={topic as AddToCollection['type']}
                                  selectionModel={selectionModel}
                                  criteria={criteria}
                                  fromRelatedAttackScripts={true}
                                />
                                <RecommendedATCDialog
                                  type={topic as AddToCollection['type']}
                                  selectionModel={selectionModel}
                                  criteria={criteria}
                                  fromRecommendedDetection
                                  title='Add Recommended Detections to Collection'
                                />
                                <BulkTagDialog selections={selectionModel} topic={topic} />
                                <MetadataBulkEditDialog
                                  selections={selectionModel}
                                  topic={topic}
                                  onSuccess={refreshMetadata}
                                />
                              </BulkLauncherInterface>
                            </MetadataBulkEditInterface>
                          </DetectionDeploymentInterface>
                          {/* MATI_DELETION */}
                          {/* </IOCHuntInterface> */}
                        </BulkConfidenceInterface>
                      </HuntInterface>
                    </BulkTagInterface>
                  </RecommendedATCInterface>
                </AttackScriptATCInterface>
              </SelectedATCInterface>
            </CollectionAuxiliaryAction>
          }
        />
        {others.children}
      </div>
    </FeedExtraProvider>
  );
}
