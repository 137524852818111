import React from 'react';

import { useHistory } from 'react-router-dom';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { Collection, CollectionDiscriminator } from 'module/Collection/Collection.type';
import { Discriminator } from 'module/Tag';

import { Status, useMountedRef } from 'storage';

import { ContentPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { caseInsensitiveStringEqual } from 'utilities/StringUtils';

import { HyperTag } from './Landing.type';
import { getAliasNames, getExpandedName, getSource } from './Landing.util';
import useLanding from './useLanding';

export type LandingCatalog = ReturnType<typeof useLanding> & {
  aliases: string[];
  collection: Collection;
  rollup: boolean;
  setRollup: (rollup: boolean) => void;
  expandedName: string;
  isAmbiguous: boolean;
  name: string;
  type: Discriminator;
  source: Partial<HyperTag>;
  handleExtends: () => void;
  handleReset: (event: React.MouseEvent) => void;
};

const LandingContext = React.createContext<LandingCatalog>(null);
LandingContext.displayName = 'LandingContext';

function useLandingCatalog(): LandingCatalog {
  const context = React.useContext<LandingCatalog>(LandingContext);

  if (!context) {
    throw new Error('useLandingCatalog must be used within the LandingContext');
  }

  return context;
}

function LandingProvider({
  children,
  type,
  name
}: {
  children: React.ReactNode;
  type: Discriminator;
  name: string;
}): React.ReactElement {
  const mounted = useMountedRef();
  const { push } = useHistory();

  const [rollup, setRollup] = React.useState(() => type === Discriminator.Attack);
  const landingAsyncBag = useLanding(type, name);
  const { data, task, getData, setData, stow, reset } = landingAsyncBag;

  const [sourceName] = React.useState<string>('combined');
  const source = getSource(sourceName, data);
  const [expandedName] = getExpandedName(name, source);
  useTitle(`${expandedName || 'Landing'} | SnapAttack`);

  const aliases = getAliasNames(expandedName, data.items);
  const [collection, setCollection] = React.useState<Collection>({ guid: null } as Collection);

  // Need to remove duplicates since we can have the same name from more than one source
  const isAmbiguous =
    [...new Map(data.items.map(i => [i.name, i])).values()].length > 1 &&
    !caseInsensitiveStringEqual(name, data.combined.name);

  React.useEffect(() => {
    if (name && type) {
      Engage.track(
        Fingerprint.load(Path.Collection).withData({
          content: ContentPermission.Read,
          artifact: ArtifactType.Landing,
          name,
          type,
          id: data.combined.id
        })
      );
      Engage.trackPersonIncrement(`view ${Path.Collection}`, 1);
    }
  }, [data.combined.id, name, type]);

  React.useEffect(() => {
    setCollection({ guid: null } as Collection);
    task(getData(rollup)).then(d => {
      if (mounted.current) {
        setData(d, Status.resolved);
        setCollection({
          name: expandedName,
          type: type as unknown as CollectionDiscriminator,
          guid: 'synthetic-collection',
          analytic_filter: d.analytic_filter,
          session_filter: d.session_filter,
          threat_intelligence_filter: d.threat_intelligence_filter,
          bas_script_filter: d.bas_script_filter
        } as Collection);
      }
    });
  }, [rollup, getData, task, setData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleExtends = () => {
    stow();
    push(`${Path.CollectionEdit}/${type}/${name}`);
  };

  const handleReset = (event: React.MouseEvent) => {
    if (!event.metaKey) reset();
  };

  const catalog: LandingCatalog = {
    ...landingAsyncBag,
    aliases,
    collection,
    rollup,
    setRollup,
    expandedName,
    isAmbiguous,
    name,
    type,
    source,
    handleExtends,
    handleReset
  };

  return <LandingContext.Provider value={catalog}>{children}</LandingContext.Provider>;
}

export { LandingProvider, useLandingCatalog };
