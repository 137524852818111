import get from 'lodash/get';
import moment from 'moment';

import { Host, Session } from 'module/Session/Session.type';

import { SigmaLogsource } from 'types/analytic';

export const formatSplunkHostname = (hosts: Host[] | undefined | null) => {
  return !hosts
    ? ''
    : hosts
        .filter(host => host.is_victim)
        .map(host => `form.hostname=${host.machine.name}`)
        .join('&');
};

export const getSplunkStartEndTime = (hosts: Host[] | undefined | null) => {
  let startTime: string, endTime: string;
  if (!hosts) return [];

  hosts.forEach(host => {
    if (!startTime || moment(host.start).isBefore(moment(host.end))) {
      startTime = host.start;
    }
    if (!endTime || moment(host.end).isAfter(moment(endTime))) {
      endTime = host.end;
    }
  });
  return [startTime, endTime];
};

export const formatSplunkTimestamp = (timestamp: string | number) => {
  if (!timestamp) {
    return timestamp;
  }
  return moment(timestamp).format('YYYY-MM-DD%20HH:mm:ss.SSS');
};

const resolveSigmaToSplunk = (logsource: SigmaLogsource) => {
  // Get values from Splunk
  // index=* | fillnull sigma_product, sigma_category, sigma_service | stats count by sigma_product, sigma_category, sigma_service
  if (logsource?.service?.toLowerCase() === 'sysmon') {
    return 'sysmon';
  } else if (logsource?.product?.toLowerCase() === 'windows') {
    return 'windows';
  } else if (logsource?.product?.toLowerCase() === 'zeek') {
    return 'zeek';
  } else if (
    ['aws', 'azure', 'gcp', 'google_workspace', 'm365', 'okta', 'onelogin'].includes(
      logsource?.product?.toLowerCase() ?? ''
    ) ||
    [
      'cloudtrail',
      'azure.activitylogs',
      'gcp.audit',
      'google_workspace.admin',
      'ThreatManagement',
      'okta',
      'onelogin.events'
    ].includes(logsource?.service?.toLowerCase() ?? '')
  ) {
    return 'cloud';
  } else if (logsource?.service?.toLowerCase() === 'auditd') {
    return 'auditd';
  } else {
    return undefined;
  }
};

interface splunkDashboardParameters {
  dashboard: string;
  session: Partial<Session>;
  neartime?: number | string;
  row_id?: number | string;
  analytic?: string;
  process_guid?: string;
  logsource?: SigmaLogsource;
}

export const splunkDashboardUrl = ({
  dashboard,
  session,
  neartime,
  row_id,
  analytic,
  process_guid,
  logsource
}: splunkDashboardParameters) => {
  if (!neartime && (!session.hosts || session.hosts.length === 0)) {
    return null;
  }
  // See snapattack/snapattack_splunk for documentation on dashboard parameters
  const range = getSplunkStartEndTime(get(session, 'hosts'));

  // Default to using the session's range when host range missing
  // if (!(range[0] && range[1])) range = [session?.start_time, session.end_time];

  const buff = [`${dashboard}?`];

  buff.push(`${formatSplunkHostname(get(session, 'hosts'))}`);
  buff.push(`&form.threat=${session?.guid}`);
  if (range[0]) buff.push(`&form.starttime=${formatSplunkTimestamp(range[0])}`);
  if (range[1]) buff.push(`&form.endtime=${formatSplunkTimestamp(range[1])}`);
  if (neartime) buff.push(`&form.time_preference=near&form.neartime=${formatSplunkTimestamp(neartime)}`);
  if (analytic) buff.push(`&form.detection=${analytic}`);
  if (row_id) buff.push(`&form.row_id=${row_id}`);
  if (process_guid) buff.push(`&form.process_guid=${process_guid}`);
  if (logsource) {
    const splunkLogsource = resolveSigmaToSplunk(logsource);
    if (splunkLogsource) buff.push(`&form.logsource=${splunkLogsource}`);
  }
  return buff.join('');
};
