import React from 'react';

import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';

import Grid from 'snap-ui/Grid';
import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import { BurgerClicker, Menu } from 'module/Layout/Artifact.widgets';

import { useAuth, usePushSnack } from 'provider';

import { formatCustomNow } from 'utilities/TimeUtils';

import { exportToPDF } from '../core/Dashboard.helper';
import { Header } from '../core/Dashboard.style';
import { EXPORT_FORMAT } from '../core/Dashboard.util';
import { DEMO_SNACK_MESSAGE } from '../core/Dashboard.widgets';
import DashboardGrid from '../core/DashboardGrid';
import NistActivity from './NistActivity';
import { NistDashboardContainer, NistDashboardPlaceHolder } from './NistDashboard.style';
import { NistArtifact } from './NistDashboard.type';
import NistTable from './NistTable';
import useNist from './useNist';

function NistDashboard() {
  useTitle('NIST Dashboard | SnapAttack');
  const { nistTags } = useNist();
  const { isSubscriber } = useAuth();
  const pushSnack = usePushSnack();
  const pdfExportRef = React.useRef<HTMLDivElement>(null);
  const todaysDate = formatCustomNow(EXPORT_FORMAT);
  const [currentNist, setCurrentNist] = React.useState([]);

  const updateNist = (filterNistTable: NistArtifact[]) => {
    setCurrentNist(filterNistTable);
  };

  React.useEffect(() => {
    if (!isSubscriber) {
      pushSnack(DEMO_SNACK_MESSAGE, 'info', 'center', 'bottom', 30000);
    }
  }, [isSubscriber, pushSnack]);
  React.useEffect(() => {
    setCurrentNist(nistTags);
  }, [nistTags]);

  return (
    <NistDashboardContainer>
      <div ref={pdfExportRef}>
        <DashboardGrid>
          <Grid item xs={12} md={12} lg={12}>
            <Header>
              <Typography variant='h1'>NIST 800-53 Dashboard</Typography>
              <Menu className='no-print'>
                <BurgerClicker
                  icon={faFilePdf}
                  onClick={() =>
                    exportToPDF({
                      ref: pdfExportRef,
                      fileName: `NIST_Dashboard_${todaysDate}.pdf`
                    })
                  }
                  title='Export PDF'
                />
              </Menu>
            </Header>
          </Grid>
        </DashboardGrid>
        {nistTags ? (
          <>
            <NistActivity nist={currentNist} />
            <NistTable nist={nistTags} updateNist={updateNist} />
          </>
        ) : (
          <NistDashboardPlaceHolder />
        )}
      </div>
    </NistDashboardContainer>
  );
}

export default NistDashboard;
