import moment from 'moment';

import { snapattack } from 'apis/snapattack';

import { EVERY, Hits, Stats, ViewedStats, CreatedStats, HuntedStats } from './SocDashboard.type';

const RANGES = {
  WEEK: 7,
  MONTH: 31,
  SIX_MONTHS: 180
};

export type PayloadBase = {
  start: Date;
  stop: Date;
};

type PayloadWithEvery = PayloadBase & {
  every: EVERY;
};

type StatsPayload = PayloadBase & {
  organization_id: number;
};

type HitsPayload = PayloadBase;

function editPayload(payload: PayloadBase, includeEvery = true): PayloadBase | PayloadWithEvery {
  const { start, stop } = payload;
  const startDate = moment(start);
  const endDate = moment(stop);
  const dateRange = Math.abs(startDate.diff(endDate, 'days'));
  let every = EVERY.DAY;
  let intermediatePayload: PayloadBase;

  if (dateRange <= RANGES.WEEK) {
    const newStop = moment(stop).subtract(7, 'd');
    intermediatePayload = { ...payload, start: newStop.toDate() };
  } else if (dateRange <= RANGES.MONTH) {
    intermediatePayload = { ...payload };
  } else if (dateRange <= RANGES.SIX_MONTHS) {
    every = EVERY.WEEK;
    intermediatePayload = { ...payload };
  } else if (dateRange > RANGES.SIX_MONTHS) {
    every = EVERY.MONTH;
    intermediatePayload = { ...payload };
  }

  if (includeEvery) {
    return { ...intermediatePayload, every };
  }

  return intermediatePayload;
}

export function getSocDashboardStats(payload: StatsPayload): Promise<Stats> {
  const params = { page: 0, size: 5 };
  const newPayload = editPayload(payload);
  return snapattack.post('/stats/integrations/', newPayload, { params }).then(r => r.data);
}

export function getIntegrationHits(payload: HitsPayload): Promise<Hits[]> {
  return snapattack.post('/stats/integrations/opensearch/hits/', editPayload(payload, false)).then(r => r.data);
}

export function getUserActivityViewedStats(payload: StatsPayload): Promise<ViewedStats> {
  const newPayload = editPayload(payload);
  return snapattack.post('/stats/activity/viewed/', newPayload).then(r => r.data);
}

export function getUserActivityHuntedStats(payload: StatsPayload): Promise<HuntedStats> {
  const newPayload = editPayload(payload);
  return snapattack.post('/stats/activity/hunted/', newPayload).then(r => r.data);
}

export function getUserActivityCreatedStats(payload: StatsPayload): Promise<CreatedStats> {
  const newPayload = editPayload(payload);
  return snapattack.post('/stats/activity/created/', newPayload).then(r => r.data);
}
