import { ActionGraph, DEPLOYED_DETECTION_TYPES } from 'module/Dashboard/SocDashboard/SocDashboard.type';

import { RiskMatrixDeployed, TITLES } from './RiskMatrix.const';

export const getDeployedDetectionTotals = (data: ActionGraph) => {
  return data.reduce(
    (totals: Record<DEPLOYED_DETECTION_TYPES, number>, row, rowIndex) => {
      row.forEach((col, colIndex) => {
        const { title: type } = TITLES[rowIndex][colIndex] as RiskMatrixDeployed;
        if (colIndex !== 0) totals[type] += col;
      });
      return totals;
    },
    {
      [DEPLOYED_DETECTION_TYPES.ALERT]: 0,
      [DEPLOYED_DETECTION_TYPES.HUNT]: 0,
      [DEPLOYED_DETECTION_TYPES.MONITOR]: 0,
      [DEPLOYED_DETECTION_TYPES.LOG]: 0
    }
  );
};
