import React from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Chip from 'snap-ui/Chip';
import Table from 'snap-ui/Table';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { ActionGraph, DEPLOYED_DETECTION_TYPES } from 'module/Dashboard/SocDashboard/SocDashboard.type';

import { formatQueryString, parseAnyQueryString } from 'utilities/SearchParam';
import { renderValueWithLabel } from 'utilities/TextUtils';

import ShouldLink from '../components/ShouldLink';
import { SEVERITY, CHIPS, EMPTY_VIEW, TOOL_TIP_TITLES, TITLES, RiskMatrixDeployed } from './RiskMatrix.const';
import { getDeployedDetectionTotals } from './RiskMatrix.util';

const Container = styled('div')`
  margin-top: 80px;
  padding-left: ${p => p.theme.spacing(5)};
  position: relative;
  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: ${p => p.theme.spacing(2)};
    }
    span {
      font-size: 1.2rem;
    }
  }
  .hide {
    display: none;
  }
  .chip {
    &.${DEPLOYED_DETECTION_TYPES.ALERT} {
      color: ${p => p.theme.palette.red.main};
      border-color: ${p => p.theme.palette.red.main};
    }
    &.${DEPLOYED_DETECTION_TYPES.MONITOR} {
      color: ${p => p.theme.palette.yellow.main};
      border-color: ${p => p.theme.palette.yellow.main};
    }
    &.${DEPLOYED_DETECTION_TYPES.LOG} {
      color: ${p => p.theme.palette.success.main};
      border-color: ${p => p.theme.palette.success.main};
    }
    &.${DEPLOYED_DETECTION_TYPES.HUNT} {
      color: ${p => p.theme.palette.orange.main};
      border-color: ${p => p.theme.palette.orange.main};
    }
  }

  .confidenceLabel {
    position: absolute;
    margin-top: 254px;
    left: 0;
    margin-left: -10px;
    transform: rotate(270deg);
    transform-origin: 0 0;
  }

  .chipContainer {
    display: flex;
    justify-content: center;
    .chip {
      margin-left: ${p => p.theme.spacing(3)};
      margin-top: ${p => p.theme.spacing(3)};
    }
  }

  .severityLabel {
    text-align: center;
  }

  .tableStyles {
    .container {
      color: #000;
      padding: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
      box-sizing: content-box;
    }
    .${DEPLOYED_DETECTION_TYPES.ALERT} {
      background: linear-gradient(135deg, ${p => p.theme.palette.red.light}, ${p => p.theme.palette.red.main});
    }
    .${DEPLOYED_DETECTION_TYPES.MONITOR} {
      background: linear-gradient(135deg, ${p => p.theme.palette.yellow.light}, ${p => p.theme.palette.yellow.main});
    }
    .${DEPLOYED_DETECTION_TYPES.LOG} {
      background: linear-gradient(135deg, ${p => p.theme.palette.success.light}, ${p => p.theme.palette.success.main});
    }
    .${DEPLOYED_DETECTION_TYPES.HUNT} {
      background: linear-gradient(135deg, ${p => p.theme.palette.orange.light}, ${p => p.theme.palette.orange.main});
    }

    .confidence {
      height: 60px;
      padding: 0;
      width: 100%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    thead {
      background: none;
    }

    .MuiTableRow-root:hover {
      background: none;
      background-color: none;
    }

    .Table-body-row {
      background-color: transparent;
    }

    .Table-row-cell {
      padding: 0;
      border: 1px solid rgba(81, 81, 81, 1);
    }
    .Table-column-cell {
      border: 1px solid rgba(81, 81, 81, 1);
    }

    .Table-column-cell:first-of-type {
      border: none;
    }
  }
`;

type RiskMatrixProps = {
  data: ActionGraph;
};

function RiskMatrix({ data }: RiskMatrixProps) {
  const { search } = useLocation();
  const matrixData = data.length === 0 ? EMPTY_VIEW : [...data.slice(1)];
  const totals = getDeployedDetectionTotals(matrixData);

  const rows = matrixData.map((row, rowIndex) => {
    return row.map((_, cellIndex) => {
      const { title, tableCellProps = {}, severity, confidence } = TITLES[rowIndex][cellIndex] as RiskMatrixDeployed;
      const className = title.replace(/\s/g, '');
      const isColumnHeader = cellIndex === 0;
      const isEmpty = Object.keys(tableCellProps).length === 0;

      const to = !isColumnHeader
        ? {
            pathname: '/feed',
            search: formatQueryString({
              ...parseAnyQueryString(search),
              page: '1',
              severity,
              confidence,
              topic: 'detection'
            })
          }
        : null;

      return {
        tableCellProps,
        className: isEmpty ? 'hide' : className,
        component: (
          <ShouldLink key={`${title} - ${cellIndex}`} to={to}>
            <div className={`container ${isColumnHeader ? 'confidence' : className}`}>
              {isColumnHeader ? (
                title
              ) : (
                <div className='titleContainer'>
                  <Typography variant='h2'>{title}</Typography>
                  <span>{renderValueWithLabel(totals[title], 'Detection')}</span>
                </div>
              )}
            </div>
          </ShouldLink>
        )
      };
    });
  });

  return (
    <Container>
      <div className='confidenceLabel'>CONFIDENCE</div>
      <div className='severityLabel'>SEVERITY</div>
      <Table className='tableStyles' aria-label='Risk Matrix' component='div' columns={SEVERITY} rows={rows} />
      <div className='chipContainer'>
        {CHIPS.map((chip, index) => {
          return (
            <Tooltip arrow placement='bottom' title={TOOL_TIP_TITLES[index]} key={index}>
              <Chip variant='outlined' size='small' label={chip} className={classNames('chip', chip)} />
            </Tooltip>
          );
        })}
      </div>
    </Container>
  );
}

export default RiskMatrix;
