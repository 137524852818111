import React from 'react';

import { Autocomplete, getDisplayValue } from 'snap-ui/Autocomplete';

import { useAuth, useManagedOrganizations } from 'provider';

import { Ident } from 'types/common';

const ALL = 'ALL';

function SuperUserOrgSelect({ value, onChange }: { value: Ident; onChange: (org: number) => void }) {
  const { user } = useAuth();

  const { organizations } = useManagedOrganizations();

  const orgOptions = React.useMemo(() => {
    if (organizations.length === 0) return [];
    const options = organizations.map(org => ({
      value: org.guid,
      content: org.name,
      orgId: org.id
    }));
    if (user.superuser) options.unshift({ value: ALL, content: 'All Organizations', orgId: null });

    return options;
  }, [organizations, user.superuser]);

  function handleOrgChange(org: string) {
    onChange(orgOptions.find(o => o.value === org).orgId);
  }
  const org = orgOptions.find(o => o.orgId === value)?.value;
  if (!user.superuser || organizations.length < 2) return null;
  return (
    <Autocomplete
      className='organization-select'
      options={orgOptions}
      onChange={handleOrgChange}
      name='organization select'
      value={organizations.length === 0 ? 'Organizations' : getDisplayValue(orgOptions, org)}
      disabled={organizations.length <= 1}
      disableClearable
      disableUserAdditions
    />
  );
}

export default SuperUserOrgSelect;
