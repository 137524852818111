import React from 'react';

import FormControlLabel from 'snap-ui/FormControlLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';
import { styled } from 'snap-ui/util';

import useQueryString from 'hooks/useQueryString';

import NotFound from 'module/Util/Fallback/NotFound';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';

import { getQueryParam } from 'utilities/SearchParam';

import useFilterRegistry from './useFilterRegistry';

const Container = styled('div')`
  padding: 50px;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 16px;

  .radio-group {
    flex-direction: row;
  }
`;

export default function FilterTest(): JSX.Element {
  const { user } = useAuth();
  const registry = useFilterRegistry(ArtifactType.Analytic);
  const { search, update } = useQueryString();
  const topic = getQueryParam(search, 'topic');

  if (!user.superuser) return <NotFound />;

  const toQuery = registry.toQuery();
  // const fromQuery = registry.fromQuery(toQuery)

  function handleTopicChange(_event: React.ChangeEvent<HTMLInputElement>, topic: string) {
    update({ topic });
  }

  return (
    <Container>
      <div>{registry.render()}</div>
      <div>
        <RadioGroup
          className='radio-group'
          defaultValue={ArtifactType.Analytic}
          name='topic'
          value={topic}
          onChange={handleTopicChange}
        >
          {Object.entries(ArtifactType).map(([key, value]) => (
            <FormControlLabel key={key} value={value} control={<Radio />} label={key} />
          ))}
        </RadioGroup>
        <pre>{JSON.stringify(toQuery, null, 2)}</pre>
      </div>
    </Container>
  );
}
