import * as React from 'react';

import { faEdit, faEllipsisVertical, faFilter, faSearch, faUsers } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import escapeRegExp from 'lodash/escapeRegExp';
import pick from 'lodash/pick';

import Button from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import Icon from 'snap-ui/Icon';
import Image from 'snap-ui/Image';
import Link from 'snap-ui/Link';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import { IconMenuItem } from 'snap-ui/MenuItem';
import Paper from 'snap-ui/Paper';
import Table from 'snap-ui/Table';
import TableSortLabel from 'snap-ui/TableSortLabel';
import TextField from 'snap-ui/TextField';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { ORG_ADMIN_FIELDS } from 'constants/organization';
import Path from 'constants/paths';

import usePaginate from 'hooks/usePaginate';
import useSort from 'hooks/useSort';
import useTitle from 'hooks/useTitle';

import PreferenceFilterDialog from 'module/Filter/PreferenceFilterDialog';
import May from 'module/May';
import TablePagination from 'module/Widgets/TablePagination';

import { useAuth, useAuthInterface, useManagedOrganizations, usePushSnack } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission, Organization, OrganizationTargetPayload } from 'types/auth';
import { SortDirection } from 'types/filter';

import { NOOP } from 'utilities/FunctionUtils';

import { BASE_PACKAGES } from '../Entitlements/Entitlements.const';
import OrgFormDialog from './OrgFormDialog';

export type OrgManagementProps = {
  className?: string;
};

const Toolbar = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${p => p.theme.spacing(2)};

  & .search-field {
    width: 200px;
  }
`;

type MappedOrganization = Organization & { mfa_required: boolean };

function OrgManagementComponent({ className }: OrgManagementProps) {
  useTitle('Organization Management | SnapAttack');

  const PAGE_SIZE = 15;
  const pushSnack = usePushSnack();

  const [isLoading, setIsLoading] = React.useState(false);
  const { user } = useAuth();
  const { fetch: fetchAuth } = useAuthInterface();
  const {
    organizations,
    create: createOrganization,
    resetError: resetOrganizationError,
    update: updateOrganization,
    error: organizationError,
    status: organizationStatus
  } = useManagedOrganizations();

  const mappedOrganizations = React.useMemo(() => {
    return organizations.map(organization => ({
      ...organization,
      mfa_required: organization?.mandatory_preference?.mfa_required || false
    }));
  }, [organizations]);

  const [filteredData, setFilteredData] = React.useState(mappedOrganizations);
  const { createSortHandler, order, sortedData } = useSort<MappedOrganization>(filteredData, {
    direction: SortDirection.asc,
    field: 'name'
  });
  const { page, pageData, pageTotal, handleChangePage } = usePaginate<MappedOrganization>(sortedData, PAGE_SIZE);

  const [editorOrganization, setEditorOrganization] = React.useState<Organization>(null);
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showFilterDialog, setFilterDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    // make sure API errors are reset when opening the editor
    if (showEditDialog) resetOrganizationError();
  }, [resetOrganizationError, showEditDialog]);

  // BEGIN filter table methods
  const [searchOrg, setSearchOrg] = React.useState('');
  function handleSearchOrgChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchOrg(event.target.value);
  }

  React.useEffect(() => {
    const searchOrgRE = new RegExp(escapeRegExp(searchOrg), 'i');
    setFilteredData(mappedOrganizations.filter(org => org.name.match(searchOrgRE)));
  }, [searchOrg, mappedOrganizations]);
  // END filter table methods

  // BEGIN add/edit modal methods
  function handleOpenNewOrganizationForm() {
    setEditorOrganization(null);
    setShowEditDialog(true);
  }

  function handleEditOrg(org: Organization) {
    return function () {
      setEditorOrganization(org);
      setShowEditDialog(true);
    };
  }

  function handleCloseEditor() {
    setEditorOrganization(null);
    setShowEditDialog(false);
    resetOrganizationError();
    setIsLoading(false);
  }

  function handleSaveFilterPref(default_feed_filter: Record<string, string | string[]>) {
    updateOrganization(editorOrganization.guid, {
      optional_preference: {
        default_feed_filter: default_feed_filter
      }
    }).then(() => {
      fetchAuth(); // need this to update the user provider that's being used on other tabs to get the new updated default feed filter
    });
  }
  async function handleSubmitEditor(values: OrganizationTargetPayload) {
    setIsLoading(true);
    try {
      if (editorOrganization) {
        const payload = user.superuser ? values : pick(values, ORG_ADMIN_FIELDS);
        await updateOrganization(editorOrganization.guid, payload);
      } else {
        if (user.superuser) await createOrganization(values);
      }
      handleCloseEditor();
      pushSnack(`${values.name} ${editorOrganization ? 'updated' : 'created'}.`, 'info', 'center', 'bottom', 5000);
    } catch (e) {
      setIsLoading(false);
    }
  }

  function editOrgPreferDefaults(org: Organization) {
    setShowEditDialog(false);
    setFilterDialog(true);
    setEditorOrganization(org);
  }

  // END add/edit modal methods
  return (
    <div className={classnames('OrganizationManagement', className)}>
      <Typography variant='h1'>Organization Management</Typography>
      <Paper>
        <Toolbar>
          <TextField
            className='search-field'
            onChange={handleSearchOrgChange}
            placeholder='Search Organizations'
            startAdornment={<Icon icon={faSearch} />}
          />
          {user.superuser && (
            <Button variant='outlined' onClick={handleOpenNewOrganizationForm}>
              Add Organization
            </Button>
          )}
        </Toolbar>
        <Table
          aria-label='Organizations'
          component='div'
          columns={[
            '',
            <TableSortLabel
              key='name'
              field='name'
              label='Name'
              sortHandler={createSortHandler('name')}
              order={order}
            />,
            <TableSortLabel
              key='subscriber'
              field='subscriber'
              label='Subscriber'
              sortHandler={createSortHandler('subscriber')}
              order={order}
            />,
            <TableSortLabel
              key='registration_mode'
              field='registration_mode'
              label='Registration Mode'
              sortHandler={createSortHandler('registration_mode')}
              order={order}
            />,
            <TableSortLabel
              key='mfa_required'
              field='mfa_required'
              label='MFA Required'
              sortHandler={createSortHandler('mfa_required')}
              order={order}
            />,
            'Actions'
          ]}
          rows={pageData.map(org => [
            <>{org?.small_image ? <Image src={org.small_image} width={20} height={20} /> : null}</>,
            <>
              {organizations.length > 0 ? <Link to={`${Path.UserManagement}/${org.guid}`}>{org.name}</Link> : org.name}
            </>,
            org.subscriber ? 'Yes' : 'No',
            org.registration_mode,
            org.mfa_required ? 'Yes' : 'No',
            <>
              <div className='align-right'>
                <MenuTrigger
                  key={org.id}
                  trigger={<MenuButton aria-label={`${org.name} Menu`} icon={faEllipsisVertical} />}
                >
                  <May key='editOrg' I={FunctionalPermission.CreateGroup}>
                    <IconMenuItem
                      icon={faEdit}
                      text='Edit Org'
                      onClick={handleEditOrg(org)}
                      tooltip={`Edit ${org.name}`}
                      placement='right'
                    />
                  </May>

                  <May key='editUser' I={FunctionalPermission.CreateGroup}>
                    <Link to={`${Path.UserManagement}/${org.guid}`}>
                      <IconMenuItem
                        icon={faUsers}
                        text='Edit Users'
                        onClick={NOOP}
                        tooltip={`Edit ${org.name} Users`}
                        placement='right'
                      />
                    </Link>
                  </May>

                  <May key='editFilters' I={FunctionalPermission.CreateGroup}>
                    <IconMenuItem
                      icon={faFilter}
                      text='Edit Default Filters'
                      onClick={() => editOrgPreferDefaults(org)}
                      tooltip={`this affects your organization default filter across the app`}
                      placement='right'
                    />
                  </May>
                </MenuTrigger>
              </div>
            </>
          ])}
        />
        {pageData?.length < 1 && !searchOrg && (
          <Typography className='align-center' sx={{ marginTop: '10px' }}>
            <CircularProgress size={16} sx={{ marginLeft: '5px' }} /> Loading organizations...
          </Typography>
        )}
        {pageData?.length < 1 && searchOrg && (
          <Typography className='align-center' sx={{ marginTop: '10px' }}>
            No organizations found matching {searchOrg}
          </Typography>
        )}
        <TablePagination changePage={handleChangePage} numPages={pageTotal} page={page} zeroIndex />
      </Paper>
      <OrgFormDialog
        isLoading={isLoading}
        apiError={organizationError}
        initialTarget={
          editorOrganization
            ? {
                content_provider: editorOrganization.content_provider || false,
                default_roles: editorOrganization.default_roles || [],
                domains: editorOrganization.domains || [],
                entitlements:
                  editorOrganization.entitlements
                    .map(entitlement => entitlement.guid)
                    .filter(guid => !BASE_PACKAGES.includes(guid)) || [],
                small_image: editorOrganization.small_image || '',
                large_image: editorOrganization.large_image || '',
                isolated: editorOrganization.isolated || false,
                registration_mode: editorOrganization.registration_mode || '',
                name: editorOrganization.name || '',
                mandatory_preference: {
                  mfa_required: editorOrganization.mandatory_preference?.mfa_required,
                  disable_ai: editorOrganization.mandatory_preference?.disable_ai
                },
                optional_preference: {
                  default_analytic_compilation_target:
                    editorOrganization.optional_preference?.default_analytic_compilation_target || null
                },
                subscriber: editorOrganization.subscriber || false,
                bulk_rank_active: editorOrganization.bulk_rank_active || false,
                detection_author_override: editorOrganization.detection_author_override || '',
                allow_impersonation: editorOrganization.allow_impersonation || false
              }
            : undefined
        }
        onClose={handleCloseEditor}
        onSubmit={handleSubmitEditor}
        open={showEditDialog}
        openFilterDialog={() => editOrgPreferDefaults(editorOrganization)}
      />
      <PreferenceFilterDialog
        initialValues={editorOrganization?.optional_preference?.default_feed_filter}
        isEditOrg
        isOpen={showFilterDialog}
        isPending={organizationStatus === Status.pending}
        onClose={() => setFilterDialog(false)}
        onSave={handleSaveFilterPref}
      />
    </div>
  );
}

const OrgManagement = styled(OrgManagementComponent)<OrgManagementProps>`
  margin-bottom: ${p => p.theme.spacing(9)};

  & .MuiTypography-h1 {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  & .align-center {
    text-align: center;
  }
`;

export default OrgManagement;
