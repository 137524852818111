import isEmpty from 'lodash/isEmpty';

import { GridColDef, GridSortCellParams } from 'snap-ui/DataGrid';

import { DetectionSummary } from 'module/Detection';
import { TagWeight, ThreatProfileTag } from 'module/ThreatProfile';
import { TAG_WEIGHTS } from 'module/ThreatProfileWizard/ThreatProfile.util';

import { Artifact, ArtifactScore } from 'types/common';

const ArtifactScoreIndex = Object.values(ArtifactScore);

export function getInitiallyHiddenColumns(
  columns: (GridColDef<Artifact> & { hideToStart?: boolean })[]
): Record<string, boolean> {
  return columns
    .filter(c => c.hideToStart)
    .reduce(
      (p, c) => ({
        ...p,
        [c.field]: false
      }),
      {}
    );
}

/**
 * Could be used for Severity And Rank (Confidence) fields. However, our data isn't 2d. Is's 3d. We cannot
 * sort on the given field since supplemental calls are done. Need a better solution.
 */
export function scoreComparator(
  _v1: ArtifactScore,
  _v2: ArtifactScore,
  cellParams1: GridSortCellParams<ArtifactScore>,
  cellParams2: GridSortCellParams<ArtifactScore>
) {
  return (
    ArtifactScoreIndex.indexOf(cellParams1.value || ArtifactScore.UNKNOWN) -
    ArtifactScoreIndex.indexOf(cellParams2.value || ArtifactScore.UNKNOWN)
  );
}

export function threatPriorityComparator(value1: ThreatProfileTag, value2: ThreatProfileTag) {
  return (
    TAG_WEIGHTS.indexOf(value1?.score_label || TagWeight.Ignored) -
    TAG_WEIGHTS.indexOf(value2?.score_label || TagWeight.Ignored)
  );
}

export function hitsComparator(v1: DetectionSummary, v2: DetectionSummary) {
  if (!v1 || !v2) return 0;
  const v1Total = v1.validated + v1.validated_gaps + v1.unvalidated;
  const v2Total = v2.validated + v2.validated_gaps + v2.unvalidated;
  return v1Total - v2Total;
}

export function validationValue(validationObj: DetectionSummary) {
  if (isEmpty(validationObj)) return 5;
  if (validationObj.validated > 0) return 1;
  else if (validationObj.validated_gaps > 0) return 2;
  else if (validationObj.unvalidated > 0) return 3;
  else if (validationObj.validated === 0 && validationObj.validated_gaps === 0 && validationObj.unvalidated === 0) {
    return 4;
  }
}

export function validationComparator(v1: DetectionSummary, v2: DetectionSummary) {
  return validationValue(v1) - validationValue(v2);
}
