import React from 'react';

import { CancelTokenSource } from 'axios';
import omit from 'lodash/omit';

import { useFilterRegistry } from 'module/GlobalFilter';
import SoftwareFilterConfig from 'module/GlobalFilter/Filters/Software';
import ThreatActorFilterConfig from 'module/GlobalFilter/Filters/ThreatActor';
import { filterStatistic } from 'module/Tag/Tag.service';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { ArtifactType } from 'types/common';

import { Statistic, Summary } from './Matrix.type';

const initial: StatisticSummary = {
  items: [],
  canceled: false
};

export const reducer = (previousValue: Summary, currentValue: Statistic) => {
  return {
    ...previousValue,
    [currentValue.id]: currentValue
  };
};

type StatisticSummary = { items: Statistic[]; canceled?: boolean };

export default function useSummary(): {
  summary: Summary;
  empty: boolean;
  fetch(cancelSource: CancelTokenSource): void;
  statisticStatus: Status;
} {
  const {
    user: { id: userId },
    defaultOrgId: orgId
  } = useAuth();
  const { data, run, status } = useAsync<StatisticSummary>(initial);
  const { generateQuery, values } = useFilterRegistry(ArtifactType.Analytic);

  const { analyticFilter, tagFilter } = React.useMemo(() => {
    if (!values) return { analyticFilter: null, tagFilter: null };

    // remove Software and ThreatActor values when generating analytic filter
    const actorDefaultFn =
      ThreatActorFilterConfig.defaults[ArtifactType.Analytic] || ThreatActorFilterConfig.defaults.default;
    const actorDefaultValues = actorDefaultFn({ userId, orgId });
    const softwareDefaultFn =
      SoftwareFilterConfig.defaults[ArtifactType.Analytic] || SoftwareFilterConfig.defaults.default;
    const softwareDefaultValues = softwareDefaultFn({ userId, orgId });
    const analyticValues = omit(values, [...Object.keys(actorDefaultValues), ...Object.keys(softwareDefaultValues)]);

    return {
      analyticFilter: generateQuery(ArtifactType.Analytic, analyticValues),
      tagFilter: generateQuery(ArtifactType.AttackTag, values)
    };
  }, [generateQuery, values, userId, orgId]);

  const fetch = React.useCallback(
    (cancelSource: CancelTokenSource) => {
      run(filterStatistic(analyticFilter, tagFilter, cancelSource), true);
    },
    [run, analyticFilter, tagFilter]
  );

  const summary = React.useMemo(() => data.items.reduce(reducer, {}), [data]);

  return {
    summary,
    empty: status === Status.resolved && data.items.length === 0,
    fetch,
    statisticStatus: status
  };
}
