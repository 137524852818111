import { CancelTokenSourceType, CancelTokenType, snapattack } from 'apis/snapattack';

import { LogPreviewResult } from 'module/Detection';

import { Guid } from 'types/common';

import {
  Job,
  JobGroup,
  JobOverviewDetail,
  JobOverviewFeed,
  JobOverviewFilters,
  JobResult,
  JobStatus,
  PostJobsInput
} from './Job.type';

const prefix = '/jobs';
const jobs = `${prefix}/`;

export function postJobs(body: PostJobsInput): Promise<JobGroup> {
  return snapattack.post(jobs, body).then(res => res.data);
}

export function getJobs(): Promise<Job[]> {
  return snapattack.get(jobs).then(res => res.data);
}

export function getJobSchemaCatalog(): Promise<Record<string, unknown>> {
  return snapattack.get(`${jobs}schema/`).then(res => res.data);
}

export function getJobOverview(
  config: JobOverviewFilters,
  cancelTokenSource?: CancelTokenSourceType
): Promise<JobOverviewFeed> {
  return snapattack
    .get(`${prefix}/overview/`, { params: config, cancelToken: cancelTokenSource?.token })
    .then(r => r.data);
}

export function getJobOverviewResult(guid: Guid): Promise<JobOverviewDetail[]> {
  return snapattack.get(`${prefix}/overview/${guid}/`).then(res => res.data);
}

export function getJobGroup(groupGuid: Guid, cancelToken?: CancelTokenType): Promise<JobGroup> {
  return snapattack.get(`/jobs/groups/${groupGuid}/`, { cancelToken }).then(r => r.data);
}

export function getJobGroups(): Promise<JobGroup[]> {
  return snapattack.get(`${jobs}groups/`).then(res => res.data);
}

export function getSingleJob(guid: Guid): Promise<Job> {
  return snapattack.get(`${jobs}${guid}/`).then(r => r.data);
}

export function pauseJob(guid: Guid): Promise<void> {
  return snapattack.post(`${jobs}${guid}/pause/`).then(res => res.data);
}

export function resumeJob(guid: Guid): Promise<void> {
  return snapattack.post(`${jobs}${guid}/resume/`).then(res => res.data);
}

export function cancelJob(guid: Guid): Promise<JobResult> {
  return snapattack.post(`${jobs}${guid}/cancel/`).then(res => res.data);
}

export function deleteJob(guid: Guid): Promise<void> {
  return snapattack.delete(`${jobs}${guid}/`).then(res => res.data);
}

export function getJobResult(guid: Guid): Promise<JobResult> {
  return snapattack.get(`${jobs}${guid}/result/`).then(res => res.data);
}

export function postClientHitsExport(groupGuid: Guid, params: { start: string; end: string }): Promise<string> {
  return snapattack
    .post(`/detection/events/client/analytic_hits/job/groups/export/${groupGuid}/raw/`, { csv: true, ...params })
    .then(r => r.data);
}

export function getClientHitsByGroup(groupGuid: Guid, cancelToken?: CancelTokenType): Promise<LogPreviewResult> {
  return snapattack
    .post(
      `/detection/events/client/analytic_hits/job/groups/${groupGuid}/`,
      { size: 0, flatten: true },
      { cancelToken }
    )
    .then(r => r.data);
}

const CLIENT_HITS_MAX_RETRY = 5;
export function pollForClientHits(
  groupGuid: Guid,
  cancelToken?: CancelTokenType,
  clientHitsCallback?: (response: LogPreviewResult) => void,
  retries = 0
): Promise<LogPreviewResult> {
  return getClientHitsByGroup(groupGuid, cancelToken).then(clientHits => {
    if (clientHitsCallback) clientHitsCallback(clientHits);
    if (clientHits.count !== 0 || retries >= CLIENT_HITS_MAX_RETRY) return clientHits;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        pollForClientHits(groupGuid, cancelToken, clientHitsCallback, ++retries).then(resolve).catch(reject);
      }, 2000);
    });
  });
}

export function pollForGroupCompletion(
  groupGuid: Guid,
  cancelToken?: CancelTokenType,
  jobGroupCallback?: (response: JobGroup) => void
): Promise<JobGroup> {
  return getJobGroup(groupGuid, cancelToken).then(group => {
    if (jobGroupCallback) jobGroupCallback(group);
    if (![JobStatus.Started, JobStatus.Pending].includes(group.status)) return group;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        pollForGroupCompletion(groupGuid, cancelToken, jobGroupCallback).then(resolve).catch(reject);
      }, 2000);
    });
  });
}
