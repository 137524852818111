import React from 'react';

import { CancelToken, CancelTokenType } from 'apis/snapattack';

import { JobGroup, pollForClientHits, pollForGroupCompletion } from 'module/Job';

import useAsync from 'storage/useAsync';

import { Guid } from 'types/common';

import { useAdHocQueryContext } from './AdHocQueryProvider';

export default function usePollAdHocQueries(groupGuid: Guid, isJobRunning: boolean, hitsFetched: boolean) {
  const { task } = useAsync();
  const { updateQueryHistoryResponse, updateQueryHistoryHits } = useAdHocQueryContext();

  const pollJobGroup = React.useCallback(
    (cancelToken: CancelTokenType) => {
      function updateCallback(response: JobGroup) {
        updateQueryHistoryResponse(response.jobs[0].guid, response);
      }
      if (groupGuid && isJobRunning) {
        task(pollForGroupCompletion(groupGuid, cancelToken, updateCallback));
      }
    },
    [groupGuid, isJobRunning, task, updateQueryHistoryResponse]
  );

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    pollJobGroup(cancelSource.token);
    return () => cancelSource.cancel();
  }, [pollJobGroup]);

  const fetchHits = React.useCallback(
    (cancelToken: CancelTokenType) => {
      if (groupGuid && !isJobRunning && !hitsFetched) {
        task(pollForClientHits(groupGuid, cancelToken)).then(result => {
          // OS job should be completed by this point
          updateQueryHistoryHits(groupGuid, result.count);
        });
      }
    },
    [groupGuid, hitsFetched, isJobRunning, task, updateQueryHistoryHits]
  );

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    fetchHits(cancelSource.token);
    return () => cancelSource.cancel();
  }, [fetchHits]);
}
