import React from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useLocation } from 'react-router-dom';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Link from 'snap-ui/Link';
import MuiPopper from 'snap-ui/Popper';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';
import { ClickAwayListener, styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import { cleanHTML } from 'module/Card/Card.util';
import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { IndicatorSearchResponse } from 'module/IOC/IOC.type';
import { IOCScoreContainer } from 'module/IOC/IOCCard.style';
import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { Glossary, MenuOrdinal } from 'module/Scaffold/Scaffold.type';
import { BaseTag, Discriminator, Tag, getLandingLink } from 'module/Tag';

import { useUserConfig } from 'provider';

import { ArtifactType } from 'types/common';

import { NOOP } from 'utilities/FunctionUtils';
import { formatQueryString } from 'utilities/SearchParam';
import { highlightSearchTerm, truncateMiddle } from 'utilities/StringUtils';

import { NameWithAliases } from './NameWithAliases';
import { CategoryResult, CategoryResultItem } from './type';
import useSearchBar, { INDICATOR_SEARCH_MIN_LENGTH } from './useSearchBar';

const CollectionTypes: CategoryResultItem['type'][] = [
  CollectionDiscriminator.Static,
  CollectionDiscriminator.Dynamic,
  CollectionDiscriminator.Hybrid
];

export const SearchDisplayPreference = {
  Indicators: 'Indicators',
  Content: 'Content'
} as const;

export type SearchDisplayPreference = (typeof SearchDisplayPreference)[keyof typeof SearchDisplayPreference];

const Input = styled(TextField)`
  min-width: 150px;
`;

const Popper = styled(MuiPopper)`
  align-items: center;
  display: flex;
`;

const MenuList = styled('div')`
  max-height: 820px;
  width: 450px;
  overflow: auto;
  transform-origin: center top;
  white-space: normal;
  text-align: left;
  text-transform: none;
  background: ${p => p.theme.palette.common.white};
  margin-top: ${p => p.theme.spacing(2)};
  border-radius: 0;
  border: 1px solid ${p => p.theme.palette.secondary.light};
  .category {
    background-color: ${p => p.theme.palette.common.black};
    display: table-row;
    background: ${p => p.theme.palette.common.white};
    box-shadow: none;
    transition: background 0.1s ease, border-color 0.1s ease;
  }

  .search-type-toggle-container {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    justify-content: space-between;
    align-items: center;
    padding: ${p => p.theme.spacing(3)};
    background-color: ${p => p.theme.palette.secondary.main};
    border-bottom: 1px solid ${p => p.theme.palette.secondary.dark};

    &.query-length-message {
      flex-direction: column;
      justify-content: center;
    }
  }

  .layout {
    background-color: ${p => p.theme.palette.secondary.main};
  }

  .name-container {
    display: flex;
    justify-content: space-between;
    padding: ${p => p.theme.spacing(3)};

    .total-number {
      display: inline-block;
      line-height: 1;
      font-size: 13px;
      vertical-align: baseline;
      margin: 0 2px;
      background-color: ${p => p.theme.palette.secondary.dark};
      background-image: none;
      padding: 10px 14px;
      text-transform: none;
      font-weight: bold;
      border: 0px solid transparent;
      border-radius: 4px;
      transition: background 0.1s ease;
    }

    .more-results {
      color: ${p => p.theme.palette.primary.main};
      text-decoration: none;
      margin-left: ${p => p.theme.spacing(3)};
    }
  }

  .results {
    background-color: ${p => p.theme.palette.common.black};
    width: 448px;
    display: table-cell;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    border-bottom: 1px solid ${p => p.theme.palette.secondary.dark};

    .result {
      display: flex;
      justify-content: space-between;
      padding: ${p => p.theme.spacing(3)};
      color: ${p => p.theme.palette.common.white};
      border-bottom: 1px solid ${p => p.theme.palette.secondary.dark};
      transition: background 0.1s ease, border-color 0.1s ease;
      background-color: ${p => p.theme.palette.common.black};
      line-height: 1.33;
      overflow: hidden;
      font-size: 16px;
      cursor: pointer;
      align-items: center;
      text-decoration: none;
      border-left: 1px solid rgba(34, 36, 38, 0.15);

      .result-title {
        flex: 1 1 auto;
        max-width: 395px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 10px;
        padding-right: 10px;
      }

      &.indicator {
        justify-content: unset;
        gap: ${p => p.theme.spacing(4)};
      }

      .indicator-name {
        flex-grow: 1;

        .highlight span {
          color: ${p => p.theme.palette.primary.main};
        }
      }

      & .locked {
        color: ${p => p.theme.palette.grey[600]};
      }

      .locked-icon {
        flex: 0 0 10px;
      }

      &:hover {
        background-color: ${p => p.theme.palette.surface.hover};
      }
    }
  }
`;

type Props = {
  glossary: Glossary;
  onClose(): void;
  onClick(id: string, element: HTMLElement): void;
};

function makeFeedLink(topic: string, query: string) {
  return (
    Path.Feed +
    formatQueryString({
      topic,
      query
    })
  );
}

function SearchBar({ glossary, onClick, onClose }: Props) {
  const { pathname } = useLocation();
  const { searchDisplay } = useUserConfig();
  // MATI_DELETION
  const isIndicatorUser = false; // useMayI(FunctionalPermission.LandingIndicators);

  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const { cancelQuery, submitQuery, result, isLoading, query } = useSearchBar();

  const { indicator, ...nonIndicatorResults } = result;
  const contentCount = Object.values(nonIndicatorResults).reduce((sum, result) => sum + result.total, 0);

  const results = Object.values(
    isIndicatorUser && searchDisplay === SearchDisplayPreference.Indicators
      ? pick(result, ArtifactType.Indicator)
      : omit(result, ArtifactType.Indicator)
  );
  const [value, setValue] = React.useState('');

  const onChange = (value: string) => {
    if (value) {
      Engage.track(
        Fingerprint.of(Widget.SearchBar)
          .withCommon(CommonEvent.Search)
          .withData({ name: 'search bar', value, pathname, display: searchDisplay })
      );
      submitQuery(value);
    } else cancelQuery();
  };

  const handleSearchBarOpen = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    onClick(id, event.currentTarget);
  };

  const onChangeTextHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    clearTimeout(timeoutRef.current);
    setValue(event.target.value);
    timeoutRef.current = setTimeout(() => onChange(event.target.value), 500);
  };

  const handleClose = () => {
    setValue('');
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={glossary[MenuOrdinal.Search] ? handleClose : NOOP}>
      <div
        aria-label='search input'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        data-id={MenuOrdinal.Search}
        onClick={handleSearchBarOpen}
        color='inherit'
        className='search-input-container'
      >
        <Input
          id='input-with-sx'
          label='Search'
          variant='outlined'
          onChange={onChangeTextHandler}
          value={value}
          InputProps={{
            endAdornment: isLoading ? <Icon.SpinnerProgress color='primary' /> : <Icon icon={faSearch} />
          }}
        />
        <Popper
          disablePortal
          open={Boolean(glossary[MenuOrdinal.Search])}
          anchorEl={glossary[MenuOrdinal.Search]}
          placement='bottom-end'
        >
          {!isLoading && value?.length > 0 && value === query && (
            <MenuList className='search-list-container'>
              <ResultTypeToggle indicatorCount={indicator?.total} contentCount={contentCount} query={query} />
              {results.map(category => {
                return (
                  <div className='category' key={category.name}>
                    <div className='layout'>
                      <div className='name'>
                        <div className='name-container'>
                          <div>{category.name}</div>
                          <div className='total'>
                            <div className='total-number'>Total: {category.total}</div>
                            {category.results.length < category.total && category.topic !== ArtifactType.Indicator ? (
                              <Link
                                to={makeFeedLink(category.topic, value)}
                                className='more-results'
                                onClick={handleClose}
                              >
                                Show more results
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {category.topic === ArtifactType.Indicator ? (
                        <IndicatorResults category={category} handleClose={handleClose} />
                      ) : (
                        <ArtifactResults category={category} handleClose={handleClose} />
                      )}
                    </div>
                  </div>
                );
              })}
            </MenuList>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

export default function SearchBarWrapper(props: Props) {
  const { pathname } = useLocation();
  if (pathname === Path.Home) return null;
  return <SearchBar {...props} />;
}

function ResultTypeToggle({
  contentCount = 0,
  indicatorCount = 0,
  query
}: {
  contentCount: number;
  indicatorCount: number;
  query: string;
}) {
  // MATI_DELETION
  const isIndicatorUser = false; // useMayI(FunctionalPermission.LandingIndicators);

  const { searchDisplay, setSearchDisplayPreference } = useUserConfig();
  const displayingIndicatorsPane = searchDisplay === SearchDisplayPreference.Indicators;
  const searchedForIndicators = query.length >= INDICATOR_SEARCH_MIN_LENGTH;

  const message = !displayingIndicatorsPane
    ? 'Showing Content Results'
    : searchedForIndicators
    ? 'Showing Indicator Results'
    : 'Searching for Indicators requires at least 6 characters.';

  const buttonText = displayingIndicatorsPane
    ? `Switch to Content Results (${contentCount})`
    : `Switch to Indicator Results (${indicatorCount})`;

  function toggle(e: React.MouseEvent) {
    e.stopPropagation();
    const newPreference =
      searchDisplay === SearchDisplayPreference.Indicators
        ? SearchDisplayPreference.Content
        : SearchDisplayPreference.Indicators;
    Engage.track(
      Fingerprint.of(Widget.SearchBar)
        .withCommon(CommonEvent.Change)
        .withQualifier('display preference')
        .withData({ display: newPreference })
    );
    setSearchDisplayPreference(newPreference);
  }

  if (!isIndicatorUser) return null;
  return (
    <div
      className={classnames('search-type-toggle-container name-container', {
        'query-length-message': !searchedForIndicators && displayingIndicatorsPane
      })}
    >
      {message}
      <Button onClick={toggle} variant='outlined'>
        {buttonText}
      </Button>
    </div>
  );
}

function IndicatorResults({ category, handleClose }: { category: CategoryResult; handleClose(): void }) {
  return (
    <div className='results'>
      {(category.results as IndicatorSearchResponse[]).map(result => {
        const tag: BaseTag = { name: result.landing_name, discriminator: result.landing_type as Discriminator };
        const truncatedIndicatorName = truncateMiddle(12, result.indicator_name);
        const [, isTruncated] = truncatedIndicatorName;
        const link = getLandingLink(tag) as string;
        const queryString = formatQueryString({
          topic: ArtifactType.Indicator,
          query: result.indicator_name
        });

        return (
          <Link
            className='indicator result'
            to={link + queryString}
            onClick={handleClose}
            key={`${result.indicator_name}_$${result.landing_name}`}
          >
            <IOCScoreContainer>{result.indicator_score}</IOCScoreContainer>
            <div className='indicator-name'>
              <Tooltip title={isTruncated ? result.indicator_name : null} placement='top' arrow>
                <div
                  className='highlight'
                  dangerouslySetInnerHTML={{
                    __html: cleanHTML(highlightSearchTerm(category.query, truncatedIndicatorName))
                  }}
                />
              </Tooltip>
            </div>
            <Tag tag={tag} noLink />
          </Link>
        );
      })}
    </div>
  );
}

function ArtifactResults({ category, handleClose }: { category: CategoryResult; handleClose(): void }) {
  return (
    <div className='results'>
      {(category.results as CategoryResultItem[]).map((item, index) => {
        const type =
          category.topic === ArtifactType.Collection && !CollectionTypes.includes(item.type)
            ? item.type
            : category.topic;
        return (
          <Link className='result' to={item.to} onClick={handleClose} key={index}>
            {getArtifactIcon(type, true)}
            <NameWithAliases item={item} />
            <span className='locked-icon'>{item.preview ? <Icon.Lock /> : null}</span>
          </Link>
        );
      })}
    </div>
  );
}
