import React, { ReactElement } from 'react';

import { svg, select } from 'd3';
import get from 'lodash/get';

import { styled } from 'snap-ui/util';

import { NodeDataType } from 'types/progressGraph';

type LinkData = {
  source: NodeDataType;
  target: NodeDataType;
};

type LinkProps = {
  linkData: LinkData;
  orientation: string;
  styles?: object;
};

type LinkState = {
  initialStyle: {
    opacity: number;
  };
};

const Path = styled('path')`
  stroke-width: 3px;
  stroke: ${p => p.theme.palette.grey[400]};
  fill: none;

  &.graph-selected-link {
    stroke-width: 6px;
    stroke: ${p => p.theme.palette.info.light};
  }

  &.graph-selected-link-red {
    stroke-width: 6px;
    stroke: ${p => p.theme.palette.error.main};
  }

  &.graph-found-link {
    stroke-width: 6px;
    stroke: fade(${p => p.theme.palette.info.light}, 50%);
  }

  &.graph-found-link-red {
    stroke-width: 6px;
    stroke: fade(${p => p.theme.palette.error.main}, 50%);
  }
`;

export default class Link extends React.PureComponent<LinkProps, LinkState> {
  link: SVGPathElement;
  state = {
    initialStyle: {
      opacity: 0
    }
  };

  componentDidMount(): void {
    this.applyOpacity(1, 0);
  }

  componentWillLeave(done: () => void): void {
    this.applyOpacity(0, 0, done);
  }

  applyOpacity(opacity: number, transitionDuration: number, done = () => {}): void {
    if (transitionDuration === 0) {
      select(this.link).style('opacity', opacity);
      done();
    } else {
      select(this.link).transition().duration(transitionDuration).style('opacity', opacity).each('end', done);
    }
  }

  /**
   * The original algorithm `(23 * linkData.source.process_name + 116)` didn't take into account very long names. So lets capt it.
   * @param linkData
   * @returns length of name
   */
  getSourceProcessNameLength(linkData: LinkData): number {
    return 23 * Math.min((linkData?.source?.process_name || '').length, 10) + 116;
  }

  drawPath(): any {
    const { linkData, orientation } = this.props;
    const diagonal = svg
      .diagonal()
      .source(d => ({
        x: d.source.x,
        y: d.source.y + this.getSourceProcessNameLength(linkData)
      }))
      .projection(d => (orientation === 'horizontal' ? [d.y, d.x] : [d.x, d.y]));
    return diagonal(linkData);
  }

  getClassNames(): string {
    const { linkData } = this.props;
    if (get(linkData, 'target.selected', false) || get(linkData, 'target.child_selected', false)) {
      return 'graph-selected-link';
    }

    if (get(linkData, 'target.selected_red', false) || get(linkData, 'target.child_selected_red', false)) {
      return 'graph-selected-link-red';
    }

    if (get(linkData, 'target.found', false) || get(linkData, 'target.child_found', false)) {
      return 'graph-found-link';
    }

    if (get(linkData, 'target.found_red', false) || get(linkData, 'target.child_found_red', false)) {
      return 'graph-found-link-red';
    }

    return '';
  }

  render(): ReactElement {
    const { styles } = this.props;
    if (get(this.props, 'linkData.target.condenseNode', false)) {
      return null;
    }
    return (
      <Path
        ref={l => {
          this.link = l;
        }}
        style={{ ...this.state.initialStyle, ...styles }}
        className={`${this.getClassNames()}`}
        d={this.drawPath()}
        data-source-id={this.props.linkData.source.id}
        data-target-id={this.props.linkData.target.id}
      />
    );
  }
}
