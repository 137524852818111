import React from 'react';

import { faBinary } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import Tooltip, { TooltipProps } from 'snap-ui/Tooltip';

import Path from 'constants/paths';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { Status } from 'storage/Storage.type';

import { FunctionalPermission } from 'types/auth';
import { StrictReactNode } from 'types/core';

export type BASButtonProps = {
  disabled?: boolean;
  onClick?(): void;
  hasAgents: boolean;
  agentStatus: Status;
  hasScripts: boolean;
  noAttacksLabel?: string;
  TooltipProps?: Partial<TooltipProps>;
};

export default function BASButton(props: BASButtonProps) {
  const { permission, isSubscriber } = useAuth();
  const canBas = checkTaskPermission(permission, FunctionalPermission.TaskBASAgent);
  let disabledTitle: StrictReactNode;

  if (!isSubscriber) disabledTitle = <span>Attack simulation agents are only available to subscribers.</span>;
  else if (!canBas) disabledTitle = <span>You do not have permissions to launch an attack simulation.</span>;
  else if (!props.hasScripts)
    disabledTitle = <span>{props.noAttacksLabel || 'Attack simulations are not available for this item.'}</span>;
  else if (!props.hasAgents && props.agentStatus === Status.resolved)
    disabledTitle = (
      <span>
        You must first download and configure an agent to run this attack simulation.{' '}
        <Link to={`${Path.Setting}/agents`}>Go to the settings page</Link>.
      </span>
    );
  // The button will momentarily appear disabled without a tooltip while the agents endpoint is loading

  const disabled = !isSubscriber || !canBas || !props.hasAgents || !props.hasScripts;

  return (
    <Tooltip arrow placement='left' title={disabledTitle} wrap {...props.TooltipProps}>
      <BurgerClicker
        onClick={props.onClick}
        icon={faBinary}
        title='Launch Attack Simulations'
        disabled={disabled || props.disabled}
      />
    </Tooltip>
  );
}
