import { RecommendationType, Actionability, RecommendationResponse } from 'module/Curation/Curation.type';

import { Collection } from './Collection.type';

export const COLLECTION_SHELL: Collection = {
  id: null,
  short_id: null,
  guid: null,
  name: null,
  small_image: null,
  large_image: null,
  updated_by: null,
  created_by: null,
  creation: null,
  modified: null,
  permission: null,
  type: null,
  description: null,
  organization: null,
  analytic_filter: null,
  session_filter: null,
  threat_intelligence_filter: null,
  references: [],
  actor_names: [],
  attack_names: [],
  datasource_names: [],
  software_names: [],
  vulnerability_names: []
};

export const RECOMMENDED_ACTION_MAP: Record<
  RecommendationType,
  Record<Actionability, keyof Omit<RecommendationResponse, 'score'>>
> = {
  [RecommendationType?.Deployment]: {
    [Actionability?.ACTIONED]: 'deployment_recommendations',
    [Actionability?.UNACTIONED]: 'deployment_recommendations',
    [Actionability?.ALL]: 'all_deployment_recommendations'
  },
  [RecommendationType?.Hunt]: {
    [Actionability?.ACTIONED]: 'hunt_recommendations',
    [Actionability?.UNACTIONED]: 'hunt_recommendations',
    [Actionability?.ALL]: 'all_hunt_recommendations'
  },
  [RecommendationType?.Validation]: {
    [Actionability?.ACTIONED]: 'validations',
    [Actionability?.UNACTIONED]: 'validations',
    [Actionability?.ALL]: 'all_validations'
  }
};
