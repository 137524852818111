import React, { ReactElement } from 'react';

import { useField } from 'formik';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';

type CheckboxFormikProps = Partial<Checkbox> & {
  className?: string;
  name: string;
  label: string;
  disabled?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
};

const CheckboxFormik = ({ className, name, label, onChange, ...props }: CheckboxFormikProps): ReactElement => {
  const [field, , helpers] = useField(name);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    helpers.setValue(e.target.checked);
    helpers.setTouched(true, false);

    if (onChange) onChange(e);
  }

  return (
    <FormControlLabel
      name={name}
      className={className}
      control={<Checkbox onChange={handleChange} value={field.value} checked={!!field.value} {...props} />}
      label={label}
    />
  );
};

export default CheckboxFormik;
