import React from 'react';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';

import { useIDEValue } from 'module/IDE';

interface ReferencesFieldProps {
  readonly?: boolean;
}

function ReferencesField({ readonly }: ReferencesFieldProps): React.ReactElement {
  const { value, setValue } = useIDEValue('references');

  function handleChange(value: string | (string | Option)[]) {
    if (typeof value === 'string') setValue([value]);
    else if (Array.isArray(value)) {
      setValue(
        value.map(v => {
          if (typeof v === 'string') return v;
          return v.value;
        })
      );
    }
  }
  return (
    <Autocomplete
      className='Form-field'
      disabled={readonly}
      elevated
      label='References'
      multiple
      name='references'
      options={[]}
      onChange={handleChange}
      value={value || []}
      testId='references'
    />
  );
}

export default ReferencesField;
