import Path from 'constants/paths';

import { ArtifactType, Visibility } from 'types/common';
import { CompositeFilter, isCompositeFilter } from 'types/filter';
import { FeedRouteGen, RouteGen } from 'types/route';

import { getLocation } from 'utilities/RouteGenUtils';

import { FeaturedQuery } from './Feature';

function reduceFeatureCompositeFilter(
  accumulated: Record<string, string>,
  current: CompositeFilter['items'][number]
): Record<string, string> {
  if (isCompositeFilter(current)) {
    return current.items.reduce(reduceFeatureCompositeFilter, accumulated);
  } else {
    const { field, value } = current;
    let prop = {};
    switch (field) {
      case 'text':
        prop = { query: value };
        break;
      case 'organization_id':
        prop = { organizations: value };
        break;
      case 'bas.product_name':
        prop = { basProduct: value };
        break;
    }

    return { ...accumulated, ...prop };
  }
}

export function getRouteGen(item: FeaturedQuery, type: ArtifactType): RouteGen | '#' {
  if (!item) return '#';

  const location: FeedRouteGen = {
    pathname: Path.Feed,
    search: { topic: type, visibility: [Visibility[Visibility.Published]] }
  };

  const initial: Record<string, string> = {
    topic: type,
    visibility: Visibility[Visibility.Published]
  };

  const search: Record<string, string> = item.payload.items.reduce(reduceFeatureCompositeFilter, initial);

  return getLocation({ ...location, search });
}
