import React from 'react';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import useTitle from 'hooks/useTitle';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

import { JobList } from '.';
import useJobList from './useJobList';

const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
`;

const ErrorContainer = styled('div')`
  display: flex;
  justify-content: center;

  .Alert {
    max-width: fit-content;
  }
`;

type JobListPageProps = { className?: string };
function JobListPage({ className }: JobListPageProps): JSX.Element {
  const jobsAsync = useJobList();

  return (
    <Container className={className}>
      <JobList includeTestCaseColumn={true} {...jobsAsync} />
    </Container>
  );
}

const withJobListPageWrapper =
  // eslint-disable-next-line react/display-name
  (WrappedComponent: React.ComponentType<JobListPageProps>) => (props: JobListPageProps) => {
    const { permission, isSubscriber } = useAuth();
    const canBas = checkTaskPermission(permission, FunctionalPermission.TaskBASAgent);
    useTitle(`Attack Simulation ${canBas ? 'Results' : ''} | SnapAttack`);

    return canBas && isSubscriber ? (
      <WrappedComponent {...props} />
    ) : (
      <ErrorContainer>
        <Alert severity='info'>
          <AlertTitle>
            {!isSubscriber
              ? 'Attack simulations are only available to subscribers.'
              : 'You do not have permissions to launch an attack simulation.'}
          </AlertTitle>
        </Alert>
      </ErrorContainer>
    );
  };

export default withJobListPageWrapper(JobListPage);
