import * as React from 'react';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import PanelFrame, { Props as PanelProps } from 'snap-ui/PanelFrame';
import { styled, useTheme } from 'snap-ui/util';

import { useDashboardStats } from 'module/Dashboard/DetectionDashboard';

export type Props = Partial<PanelProps> & {
  className?: 'red' | 'blue' | string;
};

const patternProps = {
  width: 10,
  height: 10,
  patternUnits: 'userSpaceOnUse',
  patternTransform: 'rotate(-45)'
};

const Rect = styled('rect')`
  width: 20px;
  height: 20px;
  transform: translate(0, 0);
  fill-opacity: 0.8;
  stroke: ${p => p.theme.palette.common.white};
  stroke-opacity: 0.4;
  stroke-width: 5px;
  stroke-dasharray: 20;
`;

function TechniquesPanel({ className, title, ...extraProps }: Props) {
  const { techniques, isPending } = useDashboardStats();
  const { palette } = useTheme();

  return (
    <PanelFrame
      {...extraProps}
      className={className}
      loading={isPending}
      title={title}
      content={
        <div className='recharts-container'>
          <ResponsiveContainer height={350}>
            <BarChart data={techniques}>
              <defs>
                <pattern id='technique-increase-pattern' {...patternProps}>
                  <Rect fill={palette.lime.dark} />
                </pattern>
                <pattern id='technique-decrease-pattern' {...patternProps}>
                  <Rect fill={palette.error.dark} />
                </pattern>
                <pattern id='available-pattern' {...patternProps}>
                  <Rect fill={palette.aqua.dark} />
                </pattern>
                <linearGradient id='deployedTechniques' x1='0' y1='1' x2='0' y2='0'>
                  <stop offset='25%' stopColor={palette.lime.main} stopOpacity={0.9} />
                  <stop offset='75%' stopColor={palette.lime.light} stopOpacity={0.9} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeOpacity='0.25' vertical={false} />
              <Legend iconType='circle' verticalAlign='top' align='center' />
              <XAxis
                stroke={palette.common.white}
                strokeWidth='2'
                angle={45}
                interval={0}
                dataKey='name'
                textAnchor='start'
              />
              <YAxis stroke={palette.common.white} strokeWidth='2' domain={[0, 'dataMax']} ticks={[15, 30, 45]} />
              <Tooltip
                offset={-10}
                cursor={{ fill: palette.common.black }}
                formatter={(
                  value: number,
                  name: string,
                  _props: unknown,
                  _index: unknown,
                  bars: { name: string; value: number }[]
                ) => {
                  if (name === 'Previously Deployed') {
                    // add decrease in coverage back to previous deployed value for tooltip to
                    // display correct information to the user.
                    const found = bars.find(bar => bar.name === 'Decrease' && bar.value > 0);
                    return value + (found?.value || 0);
                  } else {
                    return value;
                  }
                }}
              />

              <Bar
                id='bar-deployed'
                name='Previously Deployed'
                barSize={30}
                dataKey='coverageStart'
                stackId='name'
                fill='url(#deployedTechniques)'
              />
              <Bar
                id='bar-increase'
                name='Increase'
                barSize={30}
                dataKey='coverageIncrease'
                stackId='name'
                fill='url(#technique-increase-pattern)'
              />
              <Bar
                id='bar-decrease'
                name='Decrease'
                barSize={30}
                dataKey='coverageDecrease'
                stackId='name'
                fill='url(#technique-decrease-pattern)'
              />
              <Bar
                id='bar-available'
                name='Recommended'
                barSize={30}
                dataKey='coverageAvailable'
                stackId='name'
                fill='url(#available-pattern)'
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      }
    />
  );
}

const StyledTechniquesPanel = styled(TechniquesPanel)<Props>`
  .recharts-container {
    width: 100%;
    padding-bottom: ${p => p.theme.spacing(6)};
  }

  .recharts-responsive-container {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  .recharts-wrapper {
    overflow: visible;
  }

  .recharts-default-legend {
    text-transform: up;
    text-shadow: 0px -2px 4px ${p => p.theme.palette.common.black};
  }

  .recharts-xAxis {
    .recharts-cartesian-axis-tick {
      text-transform: uppercase;
      overflow: visible;
      font-size: 0.8em;
      text-shadow: 0px -2px 4px ${p => p.theme.palette.common.black};
    }
  }

  .recharts-yAxis {
    font-size: 0.8em;
  }

  .recharts-default-tooltip {
    background-color: ${p => p.theme.palette.common.black} !important;
    opacity: 0.95;
    border-radius: ${p => p.theme.spacing(1)};
    border-color: ${p => p.theme.palette.common.black};

    .recharts-tooltip-item-list li {
      color: ${p => p.theme.palette.common.white} !important;
    }
  }
`;

export default StyledTechniquesPanel;
