import React from 'react';

import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { ActionIconButton } from 'snap-ui/Button';
import { DataGridProps, GridColDef } from 'snap-ui/DataGrid';
import Tooltip from 'snap-ui/Tooltip';

import CopyButton from 'module/Widgets/CopyButton';

import { truncateMiddle } from 'utilities/StringUtils';

import { CopyButtonContents } from './AnalyticTuning.style';
import { Bucket } from './AnalyticTuning.types';

type Col<T = Bucket> = GridColDef<T>;

export const value: Col = {
  field: 'key',
  headerName: 'Value',
  renderCell: params => {
    if (params.rowNode.type === 'group') return '';
    const [truncated, isTruncated] = truncateMiddle(20, params.value + '');
    const empty = isEmpty(truncated);

    return (
      <CopyButton arrow tooltip={isTruncated ? params.value : ''} value={params.value} tooltipWidth={500}>
        <CopyButtonContents className={classnames({ empty })}>{empty ? '(empty)' : truncated}</CopyButtonContents>
      </CopyButton>
    );
  },
  flex: 1,
  minWidth: 300
};

export const count: Col = {
  field: 'doc_count',
  headerName: 'Count',
  renderCell: params => params.value,
  maxWidth: 60
};

export const percentage = (divisor: number): Col => ({
  field: 'percentage',
  headerName: 'Percentage',
  renderCell: params => {
    const percentage = ((params.row.doc_count / divisor) * 100).toFixed(2);
    return percentage;
  },
  sortable: false,
  maxWidth: 80
});

export const excludeAction = (onExclude: (value: Bucket) => void): Col => ({
  field: 'actions',
  headerName: 'Actions',
  disableColumnMenu: true,
  renderHeader: () => null,
  resizable: false,
  sortable: false,
  maxWidth: 50,
  renderCell: params => {
    if (params.row.key == '') return null;

    return (
      <Tooltip arrow placement='right' title='exclude from detection'>
        <ActionIconButton
          aria-label='exclude row from detection'
          icon={faMinusCircle}
          onClick={() => {
            onExclude(params.row);
          }}
        />
      </Tooltip>
    );
  }
});

export const COMMON_GRID_CONFIG: Partial<DataGridProps> = {
  disableColumnReorder: true,
  disableMultipleRowSelection: true,
  disableColumnSelector: true,
  getRowId: () => Math.ceil(Math.random() * 100_000),
  hideFooter: true
};
