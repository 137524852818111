import React from 'react';

import isEmpty from 'lodash/isEmpty';

import {
  DataGrid,
  GRID_AGGREGATION_FUNCTIONS,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  GridValueGetterParams,
  gridStringOrNumberComparator,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from 'snap-ui/DataGrid';
import { getRowForSort } from 'snap-ui/DataGrid/DatGrid.helper';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { JobGroup, JobOverview, JobStatus, JobType } from 'module/Job';
import { getGridColumnsTask } from 'module/Job/Job.util';
import { JobGridTreeDataGroupingCell, TaskTableToolbar } from 'module/Job/Job.widgets';
import {
  aggregateCreated,
  aggregateCreatedBy,
  aggregateIntegration,
  aggregateOrg,
  aggregateProgress,
  aggregateStatus,
  aggregateTimeRemaining,
  aggregateType,
  created_by,
  creation,
  detections,
  hits,
  integrationNames,
  jobGuid,
  jobName,
  jobsByGroup,
  modified,
  organization,
  progress,
  sumUp,
  taskStatus,
  timeRemaining
} from 'module/Job/Task/Column';
import EmptyState from 'module/Widgets/EmptyState';
import TablePagination from 'module/Widgets/TablePagination';

import { useAuth, useIntegrationCatalog, useManagedOrganizations, useUserConfig } from 'provider';

import { Ident } from 'types/common';

import { BULK_CONFIDENCE_JOB_TYPES } from './BulkConfidence.const';
import { getIntegrationNameFromJob } from './BulkConfidence.helper';

const Container = styled('div')`
  height: calc(75vh - ${p => p.theme.spacing(9)} * 2 - ${p => p.theme.spacing(7)});
  .no-break {
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .disable {
    font-style: italic;
    color: ${p => p.theme.palette.grey[500]};
  }
`;

export type BulkConfidenceTaskProps = {
  jobs: JobOverview[];
  jobGroupTask: JobGroup[];
  getJob: (jobGroup: JobGroup) => void;
  isPending: boolean;
  jobTypeFilter: JobType;
  onTypeChange: (jobType: JobType) => void;
  page: number;
  pageTotal: number;
  handleChangePage: (page: number) => void;
  sortModel: GridSortModel;
  handleSortModelChange: (newModel: GridSortModel) => void;
  orgFilter: Ident;
  onOrgChange: (org: Ident) => void;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const COLUMN_MODEL_CONFIDENCE_LIST_TABLE = 'confidenceJobOverview';

export default function BulkConfidenceTask({
  jobs,
  jobGroupTask,
  getJob,
  isPending,
  jobTypeFilter,
  onTypeChange,
  page,
  pageTotal,
  handleChangePage,
  sortModel,
  handleSortModelChange,
  orgFilter,
  onOrgChange,
  onSearchChange
}: BulkConfidenceTaskProps) {
  const { integrations } = useIntegrationCatalog();
  const { columnModel, setColumnModel } = useUserConfig();
  const { user } = useAuth();
  const { organizations } = useManagedOrganizations();
  const apiRef = useGridApiRef();

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: []
  });

  const filteredJobs = React.useMemo(() => {
    return jobs?.filter(job => (orgFilter === null ? job : job.organization_id === orgFilter)) || [];
  }, [jobs, orgFilter]);

  const bulkConfidenceMap = jobGroupTask
    .map(group => {
      const jobTask = filteredJobs?.find(eachJobData => group.jobs[0]?.guid === eachJobData.analytic_job_guid);
      return {
        ...group,
        ...jobTask,
        name: group?.name || jobTask?.name,
        job_status:
          group.status === JobStatus.CompletedWithErrors || group.status === JobStatus.Success ? 'done' : group.status,
        creation: jobTask?.creation,
        integration_name: getIntegrationNameFromJob(group.jobs, filteredJobs, integrations.all).join(', '),
        detections_completed: group.jobs
          .map(job =>
            filteredJobs
              .map(eachJobData => (job.guid === eachJobData.analytic_job_guid ? eachJobData?.detections_completed : 0))
              .reduce((sum, count) => sum + count, 0)
          )
          .reduce((sum, count) => sum + count, 0),
        type: jobTask?.type
      };
    })
    ?.filter(group => [JobType.Rank, JobType.Hunt].includes(group.type as JobType));
  const handleSetColumn = (value: Record<string, boolean>) =>
    setColumnModel({ ...columnModel, [COLUMN_MODEL_CONFIDENCE_LIST_TABLE]: value });

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['jobsByGroup']
      },
      filter: {
        filterModel: {
          items: []
        }
      },
      sorting: {
        sortModel: [{ field: 'creation', sort: 'desc' }]
      },
      aggregation: {
        model: {
          created_by: 'aggregateCreatedBy',
          type: 'aggregateType',
          integration_name: 'aggregateIntegration',
          status: 'aggregateStatus',
          progress_percent: 'aggregateProgress',
          detection: 'sumUp',
          hits: 'sumUp',
          estimated_remaining_duration: 'aggregateTimeRemaining',
          creation: 'aggregateCreated',
          modified: 'aggregateCreated',
          organization_id: 'aggregateOrg'
        }
      }
    }
  });

  return (
    <Container>
      <DataGrid
        apiRef={apiRef}
        loading={isPending}
        columns={[
          jobsByGroup,
          jobGuid(user.superuser),
          created_by,
          integrationNames,
          taskStatus(apiRef),
          progress,
          detections,
          hits,
          timeRemaining,
          creation,
          modified,
          organization(organizations),
          jobName
        ]}
        rows={bulkConfidenceMap}
        groupingColDef={{
          headerName: 'Name',
          flex: 1,
          minWidth: 300,
          sortingOrder: ['asc', 'desc'],
          sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1, v2, param1, param2),
          valueGetter(params: GridValueGetterParams) {
            const row = getRowForSort<JobOverview>(apiRef.current, params);
            return row?.name;
          },
          renderCell: params =>
            params.row.job_status !== 'done' ? (
              params.row.name
            ) : (
              <JobGridTreeDataGroupingCell {...params} idPath='analytic_job_guid' linkPath={Path.ConfidenceTailoring} />
            ),
          cellClassName: p => (p.row.job_status !== 'done' ? 'disable' : '')
        }}
        initialState={initialState}
        getAggregationPosition={groupNode => (groupNode == null ? null : 'inline')}
        getRowId={row => row.guid}
        disableMultipleRowSelection
        getRowClassName={p => (p.row.job_status !== 'done' ? `disableRow` : null)}
        onRowClick={params => (params.row.job_status === 'done' ? getJob(params.row) : null)}
        onColumnVisibilityModelChange={handleSetColumn}
        filterModel={filterModel}
        columnVisibilityModel={{
          modified: false,
          estimated_remaining_duration: false,
          progress_percent: false,
          analytic_job_guid: user.superuser ? true : false,
          organization_id: user.superuser ? true : false,
          ...(isEmpty(columnModel[COLUMN_MODEL_CONFIDENCE_LIST_TABLE])
            ? undefined
            : columnModel[COLUMN_MODEL_CONFIDENCE_LIST_TABLE]),
          jobsByGroup: false,
          name: false
        }}
        onFilterModelChange={newModel => setFilterModel(newModel)}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          aggregateType,
          aggregateIntegration,
          aggregateCreatedBy,
          aggregateStatus,
          aggregateProgress,
          aggregateTimeRemaining,
          aggregateCreated,
          sumUp,
          aggregateOrg
        }}
        slots={{
          toolbar: TaskTableToolbar,
          footer: () => <TablePagination changePage={handleChangePage} numPages={pageTotal} page={page} zeroIndex />,
          noRowsOverlay: EmptyState
        }}
        slotProps={{
          toolbar: {
            orgFilter,
            jobTypeFilter,
            onSearchChange,
            onTypeChange,
            onOrgChange,
            includeExport: false,
            jobTypes: BULK_CONFIDENCE_JOB_TYPES
          },
          noRowsOverlay: {
            title: 'No Results',
            children: 'There are no confidence tasks available'
          },
          columnsPanel: {
            getTogglableColumns: (columns: GridColDef[]) => getGridColumnsTask(columns, user.superuser)
          }
        }}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
      />
    </Container>
  );
}
