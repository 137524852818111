import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Chip from 'snap-ui/Chip';
import { DataGrid, GridRenderCellParams, useGridApiRef } from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { Attack, AttackType, Discriminator } from 'module/Tag';
import AttackChip from 'module/Widgets/AttackChip';

import { useLandingCatalog } from '../LandingProvider';
import { MitreIDGroupingCell, MitreNameColumn } from './Columns';

const TableContainer = styled('div')`
  width: 100%;
`;

const Table = styled(DataGrid)`
  max-height: 550px;
`;

export default function AttackFamily() {
  const apiRef = useGridApiRef();
  const { type, source, handleReset } = useLandingCatalog();

  if (type !== Discriminator.Attack) return null;

  const data = source.siblings?.length > 0 ? source.siblings : source.children;

  if (isEmpty(data)) return null;

  const rows: Attack[] = (
    source.type === AttackType.Tactic ? source.children : [source as Attack, ...source.children, ...source.siblings]
  )
    .slice()
    .sort((a, b) => a.mitre_id.localeCompare(b.mitre_id));

  const groupingProps = {
    defaultGroupingExpansionDepth: -1, // expand all
    getTreeDataPath: (row: Attack) => row.mitre_id.split('.'),
    groupingColDef: {
      headerName: 'ID',
      width: 120,
      renderCell: (p: GridRenderCellParams) => <MitreIDGroupingCell {...p} onClick={handleReset} />
    },
    treeData: true
  };

  const selectedRow = rows.filter(r => r.mitre_id === source.external_source_id).map(r => r.id);

  return (
    <Accordion className='LandingSection-accordion' defaultExpanded elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>
          {source.type === AttackType.Tactic
            ? 'Techniques'
            : `Related ${source.type === AttackType.Subtechnique ? 'Sub-technique' : source.type}s`}{' '}
          <Chip label={rows.length} />
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='LandingSection-accordionDetails'>
        {source.type !== AttackType.Tactic && (
          <>
            <Typography component='span'>
              {source.type === AttackType.Technique ? 'Technique' : 'Sub-technique'} of
            </Typography>
            {source.parents?.map(p => (
              <AttackChip
                key={p.id}
                onClick={handleReset}
                className='LandingSection-chip attack subtechnique'
                tag={{
                  ...p,
                  type: p.mitre_id.includes('TA') ? AttackType.Tactic : AttackType.Technique
                }}
              />
            ))}
          </>
        )}

        <TableContainer>
          <Table
            apiRef={apiRef}
            columns={[MitreNameColumn(source as Attack, handleReset)]}
            disableRowSelectionOnClick
            hideFooter
            rows={rows}
            rowSelectionModel={selectedRow}
            {...(source.type === AttackType.Technique ? groupingProps : {})}
          />
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}
