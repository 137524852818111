import React from 'react';

import omit from 'lodash/omit';

import { Option } from 'snap-ui/Autocomplete';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';

import { ApiError, ErrorProps } from 'module/ApiError';
import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik, { AutocompleteFormikProps } from 'module/Form/AutocompleteFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { useAuth, useSigmaBackendCatalog } from 'provider';

import { Status } from 'storage';

import { EditorLanguage, SigmaEngine } from 'types/analytic';

import { useConfigControls } from './ConfigProvider';
import { LanguageFormSchema } from './LanguageConfig.type';
import { LanguageFormAdvancedCheckbox } from './LanguageConfig.widget';
import SigmaAdvanceKeyValue, { EMPTY_BACKEND_OPTION } from './SigmaAdvanceKeyValue';

type LanguageTargetFormDialogProps = {
  apiErrorProps?: ErrorProps;
  initialTarget?: Partial<EditorLanguage>;
  onClose(): void;
  onSave(values: EditorLanguage): void;
  open: boolean;
  active: boolean;
};

export default function LanguageTargetFormDialog(props: LanguageTargetFormDialogProps): JSX.Element {
  const { defaultOrgId } = useAuth();
  const SHELL = React.useMemo<EditorLanguage>(
    () => ({
      discriminator: SigmaEngine.Legacy,
      organization_id: defaultOrgId,
      name: '',
      backend_key: '',
      output_format: 'default',
      config_keys: [],
      backend_options: EMPTY_BACKEND_OPTION,
      _clone_configs: false,
      _source_language_id: undefined
    }),
    [defaultOrgId]
  );

  const {
    legacy: legacyBackends,
    legacyStatus: legacyBackendsStatus,
    pysigma: pysigmaBackends,
    pysigmaStatus: pysigmaBackendsStatus
  } = useSigmaBackendCatalog();
  const legacyBackendOptions = React.useMemo(
    () =>
      legacyBackends.map(b => ({
        content: b.backend_key,
        value: b.backend_key
      })),
    [legacyBackends]
  );
  const pysigmaBackendOptions = React.useMemo(
    () =>
      pysigmaBackends.map(b => ({
        content: b.backend_key,
        value: b.backend_key
      })),
    [pysigmaBackends]
  );

  const configControls = useConfigControls();
  const legacyConfigOptions = React.useMemo(
    () =>
      configControls.legacy.configs.map(config => ({
        content: config.name,
        value: config.sigma_identifier
      })),
    [configControls.legacy.configs]
  );

  const pysigmaConfigOptions = React.useMemo(
    () => [
      ...configControls.pipeline.configs.map(pipeline => ({
        content: pipeline.name + ' Pipeline',
        value: pipeline.sigma_identifier
      })),
      ...configControls.validator.configs.map(validator => ({
        content: validator.name + ' Validator',
        value: validator.sigma_identifier
      }))
    ],
    [configControls.pipeline.configs, configControls.validator.configs]
  );

  const handleSubmit = (values: EditorLanguage) => {
    values = values.discriminator === SigmaEngine.Legacy ? omit(values, 'output_format') : values;
    props.onSave({
      ...values,
      backend_options: values.backend_options?.every(option => option.field === '' && option.value === '')
        ? null
        : values.backend_options
    });
  };

  return (
    <FormDialog
      DialogProps={{ open: props.open, onClose: props.onClose }}
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: { ...SHELL, ...props.initialTarget },
        validateOnChange: true,
        onSubmit: handleSubmit,
        zodSchema: LanguageFormSchema
      }}
      SubmitProps={{
        children: props.active ? (
          <CircularProgress size={25} />
        ) : props.initialTarget && props.initialTarget._source_language_id ? (
          'Clone'
        ) : props.initialTarget ? (
          'Update'
        ) : (
          'Create'
        ),
        disabled: props.active
      }}
      title={
        props.initialTarget?._source_language_id
          ? `Clone ${props.initialTarget.name} Detection Language`
          : props.initialTarget?.name
          ? `Update ${props.initialTarget.name}`
          : 'Create New Detection Language'
      }
    >
      {({ values }) => {
        let compilerProps: Pick<AutocompleteFormikProps, 'loading' | 'options'> = { loading: false, options: [] };
        let configOptions: Option[] = [];
        switch (values.discriminator) {
          case SigmaEngine.Legacy:
            compilerProps = {
              loading: legacyBackendsStatus === Status.pending,
              options: legacyBackendOptions
            };
            configOptions = legacyConfigOptions;
            break;
          case SigmaEngine.PySigma:
            compilerProps = {
              loading: pysigmaBackendsStatus === Status.pending,
              options: pysigmaBackendOptions
            };
            configOptions = pysigmaConfigOptions;
            break;
          default:
            break;
        }

        return (
          <FieldsLayout>
            {!!props.apiErrorProps && <ApiError {...props.apiErrorProps} />}
            <AutocompleteFormik
              label='Engine'
              name='discriminator'
              options={[
                { content: 'Legacy', value: SigmaEngine.Legacy },
                { content: 'PySigma', value: SigmaEngine.PySigma }
              ]}
            />
            <TextFieldFormik label='Name' name='name' />
            <AutocompleteFormik label='Compiler' name='backend_key' disableUserAdditions {...compilerProps} />
            {values.discriminator === SigmaEngine.PySigma && (
              <AutocompleteFormik
                disabled={!values.backend_key}
                label='Output Format'
                name='output_format'
                options={
                  pysigmaBackends
                    .find(b => b.backend_key === values.backend_key)
                    ?.output_formats.map(f => ({ content: f, value: f })) ?? []
                }
              />
            )}
            <AutocompleteFormik
              multiple
              label='Configurations'
              name='config_keys'
              options={configOptions}
              disableUserAdditions
            />
            <SigmaAdvanceKeyValue disabled={false} />
            <LanguageFormAdvancedCheckbox
              configs={configControls.getConfigsByKeys(values.config_keys)}
              visible={!!values._source_language_id}
            />
          </FieldsLayout>
        );
      }}
    </FormDialog>
  );
}
