import React from 'react';

import { RJSFSchema } from '@rjsf/utils';

import CronForm from 'snap-ui/CronForm';
import { DataGrid, GridColDef, GridRowModel } from 'snap-ui/DataGrid';
import Switch from 'snap-ui/Switch';
import Tooltip from 'snap-ui/Tooltip';

import { ApiError } from 'module/ApiError';
import useScheduleEnableBulk from 'module/Hunt/useScheduleEnableBulk';
import EditTaskButton from 'module/Integration/IntegrationConfidenceTailoring/EditTaskButton';
import { JobType } from 'module/Job';
import { TimeAgoTimestamp } from 'module/Widgets/TimeAgoTimestamp';

import { Status } from 'storage';

import { Guid } from 'types/common';

import { NOOP } from 'utilities/FunctionUtils';
import { transformISOtoHumanizeFormat } from 'utilities/TimeUtils';

import { HeightContainer, TableContainer } from '../Integration.style';
import { useScheduledJobsContext } from '../useScheduledJobs';
import DeleteTaskButton from './DeleteTaskButton';

type IntegrationConfidenceJobsTablesProps = {
  integration_guid: Guid;
  jobSchema: RJSFSchema;
};

function IntegrationConfidenceJobsTables({ integration_guid, jobSchema }: IntegrationConfidenceJobsTablesProps) {
  const { jobs, fetchStatus } = useScheduledJobsContext();
  const rankJobs = React.useMemo(() => jobs.filter(j => j.type === JobType.Rank), [jobs]);
  const {
    status: bulkScheduleEnableStatus,
    errorProps,
    optimisticJobs,
    pendingGuids,
    setScheduledHuntStatus
  } = useScheduleEnableBulk(rankJobs);

  const isPending = fetchStatus === Status.pending;

  if (rankJobs.length === 0 && !isPending) {
    return <>You have no recurring confidence tailoring tasks configured for this integration.</>;
  }

  const columns: GridColDef<GridRowModel>[] = [
    {
      field: 'name',
      headerName: 'Task',
      flex: 1,
      renderCell: params => (
        <Tooltip arrow title={params.row.name} wrap>
          {params.row.name}
        </Tooltip>
      )
    },
    {
      field: 'cron_trigger',
      headerName: 'Frequency',
      flex: 1,
      minWidth: 200,
      renderCell: params => <CronForm value={params.row.cron_trigger} readOnly setValue={NOOP} />
    },
    {
      field: 'last_dispatch',
      headerName: 'Last Run',
      renderCell: params =>
        params.row.last_dispatch ? <TimeAgoTimestamp timestamp={params.row.last_dispatch} /> : 'Never Run',
      flex: 0.5,
      minWidth: 100
    },
    {
      field: 'next_dispatch',
      headerName: 'Next Run',
      renderCell: params => <>{transformISOtoHumanizeFormat(params.row.next_dispatch)}</>,
      flex: 1,
      minWidth: 100
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      flex: 0.5,
      renderCell: params => (
        <Tooltip arrow title={params.row.enabled ? 'Enabled' : 'Disabled'}>
          <Switch
            checked={params.row.enabled}
            disabled={pendingGuids.includes(params.row.guid) && bulkScheduleEnableStatus === Status.pending}
            onChange={() => setScheduledHuntStatus([params.row.guid], !params.row.enabled)}
          />
        </Tooltip>
      )
    },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: p => {
        return (
          <HeightContainer>
            <EditTaskButton
              name={p.row.name}
              type={JobType.Rank}
              integration_guid={integration_guid}
              extra_parameters={p.row?.parameters}
              criteria={p.row?.criteria}
              analytic_filter={p.row.analytic_filter}
              cron_trigger={p.row?.cron_trigger}
              schedule_guid={p.row.guid}
              jobSchema={jobSchema}
            />
            <DeleteTaskButton name={p.row.name} integration_guid={integration_guid} schedule_guid={p.row.guid} />
          </HeightContainer>
        );
      },
      minWidth: 100,
      hideSortIcons: true
    }
  ];
  return (
    <TableContainer>
      {!!errorProps && <ApiError {...errorProps} />}
      <DataGrid
        columns={columns}
        className='data-table'
        rows={optimisticJobs}
        getRowId={row => row.guid}
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }]
          },
          filter: {
            filterModel: {
              items: [{ field: 'error', operator: 'isEmpty' }]
            }
          }
        }}
      />
    </TableContainer>
  );
}

export default IntegrationConfidenceJobsTables;
