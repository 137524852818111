import React from 'react';

import { IntegrationFormData, JobIntegrationCatalogSchema, SchemaCatalog } from 'module/Integration/Integration.type';

import { useIntegrationCatalog } from 'provider';

import { Status } from 'storage';

import { Guid } from 'types/common';
import { SetState } from 'types/core';

import { cleanData } from './Integration.util';
import useIntegrationSchema from './useIntegrationSchema';
import useJobSchema from './useJobSchema';

interface IntegrationInterface {
  backdropMessage: string;
  integrationSchemaCatalog: SchemaCatalog['catalog'];
  integrationSchemaCatalogStatus: Status;
  deploymentCount: number;
  setDeploymentCount: SetState<number>;
  handleSave: (formData: IntegrationFormData) => void;
  handleClone: (guid: Guid) => (formData: IntegrationFormData) => void;
  handleUpdate: (guid: Guid) => (formData: IntegrationFormData) => void;
  handleDelete: (guid: Guid) => void;
  jobSchemaCatalog: JobIntegrationCatalogSchema;
}

const IntegrationContext = React.createContext<IntegrationInterface>(null);
IntegrationContext.displayName = 'IntegrationContext';

export default function useIntegrationContext() {
  const state = React.useContext(IntegrationContext);
  if (!state) throw new Error('useIntegrationContext must be used in the IntegrationProvider');
  return state;
}

export function IntegrationProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const ic = useIntegrationCatalog();

  const [backdropMessage, setBackdropMessage] = React.useState<string>('Loading...');
  const [deploymentCount, setDeploymentCount] = React.useState<number>(null);

  const { data: jobSchemaCatalog } = useJobSchema();
  const { schema, status } = useIntegrationSchema();

  React.useEffect(() => {
    if (ic.status === Status.pending) setBackdropMessage('Loading...');
  }, [ic.status]);

  const handleSave = React.useCallback(
    (values: IntegrationFormData): void => {
      setBackdropMessage('Saving...');
      ic.create(cleanData(values, schema?.catalog));
    },
    [ic, schema?.catalog]
  );

  const handleClone = React.useCallback(
    (guid: Guid) => {
      return function (values: IntegrationFormData): void {
        setBackdropMessage('Cloning...');
        ic.clone(guid, cleanData(values, schema?.catalog));
      };
    },
    [ic, schema?.catalog]
  );

  const handleUpdate = React.useCallback(
    (guid: Guid) => {
      return function (values: IntegrationFormData): void {
        setBackdropMessage('Saving...');
        ic.update(guid, cleanData(values, schema?.catalog));
      };
    },
    [ic, schema?.catalog]
  );

  const handleDelete = React.useCallback(
    (guid: Guid) => {
      setBackdropMessage('Deleting...');
      ic.remove(guid);
    },
    [ic]
  );

  const value: IntegrationInterface = React.useMemo(
    () => ({
      backdropMessage,
      integrationSchemaCatalog: schema?.catalog,
      integrationSchemaCatalogStatus: status,
      jobSchemaCatalog,
      deploymentCount,
      setDeploymentCount,
      handleSave,
      handleClone,
      handleUpdate,
      handleDelete
    }),
    [
      backdropMessage,
      deploymentCount,
      handleClone,
      handleDelete,
      handleSave,
      handleUpdate,
      jobSchemaCatalog,
      schema?.catalog,
      status
    ]
  );

  return <IntegrationContext.Provider value={value}>{children}</IntegrationContext.Provider>;
}
