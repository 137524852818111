import React from 'react';

import { RJSFSchema } from '@rjsf/utils';

import RjsfFormik from 'module/Form/RjsfFormik';

type ImportFieldProps = {
  integrationSchema: RJSFSchema;
};

function ImportField({ integrationSchema }: ImportFieldProps) {
  return (
    <>
      <RjsfFormik name='extra_information' schema={integrationSchema} preserve />
    </>
  );
}

export default ImportField;
