import React from 'react';

import omit from 'lodash/omit';

import { ErrorProps, Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { MimeType } from '../Export';
import { getImportStatus, postSignatureCSVImport, postSignatureJSONImport } from './Import.api';
import { ImportEndpointPrefix, ImportForm, ImportJobState, ImportPayload } from './Import.type';

export type ImportUtils = {
  startImport(payload: ImportForm): void;
  status: Status;
  errorProps?: ErrorProps;
};

function _pollForFile(endpoint: ImportEndpointPrefix, taskId: Guid, filetype: string, attempt = 0): Promise<void> {
  return getImportStatus(endpoint, taskId).then(result => {
    if (result.status === ImportJobState.success) return new Promise(resolve => resolve());
    if (attempt > 60) throw { detail: 'This is taking longer than we expected. Something may be wrong.' };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        _pollForFile(endpoint, taskId, filetype, attempt + 1)
          .then(resolve)
          .catch(reject);
      }, 1000);
    });
  });
}

function useImport(endpoint: ImportEndpointPrefix): ImportUtils {
  const { status, task, errorProps } = useAsync();

  function blobToJson(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        try {
          const jsonObj = JSON.parse(event.target.result as string);
          resolve(jsonObj);
        } catch (e) {
          reject('Error parsing JSON: ' + e.message); // Reject the promise if parsing fails
        }
      };

      reader.onerror = function (event) {
        reject('Error reading file: ' + event.target.error.code); // Reject the promise if reading fails
      };

      reader.readAsText(blob);
    });
  }

  const startImport = React.useCallback(
    async (payload: ImportForm) => {
      if (payload.format === MimeType.csv) {
        task(postSignatureCSVImport(endpoint, payload)).then(serverTask =>
          task(_pollForFile(endpoint, serverTask.task_id, payload.format))
        );
      } else if (payload.format === MimeType.json) {
        const jsonData = await blobToJson(payload.file[0]);

        const jsonPayload: ImportPayload = {
          options: omit(payload, ['file', 'format']),
          detections: jsonData
        };

        task(postSignatureJSONImport(endpoint, jsonPayload)).then(serverTask =>
          task(_pollForFile(endpoint, serverTask.task_id, payload.format))
        );
      }
    },
    [endpoint, task]
  );

  return {
    startImport,
    status,
    errorProps: errorProps
  };
}

export default useImport;
