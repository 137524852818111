import React from 'react';

import createInterceptors from 'interceptor';

import { AUTH_SHELL } from 'constants/auth';

import { Status } from 'storage';

import { Auth } from 'types/auth';

import { AuthInterface } from './Account.type';
import { useAuthAsync } from './useAuthAsync';

// This should not be used directly anywhere but this file or a mocked implementation
export const AuthInterfaceContext = React.createContext<AuthInterface>(null);
AuthInterfaceContext.displayName = 'AuthInterfaceContext';

// This should not be used directly anywhere but this file or a mocked implementation
export const AuthContext = React.createContext<Auth>(null);
AuthContext.displayName = 'AuthContext';

function useAuthInterface(): AuthInterface {
  const context = React.useContext(AuthInterfaceContext);

  if (!context) {
    throw new Error('useAuthInterface must be used within the AuthInterfaceContext');
  }

  return context;
}

function useAuth(): Auth {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within the AuthContext');
  }

  return context;
}

function AccountProvider({ value = undefined, children }: { value?: Auth; children: React.ReactNode }) {
  const { auth, authStatus, fetch, setOrgs, logout } = useAuthAsync(value);

  const interfaceContext = React.useMemo(
    () => ({
      authStatus,
      fetch,
      setOrgs,
      logout
    }),
    [authStatus, fetch, logout, setOrgs]
  );

  const authContext = React.useMemo(() => auth || AUTH_SHELL, [auth]);

  React.useEffect(() => {
    createInterceptors(fetch);
  }, [fetch]);

  React.useEffect(() => {
    const t = setTimeout(() => {
      if (authStatus === Status.idle) fetch();
    });

    return () => clearTimeout(t);
  }, [authStatus, fetch]);

  return (
    <AuthInterfaceContext.Provider value={interfaceContext}>
      <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
    </AuthInterfaceContext.Provider>
  );
}

export { AccountProvider, useAuthInterface, useAuth };
