import React from 'react';

import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useAuth } from 'provider';

import { BASProductName, BASProductNameKey } from 'types/bas';
import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { CheckboxControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue } from '../GlobalFilter.util';

type BASCompatibilityKeys = {
  basProduct: BASProductNameKey[];
};

type BASCompatibilityFilterProps = {
  onChange(values: BASCompatibilityKeys): void;
  topic: ArtifactType;
  values: BASCompatibilityKeys;
};

function BASCompatibilityFilter({ onChange, topic, values }: BASCompatibilityFilterProps): JSX.Element {
  const pluralizedTopic = startCase(topic + 's');

  const { isSubscriber } = useAuth();

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as BASProductNameKey;
    const basProduct = addOrRemoveValue(checked, values.basProduct, v);
    return onChange({ basProduct });
  }

  return (
    <CheckboxControl disabled={!isSubscriber}>
      <FormLabel id='has-attack-scripts-toggle-button-group-label' className='title-tooltip'>
        Has Attack Scripts
        <Tooltip
          title={
            isSubscriber
              ? `Filters out ${pluralizedTopic} without associated Attack Script(s).`
              : 'Attack Simulations are only available to subscribers'
          }
          placement='right'
          arrow
          wrap
        >
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          key={BASProductName.SnapAttack}
          control={
            <Checkbox
              onChange={handleChange}
              value={BASProductName.SnapAttack}
              checked={values.basProduct.includes(BASProductName.SnapAttack)}
            />
          }
          label={`Show ${pluralizedTopic} with Attack Script(s)`}
        />
      </FormGroup>
    </CheckboxControl>
  );
}

function toQuery(values: BASCompatibilityKeys): Query {
  if (!values.basProduct?.length) return;

  const basProduct = [...values.basProduct];

  if (basProduct.includes('SnapAttack') && !basProduct.includes('AtomicRedTeam')) {
    basProduct.push('AtomicRedTeam');
  }

  return {
    field: 'bas.product_name',
    op: Ops.in,
    value: basProduct
  };
}

const fromQuery = zod.union([
  zod
    .object({
      field: zod.union([
        zod.literal('bas.product_name'),
        zod.literal('bas.session.bas_script.product_name'),
        zod.literal('bas_script.product_name')
      ]),
      op: zod.literal(Ops.in),
      value: zod.array(zod.string())
    })
    .transform(query => {
      const basProduct = (query.value as BASProductNameKey[])
        .filter(b => Object.keys(BASProductName).includes(b))
        .map(b => (b === 'AtomicRedTeam' ? 'SnapAttack' : b));

      return { basProduct: [...new Set(basProduct)] };
    }),
  zod
    .object({
      field: zod.union([zod.literal('analytic.bas.has_bas'), zod.literal('bas.has_bas'), zod.literal('has_bas')])
    })
    .transform(() => ({
      basProduct: Object.keys(omit(BASProductName, ['AtomicRedTeam'])) as BASProductNameKey[]
    }))
]);

const BASCompatibilityFilterConfig: FilterConfig<BASCompatibilityKeys> = {
  defaults: { default: () => ({ basProduct: [] }) },
  supportedTopics: [ArtifactType.Analytic, ArtifactType.Session],
  component: BASCompatibilityFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default BASCompatibilityFilterConfig;
