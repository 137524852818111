type Browser = {
  name: string;
  version: number;
};

/**
 * TODO - When moving to SSR, these function will need to be massaged
 */
export function getBrowser(): Browser {
  var ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: Number(tem[1]) };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: Number(tem[1]) };
    }
  }
  if (window.navigator.userAgent.indexOf('Edge') > -1) {
    tem = ua.match(/Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Edge', version: Number(tem[1]) };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: +M[1]
  };
}

export function isSupported(browser: Browser): boolean {
  let supported = false;
  if (browser.name === 'Chrome' && browser.version >= 48) {
    supported = true;
  } else if (browser.name === 'Firefox' && browser.version >= 58) {
    supported = true;
  } else if (browser.name === 'Safari' && browser.version >= 13) {
    supported = true;
  }
  return supported;
}
