import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { z } from 'zod';

import { AgentInterface } from 'aso/useAgents';

import { AttackScript } from 'module/AttackScript/AttackScript.type';

import { Status } from 'storage';

import { BASAgent, LaunchBASFormValues } from 'types/bas';

import { postBASJob } from '../BAS.api';
import LauncherForm from './Form';
import LauncherModal from './Modal';

type LauncherProps = {
  agent: BASAgent;
  agentInterface: AgentInterface;
  buttonComponent?: React.ReactElement;
  onClose(): void;
  isOpen: boolean;
  refresh?(): void;
  scripts: Pick<AttackScript, 'guid' | 'name' | 'product_id' | 'product_name' | 'platforms'>[];
};

export default function Launcher(props: LauncherProps): JSX.Element {
  const [selectedScriptGuid, setSelectedScriptGuid] = React.useState(props.scripts?.[0]?.guid);
  const script = props.scripts?.find(s => s.guid === selectedScriptGuid);
  const { filteredAgents: agents, status: agentStatus } = props.agentInterface;

  const compatibleAgents = agents?.filter(agent => script?.platforms?.includes(agent.os_name));

  const handleSubmit = (values: LaunchBASFormValues): Promise<void> => {
    return postBASJob(values).then(() => {
      if (props.refresh) props.refresh();
    });
  };

  return (
    <LauncherModal
      initialValues={{ agent_id: props.agent?.guid || compatibleAgents?.[0]?.guid, script_id: selectedScriptGuid }}
      open={props.isOpen}
      onClose={props.onClose}
      onSubmit={handleSubmit}
      submitDisabled={isEmpty(compatibleAgents) || agentStatus === Status.pending}
      zodSchema={z.object({
        agent_id: z.string().nonempty('Please select a target machine')
      })}
      subtitle={props.scripts?.length === 1 ? props.scripts?.[0]?.name : null}
    >
      <LauncherForm
        scripts={props.scripts as AttackScript[]}
        onScriptChange={setSelectedScriptGuid}
        agentInterface={props.agentInterface}
      />
    </LauncherModal>
  );
}
