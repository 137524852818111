import React from 'react';

import classnames from 'classnames';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

export const CardContainer = styled('div')`
  position: relative;
  border: 1px solid transparent;

  padding: ${p => p.theme.spacing(5)};
  background: ${p => p.theme.palette.secondary.main};
  color: ${p => p.theme.palette.common.white};

  isolation: isolate;

  .CardDescription {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    // search highlights
    span {
      color: ${p => p.theme.palette.primary.main};
    }
  }
`;

const CardSubsectionContainer = styled('div', { label: 'CardSubsection' })`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(1)};

  .title {
    text-transform: uppercase;
    font-size: small;
    color: ${p => p.theme.palette.common.white};
  }
`;

export function CardSubsection({
  className,
  title,
  children
}: {
  className?: string;
  title?: StrictReactNode;
  children?: StrictReactNode;
}): JSX.Element {
  return (
    <CardSubsectionContainer className={classnames('CardSubsection', className)}>
      {title && (
        <Typography className='title' variant='h6'>
          {title}
        </Typography>
      )}
      {children}
    </CardSubsectionContainer>
  );
}

export const CardHeaderContainer = styled('div')`
  grid-column: 1 / span 3;
  display: flex;
  justify-content: space-between;
  min-height: 26px;
  text-transform: capitalize;

  svg {
    font-size: large;
  }

  h5 {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
    color: ${p => p.theme.palette.grey[400]};
    font-weight: 400;
  }
`;

export const CardTitleContainer = styled('div')`
  font-size: x-large;
  line-height: 1;

  display: flex;
  gap: ${p => p.theme.spacing(3)};
  align-items: center;

  span {
    color: ${p => p.theme.palette.primary.main};
    &.preview {
      color: ${p => p.theme.palette.common.white};
    }
  }

  a {
    color: ${p => p.theme.palette.common.white};
    padding: ${p => p.theme.spacing(1, 0)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const CardAliasContainer = styled('div')`
  color: ${p => p.theme.palette.grey[400]};
  font-size: 1rem;
  line-height: 1;

  span {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const CardActorTargetsContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(3)};

  & > div {
    flex-grow: 1;
  }
`;
