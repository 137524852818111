import React from 'react';

import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import Alert from 'snap-ui/Alert';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import { Placeholders } from 'snap-ui/Placeholder';

import { BulkAnalyticVersionsProvider } from 'module/Analytic/BulkAnalyticVersionProvider';
import { Section, SectionTitle } from 'module/Analytic/core/MetadataPanel/util';
import { BulkLauncherButton, BulkLauncherDialog, BulkLauncherInterface } from 'module/BAS/Launcher';
import { addToCollectionInterfaceFactory } from 'module/Curation/AddToCollection';
import {
  DetectionDeploymentInterface,
  DetectionDeploymentDialog,
  DetectionDeploymentMenuButton
} from 'module/DetectionDeployment';
import { useDetectionExportInterface } from 'module/Export';
import { JobType, huntInterfaceFactory } from 'module/Job';
import { SimplePayWall } from 'module/PayWall/PayWall';
import { Session } from 'module/Session/Session.type';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { AnalyticRecommendation } from 'types/analytic';
import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { RecommendedHeader } from '../Session.style';
import RecommendedDetection from './RecommendedDetection';

const [ATCInterface, ATCButton, ATCDialog] = addToCollectionInterfaceFactory('AddToCollectionContext');
const [HuntInterface, HuntButton, HuntDialog] = huntInterfaceFactory('HuntContext');

type Props = {
  session: Session;
  preview: boolean;
  recommendations?: AnalyticRecommendation[];
  status?: Status;
  isRedMarkerCreated?: boolean;
};

export default function RecommendedDetectionListWrapper(props: Props): JSX.Element {
  return (
    <Section className={classNames('Section', { 'overflow-hidden': props.preview })}>
      <RecommendedDetectionListHeader {...props} />
      <RecommendedDetectionList {...props} />
    </Section>
  );
}

function RecommendedDetectionList(props: Props): JSX.Element {
  const { recommendations, status, isRedMarkerCreated } = props;
  const { isSubscriber } = useAuth();
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const isLoading = status === Status.pending;
  const isAnalyticRecommended = recommendations.length > 0;

  const handleChange = (panel: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const recommendationGuids = React.useMemo(
    () =>
      recommendations
        .filter(analytic => analytic.deployments[0]?.integrations?.length > 0)
        .map(analytic => analytic.guid),
    [recommendations]
  );

  if (isLoading) return <Placeholders />;
  if (!isSubscriber) return <SimplePayWall message='Recommended detections are only available to subscribers' />;

  if (isAnalyticRecommended)
    return (
      <BulkAnalyticVersionsProvider guids={recommendationGuids}>
        {recommendations.map((analytic, index) => (
          <RecommendedDetection
            key={index}
            analytic={analytic}
            index={index}
            onChange={handleChange}
            expanded={expanded}
            status={status}
          />
        ))}
      </BulkAnalyticVersionsProvider>
    );

  if (isRedMarkerCreated) return <em>No Recommended Detections</em>;

  return <Alert severity='info'>Please label an attack in order to see recommended detections</Alert>;
}

function RecommendedDetectionListHeader({ session, recommendations }: Pick<Props, 'session' | 'recommendations'>) {
  const isAnalyticRecommended = recommendations.length > 0;
  const recommendedDetectionFilter = {
    field: 'guid',
    op: Ops.in,
    value: recommendations.map(detection => detection.guid)
  };

  const [DetectionExportButton, DetectionExportDialog] = useDetectionExportInterface();

  return (
    <RecommendedHeader>
      <SectionTitle>Detections</SectionTitle>
      {isAnalyticRecommended && (
        <ATCInterface>
          <HuntInterface jobType={JobType.Hunt}>
            <DetectionDeploymentInterface>
              <BulkLauncherInterface
                topic={ArtifactType.Analytic}
                criteria={recommendedDetectionFilter}
                basParams={{ collectionName: session.name }}
              >
                <MenuTrigger
                  trigger={<MenuButton aria-label='Recommended detections menu' icon={faEllipsisVertical} />}
                >
                  <DetectionExportButton key='export' />
                  <DetectionDeploymentMenuButton />
                  <DetectionDeploymentMenuButton title='Undeploy Detections' undeploy />
                  <HuntButton />
                  <BulkLauncherButton noAttacksLabel='There are no attack scripts associated with these detections' />
                  <ATCButton key='collect' title='Add Recommended Detections To Collection' />
                </MenuTrigger>
                <BulkLauncherDialog />
                <DetectionExportDialog filter={recommendedDetectionFilter} />
                <DetectionDeploymentDialog criteria={recommendedDetectionFilter} />
                <HuntDialog query={recommendedDetectionFilter} />
                <ATCDialog
                  type={ArtifactType.Analytic}
                  selectionModel={recommendedDetectionFilter.value}
                  criteria={recommendedDetectionFilter}
                />
              </BulkLauncherInterface>
            </DetectionDeploymentInterface>
          </HuntInterface>
        </ATCInterface>
      )}
    </RecommendedHeader>
  );
}
