import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import update from 'lodash/update';

import { Integration } from 'module/Integration/Integration.type';

import { Organization } from 'types/auth';

import { AdHocQueryJobHistory, AdHocQueryStorage } from './AdHocQuery.type';

export function updateAdHocQueryJobHistory(
  data: AdHocQueryStorage,
  orgId: Organization['id'],
  integrationGuid: Integration['guid'],
  jobGuid: AdHocQueryJobHistory['jobGuid'],
  newResponse: AdHocQueryJobHistory['response']
) {
  const _data = cloneDeep(data);
  const queryHistory: AdHocQueryJobHistory[] = get(_data, `${orgId}.${integrationGuid}`) ?? [];
  const jobHistoryIndex = queryHistory.findIndex(h => h.jobGuid === jobGuid);
  const updatedHistory = cloneDeep(queryHistory);
  update(updatedHistory, `[${jobHistoryIndex}].response`, () => newResponse);
  const updatedData = set(_data, `${orgId}.${integrationGuid}`, updatedHistory);
  return isEqual(data, updatedData) ? data : updatedData;
}

export function updateAdHocQueryJobHits(
  data: AdHocQueryStorage,
  orgId: Organization['id'],
  integrationGuid: Integration['guid'],
  jobGroupGuid: AdHocQueryJobHistory['response']['guid'],
  newHits: AdHocQueryJobHistory['hits']
) {
  const _data = cloneDeep(data);
  const queryHistory: AdHocQueryJobHistory[] = get(_data, `${orgId}.${integrationGuid}`) ?? [];
  const jobHistoryIndex = queryHistory.findIndex(h => h.response.guid === jobGroupGuid);
  const updatedHistory = cloneDeep(queryHistory);
  update(updatedHistory, `[${jobHistoryIndex}].hits`, () => newHits);
  return set(_data, `${orgId}.${integrationGuid}`, updatedHistory);
}
