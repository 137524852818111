import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import {
  DEFAULT_ROLE,
  DEFAULT_ROLES,
  GROUP_TYPE,
  ROLE_LABEL_MAP,
  compareRolesDescending
} from 'constants/organization';

import { checkEntitlement } from 'services/authService';

import { FunctionalPermission, Group, Organization, RegisterStatus, User } from 'types/auth';
import { Guid } from 'types/common';

import { joinItems, presentPerfectBeVerb } from 'utilities/TextUtils';

import { UserFormDialogPayload } from './UserFormDialog';

export function formatInvitedMessage(emails: string[], organizationName: string) {
  const LIST_OUT_LENGTH = 3;
  const others = emails.length > LIST_OUT_LENGTH ? `${emails.length - LIST_OUT_LENGTH} other(s)` : '';
  const emailList = emails.length > LIST_OUT_LENGTH ? [...emails.slice(0, LIST_OUT_LENGTH), others] : emails;
  const invited = `been invited to ${organizationName}`;

  return `${joinItems(emailList)} ${presentPerfectBeVerb(emails)} ${invited}`;
}

export function userGroupMappingForOrganization(
  organization: Organization,
  user: User
): Partial<Record<DEFAULT_ROLE, boolean>> {
  if (!organization || !user) return null;
  const userGroupMapping: Partial<Record<DEFAULT_ROLE, boolean>> = organization?.groups?.reduce(
    (obj, key) => ({
      ...obj,
      [key.role]: !!user?.memberships[organization?.guid]?.some(group => group?.guid == key.guid)
    }),
    {}
  );

  if (userGroupMapping) {
    userGroupMapping[DEFAULT_ROLES.User] =
      userGroupMapping?.[DEFAULT_ROLES.User] ||
      (Object.values(userGroupMapping).some(val => val === true) && !organization?.isolated);
  }

  return userGroupMapping;
}

export function getUserGroupDisplayForOrg(userMemberships: Group[], organizationGroups: Group[]): string {
  if (!userMemberships || !organizationGroups) return null;
  return sortUserGroupDisplayForOrg(userMemberships, organizationGroups)
    .filter(role => role !== DEFAULT_ROLES.User)
    .map(role => (ROLE_LABEL_MAP[role] || startCase(role))?.replace(/ Contributor/gi, ''))
    .join(', ');
}

export function sortUserGroupDisplayForOrg(userMemberships: Group[], organizationGroups: Group[]): DEFAULT_ROLE[] {
  if (!userMemberships || !organizationGroups) return [];

  const formattedGroups = userMemberships
    .map(group => {
      const found = organizationGroups?.find(orgGroup => orgGroup.guid == group.guid);
      return { ...group, ...found };
    })
    .sort((a, b) => {
      return compareRolesDescending(a.role, b.role);
    })
    .map(group => group.role);

  return formattedGroups;
}

export function getAllUserGroupsDisplay(groups: Group[]): string {
  if (!groups) return null;
  return groups
    .map(group => {
      // Needs to massage '<Org Name> Analytic Contributors' to 'AnalyticContributor' to be mapped and renamed.
      let roleName = group.name.replace(new RegExp(group.organization_name, 'gi'), '').trim();
      if (roleName.endsWith('Contributors')) roleName = roleName.slice(0, -1).replace(/ /g, '');
      return (ROLE_LABEL_MAP[roleName as DEFAULT_ROLE] || roleName).replace(/Contributor[s]?/gi, '').trim();
    })
    .join(', ');
}

export function reduceGroupPermissions(groups: Group[], values: UserFormDialogPayload): Record<Guid, boolean> {
  if (!groups) return {};

  return groups.reduce((membership, group) => {
    const roleInValues = isEmpty(values) ? false : values[group?.role];
    return {
      ...membership,
      [group.guid]: roleInValues
    };
  }, {});
}

export function sortLastLoginColumn(a: User, b: User): number {
  const aEmpty = a.status === RegisterStatus.Invite && isEmpty(a.invites);
  const bEmpty = b.status === RegisterStatus.Invite && isEmpty(b.invites);

  if (aEmpty) return -1;
  if (bEmpty) return 1;

  const aLastActiveDate = new Date(Date.parse(a.last_active));
  const bLastActiveDate = new Date(Date.parse(b.last_active));

  return aLastActiveDate >= bLastActiveDate ? 1 : -1;
}

export function specialGroupPermissionFilter(organization: Organization, group: Group): boolean {
  if (group.type !== GROUP_TYPE.Special) return true;
  switch (group.role) {
    case DEFAULT_ROLES.Impersonator:
      return checkEntitlement(organization?.entitlements, FunctionalPermission.ImpersonateUser);
    default:
      return true;
  }
}

export function sortUserByRoles(a: User, b: User, orgGuid: string, groups: Group[]): number {
  const aUserRole = sortUserGroupDisplayForOrg(a?.memberships[orgGuid], groups);
  const bUserRole = sortUserGroupDisplayForOrg(b?.memberships[orgGuid], groups);

  return compareRolesDescending(bUserRole?.[0], aUserRole?.[0]);
}
