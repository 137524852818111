import { equalizedAllocation } from 'utilities/ArrayUtils';

import { CategoryResult, SHOW_TOTAL } from './type';

export const NO_RESULTS = (name: string): Record<string, CategoryResult> => ({
  found: {
    name: `No results found for "${name}"`,
    total: 0,
    results: [],
    topic: null
  }
});

export default function redistributeResult(
  payload: CategoryResult[],
  query: string
): {
  [index: string]: CategoryResult;
} {
  const [signature, session, intel, collection, attackScript, indicator] = payload;

  if (
    signature.results.length === 0 &&
    session.results.length === 0 &&
    intel.results.length === 0 &&
    collection.results.length === 0 &&
    attackScript.results.length === 0 &&
    indicator.results.length === 0
  )
    return NO_RESULTS(query);

  const result = equalizedAllocation(
    [
      { data: signature.results },
      { data: session.results },
      { data: intel.results },
      { data: collection.results },
      { data: attackScript.results }
    ],
    SHOW_TOTAL
  );

  return {
    signature: {
      ...signature,
      results: signature.results.splice(0, result[0])
    },

    session: {
      ...session,
      results: session.results.splice(0, result[1])
    },

    intel: {
      ...intel,
      results: intel.results.splice(0, result[2])
    },

    collection: {
      ...collection,
      results: collection.results.splice(0, result[3])
    },

    attackScript: {
      ...attackScript,
      results: attackScript.results.splice(0, result[4])
    },

    indicator
  };
}
