import { CancelTokenType, snapattack, snapattackData } from 'apis/snapattack';

import { AnalyticBASScriptResponse } from 'types/bas';
import { Guid, Ident } from 'types/common';

import {
  AnalyticVersion,
  AnalyticVersionOverview,
  FPRCreate,
  FPROutput,
  FPRTranslationResult,
  TranslationResult
} from './Analytic.type';

export function getAnalyticVersionsByGuids(guids: Guid[]): Promise<AnalyticVersionOverview[]> {
  return snapattack.post(`/signatures/signature/version/`, { analytics: guids }).then(r => r.data);
}
export function getAnalyticVersionsByGuid(guid: Guid): Promise<AnalyticVersion[]> {
  return snapattack.get(`/signatures/signature/${guid}/version/`).then(r => r.data);
}

export function getAnalyticFpr(guid: Guid, orgId: Ident): Promise<FPROutput[]> {
  return snapattack
    .get(`/signatures/signature/${guid}/false_positive_reduction/`, {
      params: {
        org_id: orgId
      }
    })
    .then(r => r.data);
}

export function translateAnalytic(
  guid: Guid,
  languageID: string | number,
  cancelToken?: CancelTokenType
): Promise<TranslationResult> {
  return snapattack.get(`signatures/signature/${guid}/language/${languageID}/`, { cancelToken }).then(r => r.data);
}

export function translateRaw(
  code: string,
  language: string | number,
  fprCode?: string | void
): Promise<FPRTranslationResult> {
  return snapattack.post(`signatures/language/${language}/`, { raw: code, fpr_raw: fprCode }).then(r => r.data);
}

export function translateRawNative(code: string, language: string | number, fprCode: string | void): Promise<string> {
  return snapattack.post(`signatures/tuning/native/${language}/`, { raw: code, fpr_raw: fprCode }).then(r => r.data);
}

/*
  False Positive Reduction API calls
*/
/**
 * Apply an existing FPR rule to an Analytic + Integration Pair
 */
export function applyFpr(guid: Guid, fprId: Ident, integrationId: Ident): Promise<void> {
  return snapattack.post(`/signatures/signature/${guid}/false_positive_reduction/${fprId}/${integrationId}/`);
}

/**
 * Remove an existing FPR rule from an Analytic + Integration Pair
 */
export function unapplyFpr(guid: Guid, fprId: Ident, integrationId: Ident): Promise<void> {
  return snapattack.delete(`/signatures/signature/${guid}/false_positive_reduction/${fprId}/${integrationId}/`);
}

/*
  CRUD APIs for managing FPR rules
*/

export function getAllFpr(orgId: Ident, onlyNormalized?: boolean): Promise<FPROutput[]> {
  return snapattack.get(`/signatures/signature/false_positive_reduction/`, {
    params: {
      org_id: orgId,
      only_normalized: onlyNormalized
    }
  });
}

export function postFpr(payload: FPRCreate, recompile = false, waitForRecompile = false): Promise<void> {
  return snapattack.post(`/signatures/signature/false_positive_reduction/`, payload, {
    params: {
      recompile,
      wait_for_recompile: waitForRecompile
    }
  });
}

export function putFpr(id: Ident, payload: FPRCreate): Promise<void> {
  return snapattack.put(`/signatures/signature/false_positive_reduction/${id}/`, payload);
}

export function deleteFpr(id: Ident): Promise<void> {
  return snapattack.delete(`/signatures/signature/false_positive_reduction/${id}/`);
}

function getHitCountQuery(guid: Guid, daysBack: number) {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            term: {
              'metadata.snapattack_analytic_guid': guid
            }
          },
          {
            bool: {
              must_not: {
                exists: {
                  field: 'metadata.snapattack_null'
                }
              }
            }
          },
          {
            range: {
              timestamp: {
                gte: `now-${daysBack}d/d`,
                lt: 'now/d'
              }
            }
          }
        ]
      }
    },
    aggs: {
      event_count: {
        value_count: {
          field: '_id'
        }
      }
    }
  };
}

type ExpectedOSResponseStructure = {
  data: {
    aggregations: {
      event_count: {
        value: number;
      };
    };
  };
};
export function getHitCount(guid: Guid, daysBack: number): Promise<number> {
  const payload = getHitCountQuery(guid, daysBack);
  return snapattackData
    .post(`/api/console/proxy?path=_search&method=GET`, payload)
    .then((r: ExpectedOSResponseStructure) => r.data?.aggregations?.event_count?.value);
}

export function updateAiDescription(guid: Guid, payload: string): Promise<void> {
  return snapattack.post(`/signatures/signature/${guid}/ai_description/`, { ai_description: payload });
}

export function getAssociatedAttackScripts(guid: Guid): Promise<AnalyticBASScriptResponse> {
  return snapattack.get(`/signatures/signature/${guid}/bas_script/`).then(r => r.data);
}

export function postAnalyticAttackScripts(guid: Guid, payload: Guid[]): Promise<void> {
  return snapattack.post(`/signatures/signature/${guid}/bas_script/`, payload);
}

export function deleteAnalyticAttackScripts(guid: Guid, payload: Guid[]): Promise<void> {
  return snapattack.delete(`/signatures/signature/${guid}/bas_script/`, { data: payload });
}
