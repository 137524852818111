import React from 'react';

import { RJSFSchema } from '@rjsf/utils';
import { Formik, FormikConfig } from 'formik';
import { ZodError, ZodSchema } from 'zod';

import { getSchemaViolations } from './validation';

export type FormikProps<Values> = FormikConfig<Values> & {
  nestedRjsf?: Record<string, RJSFSchema>;
  zodSchema?: ZodSchema<Partial<Values>>;
  /* customize the way zod errors are transformed into a formik error object. pass `true` for a simple `join('.')` */
  flattenZodErrors?: boolean | ((path: (string | number)[]) => string);
};

export default function SAFormik<Values>({ zodSchema, flattenZodErrors, ...props }: FormikProps<Values>): JSX.Element {
  const formikConfig = { ...props };

  // augment formik validation with alternate schema types
  if (zodSchema) {
    formikConfig.validate = function (values: Values) {
      let zodSchemaErrors;

      if (zodSchema) {
        try {
          zodSchema.parse(values);
        } catch (err) {
          if (err instanceof ZodError) {
            zodSchemaErrors = flattenZodErrors
              ? err.errors.reduce(
                  (acc, err) => ({
                    ...acc,
                    [typeof flattenZodErrors === 'function' ? flattenZodErrors(err.path) : err.path.join('.')]:
                      err.message
                  }),
                  {}
                )
              : getSchemaViolations(err);
          }
        }
      }

      if (props.validate) {
        return { ...zodSchemaErrors, ...props.validate(values) };
      } else {
        return zodSchemaErrors;
      }
    };
  }

  return <Formik {...formikConfig} />;
}
