import React from 'react';

import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import { User } from 'types/auth';
import { ArtifactType, PageParams } from 'types/common';
import { isCompositeFilter, Ops, Query } from 'types/filter';

import { searchParamFactory, SearchParamFactory } from 'utilities/SearchParam';

import {
  FilterAugment,
  FilterComponentProps,
  FilterConfig,
  FilterSchema,
  FilterTopic,
  FilterValues
} from './GlobalFilter.type';
import { FilterContextStore } from './GlobalFilterContext';

export class FilterRegistry {
  public static NULL = '_null';

  /* the registered filters */
  public filters: FilterConfig[];
  public augment: FilterAugment;
  /* the logged-in user */
  public user: User;

  constructor(augment: FilterAugment) {
    this.augment = augment;
    this.filters = [];
  }

  /* check filter.supportedTopics against toQuery and fromQuery and warn on inconsistencies */
  private checkSupported(filter: FilterConfig): void {
    if (process.env.NODE_ENV === 'production') return;
    const displayName = (filter.component as any).name;

    filter.supportedTopics.forEach(topic => {
      if (!filter.toQuery.default && !filter.toQuery[topic]) {
        console.warn(
          `Filter ${displayName} lists topic '${topic}' as supported but does not have a 'toQuery' rule for ${topic}.`
        );
      }

      if (!filter.fromQuery.default && !filter.fromQuery[topic]) {
        console.warn(
          `Filter ${displayName} lists topic '${topic}' as supported but does not have a 'fromQuery' rule for ${topic}.`
        );
      }
    });

    Object.keys(filter.toQuery).forEach(topic => {
      if (topic !== 'default' && !filter.supportedTopics.includes(topic as ArtifactType)) {
        console.warn(
          `Filter ${displayName} contains a 'toQuery' rule for topic '${topic}', but ${topic} is not listed as supported.`
        );
      }
    });

    Object.keys(filter.fromQuery).forEach(topic => {
      if (topic !== 'default' && !filter.supportedTopics.includes(topic as ArtifactType)) {
        console.warn(
          `Filter ${displayName} contains a 'fromQuery' rule for topic '${topic}', but ${topic} is not listed as supported.`
        );
      }
    });
  }

  private checkQuerySchema(schema: FilterSchema, query: Query): FilterValues {
    const result = schema.safeParse(query);
    if (result.success) return result.data;
    if (isCompositeFilter(query)) {
      return query.items.reduce(
        (acc, queryItem) => ({
          ...acc,
          ...this.checkQuerySchema(schema, queryItem)
        }),
        {}
      );
    }
    return {};
  }

  private isVisible(topic: FilterTopic | FilterTopic[], supportTopic?: boolean) {
    if (isArray(topic) && supportTopic)
      return (fc: FilterConfig) => fc.supportedTopics?.some(s => topic?.some(t => t === s));
    else if (!isArray(topic)) return (fc: FilterConfig) => topic === '*' || fc.supportedTopics.includes(topic);
  }

  private getFcDefaults(topic: FilterTopic, fc: FilterConfig): FilterValues {
    const fcDefaults = fc.defaults[topic] || fc.defaults.default;
    const fcDefaultValues = fcDefaults ? fcDefaults(this.augment) : {};
    const userPrefDefaultValues =
      topic === '*' ? {} : pick(this.augment.defaultFeedFilter, Object.keys(fcDefaultValues));
    return { ...fcDefaultValues, ...userPrefDefaultValues };
  }

  /* add a filter to the registry */
  register(filter: FilterConfig) {
    this.checkSupported(filter);
    this.filters.push(filter);
  }

  /* returns the filters that apply to a given topic */
  visibleFilters(topic: FilterTopic | FilterTopic[], supportTopic?: boolean): FilterConfig[] {
    if (supportTopic) return this.filters?.filter(this.isVisible(topic, supportTopic));
    else return this.filters.filter(this.isVisible(topic, supportTopic));
  }

  /* returns the set of default values for the registered filters */
  defaults(topic: FilterTopic): FilterValues {
    return this.filters.reduce((values, fc) => {
      return { ...values, ...this.getFcDefaults(topic, fc) };
    }, {});
  }

  isDefaultFromSettings(topic: FilterTopic, key: string): boolean {
    const userDefault = this.augment.defaultFeedFilter?.[key];
    if (userDefault === undefined) return false;

    let registryDefault;
    for (const fc of this.filters) {
      const fcDefaults = fc.defaults[topic] || fc.defaults.default;
      const defs = fcDefaults(this.augment);
      if (key in defs) {
        registryDefault = defs[key];
      }
    }

    return !isEqual(userDefault, registryDefault);
  }

  /* returns the number of registered filters with values not equal to the default
   * if `values` does not contain the params a given filter, it's assumed to be the default */
  appliedCount(topic: FilterTopic, values: FilterValues): number {
    return this.filters.reduce((count, fc) => {
      const fcDefaults = this.getFcDefaults(topic, fc);
      const appliedValues = Object.entries(fcDefaults).reduce((appliedVals, [key, defaultValue]) => {
        return { ...appliedVals, [key]: key in values ? values[key] : defaultValue };
      }, {});
      return isEqual(fcDefaults, appliedValues) ? count : count + 1;
    }, 0);
  }

  /* render the visible filters */
  render(
    topic: FilterTopic,
    onChange: FilterComponentProps['onChange'],
    values: FilterValues,
    filterContext?: FilterContextStore
  ): JSX.Element {
    return (
      <>
        {this.visibleFilters(topic).map((fc, i) =>
          fc.component ? React.createElement(fc.component, { key: i, onChange, topic, values, filterContext }) : null
        )}
      </>
    );
  }

  /* render the visible filters */
  renderByContainingSupportedTopics(
    topic: FilterTopic,
    topics: FilterTopic[],
    onChange: FilterComponentProps['onChange'],
    values: FilterValues,
    filterContext?: FilterContextStore
  ): JSX.Element {
    return (
      <>
        {this.visibleFilters(topics, true)?.map((fc, i) => {
          // the `topic` passed to the rendered component should be a supportedTopic. use the first one found
          // TODO: find a better way to do this. This will break when the render depends on topic
          // but more than one of `topics` is supported
          const renderTopic = fc.supportedTopics.includes(topic as ArtifactType)
            ? topic
            : topics.find(t => fc.supportedTopics.includes(t as ArtifactType));
          return fc.component
            ? React.createElement(fc.component, { key: i, onChange, topic: renderTopic, values, filterContext })
            : null;
        })}
      </>
    );
  }

  /* build a query payload for the given topic from the given values */
  toQuery(topic: ArtifactType, values: FilterValues, filterContext?: FilterContextStore): Query {
    const items: Query[] = [];
    this.visibleFilters(topic).forEach(fc => {
      const defaults = this.getFcDefaults(topic, fc);
      const keys = Object.keys(defaults);
      // don't try to convert if none of the filter's keys are present
      if (!keys.some(key => Object.keys(values).includes(key))) return;
      const transform = fc.toQuery[topic] || fc.toQuery.default;
      if (!transform) return;
      // pull out only the values relevant to this filter; apply defaults if any keys are missing
      const fcValues = { ...defaults, ...pick(values, keys) };
      const query = transform(fcValues, this.augment, filterContext);
      if (!query) return;
      items.push(query);
    });

    return {
      op: Ops.and,
      items
    };
  }

  /* convert a query payload to filter values */
  fromQuery(topic: ArtifactType, query: Query): FilterValues {
    return this.visibleFilters(topic).reduce((accumulatedValues, fc) => {
      let values = {};
      const schema = fc.fromQuery[topic] || fc.fromQuery.default;
      if (schema) values = this.checkQuerySchema(schema, query);
      return { ...accumulatedValues, ...values };
    }, {});
  }

  parseSearch(search: string, topic?: ArtifactType): FilterValues {
    const factory = searchParamFactory(search);
    topic = topic || (factory.byName('topic') as ArtifactType);
    return this.filters.reduce((values, fc) => {
      const fcDefaults = this.getFcDefaults(topic, fc);
      return {
        ...values,
        ...Object.entries(fcDefaults).reduce(
          (fcValues, [key, defaultValue]) => ({
            ...fcValues,
            [key]: processValue(factory, key, defaultValue)
          }),
          {}
        )
      };
    }, {});
  }

  pageParams(search: string, firstPage: string): PageParams {
    const factory = searchParamFactory(search);
    return {
      page: parseInt(factory.byName('page', firstPage)),
      size: parseInt(factory.byName('size', '20'))
    };
  }
}

function processValue(factory: SearchParamFactory, key: string, defaultValue: string | string[]): string | string[] {
  if (Array.isArray(defaultValue)) {
    return factory.allByName(key, defaultValue).filter(v => v !== FilterRegistry.NULL);
  } else {
    const val = factory.byName(key, defaultValue);
    if (val === FilterRegistry.NULL) return undefined;
    return val;
  }
}
