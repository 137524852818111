import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { Discriminator } from 'module/Tag';
import useTagOptions from 'module/Tag/useTagOptions';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type SoftwareKeys = {
  software: string[];
  softwareOp: Ops;
};

type SoftwareFilterProps = {
  onChange(values: Partial<SoftwareKeys>): void;
  values: SoftwareKeys;
};

function SoftwareFilter({ onChange, values }: SoftwareFilterProps): React.ReactElement {
  const { options, search, searching } = useTagOptions(Discriminator.Software);

  const handleValueChange = (option: Option[]) => {
    const payload: Partial<SoftwareKeys> = { software: option.map(o => o.value) };
    if (option.length === 0) payload.softwareOp = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (softwareOp: Ops) => {
    onChange({ softwareOp });
  };

  return (
    <InclusiveAutocomplete
      title='By Software'
      name='software_tags_dropdown'
      option={options}
      value={values.software}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={values.softwareOp}
      onSearch={search}
      searching={searching}
    />
  );
}

function toQuery(values: SoftwareKeys) {
  if (!values.software?.length) return;
  return {
    field: 'software_aliases',
    op: values.softwareOp,
    value: values.software
  };
}

function toAttackTagQuery(values: SoftwareKeys) {
  if (!values.software?.length) return;
  return {
    field: 'software.name',
    op: values.softwareOp,
    value: values.software
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('software_aliases'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    software: query.value,
    softwareOp: query.op
  }));

const fromAttackTagQuery = zod
  .object({
    field: zod.literal('software.name'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    software: query.value,
    softwareOp: query.op
  }));

const SoftwareFilterConfig: FilterConfig<SoftwareKeys> = {
  defaults: { default: () => ({ software: [], softwareOp: Ops.any }) },
  supportedTopics: [
    ArtifactType.Analytic,
    ArtifactType.AttackTag,
    ArtifactType.Collection,
    ArtifactType.Intel,
    ArtifactType.Session,
    ArtifactType.AttackScript
  ],
  component: SoftwareFilter,
  toQuery: { default: toQuery, [ArtifactType.AttackTag]: toAttackTagQuery },
  fromQuery: { default: fromQuery, [ArtifactType.AttackTag]: fromAttackTagQuery }
};
export default SoftwareFilterConfig;
