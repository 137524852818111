import React from 'react';

import { MatrixPaletteOption } from 'module/Scaffold/Scaffold.type';
import { TagWeight } from 'module/ThreatProfile/ThreatProfile.type';
import useThreatProfile from 'module/ThreatProfile/useThreatProfile';

import { catalog } from './Matrix.const';
import { getPaletteNodeColorTier } from './Matrix.service';
import { HeatMap, StatisticNode, Tier } from './Matrix.type';
import { nodeTotal } from './Matrix.util';

export default function useMatrixPalette(
  palette: MatrixPaletteOption,
  heatMap: HeatMap,
  maxRange: number,
  perBucket: number
): {
  palette: MatrixPaletteOption;
  getTier: (node: StatisticNode) => Tier | undefined;
} {
  const { tagInProfile } = useThreatProfile();

  const getTier = React.useCallback(
    (node: StatisticNode) => {
      if (heatMap === HeatMap.ThreatProfile) {
        const valueMap = {
          [TagWeight.Highest]: catalog[heatMap][0],
          [TagWeight.High]: catalog[heatMap][1],
          [TagWeight.Medium]: catalog[heatMap][2],
          [TagWeight.Low]: catalog[heatMap][3],
          [TagWeight.Lowest]: catalog[heatMap][4],
          [TagWeight.Ignored]: catalog[heatMap][5]
        };
        const tag = tagInProfile(node.id);
        const priorityScore = tag?.score_label;
        return valueMap[priorityScore];
      }
      return getPaletteNodeColorTier(palette, maxRange, perBucket, nodeTotal(node));
    },
    [heatMap, palette, maxRange, perBucket, tagInProfile]
  );
  return {
    palette,
    getTier
  };
}
