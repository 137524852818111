import React, { ReactElement } from 'react';

import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import replace from 'lodash/replace';
import round from 'lodash/round';
import size from 'lodash/size';
import upperCase from 'lodash/upperCase';

import { OutlinedAccordion as Accordion } from 'snap-ui/Accordion';
import Divider from 'snap-ui/Divider';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { MULTILINE_SIZE } from 'constants/processGraph';

import { BadgeType, Field, NodeDataType } from 'types/progressGraph';

import { convertMillisecondsToMMss } from 'utilities/TimeUtils';

type SidebarPanelProps = {
  index: number;
  title: string;
  badgeValue?: number;
  active: boolean;
  fields: Field[];
  values: NodeDataType;
  onClick: (index: number) => void;
  timeFields: string[];
  badgeType: BadgeType;
  fieldActions: { UtcTime(value: string): void };
  actionHelpers: object;
  renderTitle?: () => ReactElement;
  renderContent?: () => ReactElement;
};

const StyledSidebar = styled('div')`
  .divide-banner {
    border-width: 1px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .prevalence-badge {
    background-color: #aaed8d !important;
    border-color: #aaed8d !important;
    color: #282828 !important;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 85px;
    padding: ${p => p.theme.spacing(0, 3, 0, 3)};
    border-radius: ${p => p.theme.spacing(1)};
    h5 {
      font-weight: 800;
      font-size: 0.75rem;
    }
  }
  .field-container {
    display: flex;
    flex-direction: column;

    .field-info {
      display: flex;
      flex-direction: row;
      padding: ${p => p.theme.spacing(1, 0, 1, 0)};
      align-items: center;

      div {
        word-break: break-word;
      }

      p {
        min-width: 160px;
        margin: 0;
        padding: 0;
        font-size: 0.875rem;
        pre {
          font-size: 0.875rem;
        }
      }
    }
  }
`;

const SidebarPanel = ({
  index,
  title,
  badgeValue,
  active,
  fields,
  values,
  onClick,
  timeFields,
  badgeType,
  fieldActions,
  actionHelpers,
  renderTitle,
  renderContent
}: SidebarPanelProps): ReactElement => {
  function renderAccordionTitle(): ReactElement {
    const defaultRender = (
      <>
        <Typography className='sideBar-title' variant='h4'>
          {title}
        </Typography>
        {badgeValue ? renderBadge(badgeType) : null}
      </>
    );
    return renderTitle ? renderTitle() : defaultRender;
  }

  function renderBadge(badgeType: BadgeType): ReactElement {
    let color = 'green';
    let label = 'Low';
    if (badgeType === BadgeType.PREVALENCE) {
      color = 'red';
      label = 'Rare';
    }
    if (badgeValue > 0.33 && badgeValue < 0.67) {
      color = 'orange';
      label = 'Medium';
    } else if (badgeValue >= 0.67) {
      if (badgeType === BadgeType.MALICIOUS) {
        color = 'red';
        label = 'High';
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        color = 'green';
        label = 'Common';
      }
    }
    return (
      <div className='prevalence-badge'>
        <Typography variant='h5'>{upperCase(label)}</Typography>
      </div>
    );
  }

  function onValueClick(field: string, value: string): void {
    if (field === 'UtcTime') {
      fieldActions.UtcTime(value);
    }
  }

  function isMultiline(field: Field, value: string): boolean {
    return size(value) > MULTILINE_SIZE && field.multiline;
  }

  function renderValue(field: Field): ReactElement {
    let value: string | number = get(values, field.name, 'N/A');

    if (typeof value === 'number') {
      if (includes(timeFields, field.name)) {
        value = convertMillisecondsToMMss(Number(value));
      } else {
        value = round(value, 2).toString();
      }
    }

    let span;
    const preValue = (
      <pre className={isMultiline(field, value) ? 'long-value pre-small' : 'value pre-small'}>{value}</pre>
    );
    if (field.link) {
      span = (
        <Tooltip title={get(actionHelpers, field.name)} placement='top-start' wrap>
          <a
            href={replace(field.link, '{VALUE}', value)}
            target='_blank'
            rel='noopener noreferrer'
            className={`${isMultiline(field, value) ? 'long-value' : 'value'} clickable`}
          >
            {preValue}
          </a>
        </Tooltip>
      );
    } else {
      span = get(fieldActions, field.name) ? (
        <Tooltip title={get(actionHelpers, field.name)} placement='top-start' wrap>
          <button
            onClick={(): void => onValueClick(field.name, value?.toString())}
            className={`${isMultiline(field, value) ? 'long-value' : 'value'} clickable link`}
          >
            {preValue}
          </button>
        </Tooltip>
      ) : (
        preValue
      );
    }
    if ((!field.multiline && size(value) > MULTILINE_SIZE) || (field.multiline && size(value) > 125)) {
      return (
        <Tooltip placement='top-start' title={value}>
          {span}
        </Tooltip>
      );
    }
    return span;
  }

  if (renderContent && renderContent() === null) {
    return null;
  }

  return (
    <StyledSidebar key={index}>
      <Divider className='divide-banner' />
      <Accordion
        expanded={active}
        simple={true}
        onChange={() => {
          onClick(index);
        }}
        summary={renderAccordionTitle()}
        details={
          renderContent ? (
            renderContent()
          ) : (
            <div className='field-container'>
              {map(fields, field => (
                <div key={field.name} className='field-info'>
                  <p className='detail-name'>{field.label}</p>
                  <div>{renderValue(field)}</div>
                </div>
              ))}
            </div>
          )
        }
      />
    </StyledSidebar>
  );
};

export default SidebarPanel;
