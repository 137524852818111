export function NOOP(...args: unknown[]): void {} // eslint-disable-line @typescript-eslint/no-unused-vars

export function debounceDispatch<T>(dispatch: (action: T) => void, delay = 250): (action: T) => void {
  let timer: NodeJS.Timeout;

  return function (action: T): void {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      dispatch(action);
    }, delay);
  };
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function handleDebug(event: React.MouseEvent<HTMLDivElement>, callback: () => void) {
  if (event.ctrlKey && event.shiftKey) callback();
}
