import clamp from 'lodash/clamp';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { Attack } from 'module/Tag';

import { Ident } from 'types/common';

import { daysBetween } from 'utilities/TimeUtils/TimeUtils';

import { EMPTY_DASHBOARD_STATS, LETTER_GRADES } from './DetectionDashboard.const';
import {
  CoverageData,
  DashboardStats,
  DetectionCounts,
  DetectionData,
  StatsPayload,
  TechniqueDifference,
  TimeSeriesData
} from './DetectionDashboard.type';

const DURATION_DIVISOR = 10;

export function createStatsPayload(startDate: Date, stopDate: Date, defaultOrgId: Ident): StatsPayload {
  const momentStart = moment(startDate).startOf('day');
  const momentStop = moment(stopDate).endOf('day');
  const duration = daysBetween(startDate, stopDate);
  const every = duration <= 2 ? 'PT12H' : `P${Math.ceil(duration / DURATION_DIVISOR)}D`;

  const payload: StatsPayload = {
    start: momentStart.toISOString(),
    stop: momentStop.toISOString(),
    every,
    organization_id: defaultOrgId
  };
  return payload;
}

export function parseDashboardData(
  coverage: CoverageData[],
  detection: DetectionData[],
  tactics: Attack[]
): DashboardStats {
  const overallPosture = parseOverallPosture(detection, 'deployed');
  const postures = parsePostures(detection, 'deployed');
  const deployed = getBoundaryCounts(detection, 'deployed');
  const available = getBoundaryCounts(detection, 'available');

  const techniques = parseTechniques(coverage, tactics);
  const attackCoverage = parseAttackCoverage(techniques);

  return {
    ...EMPTY_DASHBOARD_STATS,
    overallPosture,
    postures,
    deployed,
    available,
    attackCoverage,
    techniques
  };
}

export function mapTimeSeriesIntoBuckets(data: TimeSeriesData[]): TimeSeriesData[][] {
  return [...new Set(data.map(data => data.time))].map(timestamp => data.filter(data => data.time === timestamp));
}

export function getBoundaryCounts(detections: DetectionData[], field: keyof DetectionCounts) {
  const filtered = detections.map(detection => detection[field]);

  const { 0: previousTimeslice, [filtered.length - 1]: currentTimeslice } = filtered;
  const increasePercent = Math.ceil(((currentTimeslice - previousTimeslice) / previousTimeslice) * 100);

  return {
    startCount: previousTimeslice,
    stopCount: currentTimeslice,
    difference: currentTimeslice - previousTimeslice,
    increasePercent: increasePercent,
    raw: filtered
  };
}

export function parsePostures(detections: DetectionData[], field: keyof DetectionCounts) {
  if (isEmpty(detections)) return [];
  const filtered = detections.map(detection => detection[field]);

  return filtered.map(deployed => ({
    value: percentageGrade(deployed)
  }));
}

export function parseOverallPosture(data: DetectionData[], field: keyof DetectionCounts) {
  if (isEmpty(data))
    return {
      letterGrade: undefined,
      percentageGrade: undefined
    };
  const rawDeployed = getBoundaryCounts(data, field);

  return {
    letterGrade: letterGrade(rawDeployed.stopCount),
    percentageGrade: percentageGrade(rawDeployed.stopCount)
  };
}

export function parseTechniques(coverage: CoverageData[], tactics: Attack[]): TechniqueDifference[] {
  if (isEmpty(coverage)) return [];

  const coverageBuckets = mapTimeSeriesIntoBuckets(coverage) as CoverageData[][];

  const { 0: previousTimeslice, [coverageBuckets.length - 1]: currentTimeslice } = coverageBuckets;

  const techniques = previousTimeslice.map(previousTechniqueTimeslice => {
    const currentTechniqueSlice = currentTimeslice.find(tech => tech.tag_id === previousTechniqueTimeslice.tag_id);
    const coverageChange =
      currentTechniqueSlice.detection_coverage_deployed - previousTechniqueTimeslice.detection_coverage_deployed;

    let coverageStart;
    let coverageAvailable;
    let coverageIncrease = 0;
    let coverageDecrease = 0;
    let uncovered;

    if (coverageChange >= 0) {
      coverageStart = previousTechniqueTimeslice.detection_coverage_deployed;
      coverageIncrease = coverageChange;
      coverageAvailable = currentTechniqueSlice.detection_coverage_available;
      uncovered = currentTechniqueSlice.children - (coverageStart + coverageIncrease + coverageAvailable);
    } else {
      coverageStart = currentTechniqueSlice.detection_coverage_deployed;
      coverageDecrease = Math.abs(coverageChange);
      coverageAvailable = currentTechniqueSlice.detection_coverage_available - coverageDecrease;
      uncovered =
        currentTechniqueSlice.children -
        Math.max(
          coverageStart - currentTechniqueSlice.detection_coverage_available,
          previousTechniqueTimeslice.detection_coverage_deployed
        );
    }

    return {
      id: previousTechniqueTimeslice.tag_id,
      name: previousTechniqueTimeslice.tag_name,
      coverageAvailable,
      coverageStart,
      coverageIncrease,
      coverageDecrease,
      uncovered,
      children: currentTechniqueSlice.children
    };
  });

  const tacticNames = tactics.map(a => a.name);
  techniques.sort((a, b) => tacticNames.indexOf(a.name) - tacticNames.indexOf(b.name));

  return techniques;
}

export function parseAttackCoverage(techniques: TechniqueDifference[]) {
  return techniques
    .map(technique => ({
      initial: technique.coverageStart,
      change: technique.coverageIncrease - technique.coverageDecrease,
      children: technique.children
    }))
    .reduce(
      (composite, technique) => {
        composite.initial += technique.initial;
        composite.change += technique.change;
        composite.children += technique.children;
        return composite;
      },
      {
        initial: 0,
        change: 0,
        children: 0
      }
    );
}

function logarithmicDeployed(deployed: number): number {
  const k = 0.4;
  const x0 = 4.5;
  return (1 / k) * Math.log(deployed / x0) - 1;
}

export function letterGrade(deployed: number): LETTER_GRADES {
  if (deployed <= 0) return LETTER_GRADES[0];
  const key = clamp(Math.floor(logarithmicDeployed(deployed)), 0, 14);
  return get(LETTER_GRADES, key);
}

export function percentageGrade(deployed: number): number {
  const logDeployed = logarithmicDeployed(deployed);

  let percentage = 0;

  if (logDeployed >= 15) {
    percentage = 99;
  } else if (deployed > 10) {
    percentage = logDeployed * (95 / 14 / 2) + 50;
  } else {
    percentage = deployed * 4.9;
  }

  return percentage;
}
