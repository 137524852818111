import React, { SyntheticEvent } from 'react';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import { SlimAccordion } from 'snap-ui/Accordion';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { AttackScript } from 'module/AttackScript/AttackScript.type';

const Container = styled('div', { name: 'AttackScriptListItem' })`
  display: flex;
  padding: ${p => p.theme.spacing(1, 0, 0, 0)};
  align-items: baseline;
  justify-content: space-between;

  .summary-text {
    display: inline;
    padding-right: ${p => p.theme.spacing(2)};
  }
`;

const Accordion = styled(SlimAccordion)`
  pointer-events: none;
`;

type Script = Pick<AttackScript, 'name' | 'guid' | 'product_name' | 'product_id'>;
type AttackScriptListItemProps = {
  script: Script;
  index: number;
  onChange: (panel: number) => (_event: SyntheticEvent<Element, Event>, isExpanded: boolean) => void;
  expanded: number | false;
};
export default function AttackScriptListItem({ index, script }: AttackScriptListItemProps) {
  return (
    <Container key={index}>
      <Accordion expanded={false} key={index} summary={<AccordionSummary script={script} />} />

      <Link aria-label='View attack script' to={`${Path.AttackScript}/${script.guid}`} target='_blank'>
        <Icon icon={faArrowUpRightFromSquare} />
      </Link>
    </Container>
  );
}

export type AccordionSummaryProps = {
  script: Pick<Script, 'name'>;
};

function AccordionSummary({ script }: AccordionSummaryProps) {
  return (
    <Typography className='summary-text' variant='body1'>
      {script.name}
    </Typography>
  );
}
