import chunk from 'lodash/chunk';

export function mapOrder<K extends keyof T, T>(objectArray: T[], orderArray: T[K][], key: K) {
  objectArray.sort((a, b) => {
    const aValue = a[key];
    const bValue = b[key];
    return orderArray.indexOf(aValue) - orderArray.indexOf(bValue);
  });

  return objectArray;
}

export function insert<T>(array: T[], index: number, item: T): void {
  if (array) {
    array.splice(index, 0, item);
  }
}

export type PaginationHelper<T> = {
  getNumPages: () => number;
  getPage: (page: number) => T[];
};

export function paginationHelper<T>(items: T[], perPage: number): PaginationHelper<T> {
  const getNumPages: PaginationHelper<T>['getNumPages'] = function getNumPages() {
    if (items) {
      return Math.ceil(items.length / perPage);
    }
    return 0;
  };

  const getPage: PaginationHelper<T>['getPage'] = function getPage(page) {
    if (page < 1 || page > getNumPages()) {
      return [];
    }

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return items.slice(startIndex, endIndex);
  };

  return { getNumPages, getPage };
}

/**
 * Accepts a list of items along with a total and attempts to equalize
 * the results until the total is met. Returns a list of slice indexes per item.
 *
 * e.g.
 * const subject = [
 *   { data: ["1A", "1B", "1C", "1D"] },
 *   { data: ["2A", "2B", "2C", "2D"] },
 *   { data: ["3A", "3B", "3C", "3D"] }
 * ]
 *
 * console.log(equalizedAllocation(subject, 10))
 * [LOG]: [4, 3, 3]
 */
export function equalizedAllocation<T extends { data: any[] }>(payload: T[], total: number) {
  let pointer = 0;
  let length = 0;
  const totalItems = payload.reduce((p, c) => p + c.data.length, 0);
  const result = new Array(payload.length).fill(0);

  HYDRATION_COMPLETE: while (length < total && pointer < totalItems) {
    for (let c = 0; c < payload.length; c++) {
      if (length === total) break HYDRATION_COMPLETE;
      if (payload[c].data.length > pointer) {
        result[c]++;
        length++;
      }
    }

    pointer++;
  }

  return result;
}

export function move<T>(arr: T[], oldIndex: number, targetIndex: number): T[] {
  const modified = [...arr];
  const element = modified.splice(oldIndex, 1)[0];
  modified.splice(targetIndex, 0, element);
  return modified;
}

export const sortByPrioritizedList = (prioritizedList: string[]) => (a: string, b: string) => {
  const prioritizedListLowered = prioritizedList.map(item => item.toLowerCase());
  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();

  const aIndex = prioritizedListLowered.indexOf(lowerA);
  const bIndex = prioritizedListLowered.indexOf(lowerB);

  if (aIndex >= 0 && bIndex >= 0) return aIndex - bIndex;
  if (aIndex === -1 && bIndex === -1) return lowerA < lowerB ? -1 : lowerA > lowerB ? 1 : 0;
  if (aIndex < 0) return 1;
  if (bIndex < 0) return -1;

  return 0;
};

/**
 * sorts a list of objects on a given key by the provided order
 *
 * @param fieldName the key to sort on. The values of this key must be strings
 * @param prioritizedList the order to sort by
 */
export const sortByPrioritizedListOn =
  <T extends Record<string, any>, K extends keyof T>(
    fieldName: T[K] extends string ? K : never,
    prioritizedList: string[]
  ) =>
  (a: T, b: T): number => {
    const prioritizedListLowered = prioritizedList.map(item => item.toLowerCase());
    const lowerA = a[fieldName].toLowerCase();
    const lowerB = b[fieldName].toLowerCase();

    const aIndex = prioritizedListLowered.indexOf(lowerA);
    const bIndex = prioritizedListLowered.indexOf(lowerB);

    if (aIndex >= 0 && bIndex >= 0) return aIndex - bIndex;
    if (aIndex === -1 && bIndex === -1) return lowerA < lowerB ? -1 : lowerA > lowerB ? 1 : 0;
    if (aIndex < 0) return 1;
    if (bIndex < 0) return -1;

    return 0;
  };

/**
 * breaks an array into chunks of specified size with overlap
 * if overlap is 0, leverage lodash chunk
 */
export function chunkOverlap<T>(array: T[], chunkSize: number, overlap: number): T[][] {
  const result: T[][] = [];
  if (chunkSize < 1 || overlap < 0 || chunkSize <= overlap) return result;
  if (overlap === 0) return chunk(array, chunkSize);

  for (let i = 0; i < array.length - chunkSize + 1; i += chunkSize - overlap) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export function filterVoids<T>(array: (void | T)[]): T[] {
  const returnArray: T[] = [];
  for (const a of array) {
    if (a) returnArray.push(a);
  }
  return returnArray;
}
