import { CancelTokenSource } from 'axios';

import { CancelTokenType } from 'apis';

import { Statistic } from 'module/Matrix/Matrix.type';

import { Query } from 'types/filter';

import {
  AutocompleteModifiers,
  Discriminator,
  SigmaTag,
  filterStatisticBy,
  reduceToSigmaTags,
  searchTagsByDiscriminators
} from '.';

export function filterStatistic(analyticQuery: Query, tagQuery: Query, cancelTokenSource: CancelTokenSource) {
  const config = {
    cancelToken: cancelTokenSource && cancelTokenSource.token
  };

  const payload = {
    analytic_filter: analyticQuery,
    tag_filter: tagQuery
  };

  return filterStatisticBy(payload, config)
    .then(items => ({
      items
    }))
    .catch(() => ({
      canceled: true,
      items: [] as Statistic[]
    }));
}

export function getSigmaTagsByDiscriminator(
  limit = 50,
  d: Discriminator,
  modifiers: Omit<AutocompleteModifiers, 'types'>,
  config?: { cancelToken?: CancelTokenType }
): Promise<SigmaTag[]> {
  return searchTagsByDiscriminators(limit, { types: [d], ...modifiers }, config).then(data =>
    reduceToSigmaTags(d, data)
  );
}

export function getSigmaTagsByAnyDiscriminators(
  limit = 50,
  modifiers: AutocompleteModifiers,
  config?: { cancelToken?: CancelTokenType }
): Promise<SigmaTag[]> {
  return searchTagsByDiscriminators(limit, modifiers, config).then(data => [
    ...reduceToSigmaTags(Discriminator.Actor, data),
    ...reduceToSigmaTags(Discriminator.Attack, data),
    ...reduceToSigmaTags(Discriminator.Software, data),
    ...reduceToSigmaTags(Discriminator.Vulnerability, data),
    ...reduceToSigmaTags(Discriminator.DataSource, data)
  ]);
}
