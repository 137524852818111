import React from 'react';

import classnames from 'classnames';
import { z } from 'zod';

import CircularProgress from 'snap-ui/CircularProgress';
import { styled } from 'snap-ui/util';

import { SSOLoadState } from 'aso/useSSO';

import Formik from 'module/Form/Formik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { Status } from 'storage';

import { LoginNextRequest } from 'types/auth';

import { Form, LoginButton } from '../Authentication.style';

type LoginNextFormProps = {
  className?: string;
  initialValues: LoginNextRequest;
  sso: SSOLoadState;
  setEmail(email: string): void;
  tokenError?: boolean;
};

const Container = styled('div')`
  margin-bottom: ${p => p.theme.spacing(8)};

  form {
    /**
      Attempt to eliminate the jumping button
      when helper text shows and hides on errors.
      Maybe solve this with the textfield formik
      to reserve that space.
    */
    min-height: 150px;
    justify-content: space-between;
  }
`;

const LoginNextForm = ({
  className,
  initialValues,
  tokenError,
  sso,
  setEmail
}: LoginNextFormProps): React.ReactElement => {
  const handleSubmit = ({ email }: LoginNextRequest): void => {
    setEmail(email);
    sso.checkEmail(email);
  };

  return (
    <Container className={classnames('LoginNext', className)}>
      <Formik
        enableReinitialize
        initialValues={{
          email: initialValues.email || '',
          token: initialValues.token || ''
        }}
        onSubmit={handleSubmit}
        zodSchema={z.object({
          email: z.string().email('Please enter a valid email'),
          token: z.string().nullable()
        })}
      >
        <Form>
          <TextFieldFormik
            label='Email Address'
            name='email'
            autoComplete='on'
            disabled={!!initialValues.token && !!initialValues.email}
            elevated
            required={tokenError || !initialValues.token}
          />
          {!sso.isNotSSO && (
            <LoginButton
              className={classnames('LoginNextButton')}
              ariaLabel='Next login step'
              type='submit'
              disabled={sso.status === Status.pending || (sso.status === Status.resolved && !sso.isNotSSO)}
            >
              Next {sso.status === Status.pending && <CircularProgress size={16} />}
            </LoginButton>
          )}
        </Form>
      </Formik>
    </Container>
  );
};

export default LoginNextForm;
