import React from 'react';

import { bulkUnDeploy, removeAnalyticDeployment } from 'apis/resources/analytic';

import { Integration } from 'module/Integration/Integration.type';

import { usePushSnack } from 'provider';

import { Status, useAsync } from 'storage';

import { Guid, Ident } from 'types/common';

import { AsyncInterface } from './';

type DeploymentErrorActions<T extends AsyncInterface['items'][number] = AsyncInterface['items'][number]> = {
  items: T[];
  undeployOne(analyticGuid: Guid, deploymentId: Ident, integrationGuid: Guid): Promise<void>;
  undeployAll(): Promise<void>;
  isPending: boolean;
};

export default function useDeploymentErrorActions<
  T extends AsyncInterface['items'][number] = AsyncInterface['items'][number]
>(_items: T[]): DeploymentErrorActions<T> {
  const pushSnack = usePushSnack();
  const { data: items, errorProps, setData, status, task } = useAsync(_items);

  React.useEffect(() => {
    setData(_items);
  }, [_items, setData]);

  React.useEffect(() => {
    const errorMessage = errorProps?.messages?.[0] || errorProps?.title;
    if (errorMessage) {
      pushSnack(errorProps.messages[0], 'error');
    }
  }, [errorProps, pushSnack]);

  const undeployOne = React.useCallback(
    (analyticGuid: Guid, deploymentId: Ident, integrationGuid: Guid): Promise<void> => {
      return task(removeAnalyticDeployment(analyticGuid, deploymentId, integrationGuid)).then(() => {
        setData(items.filter(i => i.deploymentId !== deploymentId));
      });
    },
    [task, setData, items]
  );

  const undeployAll = React.useCallback(() => {
    const detectionsByIntegration = items.reduce(
      (dbi: Record<Guid, { integration: Integration; detectionGuids: Guid[] }>, item) => {
        return {
          ...dbi,
          [item.integration.guid]: {
            integration: item.integration,
            detectionGuids: [...(dbi[item.integration.guid]?.detectionGuids || []), item.detection.guid]
          }
        };
      },
      {}
    );
    const batches = Promise.all(
      Object.values(detectionsByIntegration).map(({ integration, detectionGuids }) =>
        bulkUnDeploy(integration.organization_id, detectionGuids, [integration.guid])
      )
    );
    return task(batches).then(() => setData([]));
  }, [items, task, setData]);

  return {
    items,
    undeployOne,
    undeployAll,
    isPending: status === Status.pending
  };
}
