import React from 'react';

import { AutocompleteV2 } from 'snap-ui/Autocomplete/AutocompleteV2';
import TextField from 'snap-ui/TextField';

import {
  BulkActionForm,
  InlineEditorForm,
  JsonSchema,
  JsonSchema4,
  useInlineEditor,
  withJsonFormsControlProps
} from 'module/JsonView';

import { MULTI_VALUE_BULK_OPTIONS } from '../Metadata.const';
import { isMetaEmpty, sortControlOptions } from '../Metadata.util';
import { useBulkEditor } from '../MetadataBulkEditInterface';
import { Control, ControlProps } from './Control.type';
import { isControlApplied } from './Control.util';

const _Base = (props: {
  description?: string;
  disabled?: boolean;
  label: string;
  onChange: (value: readonly string[]) => void;
  options: readonly string[];
  value: readonly string[];
}) => {
  const { description, label, onChange, options, value } = props;
  return (
    <AutocompleteV2
      disabled={props.disabled}
      value={value}
      options={options}
      autoHighlight
      onChange={(_e, v) => onChange(v)}
      multiple
      renderInput={params => <TextField {...params} label={label} helperText={description} elevated />}
      className={`Control Control-${Control.MultiSelect}`}
    />
  );
};

function InlineMultiSelect(props: {
  data: readonly string[];
  enabled: boolean;
  options: readonly string[];
  path: string;
  schema: JsonSchema;
}) {
  const { data, enabled, options, path, schema } = props;
  const editor = useInlineEditor(data);
  return (
    <InlineEditorForm
      data={data}
      editor={editor}
      enabled={enabled}
      path={path}
      title={schema.title}
      type={Control.MultiSelect}
    >
      <_Base label={schema.title} value={editor.queue || []} options={options} onChange={v => editor.setQueue(v)} />
    </InlineEditorForm>
  );
}

function BulkActionMultiSelect(props: { options: readonly string[]; path: string; schema: JsonSchema }) {
  const { options, path, schema } = props;
  const editor = useBulkEditor(path);
  return (
    <BulkActionForm actions={MULTI_VALUE_BULK_OPTIONS} editor={editor} title={schema.title}>
      <_Base
        description={schema.description}
        label='Value'
        onChange={v => editor.set(editor.operation, v)}
        options={options}
        value={editor.value as string[]}
      />
    </BulkActionForm>
  );
}

const _MultiSelectControl = (props: ControlProps<readonly string[]>) => {
  const { config, data, enabled, handleChange, path, schema } = props;

  if ((!config.asPreview && !isControlApplied(schema, config)) || (config.truncated && isMetaEmpty(data))) return null;
  const options = ((schema.items as JsonSchema4).enum || []).sort(sortControlOptions);
  if (config.asBulk) return <BulkActionMultiSelect options={options} path={path} schema={schema} />;

  if (config.asInline)
    return <InlineMultiSelect data={data} enabled={enabled} options={options} path={path} schema={schema} />;
  return (
    <_Base
      label={schema.title}
      value={data || []}
      options={options}
      onChange={v => handleChange(path, v)}
      disabled={!enabled}
    />
  );
};
export const MultiSelectControl = withJsonFormsControlProps(_MultiSelectControl);
