import React from 'react';

import { superOnly } from 'module/Authentication';

import { useAuth, useUserConfig } from 'provider';

import { FunctionalPermission } from 'types/auth';

import { AlphaFeature } from './';

export function useAlphaSetting(
  feature: AlphaFeature | FunctionalPermission //eslint-disable-line @typescript-eslint/no-unused-vars
): {
  canEnableAlpha: boolean;
  enableAlpha: boolean;
  setEnableAlpha(enable: boolean): void;
} {
  const { user } = useAuth();
  const { enableAlpha: _enableAlpha, _setItem } = useUserConfig();

  const canEnableAlpha = superOnly(user);
  const enableAlpha = canEnableAlpha && _enableAlpha;

  const setEnableAlpha = React.useCallback(
    (newValue: boolean) => {
      if (!canEnableAlpha) return false;
      _setItem('enableAlpha', newValue);
    },
    [canEnableAlpha, _setItem]
  );

  return { canEnableAlpha, enableAlpha, setEnableAlpha };
}
