import React, { ReactElement } from 'react';

import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';
import { useUserback } from '@userback/react';
import ReactDOM from 'react-dom';

import { Fab } from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

import { sendFormDateToHubspot } from 'apis/resources/hubspot';

import config from 'config/config';

import { Engage, Fingerprint, NotableEvent } from 'lib/Engagement';

import { useAuth } from 'provider';

import CustomUserbackWidget from './CustomUserbackWidget';

const Bullhorn = styled(Fab)`
  font-size: 1.5rem;
  margin: 0;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
`;

const Feedback = (): ReactElement => {
  const { user, isSubscriber } = useAuth();
  const { close, open, setEmail, setName, identify } = useUserback();

  const sendUserInfoToHubspot = React.useCallback(() => {
    const payload = {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name
    };

    sendFormDateToHubspot(payload);
  }, [user.email, user.first_name, user.last_name]);

  const [targetNode, setTargetNode] = React.useState(null);

  const appendCustomWidget = React.useCallback(() => {
    // Create a div for custom userback component to mount to
    const userbackControlStep = document.querySelector('.userback-controls-se');
    // since this function can be called when a node is removed, we need to return early if the node does not exist
    if (!userbackControlStep) return;
    const customWidgetDiv = document.createElement('div');
    customWidgetDiv.setAttribute('id', 'userback-custom-widget');
    userbackControlStep.append(customWidgetDiv);

    ReactDOM.render(
      <CustomUserbackWidget
        close={() => setTimeout(close, 2500)}
        sendUserInfoToHubspot={sendUserInfoToHubspot}
        showDemo={!isSubscriber}
      />,
      document.getElementById('userback-custom-widget')
    );

    // The container needs to be targeted because the inner contents get removed on close
    if (!targetNode) setTargetNode(document.querySelector('.userback-button-container'));
  }, [close, isSubscriber, sendUserInfoToHubspot, targetNode]);

  React.useEffect(() => {
    // console.log('targetNode: ', targetNode);
    const config = { attributes: true, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = (mutationList: MutationRecord[], observer: MutationObserver): void => {
      // console.log('mutationList: ', mutationList);
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          // console.log('A child node has been added or removed.');
          // we could query mutation.addedNodes or mutation.removedNodes to see which nodes were changed
          if (!document.getElementById('userback-custom-widget') && document.querySelector('.userback-controls-se')) {
            observer.disconnect();
            appendCustomWidget();
            observer.observe(targetNode, config);
          }
        } else if (mutation.type === 'attributes') {
          // console.log(`The ${mutation.attributeName} attribute was modified.`);
          // Hides our custom widget if the user clicks anything that would move from Step 1 -> Step 2
          // All of our custom changes are made outside of Step 1 and 2 divs
          const stepOne: HTMLElement = document.querySelector('.userback-controls-step[data-step="1"]');
          if (stepOne && stepOne?.style.getPropertyValue('display') === 'none') {
            const options = document.getElementById('userback-custom-menu-options');
            if (options) options.style.display = 'none';
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    if (targetNode) {
      // Start observing the target node for configured mutations
      observer.observe(targetNode, config);
    }

    return () => {
      observer.disconnect();
    };
  }, [appendCustomWidget, targetNode]);

  function handleClick(): void {
    setEmail(user.impersonator?.email || user.email);
    setName(user.impersonator?.name || user.name);

    const payload = {
      instance: window.location.hostname,
      impersonator: user.impersonator?.name,
      version: config.VERSION,
      apiVersion: config.BACKEND_VERSION
    };

    Engage.track(Fingerprint.of(NotableEvent.Feedback).pageLoad().withData(payload));
    identify(user.id.toString(), payload);

    open();
    appendCustomWidget();
  }

  if (!user.guid) return null;

  return (
    <Bullhorn
      aria-label='User feedback'
      className='userback-button userback-button-se react-button'
      icon={faBullhorn}
      onClick={handleClick}
      color='primary'
    />
  );
};

export default Feedback;
