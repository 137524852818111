import startCase from 'lodash/startCase';

import { interpolateSigmaConfig, SigmaConfigOrder } from 'services/sigmaService';

import { ConfigType, LanguageAdvanceConfig, SigmaConfig } from 'types/analytic';

export function isConfigNotEditable(config: SigmaConfig): boolean {
  // everything is editable unless it's a python file, in which case raw is null
  return config?.raw === null || config?.raw === undefined;
}

export function combineAdvanceOptionsToSingleString(options: LanguageAdvanceConfig[]): string[] {
  return [options.map(option => `${option.field}=${option.value}`).join(',')];
}
export function parseObjString(str: string[]) {
  return str[0].split(',').map(item => {
    const [key, value] = item.split('=');
    return { field: key, value };
  });
}

export function configName(
  configType: ConfigType,
  { capital, plural }: { capital?: boolean; plural?: boolean } = {}
): string {
  let name: string;
  switch (configType) {
    case 'pipeline':
      name = plural ? 'pipelines' : 'pipeline';
      break;
    case 'validator':
      name = plural ? 'validators' : 'validator';
      break;
    case 'field_mapping':
    default:
      name = plural ? 'configurations' : 'configuration';
      break;
  }
  return capital ? startCase(name) : name;
}

const LEGACY_SHELL = {
  title: '',
  order: Number(SigmaConfigOrder.Generic),
  defaultindex: '',
  backends: [],
  fieldmappings: {},
  logsources: []
} as const;

const PIPELINE_SHELL = {
  name: '',
  priority: 100,
  transformations: [
    {
      id: '',
      type: '',
      rule_conditions: [
        {
          type: ''
        }
      ]
    }
  ]
} as const;

const VALIDATOR_SHELL = {
  name: '',
  validators: [],
  exclusions: {}
} as const;

const YAML_SHELLS = {
  [ConfigType.Legacy]: interpolateSigmaConfig(LEGACY_SHELL),
  [ConfigType.Pipeline]: interpolateSigmaConfig(PIPELINE_SHELL),
  [ConfigType.Validator]: interpolateSigmaConfig(VALIDATOR_SHELL)
};

export function getYamlShell(configType: ConfigType): string {
  return YAML_SHELLS[configType] ?? YAML_SHELLS[ConfigType.Legacy];
}
