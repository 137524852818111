import React from 'react';

import { useField } from 'formik';

import { SliderProps, WrappedSlider } from 'snap-ui/Slider';

type SliderFormikProps = Omit<SliderProps, 'value' | 'onChange'> & {
  name: string;
  fixedBoundary?: 'left' | 'right';
};

export default function SliderFormik({ name, fixedBoundary, ...sliderProps }: SliderFormikProps): JSX.Element {
  const [field, , helpers] = useField(name);

  const rightmostThumb = Array.isArray(field.value) ? field.value.length - 1 : 0;

  function handleChange(_event: Event, newValue: number | number[], activeThumb: number): void {
    if (fixedBoundary === 'right' && activeThumb === rightmostThumb) return;
    if (fixedBoundary === 'left' && activeThumb === 0) return;
    helpers.setValue(newValue);
    helpers.setTouched(true);
  }

  return (
    <WrappedSlider {...sliderProps} onChange={handleChange} value={field.value} disableSwap={Boolean(fixedBoundary)} />
  );
}
