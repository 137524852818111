import React from 'react';

import { faAdd, faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { JsonSchema4 } from '@jsonforms/core';

import Button, { ActionIconButton } from 'snap-ui/Button';
import { DataGrid, GridColDef, GridFooterContainer, GridRenderCellParams, SimpleNoRowsOverlay } from 'snap-ui/DataGrid';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { TablePlaceholder } from 'snap-ui/Table';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { useMayI } from 'module/May';
import withFunctionalPermission from 'module/Util/withFunctionalPermission';

import { useMetadataSchema } from 'provider/MetadataSchema';

import { Status, useAsync } from 'storage';

import { FunctionalPermission, Organization } from 'types/auth';

import { Control, ControlFriendlyName } from './Control';
import {
  addToOrgMetadataSchema,
  editOrgMetadataSchema,
  removeFromOrgMetadataSchema,
  reorderOrgMetadataSchema
} from './Metadata.service';
import { MetadataSchema, Operation } from './Metadata.type';
import { getControlOrdering, sortSchemaControl } from './Metadata.util';
import { AppliedTo } from './Metadata.widgets';
import { MetadataBuilder } from './MetadataBuilder';
import { MetadataEditor } from './MetadataEditor';

const Root = styled('div')`
  margin-bottom: ${p => p.theme.spacing(8)};

  .Tooltip-wrapper {
    display: inline-block;
  }

  .MuiDataGrid-root {
    min-height: 200px;
  }

  .MuiDataGrid-virtualScroller {
    min-height: 100px;
  }

  .MuiDataGrid-footerContainer {
    padding: ${p => p.theme.spacing(0, 4)};
    display: flex;
    justify-content: flex-end;
  }
`;

const DialogOpen = {
  builder: 'builder',
  delete: 'delete',
  editor: 'editor',
  none: 'none'
} as const;

type DialogOpen = (typeof DialogOpen)[keyof typeof DialogOpen];

type MetadataSchemaColumn = GridColDef<{
  id: string;
  title: string;
  type: string;
  properties: Record<string, unknown>;
  default: unknown;
  description: string;
}>;

function _MetadataSettings() {
  useTitle('Metadata | SnapAttack');
  const [propertyKeyName, setPropertyKeyName] = React.useState<string>();
  const [dialog, setDialog] = React.useState<DialogOpen>(DialogOpen.none);
  const { schema, schemaStatus, setSchema: setMetadataSchema, orgGuid } = useMetadataSchema();
  const { task, status } = useAsync<Organization>();
  const canDefine = useMayI(FunctionalPermission.DefineMeta);

  const handleSubmitAdd = async (next: MetadataSchema) => {
    setDialog(DialogOpen.none);
    const update = await task(addToOrgMetadataSchema(orgGuid, schema, next));
    setMetadataSchema(update);
  };

  const handleSubmitEdit = async (next: MetadataSchema, property: string) => {
    setDialog(DialogOpen.none);
    const update = await task(editOrgMetadataSchema(orgGuid, schema, next, property));
    setMetadataSchema(update);
  };

  const handleDelete = async (property: string) => {
    const current: MetadataSchema = {
      ...schema,
      migrations: [
        {
          operation: Operation.FieldDelete,
          field: property
        }
      ]
    };
    const update = await task(removeFromOrgMetadataSchema(orgGuid, current, property));
    setMetadataSchema(update);
    setPropertyKeyName(null);
    setDialog(DialogOpen.none);
  };

  const ordering = React.useMemo(() => getControlOrdering(schema), [schema]);

  const rows: JsonSchema4[] = React.useMemo(() => {
    const properties = schema?.properties;
    if (!properties) return [];
    return Object.entries(properties)
      .map(([id, value]) => ({
        id,
        ...value
      }))
      .sort(sortSchemaControl(ordering));
  }, [schema?.properties, ordering]);

  const columns: MetadataSchemaColumn[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.5
    },
    {
      field: 'title',
      headerName: 'Name',
      cellClassName: 'sa-cap',
      flex: 0.5
    },
    {
      field: 'type',
      headerName: 'Type',
      renderCell: (params: GridRenderCellParams<JsonSchema4>) =>
        ControlFriendlyName[params.row.properties?.control['title'] as Control],
      flex: 0.5
    },
    {
      field: 'applied',
      headerName: 'Applied To',
      renderCell: ({ row }) => <AppliedTo key='applied' data={row.properties?.appliesTo?.['enum'] as string[]} />,
      flex: 1.5
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <Tooltip key='edit' enterNextDelay={500} title={canDefine ? `Edit ${row.title}` : ''} placement='top' arrow>
            <ActionIconButton
              aria-label={`edit ${row.title} control`}
              icon={faPen}
              onClick={() => {
                setPropertyKeyName(row.id);
                setDialog(DialogOpen.editor);
              }}
              disabled={!canDefine}
            />
          </Tooltip>,
          <Tooltip
            key='delete'
            enterNextDelay={500}
            title={canDefine ? `Delete ${row.title}` : ''}
            placement='top'
            arrow
          >
            <ActionIconButton
              aria-label={`delete ${row.title} control`}
              icon={faTrash}
              onClick={() => {
                setPropertyKeyName(row.id);
                setDialog(DialogOpen.delete);
              }}
              disabled={!canDefine}
            />
          </Tooltip>
        ];
      }
    }
  ];

  const blacklist = React.useMemo(() => {
    return Object.keys(schema?.properties || {});
  }, [schema]);

  React.useEffect(() => {
    Engage.track(
      Fingerprint.load(Path.Meta).withData({
        preferred_organization_id: orgGuid
      })
    );
  }, [orgGuid]);

  const handleRowOrderChange = (change: { oldIndex: number; row: Record<string, unknown>; targetIndex: number }) => {
    reorderOrgMetadataSchema(orgGuid, schema, change.oldIndex, change.targetIndex).then(ms => setMetadataSchema(ms));
  };

  if (status === Status.pending || schemaStatus === Status.pending)
    return (
      <Root>
        <Typography variant='h1' gutterBottom>
          Metadata
        </Typography>
        <TablePlaceholder count={5} />
      </Root>
    );

  return (
    <Root>
      <Typography variant='h1' gutterBottom>
        Metadata
      </Typography>
      <DataGrid
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        columns={columns}
        rows={rows}
        slots={{
          noRowsOverlay: () => (
            <SimpleNoRowsOverlay>
              Your organization has not defined any metadata fields. Add your first field to get started.
            </SimpleNoRowsOverlay>
          ),
          footer: () => (
            <GridFooterContainer>
              <Tooltip
                className='MetaFormBuilder-addField'
                title={
                  !canDefine
                    ? 'Only organization administrators can create metadata fields'
                    : 'Create a new metadata field'
                }
                wrap
                placement='top'
                arrow
              >
                <Button
                  disabled={!canDefine}
                  color='inherit'
                  variant='text'
                  endIcon={<Icon icon={faAdd} />}
                  onClick={() => setDialog(DialogOpen.builder)}
                >
                  ADD FIELD
                </Button>
              </Tooltip>
            </GridFooterContainer>
          )
        }}
      />
      <MetadataBuilder
        isOpen={dialog === DialogOpen.builder}
        onClose={() => setDialog(DialogOpen.none)}
        onSubmit={handleSubmitAdd}
        blacklist={blacklist}
      />
      <MetadataEditor
        isOpen={dialog === DialogOpen.editor}
        onClose={() => {
          setPropertyKeyName(null);
          setDialog(DialogOpen.none);
        }}
        onSubmit={handleSubmitEdit}
        name={propertyKeyName}
        field={schema?.properties?.[propertyKeyName]}
        blacklist={blacklist}
      />
      <ConfirmDialog
        DialogProps={{
          open: dialog === DialogOpen.delete,
          onClose: () => {
            setPropertyKeyName(null);
            setDialog(DialogOpen.none);
          }
        }}
        ConfirmProps={{
          onClick: () => handleDelete(propertyKeyName),
          children: 'Delete'
        }}
        title='Delete Field'
      >
        <Typography>
          Are you sure you want to delete the metadata field &quot;
          {schema?.properties?.[propertyKeyName]?.title}&quot;?
        </Typography>
      </ConfirmDialog>
    </Root>
  );
}

export const MetadataSettings = withFunctionalPermission(_MetadataSettings, FunctionalPermission.MetadataFeatures);
