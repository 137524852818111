import React from 'react';

import { Option, getDisplayValue } from 'snap-ui/Autocomplete';

import { IntegrationSelect } from './HuntDetail/HuntDetail.style';

function IntegrationSwitcher({
  value,
  options,
  onIntegrationChange
}: {
  value: string;
  options: Option[];
  onIntegrationChange: (integration: string) => void;
}) {
  function handleIntegrationChange(integration: string) {
    onIntegrationChange(options.find(o => o.value === integration).value);
  }
  const integration = options.find(o => o.value === value);

  return (
    <>
      <dt>Integration</dt>
      {options.length <= 2 && <dd>{integration?.content}</dd>}
      {options.length > 2 && (
        <dd>
          <IntegrationSelect
            className='integration-select'
            options={options}
            loading={!value}
            onChange={handleIntegrationChange}
            name='organization select'
            value={getDisplayValue(options, integration?.value)}
            disableClearable
            disableUserAdditions
          />
        </dd>
      )}
    </>
  );
}

export default IntegrationSwitcher;
