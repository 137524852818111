import React, { ElementType } from 'react';

import { Tabs as MuiTabs, Paper, Tab } from '@mui/material';
import { TabProps as MuiTabProps } from '@mui/material/Tab';
import { TabsProps as MuiTabsProps } from '@mui/material/Tabs';

import Placeholder from './Placeholder';
import { styled } from './util';

export type TabItem = {
  value: MuiTabProps['value'];
  label: MuiTabProps['label'];
  content: JSX.Element;
  disabled?: boolean;
  component?: ElementType;
};

export type TabProps = {
  id?: string;
  initialValue?: MuiTabProps['value'];
  tabs: TabItem[];
  orientation?: 'horizontal' | 'vertical';
  onChange?(event: React.SyntheticEvent, value: MuiTabProps['value']): void;
  value?: MuiTabProps['value'];
  className?: string;
  variant?: MuiTabsProps['variant'];

  /** Skip wrapping content in Paper component */
  nowrap?: boolean;

  /**
   * If provided, renders alongside the tabs in the empty, leftover space. This should be a forward ref enabled component.
   * e.g.
   *
   * ```tsx
   *   const SomeComponent = React.forwardRef<any, any>(function SomeComponent(auxProps, ref) {
   *   return <span ref={ref}>Hello</span>;
   * });
   * ```
   * Also see - CollectionAuxiliaryAction.tsx
   *
   * @deprecated - eliminating use of this. Any customization should be done with SimpleTabs
   */
  auxiliary?: React.ReactNode;
};

export { Tab };
export function SimpleTabs(
  props: Omit<TabProps, 'initialValue' | 'auxiliary' | 'tabs'> & Pick<MuiTabsProps, 'value' | 'children'>
) {
  return <MuiTabs {...props} />;
}

const TabsWrapper = styled(MuiTabs)`
  .Tab-auxiliary {
    flex-grow: 1;
  }
`;

const AuxiliaryWrapper = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(function AuxiliaryWrapper(
  { children },
  ref
) {
  return (
    <div className='Tab-auxiliary' ref={ref}>
      {children}
    </div>
  );
});

export default function Tabs(props: TabProps): JSX.Element {
  const [currentValue, setCurrentValue] = React.useState(props.initialValue || props.tabs[0]?.value);

  function handleChange(event: React.SyntheticEvent, value: MuiTabProps['value']): void {
    setCurrentValue(value);
    props.onChange && props.onChange(event, value);
  }

  // This line is temporary and rectifies issues between props changing resulting in currentValue being greater than tabs length
  // This issue manifested when removing useAsyncStorage from useIntegrations. This will be fixed with garden ticket soon.
  // We may want to permanently fix this issue here somehow.
  const rectifiedValue = currentValue > props.tabs.length ? props.tabs[0].value : currentValue;

  const tabValue = 'value' in props ? props.value : rectifiedValue;

  return (
    <>
      <TabsWrapper
        id={props.id}
        value={tabValue}
        onChange={handleChange}
        className={props.className}
        orientation={props.orientation}
        variant={props.variant}
      >
        {props.tabs.map(tab => (
          <Tab key={tab.value} value={tab.value} label={tab.label} disabled={tab.disabled} component={tab.component} />
        ))}
        {props.auxiliary && <AuxiliaryWrapper>{props.auxiliary}</AuxiliaryWrapper>}
      </TabsWrapper>
      {props.tabs.map(tab => {
        if (tab.value !== tabValue) return null;
        if (props.nowrap) return <React.Fragment key={tab.value}>{tab.content}</React.Fragment>;
        return (
          <Paper className='TabsContent' key={tab.value}>
            {tab.content}
          </Paper>
        );
      })}
    </>
  );
}

/**
 * Using this in a Collection wrapped page? Use CollectionAuxiliaryAction instead
 */
export const AuxiliaryTab = styled('div', {
  shouldForwardProp: p => p === 'children'
})`
  flex-grow: 1;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
`;

export const TabLabel = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};
`;

export const TabPlaceholder = styled(Placeholder)`
  margin-top: ${p => p.theme.spacing(6)};
`;
