import React from 'react';

import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from '@mui/material/Autocomplete';
import classnames from 'classnames';

import useDebounce from 'hooks/useDebounce';

import { FilterOptionsState, Option } from './Autocomplete';
import CircularProgress from './CircularProgress';
import TextField from './TextField';

export { default as Autocomplete } from '@mui/material/Autocomplete';
export type { AutocompleteInputChangeReason, AutocompleteRenderOptionState } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

type Props = {
  className?: string;
  onChange(value: (string | Option)[]): void;
  onSearchChange(value: string): void;
  value: string[] | Option[];
  option: Option[];
  isSearching: boolean;
  noOptionText?: string;
  disableUserAdditions?: boolean;
};

/**
 * You are probably looking for Autocomplete which is more common. Autosearch is for niche use cases
 * where an endpoint request is kicked off on text input change.
 *
 * Input change differs from selection change as follows:
 *
 * onChange is invoked when selections change.
 * onSearchChange is invoked when input changes. This value is debounced before invoking the callback.
 */
export default function Autosearch({ option = [], ...props }: Props) {
  const [query, setQuery] = React.useState<string>('');
  const debouncedQuery = useDebounce(query);

  React.useEffect(() => {
    if (debouncedQuery) {
      props.onSearchChange && props.onSearchChange(debouncedQuery);
    }
  }, [debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  let mappedOption = option;

  const mappedValue = props.value.map(v =>
    typeof v === 'string' ? { content: mappedOption.find(o => o.value === v)?.content || v, value: v } : v
  );

  if (mappedOption.length === 0) mappedOption = [...mappedValue];

  return (
    <Autocomplete
      className={classnames('Autosearch', props.className)}
      options={mappedOption}
      multiple
      value={mappedValue}
      inputValue={query}
      onChange={handleListChange}
      onInputChange={(_event, newInputValue, reason) => {
        setQuery(reason === 'input' ? newInputValue : query);
      }}
      renderInput={renderInput}
      filterOptions={handleFilterOptions}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={props.noOptionText}
      freeSolo={!props.disableUserAdditions}
    />
  );

  function handleFilterOptions(options: Option[], params: FilterOptionsState<unknown>): Option[] {
    const filtered = filter(options, params) as Option[];

    const { inputValue } = params;
    const inputValueExists = options.some(option => option.value === inputValue);
    if (inputValue && !props.disableUserAdditions && !inputValueExists) {
      return [
        ...filtered,
        {
          content: `Add "${inputValue}"`,
          userAdded: true,
          value: inputValue
        }
      ];
    } else {
      return filtered;
    }
  }

  function getOptionLabel(option: Option): string {
    if (typeof option === 'string') return option;
    if (option.userAdded) return option.value;
    if (typeof option.content == 'string') return option.content;

    return option.label || '';
  }

  function isOptionEqualToValue(option: string | Option, value: string | Option): boolean {
    if (typeof option === 'string') {
      if (typeof value === 'string') return option === value;
      return option === value.value;
    }
    if (typeof value === 'string') {
      return value === option.value;
    }
    return option.value === value.value;
  }

  function renderOption(props: React.HTMLAttributes<HTMLLIElement>, option: Option) {
    return (
      <li {...props} key={option.value}>
        {option.content}
      </li>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {props.isSearching ? <CircularProgress size={20} /> : params.InputProps.endAdornment}
            </React.Fragment>
          )
        }}
      />
    );
  }

  function handleListChange(_event: React.SyntheticEvent<Element, Event>, value: (string | Option)[]) {
    props.onChange(value);
    setQuery('');
  }
}
