import React from 'react';

import { faLock } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { CompositeFilter } from 'types/filter';

import { getRouteGen } from './Feature.util';

export type FeaturedQuery = {
  title: string;
  subscription?: boolean;
  disabled?: boolean;
  count: number;
  payload: CompositeFilter;
};

type Props = {
  item: FeaturedQuery;
  type: ArtifactType.Analytic | ArtifactType.Session;
};

export default function Feature({ type, item }: Props) {
  const { isSubscriber } = useAuth();
  const isDisabled = item?.disabled && !isSubscriber;
  const isSubscription = item?.subscription && !isSubscriber;

  return (
    <div className='link' key={item.title}>
      <Link to={isDisabled ? '#' : getRouteGen(item, type)} className={classnames({ disabled: isDisabled })}>
        {isDisabled || isSubscription ? (
          <Tooltip
            title={
              item?.disabled
                ? 'This content is restricted to subscribers'
                : 'Additional content is available to subscribers'
            }
            placement='left'
            arrow
          >
            <span>
              <Icon icon={faLock} style={{ marginRight: '8px' }} /> {item.title}
            </span>
          </Tooltip>
        ) : (
          item.title
        )}
      </Link>
      <Chip
        size='small'
        className={type === ArtifactType.Analytic ? 'blue' : 'red'}
        label={(item?.count ?? 0).toString()}
        color={type === ArtifactType.Analytic ? 'info' : 'error'}
      />
    </div>
  );
}
