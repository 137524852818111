import React from 'react';

import z from 'zod';

import Alert from 'snap-ui/Alert';
import { DialogProgressContainer, DisplayDialog, FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';
import Placeholder from 'snap-ui/Placeholder';

import { ApiError } from 'module/ApiError';
import { standardFormikBaseProps } from 'module/Form';
import RadioGroupFormik from 'module/Form/RadioGroupFormik';
import UploadFieldFormik from 'module/Form/UploadFieldFormik';

import { ErrorProps, Status } from 'storage';

import { StrictReactNode } from 'types/core';

import { MimeType } from '../Export';
import { FormikProps } from '../Form/Formik';
import { DetectionImporterModal, ImportEndpointPrefix, ImportForm } from './Import.type';
import useImport from './useImport';

const INITIAL_VALUES: Partial<ImportForm> = {
  file: [],
  reimport: true,
  deployable: true,
  format: MimeType.csv
};

const MAX_FILE_NUMBER = 1;

type Importer = {
  isOpen: boolean;
  onClose(): void;
};

type ImporterInner<V extends ImportForm> = Importer & {
  children?: StrictReactNode;
  FormikConfig?: Partial<FormikProps<V>>;
  startImport(values: V): void;
  status: Status;
  errorProps?: ErrorProps;
};

function Importer<V extends ImportForm>({
  children,
  FormikConfig,
  isOpen,
  onClose,
  startImport,
  status,
  errorProps
}: ImporterInner<V>): JSX.Element {
  const formConfig = {
    initialValues: {
      ...FormikConfig?.initialValues,
      format: MimeType.csv
    },
    onSubmit: startImport,
    ...FormikConfig
  };

  return (
    <>
      <FormDialog
        DialogProps={{ open: isOpen && status === Status.idle, onClose }}
        FormikConfig={formConfig}
        SubmitProps={{ children: 'Import Detections' }}
        title='Import'
      >
        {({ values }) => {
          return (
            <FieldsLayout>
              <ApiError {...errorProps} />
              <UploadFieldFormik
                name='file'
                accept={`.${MimeType[values.format]}`}
                dialogName='Select File'
                maxFiles={MAX_FILE_NUMBER}
              />
              <RadioGroupFormik
                label='File Format'
                name='format'
                options={Object.values(MimeType).map(f => ({ label: f.toUpperCase(), value: f }))}
                row
              />
              {children}
            </FieldsLayout>
          );
        }}
      </FormDialog>
      <DisplayDialog DialogProps={{ open: isOpen && status === Status.pending, onClose }} title='Import in progress'>
        <Alert severity='info'>We are importing your data. Please keep this modal open until it&apos;s finished.</Alert>
        <DialogProgressContainer>
          <Placeholder variant='rectangular' height={10} animation='wave' />
        </DialogProgressContainer>
      </DisplayDialog>
      <DisplayDialog DialogProps={{ open: isOpen && status === Status.resolved, onClose }} title='Import complete'>
        <Alert severity='success'>The data import is complete.</Alert>
      </DisplayDialog>
      {errorProps && (
        <DisplayDialog DialogProps={{ open: isOpen, onClose }} title='Import Error'>
          <ApiError {...errorProps} />
        </DisplayDialog>
      )}
    </>
  );
}

export function DetectionImporter(props: DetectionImporterModal) {
  const { integration_id, organization_id } = props;

  const importUtils = useImport(ImportEndpointPrefix.Analytic);

  function handleStartImport(values: ImportForm) {
    importUtils.startImport(values);
  }

  return (
    <Importer
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: {
          organization_id: organization_id,
          integration_id: integration_id,
          ...INITIAL_VALUES
        },
        zodSchema: z.object({
          file: z.array(z.instanceof(Blob)).min(1),
          organization_id: z.number(),
          integration_id: z.string(),
          format: z.enum([MimeType.csv, MimeType.json]),
          reimport: z.boolean(),
          deployable: z.boolean()
        })
      }}
      startImport={handleStartImport}
      {...importUtils}
      {...props}
    />
  );
}
