import { z } from 'zod';

import { Artifact, ArtifactType, Guid, Tracked } from 'types/common';

export const CurationSchema = z.object({
  organization: z.string().optional(),
  owner: z.string().optional(),
  name: z.string().nonempty('Title is required'),
  description: z.string().nonempty('Description is required'),
  attack_names: z.array(z.string()).optional(),
  actor_names: z.array(z.string()).optional(),
  software_names: z.array(z.string()).optional(),
  vulnerability_names: z.array(z.string()).optional(),
  intel: z.array(z.any()).optional(), // TODO update with ArtifactSchema
  session: z.array(z.any()).optional(),
  analytic: z.array(z.any()).optional()
});

export type CurationInput = z.infer<typeof CurationSchema>;

export type CollectionCount = {
  analytics: number;
  bas_scripts: number;
  sessions: number;
  threat_intelligences: number;
};

export type CollectionCountCatalog = Record<Guid, CollectionCount>;

export interface DuckedArtifact extends Artifact {
  duck: ArtifactType;
  new?: boolean;
}

export type ExistingComposite = {
  threat_intelligences: DuckedArtifact[];
  sessions: DuckedArtifact[];
  analytics: DuckedArtifact[];
  bas_scripts: DuckedArtifact[];
};

export type TabType =
  | 'overview'
  | ArtifactType.Intel
  | ArtifactType.Session
  | ArtifactType.Analytic
  | ArtifactType.Indicator
  | ArtifactType.AttackScript;

export enum CollectionRecommenderType {
  Collection = 'collection',
  Threat = 'session',
  Attack = 'attack',
  Vulnerability = 'vulnerability',
  Software = 'software',
  Actor = 'actor'
}

export function isCollectionRecommenderType(type: string): type is CollectionRecommenderType {
  return Object.values(CollectionRecommenderType).includes(type as CollectionRecommenderType);
}

export type CollectionRecommender = {
  input_ids: string[];
};

export type RecommendationResponse = {
  score: number;
  deployment_recommendations: Tracked[];
  all_deployment_recommendations: Tracked[];
  hunt_recommendations: Tracked[];
  all_hunt_recommendations: Tracked[];
  validations: Tracked[];
  all_validations: Tracked[];
};

export function isRecommendationResponse(value: unknown): value is RecommendationResponse {
  return (
    value !== null &&
    typeof value === 'object' &&
    'deployment_recommendations' in value &&
    Array.isArray(value['deployment_recommendations']) &&
    'hunt_recommendations' in value &&
    Array.isArray(value['hunt_recommendations']) &&
    'validations' in value &&
    Array.isArray(value['validations'])
  );
}

export enum RecommendationType {
  Deployment = 'deployment',
  Hunt = 'hunt',
  Validation = 'validations'
}

export enum Actionability {
  UNACTIONED = 'unactioned',
  ACTIONED = 'actioned',
  ALL = 'all'
}
