import React, { ReactNode } from 'react';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import Checkbox from 'snap-ui/Checkbox';
import Placeholder from 'snap-ui/Placeholder';
import { styled, useTheme } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

import { DataGrid, GridColDef, GridRowsProp } from './DataGrid';

export type TableCell = {
  tableCellProps?: {
    colSpan?: number;
    rowSpan?: number;
  };
  className?: string;
  component: ReactNode;
};

type TableProps = {
  columns?: StrictReactNode[];
  rows: (StrictReactNode | TableCell)[][];
  className?: string;
  component?: 'div';
  size?: 'small';
  /* include `value` to add a row of checkboxes. This is the indexes of the checked items */
  value?: number[];
  /* include onChange to make the checkboxes functional */
  onChange?(newValue: number[]): void;
};

export function DGTable(props: TableProps): JSX.Element {
  const columns: GridColDef[] = React.useMemo(
    () =>
      props.columns.map(col => {
        const common = {
          flex: 1,
          renderCell(params) {
            return params.row[params.field];
          }
        };

        if (typeof col === 'string') {
          return col
            ? {
                ...common,
                field: col
              }
            : { ...common, field: 'actions', headerName: ' ' };
        } else if (React.isValidElement(col) && col.props.field) {
          return {
            ...common,
            field: col.props.field || col.props.key,
            headerName: col.props.label
          };
        }

        throw new Error('Unknown column type');
      }),
    [props.columns]
  );

  const rows: GridRowsProp = React.useMemo(
    () =>
      props.rows.map(row =>
        columns.reduce<GridRowsProp[0]>((rowObj, col, idx) => {
          const cell = row[idx];
          let component = cell;
          if (typeof cell === 'object' && 'component' in cell) component = cell.component;

          rowObj[col.field] = component;
          return rowObj;
        }, {})
      ),
    [columns, props.rows]
  );

  function getRowId(row) {
    return row.id || row.guid || uuidv4();
  }

  return (
    <TableContainer component={props.component || Paper} className={props.className}>
      <DataGrid columns={columns} rows={rows} autosizeOnMount getRowId={getRowId} hideFooter />
    </TableContainer>
  );
}

function SATable(props: TableProps): JSX.Element {
  const columns = props.value
    ? [
        <Checkbox
          key='checkbox'
          checked={!!props.value.length && props.value.length === props.rows.length}
          onChange={handleToggleAll}
          disabled={!props.onChange}
        />,
        ...props.columns
      ]
    : props.columns;

  const rows = props.value
    ? props.rows.map((row, idx) => [
        <Checkbox
          key='checkbox'
          checked={props.value.includes(idx)}
          onChange={() => handleToggleOne(idx)}
          disabled={!props.onChange}
        />,
        ...row
      ])
    : props.rows;

  function handleChange(value: number[]) {
    if (props.onChange) props.onChange(value);
  }

  function handleToggleAll() {
    if (props.value?.length === props.rows?.length) {
      handleChange([]);
    } else {
      handleChange(props.rows.map((_, idx) => idx));
    }
  }

  function handleToggleOne(idx: number) {
    if (!props.value) return;
    const filtered = props.value.filter(v => v !== idx);
    if (filtered.length < props.value.length) handleChange(filtered);
    else handleChange([...props.value, idx]);
  }

  return (
    <TableContainer component={props.component || Paper} className={props.className}>
      <Table size={props.size}>
        {columns && (
          <TableHead>
            <TableRow className='Table-header-row'>
              {columns.map((header, i) => (
                <TableCell key={i} className='Table-column-cell'>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex} className='Table-body-row'>
              {row.map((item, itemIndex) => {
                const tableCell = item as TableCell;
                const tableCellProps = tableCell?.tableCellProps || {};
                const className = tableCell?.className || '';
                const component = tableCell?.component || item;

                return (
                  <TableCell key={itemIndex} className={classNames('Table-row-cell', className)} {...tableCellProps}>
                    {component}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SATable;

export const ActionCell = styled('div')`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
  align-items: center;
`;

const PlaceholderContainer = styled('div')`
  background-color: ${p => p.theme.palette.surface.odd};
  span:nth-of-type(even) {
    background-color: ${p => p.theme.palette.surface.even};
  }
`;

export function TablePlaceholder({
  count = 10,
  height = 40,
  animate = true
}: {
  count?: number;
  height?: number;
  animate?: boolean;
}) {
  // Matching the timeline virtual table height currently set to 1000
  const { spacing } = useTheme();
  const _height = height ?? spacing(4);
  const places = new Array(count).fill(_height);

  return (
    <PlaceholderContainer className='TablePlaceholder'>
      {places.map((h, index) => (
        <Placeholder
          key={index}
          variant='rectangular'
          height={h}
          animation={animate ? (Math.random() > 0.5 ? false : 'wave') : false}
        />
      ))}
    </PlaceholderContainer>
  );
}
