import React from 'react';

import MuiCard, { CardProps } from '@mui/material/Card';
import classNames from 'classnames';

export { CardActions, CardContent, CardHeader, CardMedia, CardActionArea } from '@mui/material';
export type { CardContentProps } from '@mui/material';
export type Props = CardProps;

export default function Card({ className, ...props }: Props) {
  return <MuiCard className={classNames('Card', className)} {...props} />;
}
