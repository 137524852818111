import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import BackdropLoader from 'snap-ui/BackdropLoader';
import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';
import WidthContainer from 'module/Layout/WidthContainer';

import { resetPassword, useAuth, useAuthInterface } from 'provider';

import { Status, useAsync } from 'storage';

import { getQueryParam } from 'utilities/SearchParam';

import history from '../../../history';
import { Divider, ForgotResetContainer } from '../Authentication.style';
import ResetPwForm from './ResetPwForm';

type ResetFormData = {
  password: string;
  confirm_password: string;
};

type Props = {
  className?: string;
};

const ResetPassword = (props: Props): React.ReactElement => {
  useTitle('Reset Password | SnapAttack');
  const { user } = useAuth();
  const { logout } = useAuthInterface();

  const { task, status, errorProps } = useAsync<void>();
  const [tokenError, setTokenError] = React.useState<string[]>();

  const callReset = React.useCallback(
    payload => {
      return task(resetPassword(payload));
    },
    [task]
  );

  if (getQueryParam(history.location.search, 'token') && user.email) {
    logout().then(() => {
      history.push(history.location.pathname + history.location.search);
    });
  }

  return (
    <ForgotResetContainer className={props.className}>
      <WidthContainer className='pp-center-vert'>
        <div className='pp-forgot'>
          <Typography variant='h1'>Reset Password</Typography>
          <p>Please enter your new password.</p>
          <Divider />
          {status === Status.pending && <BackdropLoader data-testid='loading-dimmer' contained />}
          <ApiError {...errorProps} />
          <ApiError messages={tokenError} />
          {status === Status.resolved && isEmpty(errorProps) && (
            <Alert severity='success'>
              <AlertTitle>Password successfully changed.</AlertTitle>
            </Alert>
          )}
          <ResetPwForm submitForm={handleReset} />
        </div>
      </WidthContainer>
    </ForgotResetContainer>
  );

  function handleReset(formValue: ResetFormData): void {
    if (formValue.password === formValue.confirm_password) {
      if (!getQueryParam(history.location.search, 'token')) {
        setTokenError(['Invalid token']);
        return;
      }
      const payload = {
        password: formValue.password,
        token: getQueryParam(history.location.search, 'token')
      };
      callReset(payload);
    }
  }
};

export default ResetPassword;
