import React from 'react';

import classNames from 'classnames';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import { ExternalLink } from 'snap-ui/Link';
import Paper from 'snap-ui/Paper';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

const SimpleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;

  span {
    font-weight: bold;
  }
`;

const PaywallContainer = styled(Paper)`
  padding: 75px 0;
  width: 100%;
  z-index: 10;
  background: ${p => p.theme.palette.darkPurple.dark};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(7)};
  align-items: center;
  justify-content: center;

  &.fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 50px;
    padding: 50px 0;
  }

  .icon-row {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: ${p => p.theme.spacing(7)};
  }

  .message {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(1)};
  }

  .message-detail {
    color: ${p => p.theme.palette.grey[400]};
    a {
      color: ${p => p.theme.palette.primary.main};
    }
  }

  .MuiButton-root {
    border-radius: ${p => p.theme.spacing(1)};
    padding: ${p => p.theme.spacing(2, 7)};
    a {
      color: ${p => p.theme.palette.common.black};
    }
  }

  .MuiLink-root {
    text-decoration: none;
  }
`;

type PaywallProps = {
  fixed?: boolean;
  message?: string;
  isSubscriber?: boolean;
};

/*
 * An simple message with a lock icon used to denote that something isn't available to a user
 */
export function SimplePayWall({ message }: { message: string }) {
  return (
    <SimpleContainer>
      <Icon.Lock /> <span>{message}</span>
    </SimpleContainer>
  );
}

/*
 * Paywall a user sees when trying to view subscription content.
 */
export default function PayWall({ fixed, message, isSubscriber }: PaywallProps) {
  const defaultMessage = isSubscriber
    ? "This content isn't available with your current subscription plan."
    : 'This content is only available to subscribers.';

  return (
    // eslint-disable-next-line prettier/prettier
    <PaywallContainer className={classNames('Paywall', { fixed: fixed })}>
      <div className='icon-row'>
        <Icon.Lock size='3x' />
      </div>
      <div className='message'>
        <Typography variant='h3'>{message ? message : defaultMessage}</Typography>
        <Typography className='message-detail' variant='subtitle1'>
          {isSubscriber ? (
            <>Please reach out to your sales or customer success representative to discuss options.</>
          ) : (
            <>
              Please <ExternalLink href='https://www.snapattack.com/contact/'>contact us</ExternalLink> to learn more
              about subscription plans.
            </>
          )}
        </Typography>
      </div>
      {!isSubscriber && (
        <ExternalLink href='https://www.snapattack.com/contact/'>
          <Button color='primary' variant='contained'>
            Book Demo
          </Button>
        </ExternalLink>
      )}
    </PaywallContainer>
  );
}
