import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { DEFENSIVE_POSTURES } from 'constants/feed';

import { addOrRemoveValue } from 'module/Filter/Filter.util';
import { FilterControl } from 'module/GlobalFilter';
import AnalyticGapIcon from 'module/Icons/AnalyticGapIcon';
import UndetectedThreatIcon from 'module/Icons/UndetectedThreatIcon';
import UntestedAnalyticIcon from 'module/Icons/UntestedAnalyticIcon';
import UnvalidatedAnalyticIcon from 'module/Icons/UnvalidatedAnalyticIcon';
import ValidatedAnalyticIcon from 'module/Icons/ValidatedAnalyticIcon';

import { ArtifactType, DefensivePosture, DefensivePostureQueryFields } from 'types/common';
import { Ops } from 'types/filter';

import { IconFormControlLabel } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';

type DefensivePostureKeys = {
  posture: DefensivePosture[];
};

type DefensivePostureFilterProps = {
  disabled?: boolean;
  onChange(values: Partial<DefensivePostureKeys>): void;
  values: DefensivePostureKeys;
  topic: ArtifactType;
};

function DefensivePostureFilter({ disabled, onChange, values, topic }: DefensivePostureFilterProps): JSX.Element {
  return (
    <FilterControl disabled={disabled} className='DefensivePostureFilter'>
      <FormLabel id='posture-toggle-button-group-label' className='title-tooltip'>
        By Validation Status
        <Tooltip title='Tested in SnapAttack sandbox and confirmed accuracy/precision' placement='right' arrow wrap>
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='posture-toggle-button-group-label'>
        {Object.keys(DEFENSIVE_POSTURES[topic]).map(v => {
          switch (v) {
            case DefensivePosture.VALIDATED:
              return (
                <IconFormControlLabel
                  key={DefensivePosture.VALIDATED}
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value={DefensivePosture.VALIDATED}
                      checked={values.posture.includes(DefensivePosture.VALIDATED)}
                    />
                  }
                  label={
                    <>
                      <ValidatedAnalyticIcon /> Validated Detections
                    </>
                  }
                />
              );
            case DefensivePosture.ANALYTIC_GAP:
              return (
                <IconFormControlLabel
                  key={DefensivePosture.ANALYTIC_GAP}
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value={DefensivePosture.ANALYTIC_GAP}
                      checked={values.posture.includes(DefensivePosture.ANALYTIC_GAP)}
                    />
                  }
                  label={
                    <>
                      <AnalyticGapIcon /> Detection Gaps
                    </>
                  }
                />
              );
            case DefensivePosture.UNDETECTED:
              return (
                <IconFormControlLabel
                  key={DefensivePosture.UNDETECTED}
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value={DefensivePosture.UNDETECTED}
                      checked={values.posture.includes(DefensivePosture.UNDETECTED)}
                    />
                  }
                  label={
                    <>
                      <UndetectedThreatIcon /> Undetected Threats
                    </>
                  }
                />
              );
            case DefensivePosture.UNVALIDATED:
              return (
                <IconFormControlLabel
                  key={DefensivePosture.UNVALIDATED}
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value={DefensivePosture.UNVALIDATED}
                      checked={values.posture.includes(DefensivePosture.UNVALIDATED)}
                    />
                  }
                  label={
                    <>
                      <UnvalidatedAnalyticIcon /> Unvalidated Detections
                    </>
                  }
                />
              );
            case DefensivePosture.UNTESTED:
              return (
                <IconFormControlLabel
                  key={DefensivePosture.UNTESTED}
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value={DefensivePosture.UNTESTED}
                      checked={values.posture.includes(DefensivePosture.UNTESTED)}
                    />
                  }
                  label={
                    <>
                      <UntestedAnalyticIcon /> Untested Detections
                    </>
                  }
                />
              );
            default:
              return null;
          }
        })}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as DefensivePosture;
    const posture = addOrRemoveValue(checked, values.posture, v) as DefensivePosture[];
    onChange({ posture });
  }
}

function checkForCompleteSet(values: DefensivePosture[], defaults: DefensivePosture[]): boolean {
  return !values?.length || defaults.every(posture => values?.includes(posture));
}

const defaults: Partial<Record<ArtifactType | 'default', () => { posture: DefensivePosture[] }>> = {
  [ArtifactType.Session]: () => ({
    posture: [
      DefensivePosture.ANALYTIC_GAP,
      DefensivePosture.UNVALIDATED,
      DefensivePosture.UNDETECTED,
      DefensivePosture.VALIDATED
    ]
  }),
  [ArtifactType.Analytic]: () => ({
    posture: [
      DefensivePosture.ANALYTIC_GAP,
      DefensivePosture.UNVALIDATED,
      DefensivePosture.UNTESTED,
      DefensivePosture.VALIDATED
    ]
  }),
  [ArtifactType.Marker]: () => ({
    posture: [DefensivePosture.VALIDATED, DefensivePosture.ANALYTIC_GAP]
  }),
  default: () => ({
    posture: [
      DefensivePosture.ANALYTIC_GAP,
      DefensivePosture.UNVALIDATED,
      DefensivePosture.UNDETECTED,
      DefensivePosture.UNTESTED,
      DefensivePosture.VALIDATED
    ]
  })
};

const toQuery = (topic: ArtifactType) => (values: DefensivePostureKeys) => {
  if (checkForCompleteSet(values.posture, defaults[topic] ? defaults[topic]().posture : [])) return;

  return {
    op: Ops.or,
    items: values.posture
      .filter(val => Object.keys(DEFENSIVE_POSTURES[topic] || {}).includes(val))
      .map(posture => ({
        field: DefensivePostureQueryFields[posture],
        op: Ops.equals,
        value: 'true'
      }))
  };
};

const fromQuery = zod
  .object({
    op: zod.nativeEnum(Ops),
    items: zod.array(
      zod.object({
        field: zod.nativeEnum(DefensivePostureQueryFields),
        op: zod.nativeEnum(Ops),
        value: zod.literal('true')
      })
    )
  })
  .transform(query => ({
    posture: query.items.flatMap(item =>
      Object.keys(DefensivePostureQueryFields).find(
        (key: DefensivePosture) => DefensivePostureQueryFields[key] === item.field
      )
    ) as DefensivePosture[]
  }));

const DefensivePostureFilterConfig: FilterConfig<DefensivePostureKeys> = {
  defaults,
  supportedTopics: [ArtifactType.Session, ArtifactType.Analytic, ArtifactType.Marker],
  component: DefensivePostureFilter,
  toQuery: {
    [ArtifactType.Session]: toQuery(ArtifactType.Session),
    [ArtifactType.Analytic]: toQuery(ArtifactType.Analytic),
    [ArtifactType.Marker]: toQuery(ArtifactType.Marker)
  },
  fromQuery: { default: fromQuery }
};
export default DefensivePostureFilterConfig;
