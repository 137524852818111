import React from 'react';

import classnames from 'classnames';
import isEqual from 'lodash/isEqual';

import { CardContent, CardContentProps } from 'snap-ui/Card';
import Typography, { TypographyProps } from 'snap-ui/Typography';

import { Integration } from 'module/Integration/Integration.type';
import { SupplementalArtifact } from 'module/Search/Search.type';

import { AnalyticDeploymentDetail, DeploymentIntegration } from 'types/analytic';
import { Guid, Ident } from 'types/common';

type SectionTitleProps = Omit<TypographyProps, 'className' | 'variant'>;
export function SectionTitle(props: SectionTitleProps): JSX.Element {
  return <Typography className='SectionTitle' variant='h5' {...props} />;
}

export function Section(props: CardContentProps): JSX.Element {
  return <CardContent className={classnames('Section', props.className)} {...props} />;
}

export function addDeployment(
  deployments: AnalyticDeploymentDetail[],
  newDeployment: AnalyticDeploymentDetail,
  guid: Guid
): AnalyticDeploymentDetail[] {
  const createDate = new Date();
  const deployment = {
    ...newDeployment,
    creation: createDate.toString(),
    pending_deployment: [
      {
        guid
      }
    ]
  };
  return [...deployments, deployment];
}

export function removeDeploymentOrIntegration(
  deployments: AnalyticDeploymentDetail[],
  deploymentId: Ident,
  integrationGuid?: Guid
): AnalyticDeploymentDetail[] {
  if (integrationGuid) {
    const filteredIntegrations = deployments
      .find(d => d.id === deploymentId)
      .integrations.filter(i => i.guid !== integrationGuid);
    if (filteredIntegrations.length) {
      return deployments.map(d => (d.id === deploymentId ? { ...d, integrations: filteredIntegrations } : d));
    } else {
      return removeDeployment(deployments, deploymentId);
    }
  } else {
    return removeDeployment(deployments, deploymentId);
  }
}

function removeDeployment(deployments: AnalyticDeploymentDetail[], deploymentId: Ident): AnalyticDeploymentDetail[] {
  return deployments.filter(d => d.id !== deploymentId);
}

export function getIntegrationGuidForRemoval(
  deployment: AnalyticDeploymentDetail,
  integration?: DeploymentIntegration
): Guid | undefined {
  if (!integration) return;
  if (isEqual(deployment.integrations, [{ guid: integration.guid }])) return;
  return integration.guid;
}

export function sortDeployment(a: DeploymentIntegration, b: DeploymentIntegration) {
  const aDeployed = !!a.deployment;
  const bDeployed = !!b.deployment;
  if (aDeployed) return -1;
  else if (bDeployed) return 1;

  const aCompat = !!a.compatible;
  const bCompat = !!b.compatible;
  if (aCompat) return -1;
  else if (bCompat) return 1;
  return 0;
}

export function getCompatibleIntegration(analyticSupplement: SupplementalArtifact, integration: Integration) {
  return analyticSupplement?.deployable_integrations?.some(
    deployableIntegration => deployableIntegration.guid === integration.guid
  );
}

export function isDeploymentLatestVersion(deployments: AnalyticDeploymentDetail[], integration: DeploymentIntegration) {
  return deployments?.find(deploy => deploy.analytic_version_id === integration.deployment?.analytic_version_id)
    ?.is_current;
}

export function getDeployDisabledTooltip(integration: Integration): string {
  if (!integration) return '';
  return `The current version of this detection is not compatible with ${integration.name}`;
}
