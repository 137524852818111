import React from 'react';

import { Formik } from 'formik';

import Button from 'snap-ui/Button';

import TextFieldFormik from 'module/Form/TextFieldFormik';

import { PasswordType, pwValidation, resetPwSchema } from 'services/pwValidationService';

import { LoginRegisterLinks } from '../Authentication.helper';
import { Form } from '../Authentication.style';

type ResetPwFormData = {
  [PasswordType.PASSWORD]: string;
  [PasswordType.CONFIRM_PASSWORD]: string;
};

type ResetPwFormProps = {
  submitForm: (formValue: ResetPwFormData) => void;
};

const ResetPwForm = ({ submitForm }: ResetPwFormProps): React.ReactElement => {
  const handleSubmit = (formValue: ResetPwFormData): void => {
    submitForm(formValue);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        [PasswordType.PASSWORD]: '',
        [PasswordType.CONFIRM_PASSWORD]: ''
      }}
      onSubmit={handleSubmit}
      validate={(values: { password: string }) => {
        return pwValidation(PasswordType.PASSWORD, values.password);
      }}
      validationSchema={resetPwSchema}
    >
      <Form>
        <TextFieldFormik label='Password' name={PasswordType.PASSWORD} type={PasswordType.PASSWORD} required />
        <TextFieldFormik
          label='Confirm Password'
          name={PasswordType.CONFIRM_PASSWORD}
          type={PasswordType.PASSWORD}
          required
        />
        <Button type='submit'>Reset Password</Button>
        <LoginRegisterLinks />
      </Form>
    </Formik>
  );
};

export default ResetPwForm;
