import React from 'react';

import { filterBlueMarkers } from 'apis/resources/analytic';

import { AnalyticDetectionSummaryState } from 'module/Detection/useAnalyticDetectionSummary';

import { Status } from 'storage';

import { AnalyticLogState } from 'types/analytic';
import { Guid } from 'types/common';
import { MarkerEvent } from 'types/marker';

const SIZE = 50;
const FIRST_PAGE = 0;

interface AssociatedLogState {
  log: MarkerEvent;
  status: Status;
}

type LogsByState = Partial<Record<AnalyticLogState, MarkerEvent[]>>;

interface LogsByStateReceiverAction {
  logState: AnalyticLogState;
  page: number;
  logs: MarkerEvent[];
}

function receiveLogsByState(state: LogsByState, action: LogsByStateReceiverAction): LogsByState {
  const initialIndex = (action.page - FIRST_PAGE) * SIZE;
  const finalIndex = initialIndex + SIZE;
  return {
    ...state,
    [action.logState]: [
      ...state[action.logState].slice(0, initialIndex),
      ...action.logs,
      ...state[action.logState].slice(finalIndex)
    ]
  };
}

interface LogsByStateInitializerAction {
  [AnalyticLogState.VALIDATED]: number;
  [AnalyticLogState.VALIDATED_GAPS]: number;
  [AnalyticLogState.UNVALIDATED]: number;
}

function initializeLogsByState(action: LogsByStateInitializerAction): LogsByState {
  return {
    [AnalyticLogState.VALIDATED]: Array(action[AnalyticLogState.VALIDATED]),
    [AnalyticLogState.VALIDATED_GAPS]: Array(action[AnalyticLogState.VALIDATED_GAPS]),
    [AnalyticLogState.UNVALIDATED]: Array(action[AnalyticLogState.UNVALIDATED])
  };
}

type LogsByStateAction =
  | {
      type: 'initialize';
      action: LogsByStateInitializerAction;
    }
  | { type: 'receive'; action: LogsByStateReceiverAction };

function logsByStateReducer(state: LogsByState, action: LogsByStateAction): LogsByState {
  switch (action.type) {
    case 'initialize':
      return initializeLogsByState(action.action);
    case 'receive':
      return receiveLogsByState(state, action.action);
    default:
      return state;
  }
}

export default function useAssociatedLog(
  analyticGuid: Guid,
  summary: AnalyticDetectionSummaryState,
  logTab: AnalyticLogState,
  currentLog: number
): AssociatedLogState {
  const [logsByState, logsByStateDispatch] = React.useReducer(logsByStateReducer, null as unknown as LogsByState);
  const currentTotal = summary?.summary[logTab === AnalyticLogState.VALIDATED_GAPS ? 'validated_gaps' : logTab];
  const hasLogs = !!currentTotal;
  React.useEffect(() => {
    // initialize logsByState to empty arrays of the expected length
    if (!logsByState && (summary.status === Status.resolved || summary.status === Status.idle)) {
      logsByStateDispatch({
        type: 'initialize',
        action: {
          [AnalyticLogState.VALIDATED]: summary.summary.validated,
          [AnalyticLogState.VALIDATED_GAPS]: summary.summary.validated_gaps,
          [AnalyticLogState.UNVALIDATED]: summary.summary.unvalidated
        }
      });
    }
  }, [logsByState, summary.status, summary.summary]);

  const [status, setStatus] = React.useState<Status>(Status.idle);

  React.useEffect(() => {
    const isRequestedLogInRange = 0 <= currentLog && currentLog < currentTotal;
    const isRequestedLogInCache = !!logsByState?.[logTab]?.[currentLog];
    const nextLog = currentLog + 1;
    const isRequestedLogLastInRange =
      nextLog < currentTotal && nextLog % SIZE === 0 && !logsByState?.[logTab]?.[nextLog];
    if (hasLogs && isRequestedLogInRange) {
      if (!isRequestedLogInCache) {
        const neededPage = Math.floor(currentLog / SIZE);
        doTheFetch(neededPage);
      } else if (isRequestedLogLastInRange) {
        // pre-fetch the next page if we're at the edge of the cache
        const neededPage = Math.floor(nextLog / SIZE);
        doTheFetch(neededPage);
      }
    }

    function doTheFetch(neededPage: number): void {
      setStatus(Status.pending);
      filterBlueMarkers(analyticGuid, logTab, neededPage, SIZE)
        .then(data => {
          logsByStateDispatch({
            type: 'receive',
            action: { logState: logTab, page: neededPage, logs: data.items.map(marker => marker.event) }
          });
          setStatus(Status.resolved);
        })
        .catch(() => setStatus(Status.rejected));
    }
  }, [analyticGuid, currentLog, logsByState, logTab, setStatus, currentTotal, hasLogs]);

  return {
    log: logsByState?.[logTab]?.[currentLog],
    status
  };
}
