import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { SlimAccordion } from 'snap-ui/Accordion';
import CircularProgress from 'snap-ui/CircularProgress';
import { GridRowSelectionModel } from 'snap-ui/DataGrid';
import { FormDialog } from 'snap-ui/Dialog';
import { styled } from 'snap-ui/util';

import { asValidationError, ValidationError } from 'apis';
import { bulkDeploy, bulkUnDeploy } from 'apis/resources/analytic';
import { CancelToken } from 'apis/snapattack';

import useDetectionQuery from 'aso/useDetectionQuery';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import { ExpandedArtifact } from 'module/Analytic/Analytic.util';
import { standardFormikBaseProps } from 'module/Form';
import RjsfFormik from 'module/Form/RjsfFormik';
import useIntegrationAdvancedConfig, { AdvancedConfig } from 'module/Integration/useIntegrationAdvancedConfig';
import ValidationAlert from 'module/Widgets/ValidationAlert';

import { useAuth, useIntegrationCatalog, usePushSnack } from 'provider';

import { Guid } from 'types/common';
import { Ops, Query } from 'types/filter';

import { presentPerfectBeVerb, renderValueWithLabel } from 'utilities/TextUtils';

import IntegrationsTable, { IntegrationCounts } from './IntegrationsTable';

type Message = { severity?: string; data: ValidationError };

const BUSY_MESSAGE = {
  data: {
    detail: [<div key='warning'>Handling your request...</div>]
  },
  severity: 'info'
};

const Container = styled('div')`
  margin: ${p => p.theme.spacing(2, 0)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(4)};

  .sub-text {
    color: ${p => p.theme.palette.text.secondary};
    font-size: smaller;
    margin-left: ${p => p.theme.spacing(4)};
  }
`;

const Message = styled(ValidationAlert)`
  width: 100%;
  min-height: fit-content;
  margin: 0;
`;

export type BulkDetectionDeployment = {
  criteria: Query;
  onClose(): void;
  isOpen: boolean;
  onDeployed?(): void;
  selectionModel?: GridRowSelectionModel;
  unDeploy: boolean;
};

/**
 * @deprecated This should not be used directly. Use useDetectionDeploymentInterface
 */
export function BulkDetectionDeployment(props: BulkDetectionDeployment) {
  const pushSnack = usePushSnack();
  const { criteria, onClose, onDeployed, isOpen, unDeploy } = props;
  const query = { op: Ops.and, items: [{ field: 'deployable', op: Ops.equals, value: 'true' }, criteria] };
  const { defaultOrgId, isSubscriber } = useAuth();
  const [activity, setActivity] = React.useState(false);
  const { isLoading, refresh, ...feed } = useDetectionQuery(query);
  const [selectedDetections, setSelectedDetections] = React.useState<Guid[]>([]);
  const [message, setMessage] = React.useState<Message>();
  const [userDeployed, setUserDeployed] = React.useState<Guid[]>([]);
  const deployableDetectionCount = feed.items.filter(i => !i.preview).length;
  const { integrations } = useIntegrationCatalog();
  const [selectedIntegrationGuids, setSelectedIntegrationGuids] = React.useState<Guid[]>([]);
  const [showAdvanced, setShowAdvanced] = React.useState<boolean>(false);
  const toggleShowAdvanced = () => setShowAdvanced(showAdvanced => !showAdvanced);

  const title = unDeploy
    ? `Undeploy ${deployableDetectionCount || ''} ${deployableDetectionCount === 1 ? 'Detection' : 'Detections'}`
    : `Deploy ${deployableDetectionCount || ''} ${deployableDetectionCount === 1 ? 'Detection' : 'Detections'}`;
  const confirmButtonText = unDeploy ? 'Undeploy' : 'Deploy';

  const isAllIntegrationsSelected = selectedIntegrationGuids.length === integrations.deployable.length;

  React.useEffect(() => {
    setSelectedDetections(feed.items.map(i => i.guid));
    const selectedIntegrationsList = integrations.deployable.map(i => i.guid);
    setSelectedIntegrationGuids(selectedIntegrationsList);
  }, [feed.items, integrations.deployable, defaultOrgId]);

  const advancedConfigurations = useIntegrationAdvancedConfig(unDeploy ? [] : selectedIntegrationGuids);

  const integrationCounts: IntegrationCounts = React.useMemo(() => {
    const countObj = integrations.deployable.reduce((composed: IntegrationCounts, integration) => {
      return {
        ...composed,
        [integration.guid]: {
          deployed: [],
          notDeployed: { compatible: [], incompatible: [] },
          name: integration.name
        }
      };
    }, {});

    feed.items.forEach(({ deployable_integrations, deployments, guid: detectionGuid }: ExpandedArtifact) => {
      Object.entries(countObj).forEach(([integrationGuid, { deployed, notDeployed }]) => {
        const isDeployed = deployments?.find(deployment =>
          deployment.integrations.find(integration => integration.guid === integrationGuid)
        );

        const isCompatible = deployable_integrations?.find(deployable => deployable.guid === integrationGuid);

        if (isDeployed) deployed.push(detectionGuid);
        else if (isCompatible) notDeployed.compatible.push(detectionGuid);
        else notDeployed.incompatible.push(detectionGuid);
      });
    });

    Object.values(countObj).forEach(count => {
      count.deployed = [...new Set(count.deployed)];
      count.notDeployed.compatible = [...new Set(count.notDeployed.compatible)];
      count.notDeployed.incompatible = [...new Set(count.notDeployed.incompatible)];
    });

    return countObj;
  }, [feed.items, integrations.deployable]);

  const handleClose = () => {
    setSelectedDetections([]);
    setSelectedIntegrationGuids([]);
    setMessage(null);
    setActivity(false);
    setShowAdvanced(false);
    onClose();
  };

  const handleUnDeploy = async () => {
    setMessage(BUSY_MESSAGE);
    setActivity(true);
    try {
      await bulkUnDeploy(defaultOrgId, selectedDetections, selectedIntegrationGuids);
      pushSnack(getSuccessMessage('undeployment', selectedDetections), 'info', 'center', 'bottom', 3000);
      handleClose();
    } catch (e) {
      setMessage(getErrorMessage(asValidationError(e)));
    }
    setActivity(false);
  };

  const handleDeploy = async (options: Record<Guid, unknown>) => {
    setMessage(BUSY_MESSAGE);
    setActivity(true);

    try {
      await bulkDeploy(defaultOrgId, selectedDetections, selectedIntegrationGuids, options).then(result => {
        // Using result here would be better but no data is returned which I can make a correlation
        // So regardless of items that didn't get deployed, stuff everything into user attempted
        setUserDeployed([...userDeployed, ...selectedDetections]);

        Engage.track(
          Fingerprint.of(Widget.Modal).withCommon(CommonEvent.Submit).withQualifier('deploy detections').withData({
            defaultOrgId,
            selected: selectedDetections?.length,
            selectedIntegrations: selectedIntegrationGuids
          })
        );
        if (onDeployed) setTimeout(() => onDeployed(), 2500);
        pushSnack(getSuccessMessage('deployment', result), 'info', 'center', 'bottom', 3000);
        handleClose();
      });
    } catch (e) {
      const message = getErrorMessage(asValidationError(e));
      Engage.track(
        Fingerprint.error(Widget.Modal)
          .withCommon(CommonEvent.Submit)
          .withQualifier('deploy detections failed')
          .withData({
            message
          })
      );
      setMessage(message);
    }

    setActivity(false);
  };

  const toggleSelectAll = (_event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setSelectedIntegrationGuids(checked ? integrations.deployable.map(i => i.guid) : []);
  };

  const handleSelect = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      const guid = event.currentTarget.id;
      const newSelectedItems = checked
        ? [...selectedIntegrationGuids, guid]
        : selectedIntegrationGuids.filter(s => s !== guid);
      setSelectedIntegrationGuids(newSelectedItems);
    },
    [selectedIntegrationGuids]
  );

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (isOpen) refresh(cancelSource);
    return () => cancelSource.cancel();
  }, [isOpen, refresh]);

  return (
    <FormDialog
      title={title}
      DialogProps={{ open: isOpen, onClose: handleClose }}
      SubmitProps={{
        children: isLoading || activity ? <CircularProgress size={25} /> : confirmButtonText,
        disabled: !isSubscriber || isLoading || activity || deployableDetectionCount === 0
      }}
      SecondaryActionProps={
        unDeploy
          ? null
          : {
              children: showAdvanced ? 'Show Integrations' : 'Show Advanced Configuration',
              onClick: toggleShowAdvanced,
              variant: 'outlined',
              disabled: isLoading || isEmpty(advancedConfigurations.schemas)
            }
      }
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: advancedConfigurations.initialValues,
        onSubmit: unDeploy ? handleUnDeploy : handleDeploy
      }}
    >
      <Container>
        {message?.data && <Message error={message.data} severity={message.severity as any} />}
        {!unDeploy && showAdvanced && !isEmpty(advancedConfigurations.schemas) ? (
          <AdvancedConfiguration schemas={advancedConfigurations.schemas} />
        ) : (
          <IntegrationsTable
            toggleSelectAll={toggleSelectAll}
            isAllIntegrationsSelected={isAllIntegrationsSelected}
            loading={isLoading}
            disabled={activity || isLoading}
            selectedIntegrations={selectedIntegrationGuids}
            deployableDetectionCount={deployableDetectionCount}
            integrationsList={integrations.deployable}
            integrationCounts={integrationCounts}
            handleSelect={handleSelect}
            unDeploy={unDeploy}
          />
        )}
      </Container>
    </FormDialog>
  );
}

function getSuccessMessage(action: 'deployment' | 'undeployment', items: unknown[]) {
  return `${renderValueWithLabel(items.length, 'detection')} ${presentPerfectBeVerb(items)} been queued for ${action}.`;
}

function getErrorMessage(error: ValidationError) {
  return { data: error, severity: 'error' };
}

function AdvancedConfiguration({ schemas }: { schemas: AdvancedConfig['schemas'] }): JSX.Element {
  const { integrations } = useIntegrationCatalog();
  const [expandedAccordion, setExpandedAccordion] = React.useState<Guid | false>(false);

  const handleChange = (accordion: string) => () => {
    setExpandedAccordion(accordion);
  };

  React.useEffect(() => {
    if (!expandedAccordion) setExpandedAccordion(Object.keys(schemas)[0]);
  }, [expandedAccordion, schemas]);

  return (
    <div>
      {Object.entries(schemas).map(([guid, schema]) => (
        <SlimAccordion
          expanded={expandedAccordion === guid}
          onChange={handleChange(guid)}
          key={guid}
          details={<RjsfFormik name={guid} schema={schema} />}
          summary={integrations.deployable.find(i => i.guid === guid)?.name || 'Advanced Configuration'}
        />
      ))}
    </div>
  );
}
