import cloneDeep from 'lodash/cloneDeep';
import escapeRegExp from 'lodash/escapeRegExp';
import isEmpty from 'lodash/isEmpty';

import { CategorizedIocNames, CategorizedIOCs, HASHES, IOC, IOCType, IOCTypeCategory, IPS } from '../IOC.type';

export function pluralize(category: IOCTypeCategory): string {
  switch (category) {
    case IOCTypeCategory.Hash:
      return 'Hashes';
    case IOCTypeCategory.IP_Address:
      return 'IPs';
    case IOCTypeCategory.Domain:
      return 'Domains';
    case IOCTypeCategory.URL:
      return 'URLs';
  }
}

export const INITIAL_CATEGORIES: CategorizedIOCs<IOC> = {
  [IOCTypeCategory.Hash]: [],
  [IOCTypeCategory.IP_Address]: [],
  [IOCTypeCategory.Domain]: [],
  [IOCTypeCategory.URL]: []
};

export const INITIAL_KEYWORDS: CategorizedIocNames = {
  [IOCTypeCategory.Hash]: [],
  [IOCTypeCategory.IP_Address]: [],
  [IOCTypeCategory.Domain]: [],
  [IOCTypeCategory.URL]: []
};

export function mapIocType(type: IOCType): IOCTypeCategory {
  if (HASHES.includes(type)) {
    return IOCTypeCategory.Hash;
  } else if (IPS.includes(type)) {
    return IOCTypeCategory.IP_Address;
  } else {
    return type as IOCTypeCategory;
  }
}

export function categorizeIocs<T extends IOC>(iocs: T[]): CategorizedIOCs<T> {
  const initial = INITIAL_CATEGORIES as CategorizedIOCs<T>;
  if (isEmpty(iocs)) return initial;
  return iocs.reduce((categories, ioc) => {
    const cat = mapIocType(ioc.type);
    categories[cat].push(ioc);

    return categories;
  }, cloneDeep(initial));
}

export function getKeywords<T extends IOC>(categorizedIOCs: CategorizedIOCs<T>): CategorizedIocNames {
  if (isEmpty(categorizedIOCs)) return INITIAL_KEYWORDS;

  return Object.entries(categorizedIOCs).reduce((accumulator: CategorizedIocNames, [category, iocs]) => {
    return {
      ...accumulator,
      [category]: iocs.map(ioc => ioc.name)
    };
  }, cloneDeep(INITIAL_KEYWORDS));
}

export function highlight(keywords: CategorizedIocNames) {
  return function (rawText: string) {
    let highlightedText = rawText;
    Object.entries(keywords ?? {})
      .sort(([keyA], [keyB]) => keyB.localeCompare(keyA))
      .forEach(([key, values]) => {
        values.forEach(value => {
          highlightedText = highlightedText.replace(
            new RegExp(escapeRegExp(value.toString()), 'gi'),
            `<span class="token ${key}">$&</span>`
          );
        });
      });

    return highlightedText;
  };
}
