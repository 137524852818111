import { DEFAULT_ROLE, GROUP_TYPE } from 'constants/organization';

import { FilterValues } from 'module/GlobalFilter';
import { Entitlement } from 'module/Setting/core/Entitlements/Entitlements.type';

import { Config } from 'provider/UserConfig/UserConfig.type';

import { Guid, Ident, Tracked } from './common';

export enum RegistrationMethod {
  Public = 'Public',
  Invite = 'Invite'
}

export type Entity = Tracked & {
  name: string;
  id: Ident;
  readonly?: boolean;
};

export type User = Entity & {
  first_name: string;
  last_name: string;
  email: string;
  tenant_organization_id?: number;
  verified: boolean; // email has been verified
  confidence_filters?: { filter_str: string }[];
  superuser: boolean;
  memberships?: {
    [key: Guid]: Group[];
  };
  permission?: ContentPermission[];
  organizations?: OrganizationMeta[];
  impersonator?: Omit<User, 'impersonator'>;
  effective_preference?: UserPreference;
  preference?: UserPreference;
  preferred_organization?: Partial<Organization>;
  is_subscriber: boolean;
  is_isolated: boolean;
  status: string;
  invites?: {
    state: string;
    expire_time: string;
  }[];
  last_active?: string;
  sso: boolean;
  mfa: boolean; //user needs to use mfa to sign in, whether required by org or opt-in
  mfa_configured: boolean; //mfa is configured  for this user
  mfa_required_org: boolean; //org requires mfa
  two_factor_type?: TwoFactorType;
};

export type HubspotUser = {
  email: string;
  firstName: string;
  lastName: string;
};

export type Auth = {
  user: User;
  permission: Organization[];
  preferable: Organization[];

  /**
   * This is the same as user.preferred_organization.id
   */
  defaultOrgId: number;
  defaultOrgGuid: Guid;
  defaultOrgName: string;
  tenantOrgId: number; //the organization that sponsors the account
  defaultLanguageId?: number;
  isSubscriber: boolean;
  /**
   * This is the same as user.preferred_organization.isolated and user.is_isolated
   */
  isTraining: boolean;
  impersonate?: User;
};

export type ChangeRequest = { current_password: string; new_password: string };
export type ForgotRequest = { email: string };
export type LoginRequest = { email: string; password: string };
export type ResetRequest = { password: string; token: string };
export type CheckSSORequest = { email: string };
export type CheckSSOResponse = { url: string };
export type MFARequiredResponse = {
  data: {
    login_identifier: string;
  };
} | null;

export enum RegisterStatus {
  Invite = 'AwaitingInvite',
  Verification = 'AwaitingVerification',
  Approval = 'AwaitingApproval',
  Active = 'Active'
}

/**
 * Register
 * 1. Open Community, no tenant - user verifies email and has community access
 * 2. Invite Community, no tenant - registration is confirmed but user must await invite to activate
 * 3. Approval Community, no tenant - request must be approved by community admin before access is granted
 * 4. Open Community, Invite tenant - user verifies email and has community access, but no tenant access
 * 5. Invite Community, Approval tenant - request must be approved by tenant admin before access is granted to tenant and community
 * 6. Approval Community, Approval tenant - request must be approved by tenant admin before access is granted to tenant and community
 * 7. Open Community, Approval tenant - user verifies email and has community access, request must be approved by tenant admin before access is granted to tenant
 * 8. Invite Community, Open tenant - user verifies email and has tenant and community access
 * 9. Approval Community, Open tenant - user verifies email and has tenant and community access
 */
export type LoginNextRequest = {
  email: string;
  token?: string;
};

export type RegisterResponse = {
  status: RegisterStatus;
  message: string;
};

export type RegisterRequest = {
  name: string; // display name
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company?: string;
  job_title?: string;
  interest?: string;
  token?: string;
};

export type UserRequest = {
  preferred_organization_id?: string | number;
  first_name?: string;
  last_name?: string;
  company?: string;
  email?: string;
  name?: string;
  interest?: string;

  preference?: UserPreference;
};

export type UserPreference = {
  default_analytic_compilation_target?: number;
  default_feed_filter?: Record<string, string | string[]>;
  mfa_required?: boolean;
  rank_threshold?: RankThresholdPreference;
  frontend?: Config;
};

export type UserResponse = (User & { organizations: Organization[] }) | { detail: string };

export type RegisterFormData = RegisterRequest & {
  confirm_password: string;
  legal?: boolean;
};

export enum ContentPermission {
  All = '*',
  Read = 'Read',
  Edit = 'Edit',
  Delete = 'Delete',
  Share = 'Share'
}

export function isContentPermission(action: ContentPermission | FunctionalPermission): action is ContentPermission {
  return Object.values(ContentPermission).includes(action as ContentPermission);
}

// if you're looking at this, double check that it's up to date with snapattack_be/snapattack/core/data/structures/user/enums.py
export enum FunctionalPermission {
  All = '*',
  BASStableFeatures = 'BASStableFeatures', // FE Feature Flag only
  Beta = 'Beta',
  // Permits beta features in development.
  BulkImportAnalytic = 'BulkImportAnalytic',
  BulkImportBASScript = 'BulkImportBASScript',
  BulkExportAnalytic = 'BulkExportAnalytic',
  CalculateRecommendation = 'CalculateRecommendation',
  CreateAnalytic = 'CreateAnalytic',
  CreateAnalyticJob = 'CreateAnalyticJob',
  CrossOrganizationJob = 'CrossOrganizationJob',
  CreateAPIKey = 'CreateAPIKey',
  CreateCollection = 'CreateCollection',
  CreateCompilationTarget = 'CreateCompilationTarget',
  CreateGroup = 'CreateGroup',
  CreateIntegration = 'CreateIntegration',
  CreateIntel = 'CreateIntel',
  CreateSession = 'CreateSession',
  CreateVMSession = 'CreateVMSession',
  DefineMeta = 'DefineMeta',
  DeployAnalytic = 'DeployAnalytic',
  DetectionHuntFeatures = 'DetectionHuntFeatures', // FE Feature Flag only
  DetectionHealthDashboard = 'DetectionHealthDashboard', // FE Feature Flag only
  ExportThreat = 'ExportThreat',
  ImpersonateUser = 'ImpersonateUser',
  InviteUser = 'InviteUser',
  IocHunt = 'IocHunt',
  LandingIndicators = 'LandingIndicators',
  MetadataFeatures = 'MetadataFeatures',
  MitreCoverageDashboard = 'MitreCoverageDashboard', // FE Feature Flag only
  OpenSearchUIAccess = 'OpenSearchUIAccess',
  OpenSearchAuditAccess = 'OpenSearchAuditAccess',
  PreferOrganization = 'PreferOrganization',
  ReadSigmaConfig = 'ReadSigmaConfig',
  RecommendationFeedFilter = 'RecommendationFeedFilter', // FE Feature Flag only
  SecurityProfileDashboard = 'SecurityProfileDashboard', // FE Feature Flag only
  SetAnalyticMeta = 'SetAnalyticMeta', // Rank, Severity, State, Metadata
  SetBasScriptMeta = 'SetBasScriptMeta', // Rank, Severity, State, Metadata
  SetCollectionMeta = 'SetCollectionMeta', // Metadata
  SetIntelMeta = 'SetIntelMeta', // Metadata
  SetSessionMeta = 'SetSessionMeta', // Metadata
  SetTagMeta = 'SetTagMeta', // Metadata
  SetSecurityProfile = 'SetSecurityProfile', // Threat Profile
  SimulationFeatures = 'SimulationFeatures', // FE Feature Flag only
  SnapScore = 'SnapScore', // FE Feature Flag only
  SplunkProxy = 'SplunkProxy',
  SplunkUIAccess = 'SplunkUIAccess',
  SwaggerDocs = 'SwaggerDocs',
  TaskBASAgent = 'TaskBASAgent',
  Tuning = 'Tuning'
  // Permits configuration and/or training of (usually organization tier) algorithms.
}

// sync with snapattack_be/snapattack/core/data/structures/auth/authentication.py
export enum TwoFactorType {
  TOTP = 'TOTP',
  EMAIL = 'EMAIL'
}

export type Permitted = {
  permission: Omit<ContentPermission, ContentPermission.All>[] | [ContentPermission.All];
};

export type OrganizationMeta = Entity & Tracked;

export type Organization = OrganizationMeta &
  Permitted & {
    subscriber: boolean;
    isolated: boolean;
    content_provider: boolean;
    small_image?: string;
    large_image?: string;
    groups: Group[];
    permission: Omit<FunctionalPermission, FunctionalPermission.All>[] | [FunctionalPermission.All];
    registration_mode?: string;
    domains?: string[];
    default_roles?: string[];
    mandatory_preference?: Preference;
    optional_preference?: Preference;
    entitlements?: Entitlement[];
    bulk_rank_active?: boolean;
    detection_author_override?: string;
    allow_impersonation?: boolean;
  };

export type OrganizationTargetPayload = {
  small_image: string;
  large_image: string;
  subscriber?: boolean;
  isolated?: boolean;
  content_provider?: boolean;
  default_roles: string[];
  domains: string[];
  registration_mode: string;
  entitlements: string[];
  name: string;
  mandatory_preference?: Preference;
  optional_preference?: Preference;
  bulk_rank_active?: boolean;
  detection_author_override?: string;
  allow_impersonation?: boolean;
};

export type Preference = {
  default_analytic_compilation_target?: number;
  hidden_analytic_compilation_targets?: number[];
  mfa_required?: boolean;
  disable_ai?: boolean;
  rank_threshold?: RankThresholdPreference;
  default_feed_filter?: FilterValues;
};

type RankThresholdPreference = {
  Highest: {
    min: number;
    max: number;
    closed_min: true;
    closed_max: true;
  };
  High: {
    min: number;
    max: number;
    closed_min: false;
    closed_max: true;
  };
  Medium: {
    min: number;
    max: number;
    closed_min: false;
    closed_max: true;
  };
  Low: {
    min: number;
    max: number;
    closed_min: false;
    closed_max: true;
  };
  Lowest: {
    min: number;
    closed_min: false;
    closed_max: true;
  };
};

export type Group = Entity & {
  organization_guid?: Guid;
  organization_name?: string;
  organization?: {
    name: string;
    guid: Guid;
    id: Ident;
  };
  role?: DEFAULT_ROLE;
  type?: GROUP_TYPE;
};

export type EntityOption = {
  id: number;
  value: number;
  text: string;
};

export type Monitoring = {
  errors: {
    datasource: string;
    debug: boolean;
    environment: string;
    error_sample_rate: number;
    profile_sample_rate: number;
    enabled: boolean;
    release: string;
  };
  tracking: string;
};
export type Maintenance = {
  message: string;
  start_time: string;
  active: boolean;
  end_time: string;
};
export type ProtectedSettings = {
  snapattack_splunk_app: string;
};
