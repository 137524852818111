import React from 'react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { deleteIntel } from 'module/Intel/Intel.api';

import { usePushSnack } from 'provider';

import { Guid, Visibility } from 'types/common';
import { SetState } from 'types/core';

const Container = styled(Tooltip)`
  z-index: 1;
`;

type Props = {
  guid: Guid;
  title: string;
  setVisibility: SetState<Visibility>;
};

function CardDeleteIntelButton({ guid, title, setVisibility }: Props) {
  const [disable, setDisable] = React.useState(false);
  const pushSnack = usePushSnack();
  function handleUpdateIntel() {
    setDisable(true);
    deleteIntel(guid)
      .then(() => setVisibility(Visibility.Deleted))
      .then(() => pushSnack(<>Intelligence Deleted: {title}</>, 'info', 'center', 'bottom', 3000))
      .catch(() => pushSnack(<>Something Went Wrong</>, 'error', 'center', 'bottom', 3000));
  }

  return (
    <Container arrow placement='top' title={`Delete`}>
      <ActionIconButton
        aria-label={`Delete`}
        color='inherit'
        icon={faTrash}
        onClick={handleUpdateIntel}
        disabled={disable}
      />
    </Container>
  );
}

export default CardDeleteIntelButton;
