import { PeriodType } from 'react-js-cron';

import { Option } from 'snap-ui/Autocomplete';

import { TimeInSeconds } from 'utilities/TimeUtils';

import { JobStatus } from './Job.type';

export const ALL_INTEGRATION = { value: 'All Integrations', content: 'All Integrations' };

export const JOB_SAFE_DELETE_STATUS = [
  JobStatus.Started,
  JobStatus.Success,
  JobStatus.Pending,
  JobStatus.CompletedWithErrors
];

export const JOB_STATUS_COMPLETE_JOB_STATUS = [JobStatus.Success, JobStatus.CompletedWithErrors];
export const DISPLAY_JOB_STATUS_DETAIL = [JobStatus.CompletedWithErrors, JobStatus.Failed, JobStatus.Started];

export const JOB_PAGE_SIZE = 25;

export const COLUMN_MODEL_JOB_OVERVIEW = 'jobOverview';
export const HIDDEN_FIELDS = ['jobsByGroup', 'name', 'analytic_job_guid', 'organization_id'];
export const SUPERUSER_HIDDEN_FIELDS = ['jobsByGroup', 'name'];
export const WEEKLY = '0 0 * * 0';
export const DEFAULT_PERIOD: PeriodType = 'week';

export const maxResultsPerQuery = 500;

export const maxHuntSearchWindowOptions: Option[] = [
  { content: '1 hour', value: TimeInSeconds.hour.toString() },
  { content: '24 hours', value: TimeInSeconds.day.toString() },
  { content: '7 days', value: TimeInSeconds.sevenDays.toString() },
  { content: '14 days', value: TimeInSeconds.fourteenDays.toString() },
  { content: '1 month (31 days)', value: TimeInSeconds.oneMonth.toString() },
  { content: '90 days', value: TimeInSeconds.ninetyDays.toString() },
  { content: '180 days', value: TimeInSeconds.hundredEightyDays.toString() },
  { content: '1 year (365 days)', value: TimeInSeconds.oneYear.toString() }
];

export const maxRecurringHuntSearchWindowOptions: Option[] = [
  { content: '15 minutes', value: TimeInSeconds.fifteenMinutes.toString() },
  { content: '1 hour', value: TimeInSeconds.hour.toString() },
  { content: '24 hours', value: TimeInSeconds.day.toString() },
  { content: '7 days', value: TimeInSeconds.sevenDays.toString() },
  { content: '14 days', value: TimeInSeconds.fourteenDays.toString() },
  { content: '1 month (31 days)', value: TimeInSeconds.oneMonth.toString() }
];

export const maxConfidenceTailoringSearchWindowOptions: Option[] = [
  { content: '4 hours', value: TimeInSeconds.fourHours.toString() },
  { content: '6 hours', value: TimeInSeconds.sixHours.toString() },
  { content: '8 hours', value: TimeInSeconds.eightHours.toString() },
  { content: '12 hours', value: TimeInSeconds.twelveHours.toString() },
  { content: '1 day', value: TimeInSeconds.day.toString() },
  { content: '1 week', value: TimeInSeconds.sevenDays.toString() },
  { content: '1 month (31 days)', value: TimeInSeconds.oneMonth.toString() }
];

export const FREQUENCY_TYPE = {
  Once: 'Once',
  Recurring: 'Recurring'
} as const;
