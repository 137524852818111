/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

import { extractYouTubeVideoID } from 'utilities/YouTubeUtils';

const StyledIframe = styled('iframe')`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: block;
  aspect-ratio: 16 / 9;
  border: 0;
  color-scheme: normal;
`;

export const RICH_MARKDOWN = {
  a: ({ node, ...props }) => {
    try {
      // Both of these will throw an error if invalid
      const url = new URL(props.href); // Making sure we have a valid url first
      const video_id = extractYouTubeVideoID(url.href);
      return <StyledIframe src={'https://youtube.com/embed/' + video_id} allowFullScreen></StyledIframe>;
    } catch (e) {} // eslint-disable-line no-empty
    return <a {...props} target='_blank' rel='noopener noreferrer' />;
  }
};

const raw = ({ children }: { children: StrictReactNode }) => <>{children}</>;

export const RAW_MARKDOWN = {
  a: raw,
  p: raw,
  h1: raw,
  h2: raw,
  h3: raw,
  h4: raw,
  h5: raw,
  h6: raw,
  img: raw,
  blockquote: raw,
  table: raw,
  thead: raw,
  tbody: raw,
  tr: raw,
  td: raw,
  th: raw,
  pre: raw,
  ol: raw,
  ul: raw,
  li: raw,
  code: raw,
  br: raw
};
