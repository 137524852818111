import { object, string, boolean, ref } from 'yup';

export enum PasswordType {
  PASSWORD = 'password',
  NEW_PASSWORD = 'new_password',
  CONFIRM_PASSWORD = 'confirm_password',
  CONFIRM_NEW_PASSWORD = 'confirm_new_password'
}

export const pwValidation = (fieldName = '', fieldInput = ''): Record<string, string[]> => {
  const errors: Record<string, string[]> = { [fieldName]: [] };

  if (fieldInput.length < 8) {
    errors[fieldName].push('Password must be at least 8 characters in length');
  }
  if (!/(?=.*[A-Z])/.test(fieldInput)) {
    errors[fieldName].push('Password must contain at least one uppercase character');
  }
  if (!/(?=.*[a-z])/.test(fieldInput)) {
    errors[fieldName].push('Password must contain at least one lowercase character');
  }
  if (!/(?=.*\d)/.test(fieldInput)) {
    errors[fieldName].push('Password must contain at least one number');
  }
  if (fieldInput.replaceAll(/[\w]+/g, '').length < 2) {
    errors[fieldName].push('Password must contain at least two special characters');
  }
  if (errors[fieldName].length === 0) return {};
  return errors;
};

export const changePwSchema = object().shape({
  [PasswordType.PASSWORD]: string().required('Existing password is required')
});

export const resetPwSchema = object().shape({
  [PasswordType.CONFIRM_PASSWORD]: string()
    .required('Confirm password is required')
    .oneOf([ref(PasswordType.PASSWORD), null], 'Passwords must match')
});

export const registerSchema = object().shape({
  name: string().required('Display name is required'),
  first_name: string().required('First name is required'),
  last_name: string().required('Last name is required'),
  email: string().when('token', {
    is: (token: string) => !token,
    then: string().email('Please enter a valid email').required('Email is required'),
    otherwise: string().nullable()
  }),
  token: string().nullable(),
  legal: boolean().oneOf([true], 'You must read and agree to the terms')
});
