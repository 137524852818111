import { COMMUNITY_ORGANIZATION } from 'constants/auth';

import { AttackScript } from 'module/AttackScript/AttackScript.type';
import { Collection } from 'module/Collection/Collection.type';

import { useAuth, useUserCatalog } from 'provider';

import { Organization } from 'types/auth';
import { Artifact } from 'types/common';

export type ExtractedDetails = {
  creation: string;
  created_by: string;
  modified: string;
  updated_by: string;
  organization: Organization;
};

export default function useExtractDetails(resource: Artifact | Collection | AttackScript): ExtractedDetails {
  const { permission: organizations } = useAuth();
  const { users } = useUserCatalog();
  const created_by =
    'created_by_id' in resource
      ? users.find(user => user.id === resource?.created_by_id)?.name
      : resource.created_by?.name;
  const modified_by =
    'modified_by_id' in resource
      ? users.find(user => user.id === resource?.modified_by_id)?.name
      : resource.created_by?.name;
  const organizationId = 'organization_id' in resource ? resource.organization_id : resource.organization?.id;

  return {
    creation: resource.creation,
    created_by: created_by || 'SnapAttack User',
    modified: resource.modified,
    updated_by: modified_by || 'SnapAttack User',
    organization:
      organizationId === COMMUNITY_ORGANIZATION.id
        ? (COMMUNITY_ORGANIZATION as Organization)
        : organizations.find(org => org.id === organizationId)
  };
}
