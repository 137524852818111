import get from 'lodash/get';

import { highlight, languages, PrismGrammar } from './SyntaxEditor.languages';

/**
 * point sigma compiler names to the correct prism grammar names
 */
function mapGrammarName(languageKey: string): string {
  switch (languageKey) {
    case 'sigma':
      return 'yaml';

    case 'splunk':
    case 'splunkdm-snapattack':
    case 'crowdstrike':
    case 'crowdstrike-ioa':
      return 'splunk-spl';

    case 'chronicle':
      return 'yara-l';
    case 'chronicle-udm':
      return 'udm';

    case 'es-dsl':
    case 'es-rule':
    case 'es-rule-eql':
      return 'json';

    // TO-DO: Write new grammar
    case 'es-qs':
    case 'elastalert':
      return 'splunk-spl';

    case 'carbonblack':
    case 'carbon_black':
      return 'carbonblack';

    case 'ala':
    case 'mde':
      return 'kusto';

    case 'sentinel-one':
    case 'sentinel-one-pq':
      return 'sentinelone';

    case 'hx':
      return 'markup';

    // NOT IMPLEMENTED
    // case 'devo':
    // case 'qradar':
    // case 'sumologic':
    // case 'hx':
    // case 'usm-anywhere-correlation-rule':
    // case 'netwitness':
    // case 'powershell':
    // case 'athena'
    // case 'qualys'
    // case 'arcsight'
    // case 'cortex_xdr':

    default:
      return languageKey;
  }
}

export type Highlighter = (code: string) => string;

export function getGrammarName(_languageKey: string): string {
  const languageKey = mapGrammarName(_languageKey);
  if (languages[languageKey]) return languageKey;

  // possible language with multiple formats like `splunk_data_model` that isn't fully mapped yet
  if (languageKey?.includes('_')) {
    const [rootKey] = languageKey.split('_');
    return getGrammarName(rootKey);
  }

  // default to sql
  return 'sql';
}

export function getGrammar(_languageKey: string): PrismGrammar {
  return languages[getGrammarName(_languageKey)];
}

export function getHighlighter(languageKey: string): Highlighter {
  const grammar = getGrammar(languageKey);
  return code => highlight(code, grammar, languageKey);
}

export function getKeywordHighlighter(keyword: string | RegExp): Highlighter {
  if (!(keyword instanceof RegExp)) keyword = new RegExp(keyword);
  const grammar = { keyword };
  return (code: string) => highlight(code, grammar, `keyword-${keyword.toString()}`);
}

export const Highlight: Record<string | 'yaml' | 'none', Highlighter> = new Proxy(
  // some specific ones for intellisense that we might want to refer to in code
  {
    yaml: getHighlighter('yaml'),
    none: code => code
  },
  {
    get: (target, key) => get(target, key, getHighlighter(key.toString()))
  }
);
