import { AxiosRequestConfigType, snapattack } from 'apis/snapattack';

import { AssociatedCollections } from 'module/Collection/Collection.type';
import { TagGroup, convertTagsToTagNames } from 'module/Tag';

import { ArtifactScoreDetail, Guid } from 'types/common';
import { Permitted, Version } from 'types/common.zod';
import { Query } from 'types/filter';

import { AttackScript, AttackScriptFormInput, AttackScriptSupplemental } from './AttackScript.type';

const prefix = '/bas/script';

export function postAttackScripts(body: AttackScriptFormInput) {
  return snapattack.post(`${prefix}/`, body).then(res => res.data);
}

export function putAttackScripts(guid: Guid, payload: Partial<AttackScriptFormInput>) {
  return snapattack.put(`${prefix}/${guid}/`, payload).then(res => res.data);
}

export function quickAddTags(guid: Guid, additions: string[]) {
  return snapattack.put(`${prefix}/${guid}/tags/`, additions);
}

export function quickDeleteTags(guid: Guid, deletions: string[]) {
  return snapattack.delete(`${prefix}/${guid}/tags/`, { data: deletions });
}

export function tagRefresh(guid: Guid): Promise<TagGroup> {
  return snapattack.get(`${prefix}/${guid}/tags/`).then(r => convertTagsToTagNames(r.data));
}

export function getAttackScript(guid: Guid) {
  return snapattack.get(`${prefix}/${guid}/`).then(res => res.data);
}

export function getAttackScriptPermissions(guid: Guid): Promise<Permitted> {
  return snapattack.get(`${prefix}/${guid}/permissions/`).then(res => res.data);
}

export function getAttackScriptVersions(guid: Guid): Promise<Version[]> {
  return snapattack.get(`${prefix}/${guid}/versions/`).then(res => res.data);
}

export function postAttackScriptSeverity(guid: Guid, payload: ArtifactScoreDetail): Promise<AttackScriptSupplemental> {
  return snapattack.post(`${prefix}/${guid}/severity/`, payload).then(res => res.data);
}

export function postAttackScriptSessions(validationGuid: Guid, mode: 'add' | 'delete', sessionGuids: Guid[]) {
  return snapattack.post(`${prefix}/${validationGuid}/sessions/${mode}/`, sessionGuids).then(res => res.data);
}

export function postAttackScriptAnalytics(validationGuid: Guid, mode: 'add' | 'delete', detectionGuids: Guid[]) {
  return snapattack.post(`${prefix}/${validationGuid}/detections/${mode}/`, detectionGuids).then(res => res.data);
}

export function getAttackScriptCollectionAssociations(guid: Guid): Promise<AssociatedCollections[]> {
  const url = `${prefix}/${guid}/collections/`;
  return snapattack.get(url).then(r => r.data);
}

export function deleteAttackScript(guid: Guid): Promise<void> {
  return snapattack.delete(`${prefix}/${guid}/`);
}

/**
 * Used to query the collections via a query
 */
export function postSearchScripts(payload: Query, config?: AxiosRequestConfigType): Promise<AttackScript[]> {
  return snapattack.post(`/search${prefix}/`, payload, config).then(res => res.data.items);
}
