import React, { ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import BackdropLoader from 'snap-ui/BackdropLoader';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import Paper from './Paper';

export type Props = {
  className?: string;
  content: React.ReactNode;
  elevation?: number;
  footer?: ReactElement | string;
  linkTo?: string | object;
  loading?: boolean;
  title: ReactElement | string;
  tooltip?: ReactElement;
};

function PanelFrameComponent({
  className,
  content,
  elevation,
  footer,
  linkTo,
  loading,
  title,
  tooltip,
  ...extraProps
}: Props): ReactElement {
  const rootProps = {
    ...extraProps,
    className: classNames(className)
  };
  const [element, props] = linkTo ? [Link, { ...rootProps, to: linkTo }] : ['div', { ...rootProps, to: {} }];
  const panel = React.createElement(
    element,
    props,
    <>
      <Paper square elevation={elevation || 5} className={classNames('panel-frame', className)}>
        {loading && <BackdropLoader contained />}
        <div className='panel-title'>{title}</div>
        <div className='panel-contents'>{content}</div>
        {footer && <div className='panel-footer'>{footer}</div>}
      </Paper>
    </>
  );

  return tooltip ? (
    <Tooltip arrow title={tooltip}>
      {panel}
    </Tooltip>
  ) : (
    panel
  );
}

const PanelFrame = styled(PanelFrameComponent)<Props>`
  position: relative;
  background-color: ${p => p.theme.palette.common.black};

  .panel-frame {
    display: flex;
    flex-direction: column;
    padding: ${p => p.theme.spacing(2)};
    height: 100%;
    overflow: visible;
  }

  .panel-title {
    color: ${p => p.theme.palette.common.white};
    text-transform: uppercase;
    border-bottom: 2px solid ${p => p.theme.palette.grey[600]};
    text-align: left;
    left: ${p => p.theme.spacing(3)};
    letter-spacing: 0.7px;
  }

  .panel-contents {
    margin: auto ${p => p.theme.spacing(1)};
    text-align: center;
    letter-spacing: 0.5px;
  }

  & .panel-footer {
  }
`;

export default PanelFrame;
