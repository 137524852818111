import React from 'react';

import { getAnalyticLanguages } from 'apis/resources/analytic';

import { Status, useAsync } from 'storage';

import { AnalyticLanguage } from 'types/analytic';
import { Guid, Ident } from 'types/common';

export type AnalyticLanguageCatalog = {
  data: AnalyticLanguage[];
  allLanguages: AnalyticLanguage[];
  status: Status;
  getLanguageById(id: Ident): AnalyticLanguage;
};

/** Get the list of supported languages for the given analytic */
export default function useAnalyticLanguages(guid: Guid): AnalyticLanguageCatalog {
  const { data: languageList, run, status } = useAsync<AnalyticLanguage[]>([]);

  React.useEffect(() => {
    run(getAnalyticLanguages(guid));
  }, [run, guid]);

  const filteredLanguageList = React.useMemo(() => {
    return languageList.filter(lang => !lang.hidden);
  }, [languageList]);

  const getLanguageById = React.useCallback(
    (languageId: Ident): AnalyticLanguage => {
      return languageList.find(language => language.id === languageId);
    },
    [languageList]
  );

  return { data: filteredLanguageList, status, getLanguageById, allLanguages: languageList };
}
