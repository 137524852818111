import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Switch from 'snap-ui/Switch';
import Tooltip from 'snap-ui/Tooltip';

import { ARTIFACT_SCORE_OPTIONS } from 'constants/common';

import Badge from 'module/Widgets/Badge';

import { ArtifactScore, ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { filterVoids } from 'utilities/ArrayUtils';

import { FilterControl } from '../GlobalFilter.style';
import { FilterAugment, FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue, fromQueryNoop } from '../GlobalFilter.util';

type AnalyticRankKeys = {
  confidence: ArtifactScore[];
  confidenceFallback?: string;
};

type AnalyticRankFilterProps = {
  onChange(values: AnalyticRankKeys): void;
  values: AnalyticRankKeys;
};

function AnalyticRankFilter({ onChange, values }: AnalyticRankFilterProps): React.ReactElement {
  return (
    <FilterControl>
      <FormLabel id='rank-toggle-button-group-label' className='title-tooltip'>
        By Detection Confidence
        <Tooltip title='Measure of false positive hits in real world' placement='right' arrow wrap>
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='rank-toggle-button-group-label'>
        {ARTIFACT_SCORE_OPTIONS.map(option => (
          <FormControlLabel
            key={option.key}
            control={
              <Checkbox
                onChange={handleChange}
                value={option.value}
                checked={values.confidence.includes(option.value)}
              />
            }
            label={<Badge badgeSignature={option.value} badgeName='CONFIDENCE' />}
          />
        ))}
        {values['confidenceFallback'] !== undefined && (
          <FormControlLabel
            control={
              <Switch checked={values.confidenceFallback !== 'false'} onChange={handleBlendedConfidenceChange} />
            }
            label={
              <FormLabel id='blended-confidence-button-group-label' className='title-tooltip'>
                <>
                  {values.confidenceFallback === 'false' ? 'Organization Confidence' : 'Blended Confidence'}
                  <Tooltip
                    title={
                      values.confidenceFallback === 'false'
                        ? `Shows only your organization's confidence`
                        : `Shows organization confidence if available, otherwise shows the SnapAttack confidence`
                    }
                    placement='right'
                    arrow
                    wrap
                  >
                    <Icon.Info />
                  </Tooltip>
                </>
              </FormLabel>
            }
          />
        )}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as ArtifactScore;
    const confidence = addOrRemoveValue(checked, values.confidence, v);
    onChange({ confidence, confidenceFallback: values.confidenceFallback });
  }

  function handleBlendedConfidenceChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    onChange({
      confidence: checkConfidenceForCompleteSet(values) ? [] : values.confidence,
      confidenceFallback: checked ? undefined : 'false'
    });
  }
}

function checkConfidenceForCompleteSet(values: AnalyticRankKeys): boolean {
  return (
    !values?.confidence?.length || Object.values(ArtifactScore).every(score => values?.confidence?.includes(score))
  );
}

function shouldSkipQuery(values: AnalyticRankKeys): boolean {
  const shouldDisplay = values.confidenceFallback === '' || values.confidenceFallback === 'false';
  const isCompleteSet = checkConfidenceForCompleteSet(values);

  return shouldDisplay ? isCompleteSet && values.confidenceFallback !== 'false' : isCompleteSet;
}

function createBaseQuery(path: string, orgId: number): Query {
  return {
    field: `${path}.organization_id`,
    op: Ops.equals,
    value: orgId
  };
}

function createRankQuery(path: string, confidence: ArtifactScore[]): Query {
  return {
    field: `${path}.rank`,
    op: Ops.in,
    value: confidence
  };
}

function createFallbackQuery(path: string, fallbackValue: string): Query {
  return {
    field: `${path}.is_fallback`,
    op: Ops.equals,
    value: fallbackValue
  };
}

function buildQueryItems(path: string, values: AnalyticRankKeys, orgId: number): Query[] {
  const queryItems: Query[] = [createBaseQuery(path, orgId)];

  if (!checkConfidenceForCompleteSet(values)) {
    queryItems.push(createRankQuery(path, values.confidence));
  }

  const shouldAddFallback = values.confidenceFallback === 'false';
  if (shouldAddFallback) {
    queryItems.push(createFallbackQuery(path, values.confidenceFallback));
  }

  return queryItems;
}

function toQuery(path: string) {
  return function (values: AnalyticRankKeys, { orgId }: FilterAugment): Query {
    const createQueryFunction =
      (path: string) =>
      (values: AnalyticRankKeys, orgId: number): Query | undefined => {
        if (shouldSkipQuery(values)) {
          return undefined;
        }

        return {
          op: Ops.for_each,
          items: buildQueryItems(path, values, orgId)
        };
      };
    return createQueryFunction(path)(values, orgId);
  };
}

function fromQuery(path: string) {
  return zod.union([
    zod
      .object({
        op: zod.literal(Ops.for_each),
        items: zod.array(
          zod.union([
            zod
              .object({
                field: zod.literal(`${path}.organization_id`)
              })
              .transform(() => ({
                confidence: [] as ArtifactScore[],
                confidenceFallback: undefined
              })),
            zod
              .object({
                field: zod.literal(`${path}.rank`),
                op: zod.literal(Ops.in),
                value: zod.array(zod.nativeEnum(ArtifactScore))
              })
              .transform(query => ({ confidence: query.value, confidenceFallback: undefined })),
            zod
              .object({
                field: zod.literal(`${path}.is_fallback`),
                op: zod.literal(Ops.equals),
                value: zod.union([zod.literal('false'), zod.literal(undefined)])
              })
              .transform(query => ({ confidence: [] as ArtifactScore[], confidenceFallback: query.value })),
            zod
              .object({
                field: zod.literal(`${path}.rank`),
                op: zod.literal(Ops.equals),
                value: zod.union([zod.literal(null), zod.literal(undefined)])
              })
              .transform(() => ({ confidence: [ArtifactScore.UNKNOWN], confidenceFallback: undefined }))
          ])
        )
      })
      .transform(query => {
        return {
          confidence: query.items.filter(i => i.confidence).flatMap(i => i.confidence),
          confidenceFallback: query.items?.find(item => item.confidenceFallback)?.confidenceFallback
        };
      }),
    zod
      .object({
        op: zod.literal(Ops.or),
        items: zod.array(
          zod.union([
            zod
              .object({
                op: zod.literal(Ops.for_each),
                items: zod.array(
                  zod.union([
                    zod
                      .object({
                        field: zod.literal(`${path}.organization_id`)
                      })
                      .transform((): void => undefined),
                    zod
                      .object({
                        field: zod.literal(`${path}.rank`),
                        op: zod.literal(Ops.in),
                        value: zod.array(zod.nativeEnum(ArtifactScore))
                      })
                      .transform(query => query.value)
                  ])
                )
              })
              .transform(query => filterVoids(query.items).flat()),
            zod
              .object({
                field: zod.literal(`${path}.rank`),
                op: zod.literal(Ops.equals),
                value: zod.union([zod.literal(null), zod.literal(undefined)])
              })
              .transform(() => [ArtifactScore.UNKNOWN])
          ])
        )
      })
      .transform(query => ({
        confidence: query.items.flat()
      }))
  ]);
}

const AnalyticRankFilterConfig: FilterConfig<AnalyticRankKeys> = {
  defaults: {
    default: () => ({ confidence: Object.values(ArtifactScore) }),
    [ArtifactType.Analytic]: () => ({ confidence: Object.values(ArtifactScore), confidenceFallback: '' })
  },
  supportedTopics: [ArtifactType.Analytic, ArtifactType.Marker, ArtifactType.Session],
  component: AnalyticRankFilter,
  toQuery: {
    [ArtifactType.Analytic]: toQuery('ranks'),
    [ArtifactType.Session]: toQuery('recommended_analytic_ranks'),
    [ArtifactType.Marker]: () => null
  },
  fromQuery: {
    [ArtifactType.Analytic]: fromQuery('ranks'),
    [ArtifactType.Session]: fromQuery('recommended_analytic_ranks'),
    [ArtifactType.Marker]: fromQueryNoop({
      field: zod.union([zod.literal('ranks.rank'), zod.literal('recommended_analytic_ranks.rank')]),
      value: zod.array(zod.string())
    })
  }
};
export default AnalyticRankFilterConfig;
