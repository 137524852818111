import React from 'react';

import { setUser as setUserInSentry } from '@sentry/react';

import { COMMUNITY_ORGANIZATION, SUBSCRIBER_ORGANIZATION_ID } from 'constants/auth';

import { Engage } from 'lib/Engagement';

import { useAsync } from 'storage';

import { Auth, FunctionalPermission, Organization, User } from 'types/auth';

import { getCurrentUser, getVisibleOrganizations, logout as logoutUser } from './Account.service';

async function me(): Promise<Auth> {
  const p: [Promise<User & { organizations: Organization[] }>, Promise<Organization[]>] = [
    getCurrentUser(),
    getVisibleOrganizations()
  ];

  const data: Auth = await Promise.all(p).then(([auth, catalog]) => {
    const user = { ...auth };
    delete user.organizations;
    return {
      user,
      permission: catalog,
      preferable: catalog.filter(
        org => org.permission.includes('*') || org.permission.includes(FunctionalPermission.PreferOrganization)
      ),
      isSubscriber: user.superuser || user.is_subscriber,
      isTraining: user.is_isolated,

      /* This will be overwritten, later below, once we establish preferred org and before we return */
      defaultOrgId: COMMUNITY_ORGANIZATION.id,
      tenantOrgId: COMMUNITY_ORGANIZATION.id,
      defaultOrgGuid: COMMUNITY_ORGANIZATION.guid,
      defaultOrgName: 'SnapAttack Community'
    };
  });

  // Engagement identify and Sentry track should run against distinct user. Everything else against data.user
  const isImpersonating = !!data.user.impersonator;
  const distinctUser = isImpersonating ? data.user.impersonator : data.user;
  Engage.identify(distinctUser.email);

  // This sets a cookie that the docs site can use to identify the user
  // This will fail locally without any warning
  document.cookie =
    'mp_user=' + btoa(distinctUser.email) + '; domain=snapattack.com; max-age=86400; samesite: strict; secure';

  setUserInSentry({
    email: distinctUser.email,
    username: `${distinctUser?.first_name} ${distinctUser?.last_name}`,
    id: distinctUser?.id?.toString()
  });

  // Non critical items shouldn't break login - wrap in t/c
  try {
    if (data.user?.preferred_organization) {
      /**
       * Isolated data isn't returned with preferred org
       * So let's cross reference it and add it in ourselves
       */
      data.user.preferred_organization = {
        ...data.user.preferred_organization,
        isolated: data.permission.some(
          org => org.guid === data.user.preferred_organization.guid && org.isolated === true
        )
      };
    } else if (data.user && !data.user.preferred_organization) {
      const selectionPool = data.preferable.filter(org => !org.isolated && org.subscriber);

      if (selectionPool.length === 0) {
        const nextSelectionPool = data.preferable.filter(
          org => !org.isolated && org.id !== SUBSCRIBER_ORGANIZATION_ID && org.id !== COMMUNITY_ORGANIZATION.id
        );

        if (nextSelectionPool.length === 0) {
          data.user.preferred_organization = COMMUNITY_ORGANIZATION;
        } else if (nextSelectionPool.length === 1) {
          data.user.preferred_organization = {
            id: nextSelectionPool[0].id,
            name: nextSelectionPool[0].name,
            guid: nextSelectionPool[0].guid,
            isolated: nextSelectionPool[0].isolated
          };
        }
      } else if (selectionPool.length === 1) {
        /**
         * In some cases a person only has one org and preferred or isn't set
         * so simulate it
         */
        data.user.preferred_organization = {
          id: selectionPool[0].id,
          name: selectionPool[0].name,
          guid: selectionPool[0].guid,
          isolated: selectionPool[0].isolated
        };
      }
    }

    data.defaultOrgId = data.user.preferred_organization.id;
    data.defaultOrgGuid = data.user.preferred_organization.guid;
    data.defaultOrgName = data.user.preferred_organization.name;
    data.tenantOrgId = data.user.tenant_organization_id;
    data.defaultLanguageId =
      data.user?.preference?.default_analytic_compilation_target ??
      data.user?.effective_preference?.default_analytic_compilation_target;

    Engage.register({
      isSubscriber: data.isSubscriber,
      preferred_organization: data.user?.preferred_organization?.name,
      impersonating: isImpersonating ? data.user.email : null,
      impersonator: isImpersonating ? data.user?.impersonator?.email : null,
      event_tenant_name: data.permission.find(o => o.id === data.user?.tenant_organization_id)?.name,
      event_tenant_id: data.user?.tenant_organization_id
    });

    if (!isImpersonating) {
      Engage.trackPersonBatch({
        isSubscriber: data.isSubscriber,
        preferred_organization: data.user?.preferred_organization?.name,
        tenant_name: data.permission.find(o => o.id === data.user?.tenant_organization_id)?.name,
        tenant_id: data.user?.tenant_organization_id
      });
      if (!data.user?.superuser) Engage.assignOrganizations(data.permission.map(o => o.name));
      Engage.trackPersonOnceBatch({
        $first_name: data.user?.first_name,
        $last_name: data.user?.last_name,
        $email: data.user?.email,
        full_name: `${data.user?.first_name} ${data.user?.last_name}`
      });
    }
  } catch (e) {
    console.error(e);
  }

  return data;
}

export function useAuthAsync(initialData: Auth) {
  const { data: auth, run, status: authStatus, reset, setData } = useAsync<Auth>(initialData);
  const fetch = React.useCallback(() => run(me()), [run]);
  const logout = React.useCallback(() => logoutUser().finally(() => reset()), [reset]);

  const setOrgs = React.useCallback(
    orgs => {
      setData({ ...auth, permission: orgs }, authStatus);
    },
    [auth, authStatus, setData]
  );

  return {
    auth,
    authStatus,
    fetch,
    logout,
    setOrgs
  };
}
