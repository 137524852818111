import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';

export type VulnerabilityCharacteristicsKeys = {
  zeroDay: string;
  knownExploited: string;
  observedWild: string;
};

export type VulnerabilityCharacteristicsFilterProps = {
  onChange(values: VulnerabilityCharacteristicsKeys): void;
  values: VulnerabilityCharacteristicsKeys;
};

function VulnerabilityCharacteristics({
  onChange,
  values
}: VulnerabilityCharacteristicsFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();

  // MATI_DELETION
  // const zeroDay = values.zeroDay?.toLowerCase() === 'true';
  const knownExploited = values.knownExploited?.toLowerCase() === 'true';
  const observedWild = values.observedWild?.toLowerCase() === 'true';

  return (
    <FilterControl disabled={!isSubscriber}>
      <FormLabel id='vulnerability-characteristics-toggle-button-group-label' className='title-tooltip'>
        By Vulnerability Characteristics
        {!isSubscriber && (
          <Tooltip title='This filter is only available to subscribers' placement='right' arrow wrap>
            <Icon.Info />
          </Tooltip>
        )}
      </FormLabel>
      <FormGroup aria-labelledby='vulnerability-characteristics-toggle-button-group-label'>
        {/* MATI_DELETION */}
        {/* <FormControlLabel
          key='zeroDay'
          control={<Checkbox onChange={handleChange} value='zeroDay' checked={zeroDay} />}
          label='Exploited as a Zero Day'
        /> */}
        <FormControlLabel
          key='knownExploited'
          control={<Checkbox onChange={handleChange} value='knownExploited' checked={knownExploited} />}
          label='CISA Known Exploited Vulnerabilities'
        />
        <FormControlLabel
          key='observedWild'
          control={<Checkbox onChange={handleChange} value='observedWild' checked={observedWild} />}
          label='Exploited in the Wild'
        />
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as string;
    if (v === 'zeroDay') {
      onChange({
        zeroDay: checked ? String(checked) : undefined,
        knownExploited: values.knownExploited,
        observedWild: values.observedWild
      });
    } else if (v === 'knownExploited') {
      onChange({
        zeroDay: values.zeroDay,
        knownExploited: checked ? String(checked) : undefined,
        observedWild: values.observedWild
      });
    } else if (v === 'observedWild') {
      onChange({
        zeroDay: values.zeroDay,
        knownExploited: values.knownExploited,
        observedWild: checked ? String(checked) : undefined
      });
    }
  }
}

const zeroDayFilter = {
  field: 'was_zero_day',
  op: Ops.equals,
  value: 'true'
};

const knownExploitedFilter: Query = {
  field: 'cisa_known_exploited_date',
  op: Ops.not_equals,
  value: null
};

const observedWildFilter = {
  field: 'observed_in_the_wild',
  op: Ops.equals,
  value: 'true'
};

function toQuery(values: VulnerabilityCharacteristicsKeys): Query {
  if (
    values.zeroDay?.toLowerCase() !== 'true' &&
    values.knownExploited?.toLowerCase() !== 'true' &&
    values.observedWild?.toLowerCase() !== 'true'
  )
    return;

  // orWrapper
  const filter: Query = {
    op: Ops.or,
    items: []
  };
  if (values.zeroDay?.toLowerCase() === 'true') filter.items.push(zeroDayFilter);
  if (values.knownExploited?.toLowerCase() === 'true') filter.items.push(knownExploitedFilter);
  if (values.observedWild?.toLowerCase() === 'true') filter.items.push(observedWildFilter);
  return filter;
}

const zeroDay = zod
  .object({
    field: zod.literal('was_zero_day'),
    op: zod.literal(Ops.equals),
    value: zod.string()
  })
  .transform(() => ({ zeroDay: 'true' }));

const knownExploited = zod
  .object({
    field: zod.literal('cisa_known_exploited_date'),
    op: zod.literal(Ops.not_equals),
    value: zod.literal(null)
  })
  .transform(() => ({ knownExploited: 'true' }));

const observedWild = zod
  .object({
    field: zod.literal('observed_in_the_wild'),
    op: zod.literal(Ops.equals),
    value: zod.string()
  })
  .transform(() => ({ observedWild: 'true' }));

const fromQuery = zod
  .object({
    op: zod.literal(Ops.or),
    items: zod.array(zod.union([zeroDay, knownExploited, observedWild]))
  })
  .transform(result => {
    const values: VulnerabilityCharacteristicsKeys = { zeroDay: null, knownExploited: null, observedWild: null };
    result.items.forEach((item: Partial<VulnerabilityCharacteristicsKeys>) => {
      if (item['zeroDay']) values.zeroDay = 'true';
      if (item['knownExploited']) values.knownExploited = 'true';
      if (item['observedWild']) values.observedWild = 'true';
    });
    return values;
  });

const VulnerabilityCharacteristicsFilterConfig: FilterConfig<VulnerabilityCharacteristicsKeys> = {
  defaults: { default: () => ({ zeroDay: null, knownExploited: null, observedWild: null }) },
  supportedTopics: [ArtifactType.Collection],
  component: VulnerabilityCharacteristics,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default VulnerabilityCharacteristicsFilterConfig;
