import React, { ReactElement } from 'react';

import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Icon from 'snap-ui/Icon';
import { Placeholders } from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';

import { Section } from 'module/Analytic/core/MetadataPanel/util';
import { ApiError } from 'module/ApiError';
import { TIMEOUT } from 'module/Training//Training.const';
import { fetchMyChallenges } from 'module/Training/Training.service';
import { Player } from 'module/Training/Training.type';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { timeAgo } from 'utilities/TimeUtils';

type Props = {
  guid: Guid;
  user_name: string;
  session_name: string;
};

export default function TrainingScore(props: Props): ReactElement {
  const { guid, user_name, session_name } = props;
  const { data, setData, task, status, errorProps } = useAsync<Player>();
  const interval = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    const poll = () => task(fetchMyChallenges(guid, user_name)).then(d => setData(d));
    poll();
    interval.current = setInterval(poll, TIMEOUT);
    return () => clearInterval(interval.current);
  }, [guid, user_name, setData, task]);

  const challengeSolved = data?.challenges?.includes(session_name);

  return (
    <div>
      <ApiError {...errorProps} />
      <Section>
        <Typography variant='h4'>TRAINING SCORE</Typography>
      </Section>
      <Section>
        {Status.pending === status && <Placeholders />}
        {Status.pending !== status && isEmpty(data) && (
          <>
            <p>You haven&apos;t solved a challenge yet! Review this threat and create a detection to score points.</p>
          </>
        )}
        {Status.pending !== status && !isEmpty(data) && (
          <>
            <p>
              {challengeSolved ? (
                <span>
                  <Icon icon={faCheck} color='mint' /> &nbsp;&nbsp;This challenge has been solved!
                </span>
              ) : (
                <span>
                  <Icon icon={faXmark} color='red' /> &nbsp;&nbsp;This challenge has not been solved yet.
                </span>
              )}
            </p>
            <p>
              <strong>CURRENT SCORE:</strong> {data.score} points
            </p>
            <p>
              <strong>LAST SOLVE:</strong> {timeAgo(data.last_attempt)}
            </p>
          </>
        )}
      </Section>
    </div>
  );
}
