import React from 'react';

import { faBan, faPlay, faTrash } from '@fortawesome/pro-solid-svg-icons';

import ConfirmDialog from 'snap-ui/Dialog/ConfirmDialog';
import { IconMenuItem, IconMenuItemProps } from 'snap-ui/MenuItem';

import { AgentInterface } from 'aso/useAgents';

import { usePushSnack } from 'provider/Snack';

import { BulkLaunchBASFormValues } from 'types/bas';

import { BASCampaign, BASCampaignFeed } from '../BAS.type';
import CampaignRerunDialog from './CampaignRerunDialog';
import { CampaignInterface } from './useCampaignUtils';

type MenuItem = Omit<IconMenuItemProps, 'onClick' | 'icon' | 'text'>;

const DialogType = {
  Cancel: 'Cancel',
  Delete: 'Delete',
  Rerun: 'Rerun'
} as const;

type DialogType = keyof typeof DialogType;

type RerunDialog = {
  onSuccess(newCampaign: BASCampaign): void;
};

type CancelDialog = {
  onSuccess(success: boolean): void;
};

type DeleteDialog = {
  onSuccess(success: boolean): void;
};

type CampaignActionInterfaceProps = {
  agentInterface: AgentInterface;
  campaign: BASCampaign | BASCampaignFeed;
  campaignInterface: CampaignInterface;
  children: React.ReactNode;
};

export interface CampaignActionInterface {
  agentInterface: AgentInterface;
  campaign: BASCampaign | BASCampaignFeed;
  campaignInterface: CampaignInterface;
  open: DialogType;
  setOpen(type: DialogType): void;
}

const CampaignActionContext = React.createContext<CampaignActionInterface>(null);

export function CampaignActionInterface({
  agentInterface,
  campaign,
  children,
  campaignInterface
}: CampaignActionInterfaceProps) {
  const [open, setOpen] = React.useState<DialogType>(null);
  return (
    <CampaignActionContext.Provider value={{ agentInterface, campaign, campaignInterface, open, setOpen }}>
      {children}
    </CampaignActionContext.Provider>
  );
}

function useCampaignAction() {
  const context = React.useContext(CampaignActionContext);
  if (!context) {
    throw new Error('useCampaignAction must be used within an CampaignActionInterface');
  }
  return context;
}

export function RerunMenuItem(props: MenuItem) {
  const { setOpen } = useCampaignAction();
  return (
    <IconMenuItem onClick={() => setOpen(DialogType.Rerun)} icon={faPlay} text='Rerun attack simulation' {...props} />
  );
}

export function RerunDialog(props: RerunDialog) {
  const { agentInterface, campaign, open, setOpen, campaignInterface } = useCampaignAction();
  const { rerun } = campaignInterface;

  function handleRerun(payload: BulkLaunchBASFormValues) {
    setOpen(null);
    return rerun(payload);
  }

  return (
    <CampaignRerunDialog
      agentInterface={agentInterface}
      onClose={() => setOpen(null)}
      open={open === DialogType.Rerun}
      campaign={campaign}
      onRerun={handleRerun}
      {...props}
    />
  );
}

export function CancelMenuItem(props: MenuItem) {
  const { setOpen } = useCampaignAction();
  return (
    <IconMenuItem onClick={() => setOpen(DialogType.Cancel)} icon={faBan} text='Cancel pending attacks' {...props} />
  );
}

export function CancelDialog(props: CancelDialog) {
  const pushSnack = usePushSnack();
  const { campaign, open, setOpen, campaignInterface } = useCampaignAction();
  const { cancel } = campaignInterface;

  function handleCancel() {
    setOpen(null);
    pushSnack('Attacks canceled.', 'info', 'center', 'bottom', 3000);
    return cancel();
  }

  return (
    <ConfirmDialog
      ConfirmProps={{
        children: 'Cancel Attacks',
        onClick: () => handleCancel().then(props.onSuccess)
      }}
      DialogProps={{ open: open === DialogType.Cancel, onClose: () => setOpen(null) }}
      title={`Cancel ${campaign.name}`}
    >
      All pending attack scripts will be cancelled. Attacks that are in progress or already completed will not be
      affected.
    </ConfirmDialog>
  );
}

export function DeleteMenuItem(props: MenuItem) {
  const { setOpen } = useCampaignAction();
  return (
    <IconMenuItem
      onClick={() => setOpen(DialogType.Delete)}
      icon={faTrash}
      text='Delete attack simulation'
      {...props}
    />
  );
}

export function DeleteDialog(props: DeleteDialog) {
  const pushSnack = usePushSnack();
  const { campaign, open, setOpen, campaignInterface } = useCampaignAction();
  const { deleteCampaign } = campaignInterface;

  function handleDelete() {
    setOpen(null);
    pushSnack('Attack simulation deleted.', 'info', 'center', 'bottom', 3000);
    return deleteCampaign();
  }

  return (
    <ConfirmDialog
      ConfirmProps={{
        children: 'Delete Attack Simulation',
        onClick: () => handleDelete().then(props.onSuccess)
      }}
      DialogProps={{ open: open === DialogType.Delete, onClose: () => setOpen(null) }}
      title={`Delete ${campaign.name}`}
    >
      Are you sure you want to delete {campaign.name}?
    </ConfirmDialog>
  );
}
