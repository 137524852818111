import React from 'react';

import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import Icon from 'snap-ui/Icon';
import { LinkProps } from 'snap-ui/Link';

import { Clickable } from 'module/Layout/Styled';

import { RAW_MARKDOWN, RICH_MARKDOWN } from './Markdown.mapping';
import { MarkdownReadWrapper, PrimaryLink } from './MarkdownRead.style';

type Props = {
  className?: string;
  id?: string;
  /**
   * If true, the content will render all markdown as raw text
   */
  raw?: boolean;
  /**
   * If true, the content is truncated when more than 96px. A show more show less button is provided to toggle overflow.
   */
  restricted?: boolean;
  /**
   * If existing and content is restricted, show more will become a link opening the item in a new tab
   */
  to?: LinkProps['to'];
  value: string;
};

const MarkdownRead = ({ className, id, restricted, raw, to, value }: Props) => {
  const [truncateValue, setTruncateValue] = React.useState(restricted);
  const [showButtons, setShowButtons] = React.useState(false);

  const calcShouldShowButtons: React.LegacyRef<HTMLDivElement> = node => {
    setShowButtons(node?.scrollHeight > node?.clientHeight + 1);
  };

  return (
    <>
      <MarkdownReadWrapper
        id={id}
        ref={calcShouldShowButtons}
        className={classnames('MarkdownRead', restricted && truncateValue && 'restrict', className)}
      >
        <MarkdownText raw={raw} value={cleanMarkdown(value)} />
      </MarkdownReadWrapper>
      {!to && restricted && showButtons && truncateValue && (
        <Clickable onClick={() => setTruncateValue(false)}>Show More</Clickable>
      )}
      {!to && restricted && !truncateValue && <Clickable onClick={() => setTruncateValue(true)}>Show Less</Clickable>}
      {!!to && restricted && showButtons && (
        <PrimaryLink to={to} target='_blank' rel='noopener noreferrer'>
          <Icon icon={faExternalLinkAlt} /> See more
        </PrimaryLink>
      )}
    </>
  );
};

export default MarkdownRead;

export function MarkdownText({ raw, value }: Props) {
  return (
    <ReactMarkdown components={raw ? RAW_MARKDOWN : RICH_MARKDOWN} remarkPlugins={[gfm]}>
      {value}
    </ReactMarkdown>
  );
}

/*
 * Deal with the oddities and messy data from various input sources
 * Please update tests as we add new filters
 */
export function cleanMarkdown(value: string | void) {
  return (value || '')
    .replaceAll(/\n\u00A0\n/g, '') // Removes unicode non-breaking space (U+00A0) between new lines
    .replaceAll(/\u00A0/g, ' ') // Replace any remaining non-breaking spaces (U+00A0) with regular space
    .replaceAll(/\n{2,}/g, '\n\n') // Removes extra newlines (more than two in a row)
    .replaceAll('“', '"') // Removes smart quote U+201C
    .replaceAll('”', '"') // Removes smart quote U+201D
    .replaceAll('’', "'") // Removes smart apostrophes U+2019, then below remove MITRE Citations, e.g., (Citation: cobaltstrike manual)
    .replaceAll(/\(Citation:([^\)]+)\)/gi, ''); // eslint-disable-line no-useless-escape
}
