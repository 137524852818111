import React from 'react';

import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { LandingArtifact } from 'module/Landing/Landing.type';

import { TargetIndustryDetails } from 'types/mati';

import { sortByPrioritizedList } from 'utilities/ArrayUtils';

export const Container = styled('div', { label: 'TargetedCountries' })`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;

  .MuiChip-label {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
  }
  .remaining {
    line-height: 1;
  }
`;

const targetIndustries = Object.keys(TargetIndustryDetails);
export default function TargetedIndustries({ industries }: { industries: LandingArtifact['industries'] }) {
  const sorted = (industries || [])?.map(industry => industry.name).sort(sortByPrioritizedList(targetIndustries));

  const display = sorted.slice(0, 5);
  const remaining = sorted.length - display.length;

  return (
    <Container>
      {isEmpty(display) && <Chip size='small' label='No Targeted Industries Listed' />}
      {display.map((industry: keyof typeof TargetIndustryDetails) => {
        return (
          <Tooltip key={industry} arrow title={industry} wrap>
            <Icon icon={TargetIndustryDetails[industry]?.icon} />
          </Tooltip>
        );
      })}
      {remaining > 0 && (
        <Chip
          className='remaining'
          size='small'
          label={
            <>
              <Icon icon={faCirclePlus} /> <span>{remaining}</span>
            </>
          }
        />
      )}
    </Container>
  );
}
