import React from 'react';

import set from 'lodash/set';
import { z } from 'zod';

import Alert from 'snap-ui/Alert';
import { FormDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import RadioGroupFormik from 'module/Form/RadioGroupFormik';

import { Status, useAsync } from 'storage';

import { ArtifactType, Guid } from 'types/common';

import { massAddTag, massRemTag } from '../Tag.api';
import { MassTagPayload } from '../Tag.type';
import { BulkCatalogFormik } from './BulkCatalogFormik';

export const BulkTagFormSchema = z
  .object({
    op: z.enum(['add', 'rem']),
    actor_names: z.array(z.string()).optional(),
    attack_names: z.array(z.string()).optional(),
    software_names: z.array(z.string()).optional(),
    vulnerability_names: z.array(z.string()).optional(),
    catalog: z.string().optional()
  })
  .refine(
    schema =>
      schema.actor_names.length <= 0 &&
      schema.attack_names.length <= 0 &&
      schema.software_names.length <= 0 &&
      schema.vulnerability_names.length <= 0
        ? false
        : true,
    {
      message: 'Select at least one item from the above lists',
      path: ['catalog']
    }
  );

export type BulkTagInput = Required<z.infer<typeof BulkTagFormSchema>>;

const Container = styled('div')`
  margin: ${p => p.theme.spacing(2)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(4)};

  .Alert {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  .BulkTag-catalog {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(4)};
  }
`;

export type BulkTag = {
  isOpen: boolean;
  onClose(): void;
  selections: Guid[];
  /**
   * @param topic Only applicable to ArtifactType.Analytic, ArtifactType.Collection, ArtifactType.Intel
   */
  topic: ArtifactType;
};

export function _BulkTag(props: BulkTag) {
  const { isOpen, onClose, selections } = props;
  const { task, status } = useAsync();

  const handleSubmit = (values: BulkTagInput) => {
    const payload: MassTagPayload = {
      actor_names: values.actor_names,
      attack_names: values.attack_names,
      software_names: values.software_names,
      vulnerability_names: values.vulnerability_names,
      detections: [],
      collections: [],
      intelligence: []
    };
    switch (props.topic) {
      case ArtifactType.Analytic:
        set(payload, 'detections', selections);
        break;
      case ArtifactType.Collection:
        set(payload, 'collections', selections);
        break;
      case ArtifactType.Intel:
        set(payload, 'intelligence', selections);
        break;
    }
    const func = values.op === 'add' ? massAddTag : massRemTag;
    task(func(payload)).then(onClose, e => console.log(e));
  };

  return (
    <FormDialog
      title='Manage Tags'
      DialogProps={{ open: isOpen, onClose }}
      SubmitProps={{
        children: <>Submit {status === Status.pending && <Icon.SpinnerProgress />}</>,
        disabled: selections.length <= 0 || status === Status.pending
      }}
      FormikConfig={{
        initialValues: {
          op: null,
          actor_names: [],
          attack_names: [],
          software_names: [],
          vulnerability_names: [],
          catalog: ''
        },
        onSubmit: handleSubmit,
        zodSchema: BulkTagFormSchema
      }}
    >
      <Container className='BulkTag'>
        {selections.length <= 0 && (
          <Alert severity='warning'>You selected nothing! Go back and select one or more items</Alert>
        )}
        {selections.length > 0 && (
          <Alert severity='info'>
            Tags will not be applied to content that is not owned by your organization. Use metadata to create and
            manage your own tags. Changes may take up to 1 minute to appear and the feed will need to be manually
            refreshed.
          </Alert>
        )}
        <RadioGroupFormik
          label={`Bulk Operation for ${selections.length} selected item${selections.length === 1 ? '' : 's'}`}
          name='op'
          row
          options={[
            {
              label: 'Add tags',
              value: 'add'
            },
            {
              label: 'Remove tags',
              value: 'rem'
            }
          ]}
        />
        <BulkCatalogFormik />
      </Container>
    </FormDialog>
  );
}

export function BulkTag(props: BulkTag) {
  if (!props.isOpen) return null;
  return <_BulkTag {...props} />;
}
