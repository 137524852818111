import React from 'react';

import { RJSFSchema } from '@rjsf/utils';

import Divider from 'snap-ui/Divider';

import { Guid } from 'types/common';

import { LanguageOption } from '../Integration.type';
import IntegrationManualImport from '../IntegrationManualImport';
import IntegrationScheduledImport from '../IntegrationScheduledImport';
import DeploymentField from './DeploymentField';
import ImportField from './ImportField';
import SaveButton from './SaveButton';

type DeploymentTabProps = {
  integrationGuid?: Guid;
  languageOptionLists: LanguageOption;
  deploymentSchema: RJSFSchema;
  importSchema: RJSFSchema;
};

function DeploymentTab({ languageOptionLists, integrationGuid, deploymentSchema, importSchema }: DeploymentTabProps) {
  return (
    <>
      <DeploymentField languageOptionLists={languageOptionLists} integrationSchema={deploymentSchema} />
      <DetectionImport integrationGuid={integrationGuid} />
      <ImportField integrationSchema={importSchema} />
      <SaveButton />
    </>
  );
}

export default DeploymentTab;

function DetectionImport({ integrationGuid }: { integrationGuid: Guid }) {
  if (!integrationGuid) return null;
  return (
    <>
      <Divider textAlign='left'>Detection Import</Divider>
      <p>
        Sync native detections from this integration manually or automatically with SnapAttack. Imported detections will
        be tracked, version controlled, and reported on for MITRE ATT&CK coverage and other reports.
      </p>
      <IntegrationManualImport integration_id={integrationGuid} />
      <IntegrationScheduledImport integration_id={integrationGuid} />
    </>
  );
}
