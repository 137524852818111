import { ArtifactType } from 'types/common';

import { Feed } from './Feed.type';

export const FEED_ENDPOINT: Partial<Record<ArtifactType, string>> = {
  [ArtifactType.Intel]: '/search/intel/query/',
  [ArtifactType.Session]: '/search/sessions/query/',
  [ArtifactType.AttackScript]: '/search/bas/script/',
  [ArtifactType.Analytic]: '/search/signatures/query/',
  [ArtifactType.Collection]: '/search/collections/query/'
};

export const FEED_SKELETON: Feed = {
  items: [],
  total: 0,
  page: 0,
  pageTotal: 0,
  size: 0,
  lastUsedQuery: null
};
