import React from 'react';

import omit from 'lodash/omit';

import { SetState } from 'types/core';

type Store = Record<string, unknown>;
type Setter<T> = (value: T) => void;
type SetStore = SetState<Store>;
type Context = [Store, SetStore];
export type { Store as FilterContextStore };

const GlobalFilterContext = React.createContext<Context>(null);
GlobalFilterContext.displayName = 'GlobalFilterContext';

export function GlobalFilterContextProvider({ children }: React.PropsWithChildren<Record<never, never>>): JSX.Element {
  const [store, setStore] = React.useState({});
  return <GlobalFilterContext.Provider value={[store, setStore]}>{children}</GlobalFilterContext.Provider>;
}

export function useGlobalFilterContext(): [Store, SetStore] {
  const context = React.useContext(GlobalFilterContext);
  if (!context) throw new Error('useGlobalFilterContext used outside GlobalFilterContext');
  return context;
}

/**
 * Use this hook to set filter context from an arbitrary application component. That context can then be used in a
 * filter's toQuery method to modify the resulting query.
 *
 * Example:
 * The By Recommendation filter may use the feed's "recommendation_score" field, or it may used a
 * precomputed list of guids, depending on the application context. For that use case, you would have:
 *
 * In the Recommender component:
 *
 * const { recommendations } = useRecommendations()
 * const [, setRecommendationContext] = useGlobalFilterContextValue<Recommendations>('recommendations')
 *
 * React.useEffect(() => {
 *  setRecommendationContext(recommendations)
 * }, [recommendations, setRecommendationContext])
 *
 *
 * In the Recommendation filter config. Note that you have no guarantees here about the types of the values in the
 * filter context, so you need to do a lot of type checking. Here I'm expecting filterContext.recommendations to be
 * Record<ArtifactType, Guid[]> (you could also use Zod for this, which might be helpful for more complex structures):
 *
 * function toQuery(values, augment, filterContext) {
 *  const contextValue = filterContext[recommendations]
 *  if (
 *  contextValue
 *  && typeof contextValue === 'object'
 *  && values.topic in contextValue
 *  && Array.isArray(contextValue[values.topic])
 *  ) {
 *    return {
 *      field: 'guid',
 *      op: Ops.in,
 *      value: contextValue[values.topic].filter(v => typeof v === 'string')
 *    }
 *  } else {
 *    return {
 *      field: 'recommendation_score',
 *      op: Ops.greaterthan,
 *      value: 0.75
 *    }
 *  }
 * }
 *
 * Context values will be erased when the setting component unmounts.
 */
export function useGlobalFilterContextValue<T>(key: string): [unknown, Setter<T>] {
  const context = React.useContext(GlobalFilterContext);
  if (!context) throw new Error('useGlobalFilterContext used outside GlobalFilterContext');
  const [store, setStore] = context;

  const setter = React.useCallback(
    (value: T) => {
      setStore((s: Store) => ({
        ...s,
        [key]: value
      }));
    },
    [key, setStore]
  );

  React.useEffect(() => {
    // remove `key` from store on dismount
    return () => {
      setStore(s => omit(s, key));
    };
  }, [key, setStore]);

  return [store[key], setter];
}
