import React from 'react';

import { ErrorProps } from 'module/ApiError';
import { getSearchPage } from 'module/Feed/Feed.service';
import { Page } from 'module/Feed/Feed.type';
import { FilterValues, useFilterRegistry } from 'module/GlobalFilter';
import { DetectionDeploymentStatus } from 'module/GlobalFilter/Filters/DeployedEnvironment';
import { Integration } from 'module/Integration/Integration.type';
import { useMayI } from 'module/May';
import useSupplementalItems, { SupplementalInterface } from 'module/Search/useSupplementalItems';

import { useAuth, useIntegrationCatalog } from 'provider';

import { Meta, Status, useAsync } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Artifact, ArtifactScore, ArtifactScoreDetail, ArtifactType, Ident } from 'types/common';
import { Query } from 'types/filter';

import { getScoreDetails } from 'utilities/ArtifactUtils';

export type ItemBase = { deploymentId: Ident; detection: Artifact; integration: Integration };

export type AsyncInterface<T extends ItemBase = ItemBase> = {
  items: T[];
  status: Status;
  total: number;
  errorProps?: ErrorProps;
};

export type DeployedFeeds = {
  healthy: AsyncInterface<never>;
  deploymentErrors: AsyncInterface<
    ItemBase & {
      error_message: string;
    }
  >;
  needTuning: AsyncInterface<
    ItemBase & {
      confidenceDetails: ArtifactScoreDetail;
    }
  >;
  outdatedDeployments: AsyncInterface<
    ItemBase & {
      versionId: Ident;
    }
  >;
  supplemental: SupplementalInterface;
  willFetchSupplemental: boolean;
};

const SIZE = 1000;

const QUERIES = {
  HEALTHY: {
    deployedStatus: DetectionDeploymentStatus.success
  },
  DEPLOYMENT_ERRORS: {
    deployedStatus: DetectionDeploymentStatus.failed
  },
  OUTDATED: {
    deployedStatus: DetectionDeploymentStatus.outdated
  },
  TUNABLE: {
    deployedStatus: DetectionDeploymentStatus.success,
    confidence: [ArtifactScore.MEDIUM, ArtifactScore.LOW, ArtifactScore.LOWEST]
  }
};

export default function useDeployedFeeds(): DeployedFeeds {
  const { defaultOrgId } = useAuth();
  const integrationCatalog = useIntegrationCatalog();
  const { generateQuery } = useFilterRegistry();
  const canTune = useMayI(FunctionalPermission.Tuning);
  const { run: hdRun, ...healthyDetections } = useAsync<Page<Artifact>>();
  const { run: deRun, ...deploymentErrors } = useAsync<Page<Artifact>>();
  const { run: ntRun, ...needTuning } = useAsync<Page<Artifact>>();
  const { run: odRun, ...outdatedDeployments } = useAsync<Page<Artifact>>();

  const allItems = React.useMemo(() => {
    // "if all requests are finished"
    if (
      ![deploymentErrors.status, canTune ? needTuning.status : Status.resolved, outdatedDeployments.status].some(
        status => [Status.idle, Status.pending].includes(status)
      )
    ) {
      return Array.from(
        new Set([
          ...mapToGuids(deploymentErrors.data),
          ...mapToGuids(canTune ? needTuning.data : { items: [] }),
          ...mapToGuids(outdatedDeployments.data)
        ])
      ).map(guid => ({ guid }));
    }
    return [];
  }, [
    canTune,
    deploymentErrors.data,
    deploymentErrors.status,
    needTuning.data,
    needTuning.status,
    outdatedDeployments.data,
    outdatedDeployments.status
  ]);
  // do not use `!!allItems.legnth` for performance reasons
  const willFetchSupplemental =
    !!deploymentErrors.data?.items?.length ||
    !!outdatedDeployments.data?.items?.length ||
    (canTune && !!needTuning.data?.items.length);

  const supplemental = useSupplementalItems(ArtifactType.Analytic, allItems);

  const hdQuery = React.useMemo(() => getStringifiedQuery(generateQuery, QUERIES.HEALTHY), [generateQuery]);

  const deQuery = React.useMemo(() => getStringifiedQuery(generateQuery, QUERIES.DEPLOYMENT_ERRORS), [generateQuery]);

  const odQuery = React.useMemo(() => getStringifiedQuery(generateQuery, QUERIES.OUTDATED), [generateQuery]);

  const ntQuery = React.useMemo(() => getStringifiedQuery(generateQuery, QUERIES.TUNABLE), [generateQuery]);

  React.useEffect(() => {
    if (hdQuery) hdRun(runQuery(hdQuery, 0));
  }, [hdRun, hdQuery]);

  React.useEffect(() => {
    if (deQuery) deRun(runQuery(deQuery));
  }, [deRun, deQuery]);

  React.useEffect(() => {
    if (canTune && ntQuery) ntRun(runQuery(ntQuery));
  }, [canTune, ntRun, ntQuery]);

  React.useEffect(() => {
    if (odQuery) odRun(runQuery(odQuery));
  }, [odRun, odQuery]);

  return {
    healthy: pickAsyncInterface(healthyDetections, () => [] as never[]),
    deploymentErrors: React.useMemo(
      () =>
        pickAsyncInterface(deploymentErrors, items =>
          items.flatMap(item => {
            const sup = supplemental.supplemental[item.guid];
            if (!sup) return [];
            return sup.deployments.flatMap(deployment =>
              deployment.failed_deployment.map(fd => ({
                deploymentId: deployment.id,
                detection: item,
                integration: integrationCatalog.integrations.all.find(i => i.guid === fd.guid),
                error_message: deployment.status.find(status => status.integration === fd.guid && !status.success)
                  ?.error_message
              }))
            );
          })
        ),
      [deploymentErrors, integrationCatalog.integrations.all, supplemental]
    ),
    needTuning: React.useMemo(
      () =>
        pickAsyncInterface(needTuning, items =>
          items.flatMap(item => {
            const sup = supplemental.supplemental[item.guid];
            if (!sup) return [];
            return sup.deployments.flatMap(deployment =>
              deployment.success_deployment.map(sd => ({
                deploymentId: deployment.id,
                detection: item,
                integration: integrationCatalog.integrations.all.find(i => i.guid === sd.guid),
                confidenceDetails: getScoreDetails(defaultOrgId, sup?.ranks)
              }))
            );
          })
        ),
      [defaultOrgId, needTuning, integrationCatalog.integrations.all, supplemental]
    ),
    outdatedDeployments: React.useMemo(
      () =>
        pickAsyncInterface(outdatedDeployments, items =>
          items.flatMap(item => {
            const sup = supplemental.supplemental[item.guid];
            if (!sup) return [];
            return sup.deployments
              .filter(deployment => !deployment.is_current && deployment.organization_id === defaultOrgId)
              .flatMap(deployment =>
                deployment.integrations.map(integration => ({
                  deploymentId: deployment.id,
                  versionId: deployment.analytic_version_id,
                  detection: item,
                  integration: {
                    ...integrationCatalog.integrations.all.find(i => i.guid === integration.guid),
                    compatible: sup.deployable_integrations.some(i => i.guid === integration.guid)
                  }
                }))
              );
          })
        ),
      [defaultOrgId, outdatedDeployments, integrationCatalog.integrations.all, supplemental]
    ),
    supplemental,
    willFetchSupplemental
  };
}

function getStringifiedQuery(generateQuery: (a: ArtifactType, q: FilterValues) => Query, query: FilterValues): string {
  const q = generateQuery(ArtifactType.Analytic, query);
  return q && JSON.stringify(q);
}

function runQuery(query: string, size = SIZE) {
  return getSearchPage(ArtifactType.Analytic, 1, size, JSON.parse(query));
}

function pickAsyncInterface<T extends ItemBase = ItemBase>(
  meta: Meta<Page<Artifact>>,
  mapper: (items: Artifact[]) => T[] = i => i.map(detection => ({ detection } as T))
): AsyncInterface<T> {
  return {
    items: mapper(meta.data?.items || []),
    status: meta.status,
    total: meta.data?.total,
    errorProps: meta.errorProps
  };
}

function mapToGuids(data?: Pick<Page<Artifact>, 'items'>): string[] {
  return data?.items?.map(i => i.guid) || [];
}
