import React from 'react';

import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import useQueryString from 'hooks/useQueryString';

import { CardHeaderContainer, CardTitleContainer } from 'module/Card/Card.style';
import { cleanHTML } from 'module/Card/Card.util';
import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { FirstSeen, LastSeen } from 'module/Widgets/MATI/Seen';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';

import { getQueryParam } from 'utilities/SearchParam';
import { highlightSearchTerm, truncateMiddle } from 'utilities/StringUtils';

import { IOCArtifact } from './IOC.type';
import { IOCScoreContainer, IOCCardContainer } from './IOCCard.style';

type IOCCardProps = {
  ioc: IOCArtifact;
};

function IOCCardHeader({ ioc }: IOCCardProps) {
  const { permission: organizations } = useAuth();
  return (
    <CardHeaderContainer className='CardHeader'>
      <Typography variant='h5'>
        {getArtifactIcon(ArtifactType.Indicator)}
        {ioc.type} {' | '}
        {ioc && <>{organizations.find(o => o.id === ioc.organization_id)?.name || 'Private Organization'} </>}
      </Typography>
    </CardHeaderContainer>
  );
}
function IOCCard({ ioc }: IOCCardProps) {
  const { search } = useQueryString();
  const query = getQueryParam(search, 'query');
  const truncatedIndicatorName = truncateMiddle(20, ioc.name);
  const [, isTruncated] = truncatedIndicatorName;

  return (
    <IOCCardContainer className='Card item'>
      <IOCCardHeader ioc={ioc} />
      <div className='row'>
        <IOCScoreContainer>{ioc.score}</IOCScoreContainer>
        <Tooltip title={isTruncated ? ioc.name : null} placement='bottom' wrap arrow>
          <CardTitleContainer className='CardTitle'>
            <div
              className='highlight'
              dangerouslySetInnerHTML={{
                __html: cleanHTML(highlightSearchTerm(query, truncatedIndicatorName))
              }}
            />
          </CardTitleContainer>
        </Tooltip>
      </div>
      <div className='row'>
        <FirstSeen timestamp={ioc.first_seen} />
        <LastSeen timestamp={ioc.last_seen} />
      </div>
    </IOCCardContainer>
  );
}

export default IOCCard;
