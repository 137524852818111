import React from 'react';

import omit from 'lodash/omit';

import { CancelToken, CancelTokenSourceType } from 'apis';

import useDebounce from 'hooks/useDebounce';

import { useIntegrationCatalog } from 'provider';

import { Status, useAsync } from 'storage';

import { Guid, PageParams } from 'types/common';

import { cancelJob, deleteJob, getJobOverview, pauseJob, resumeJob } from './Job.api';
import { JobOverview, JobOverviewFeed, JobOverviewFeedAsync, JobOverviewFilters, JobType } from './Job.type';

export default function useJobOverview(
  types: JobType[],
  pageParams: PageParams,
  filters: JobOverviewFilters
): JobOverviewFeedAsync & {
  deleteJobs(guid: Guid[]): Promise<void>;
  pause(guid: Guid[]): Promise<void>;
  resume(guid: Guid[]): Promise<void>;
  cancel(guid: Guid[]): Promise<void>;
  taskStatus: Status;
} {
  const { integrations } = useIntegrationCatalog();
  const { data, run: jobsRun, errorProps: jobErrorProps, status } = useAsync<JobOverviewFeed>();
  const { task, status: taskStatus } = useAsync();
  const [enhancedJobs, setEnhancedJobs] = React.useState<JobOverview[]>([]);

  const config = React.useMemo(
    () => ({
      type: types,
      ...pageParams,
      ...omit(filters, 'sort'),
      sort_by: filters.sort
    }),
    [filters, pageParams, types]
  );

  const debouncedConfig = useDebounce(config);

  const fetchFeed = React.useCallback(
    (cancelSource?: CancelTokenSourceType) => {
      jobsRun(getJobOverview(debouncedConfig, cancelSource));
    },
    [debouncedConfig, jobsRun]
  );

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    fetchFeed(cancelSource);
    return () => {
      cancelSource.cancel('User made new selection');
    };
  }, [fetchFeed]);

  React.useEffect(() => {
    const jobGroupOccurrences = data?.items.reduce((acc: Record<Guid, Guid[]>, job) => {
      if (!acc[job.analytic_job_group_guid]) {
        acc[job.analytic_job_group_guid] = [];
      }
      acc[job.analytic_job_group_guid].push(job.analytic_job_group_guid);
      return acc;
    }, {});

    const enhancedJobsData: JobOverview[] = data?.items.map(job => ({
      ...job,
      jobsByGroup:
        jobGroupOccurrences[job.analytic_job_group_guid]?.length > 1
          ? jobGroupOccurrences[job.analytic_job_group_guid]
          : null
    }));

    setEnhancedJobs(enhancedJobsData);
  }, [integrations.all, data]);

  const deleteJobs = React.useCallback(
    (guids: Guid[]) => task(Promise.all(guids.map(g => deleteJob(g)))).then(() => fetchFeed()),
    [fetchFeed, task]
  );

  const pause = React.useCallback(
    (guids: Guid[]) => task(Promise.all(guids.map(g => pauseJob(g)))).then(() => fetchFeed()),
    [fetchFeed, task]
  );

  const resume = React.useCallback(
    (guids: Guid[]) => task(Promise.all(guids.map(g => resumeJob(g)))).then(() => fetchFeed()),
    [fetchFeed, task]
  );

  const cancel = React.useCallback(
    (guid: Guid[]) => task(Promise.all(guid.map(g => cancelJob(g)))).then(() => fetchFeed()),
    [fetchFeed, task]
  );

  return {
    ...data,
    items: enhancedJobs,
    status,
    error: jobErrorProps,
    deleteJobs,
    pause,
    resume,
    cancel,
    taskStatus
  };
}
