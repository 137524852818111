import * as React from 'react';

import { faEdit, faSearch, faTrash } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import escapeRegExp from 'lodash/escapeRegExp';

import Button, { ActionIconButton } from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import Table, { TablePlaceholder } from 'snap-ui/Table';
import TableSortLabel from 'snap-ui/TableSortLabel';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import usePaginate from 'hooks/usePaginate';
import useSort from 'hooks/useSort';
import useTitle from 'hooks/useTitle';

import May from 'module/May';
import TablePagination from 'module/Widgets/TablePagination';

import { useAuth, usePushSnack } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission, Group } from 'types/auth';
import { SortDirection } from 'types/filter';

import { TableSearchInformationalMessage } from '../Core.style';
import EntitlementFormDialog from './EntitlementFormDialog';
import { BASE_PACKAGES } from './Entitlements.const';
import { useEntitlements } from './Entitlements.service';
import { Container } from './Entitlements.style';
import { Entitlement, EntitlementPayload } from './Entitlements.type';

export type EntitlementsProps = {
  className?: string;
};

function Entitlements({ className }: EntitlementsProps) {
  useTitle('Entitlement Management | SnapAttack');

  const PAGE_SIZE = 15;
  const pushSnack = usePushSnack();

  const { user } = useAuth();
  const {
    entitlements,
    create: createEntitlement,
    resetError: resetEntitlementError,
    update: updateEntitlement,
    delete: deleteEntitlement,
    status: entitlementsStatus,
    errorProps: entitlementsErrorProps
  } = useEntitlements();

  const [filteredData, setFilteredData] = React.useState(entitlements);
  const { createSortHandler, order, sortedData } = useSort<Entitlement>(filteredData, {
    direction: SortDirection.asc,
    field: 'name'
  });
  const { page, pageData, pageTotal, handleChangePage } = usePaginate<Entitlement>(sortedData, PAGE_SIZE);

  const [editorEntitlement, setEditorEntitlement] = React.useState<Entitlement>(null);
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  React.useEffect(() => {
    // make sure API errors are reset when opening the editor
    if (showEditDialog) resetEntitlementError();
  }, [resetEntitlementError, showEditDialog]);

  // BEGIN filter table methods
  const [searchEntitlement, setSearchEntitlement] = React.useState('');
  function handleSearchEntitlementChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchEntitlement(event.target.value);
  }

  React.useEffect(() => {
    const searchEntitlementRE = new RegExp(escapeRegExp(searchEntitlement), 'i');
    setFilteredData(
      entitlements.filter(
        org =>
          org.name.match(searchEntitlementRE) ||
          org.description.match(searchEntitlementRE) ||
          org.groups.some(group => group.name.match(searchEntitlementRE)) ||
          org.rights.some(right => right.match(searchEntitlementRE))
      )
    );
  }, [searchEntitlement, entitlements]);
  // END filter table methods

  // BEGIN add/edit modal methods
  function handleOpenNewEntitlementForm() {
    setEditorEntitlement(null);
    setShowEditDialog(true);
  }

  function handleEdit(entitlement: Entitlement) {
    return function () {
      setEditorEntitlement(entitlement);
      setShowEditDialog(true);
    };
  }

  function handleCloseEditor() {
    setEditorEntitlement(null);
    setShowEditDialog(false);
    resetEntitlementError();
  }

  async function handleSubmitEditor(values: EntitlementPayload) {
    if (editorEntitlement) {
      await updateEntitlement(editorEntitlement.guid, values);
    } else {
      await createEntitlement(values);
    }
    handleCloseEditor();
    pushSnack(`${values.name} ${editorEntitlement ? 'updated' : 'created'}.`, 'info', 'center', 'bottom', 5000);
  }

  // END add/edit modal methods

  // BEGIN delete modal methods
  function handleOpenDeleteDialog(entitlement: Entitlement) {
    return function () {
      setEditorEntitlement(entitlement);
      setShowDeleteDialog(true);
    };
  }
  function handleCloseDeleteDialog() {
    setEditorEntitlement(null);
    setShowDeleteDialog(false);
  }

  async function handleDelete() {
    if (editorEntitlement?.guid) {
      await deleteEntitlement(editorEntitlement.guid);
    }
    handleCloseDeleteDialog();
    pushSnack(`${editorEntitlement.name} deleted.`, 'info', 'center', 'bottom', 5000);
  }
  // END delete modal methods

  // BEGIN table render methods
  function renderGroups(groups: Group[]): React.ReactNode {
    return (
      <div className='groups'>
        <ul>
          {groups.map(group => (
            <li key={group.guid}>{group.name}</li>
          ))}
        </ul>
      </div>
    );
  }

  function renderRights(rights: string[]): React.ReactNode {
    return (
      <div className='rights'>
        <ul>
          {rights.map(right => (
            <li key={right}>{right}</li>
          ))}
        </ul>
      </div>
    );
  }

  function renderActions(entitlement: Entitlement): React.ReactNode {
    // Allow BASE_PACKAGES to be edited, but prevent accidental deletion via frontend
    return (
      <div className='actions-column'>
        <May I={FunctionalPermission.CreateGroup}>
          <Tooltip placement='left' arrow title={`Edit ${entitlement.name}`}>
            <ActionIconButton aria-label={`Edit ${entitlement.name}`} icon={faEdit} onClick={handleEdit(entitlement)} />
          </Tooltip>
        </May>
        {!BASE_PACKAGES.includes(entitlement.guid) && (
          <May I={FunctionalPermission.CreateGroup}>
            <Tooltip placement='left' arrow title={`Delete ${entitlement.name}`}>
              <ActionIconButton
                aria-label={`Delete ${entitlement.name}`}
                icon={faTrash}
                onClick={handleOpenDeleteDialog(entitlement)}
              />
            </Tooltip>
          </May>
        )}
      </div>
    );
  }
  //END table render methods

  return (
    <Container className={classnames('Entitlements', className)}>
      <Typography variant='h1'>Entitlement Management</Typography>
      <div className='contents'>
        <Paper className='table-container'>
          <div className='table-toolbar'>
            <TextField
              className='search-field'
              onChange={handleSearchEntitlementChange}
              placeholder='Search any field'
              startAdornment={<Icon icon={faSearch} />}
            />
            {user.superuser && (
              <Button variant='outlined' onClick={handleOpenNewEntitlementForm}>
                Add Entitlement
              </Button>
            )}
          </div>
          <Table
            aria-label='Entitlements'
            component='div'
            columns={[
              <TableSortLabel
                key='name'
                field='name'
                label='Name'
                sortHandler={createSortHandler('name')}
                order={order}
              />,
              'Description',
              'Groups',
              'Rights',
              'Actions'
            ]}
            rows={pageData.map(entitlement => [
              entitlement.name,
              entitlement.description,
              renderGroups(entitlement.groups),
              renderRights(entitlement.rights),
              renderActions(entitlement)
            ])}
          />
          {entitlementsStatus === Status.pending && pageData?.length < 1 ? (
            <TablePlaceholder count={10} height={40} />
          ) : (
            <>
              {pageData?.length < 1 && (
                <TableSearchInformationalMessage>
                  <>{`No entitlement found matching ${searchEntitlement}`}</>
                </TableSearchInformationalMessage>
              )}
            </>
          )}
          <TablePagination changePage={handleChangePage} numPages={pageTotal} page={page} zeroIndex />
        </Paper>
      </div>
      <EntitlementFormDialog
        isLoading={entitlementsStatus === Status.pending}
        isBasePackage={BASE_PACKAGES.includes(editorEntitlement?.guid)}
        apiErrorProps={entitlementsErrorProps}
        initialTarget={{
          name: editorEntitlement?.name || '',
          description: editorEntitlement?.description || '',
          groups: editorEntitlement?.groups.map(group => group.guid) || [],
          rights: editorEntitlement?.rights || []
        }}
        onClose={handleCloseEditor}
        onSubmit={handleSubmitEditor}
        open={showEditDialog}
      />
      <ConfirmDialog
        ConfirmProps={{
          children: entitlementsStatus === Status.pending ? <CircularProgress color='primary' size={25} /> : 'Delete',
          disabled: entitlementsStatus === Status.pending,
          onClick: handleDelete
        }}
        DialogProps={{ open: showDeleteDialog, onClose: handleCloseDeleteDialog }}
        title='Delete Entitlement'
      >
        Are you sure you want to delete <strong>{editorEntitlement?.name}</strong>?
      </ConfirmDialog>
    </Container>
  );
}

export default Entitlements;
