import React, { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import useQueryString from 'hooks/useQueryString';

import { Detection } from 'module/Detection';
import { SupplementalArtifact } from 'module/Search';
import { getCompositeMarker } from 'module/Session/Session.api';
import { MarkersBlue, MarkersResponse } from 'module/Session/Session.type';

import { useAuth, useFilter } from 'provider';

import { applySessionFilterToMarkers } from 'services/filterService';

import { Status, useAsync } from 'storage';

import { DefensivePosture, Guid } from 'types/common';
import {
  BlueMarker,
  BlueMarkerExtended,
  CombinedCompositeMarker,
  CompositeMarker,
  MarkerExtended,
  RedMarkerExtended
} from 'types/marker';

import { getDiff } from 'utilities/TimeUtils';

type LoadState<T> = {
  status: Status;
  markers: T;
  refresh(): void;
};

export type CompositeMarkerLoadState = LoadState<CombinedCompositeMarker>;

export default function useCompositeMarker(
  sessionId: Guid,
  detection: Detection,
  start: string,
  machineName: string,
  updatePostureFilterOnLoad = true,
  totalAnalyticRecommendations?: SupplementalArtifact[]
): CompositeMarkerLoadState {
  const { defaultOrgId } = useAuth();
  const { data, status, run } = useAsync<MarkersResponse>();

  const { current: filter, setClearParamOverride } = useFilter();
  const [isUnvalidatedMarkerRectified, setIsUnvalidatedMarkerRectified] = React.useState(false);
  const { update: updateQuery } = useQueryString();

  const refresh = React.useCallback(() => {
    if (sessionId) {
      run(getCompositeMarker(sessionId), true);
    }
  }, [sessionId, run]);

  const mappedData = React.useMemo<CompositeMarker>(() => {
    if (!isEmpty(data) && status === Status.resolved) {
      const mappedBlue: MarkersBlue = Object.entries(data.blue).reduce((composedOBJ, [key, analytics]) => {
        return {
          ...composedOBJ,
          [key]: analytics
        };
      }, {});

      const red = isEmpty(data.red) ? [] : Object.values(data.red);

      const blue: BlueMarker[] =
        isEmpty(data.blue) || isEmpty(data.events)
          ? []
          : detection.blue_collisions.map(blueCollision => {
              const blueMarker: BlueMarker = {
                ...blueCollision,
                event: data.events[blueCollision.row_id],
                analytics: mappedBlue[blueCollision.analytic_compilation_id],
                offset: undefined,
                attack_names: undefined
              };
              return blueMarker;
            });

      return { red, blue };
    } else {
      return {
        red: [],
        blue: []
      };
    }
  }, [data, detection.blue_collisions, status]);

  const transformData = React.useMemo(() => {
    if (start) {
      return {
        red:
          mappedData.red?.map(r => ({
            ...r,
            offset: getDiff(r.timestamp, start)
          })) || [],
        blue:
          mappedData.blue?.map(b => ({
            ...b,
            offset: getDiff(b?.event?.timestamp, start)
          })) || []
      };
    } else return mappedData;
  }, [mappedData, start]);

  const validatedSet = useMemo(
    () => new Set(detection.validated.map(bm => `${bm.row_id}_${bm.analytic_compilation_id}`)),
    [detection.validated]
  );
  const detectedSet = useMemo(() => new Set(detection.detected.map(rm => rm.id)), [detection.detected]);

  const raw: CombinedCompositeMarker = useMemo(() => {
    const blue: BlueMarkerExtended[] = transformData.blue.flatMap(m =>
      m.analytics?.map(analytic => ({
        ...m,
        analytics: [analytic],
        attack_names: analytic.attack_names,
        lonely: !validatedSet.has(`${m.row_id}_${m.analytic_compilation_id}`)
      }))
    );
    const red: RedMarkerExtended[] = transformData.red.map(m => ({ ...m, lonely: !detectedSet.has(m.id) }));
    const all: MarkerExtended[] = [...blue, ...red];
    return { blue, red, all };
  }, [transformData, detectedSet, validatedSet]);

  const markers = useMemo(() => {
    return applySessionFilterToMarkers(
      defaultOrgId,
      filter,
      raw,
      start,
      detection,
      machineName,
      totalAnalyticRecommendations
    );
  }, [defaultOrgId, raw, filter, start, detection, machineName, totalAnalyticRecommendations]);

  React.useEffect(() => {
    if (updatePostureFilterOnLoad) {
      if (status === Status.resolved && raw.red.length === 0 && raw.blue.length > 0 && !isUnvalidatedMarkerRectified) {
        setIsUnvalidatedMarkerRectified(true);
        const overrides = {
          posture: [DefensivePosture.VALIDATED, DefensivePosture.ANALYTIC_GAP, DefensivePosture.UNVALIDATED]
        };
        setClearParamOverride(overrides);
        updateQuery(overrides, true);
      }
    }
  }, [raw, status, isUnvalidatedMarkerRectified, setClearParamOverride, updateQuery, updatePostureFilterOnLoad]);

  React.useEffect(() => {
    if (status === Status.resolved && raw.red.length > 0 && isUnvalidatedMarkerRectified) {
      setIsUnvalidatedMarkerRectified(false);
      setClearParamOverride(null);
    }
  }, [raw, status, isUnvalidatedMarkerRectified, setClearParamOverride]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { status, markers, refresh };
}
