import React from 'react';

import upperCase from 'lodash/upperCase';

import { StandardChip } from 'snap-ui/Chip';
import { GridColDef } from 'snap-ui/DataGrid';
import { styled } from 'snap-ui/util';

import { STATE_LABEL_COLOR } from 'constants/feed';

import { MarkdownRead } from 'module/Widgets/Markdown';

import { Organization } from 'types/auth';
import { Artifact, UserPublic, Visibility } from 'types/common';

import { formatFullTimestamp, timeAgo } from 'utilities/TimeUtils';

import { CreatorContainer, TagContainer } from './DisplayGrid.helper';

export const DescriptionContainer = styled(MarkdownRead)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function getGridColumnsCommon(users: UserPublic[], organizations: Organization[]) {
  const GridColumnsCommon: (GridColDef<Artifact> & { hideToStart?: boolean })[] = [
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      filterable: false,
      hideable: true,
      hideToStart: true,
      width: 300,
      renderCell: cell => <DescriptionContainer value={cell.value} raw />
    },
    {
      field: 'actor_tags',
      headerName: 'Actors',
      sortable: false,
      filterable: false,
      hideable: true,
      width: 400,
      renderCell: cell => {
        return <TagContainer name={cell.row.name} actor={cell.row.actor_names} />;
      }
    },
    {
      field: 'software_tags',
      headerName: 'Software',
      sortable: false,
      filterable: false,
      hideable: true,
      width: 400,
      renderCell: cell => {
        return <TagContainer name={cell.row.name} software={cell.row.software_names} />;
      }
    },
    {
      field: 'vulnerability_tags',
      headerName: 'Vulnerabilities',
      sortable: false,
      filterable: false,
      hideable: true,
      width: 400,
      renderCell: cell => {
        return <TagContainer name={cell.row.name} vulnerability={cell.row.vulnerability_names} />;
      }
    },
    {
      field: 'attack_tags',
      headerName: 'ATT&CK',
      sortable: false,
      filterable: false,
      hideable: true,
      width: 600,
      renderCell: cell => {
        return <TagContainer name={cell.row.name} attack={cell.row.attack_names} />;
      }
    },
    {
      field: 'created_by_id',
      headerName: 'Created By',
      valueGetter: p => {
        const user =
          p.row?.original_author ||
          p.row?.author ||
          users.find(u => u.id === p.row.created_by_id)?.name ||
          'SnapAttack User';

        return user;
      },
      filterable: false,
      hideable: true,
      hideToStart: true,
      width: 150,
      renderCell: cell => <CreatorContainer name={cell.value} />
    },
    {
      field: 'modified_by_id',
      headerName: 'Modified By',
      valueGetter: p => {
        return users.find(u => u.id === p.row.modified_by_id)?.name || 'SnapAttack User';
      },
      filterable: false,
      hideable: true,
      hideToStart: true,
      width: 150,
      renderCell: cell => <CreatorContainer name={cell.value} />
    },
    {
      field: 'organization_id',
      headerName: 'Organization',
      valueGetter: p => {
        return organizations.find(o => o.id === p.row.organization_id)?.name || 'SnapAttack Organization';
      },
      filterable: false,
      hideable: true,
      hideToStart: true,
      width: 200,
      renderCell: cell => <CreatorContainer name={cell.value} />
    },
    {
      field: 'modified',
      headerName: 'Modified',
      sortable: true,
      filterable: false,
      hideable: true,
      hideToStart: true,
      width: 100,
      valueFormatter: params => timeAgo(params.value)
    },
    {
      field: 'sudo-modified-field',
      headerName: 'Modified Date',
      sortable: true,
      filterable: false,
      hideable: true,
      hideToStart: true,
      width: 150,
      valueGetter: params => params.row.modified,
      valueFormatter: params => formatFullTimestamp(params.value)
    },
    {
      field: 'creation',
      headerName: 'Created',
      sortable: true,
      filterable: false,
      hideable: true,
      hideToStart: true,
      width: 100,
      valueFormatter: params => timeAgo(params.value)
    },
    {
      field: 'sudo-creation-field',
      headerName: 'Created Date',
      sortable: true,
      filterable: false,
      hideable: true,
      hideToStart: true,
      width: 150,
      valueGetter: params => params.row.creation,
      valueFormatter: params => formatFullTimestamp(params.value)
    },
    {
      field: 'visibility',
      headerName: 'Visibility',
      sortable: true,
      filterable: true,
      hideable: true,
      hideToStart: true,
      width: 125,
      renderCell: ({ value }) => (
        <StandardChip
          label={upperCase(Visibility[value as keyof typeof Visibility])}
          color={STATE_LABEL_COLOR[value as keyof typeof STATE_LABEL_COLOR] || 'default'}
        />
      )
    }
  ];

  return GridColumnsCommon;
}
