import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import May from 'module/May';
import NotFound from 'module/Util/Fallback/NotFound';

import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';

import CampaignReport from './CampaignReport';

export default function CampaignReportWrapper(
  props: RouteComponentProps<{
    guid: Guid;
  }>
): JSX.Element {
  return (
    <May I={FunctionalPermission.BASStableFeatures}>
      <May.Yes>
        <CampaignReport {...props} />
      </May.Yes>
      <May.No>
        <NotFound />;
      </May.No>
    </May>
  );
}
