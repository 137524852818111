export function renderValueWithLabel(
  value: number | null | undefined,
  singularLabel: string,
  multipleLabel?: string
): string {
  if (value === 1) {
    return `1 ${singularLabel}`;
  }

  return `${(value ?? 0).toLocaleString()} ${multipleLabel || singularLabel + 's'}`;
}

export function joinItems(items: string[]): string {
  let joined = '';
  if (items?.length === 1) {
    joined = `${items[0]}`;
  } else if (items?.length === 2) {
    joined = `${items[0]} and ${items[1]}`;
  } else if (items?.length > 2) {
    joined = items.reduce((combined, name, index) => {
      if (index === 0) return name;
      if (index === items.length - 1) return `${combined}, and ${name}`;
      return `${combined}, ${name}`;
    }, '');
  }
  return joined;
}

export function beVerb(items: any[]): string {
  if (!items) return '';
  return items.length === 1 ? 'is' : 'are';
}

export function presentPerfectBeVerb(items: any[]): string {
  if (!items) return '';
  return items.length === 1 ? 'has' : 'have';
}

const startsWithVowelRegex = new RegExp(/^[aeiou]/i);
export function correctIndefiniteArticle(str: string): string {
  return `${str.match(startsWithVowelRegex) ? 'an' : 'a'} ${str}`;
}

export function titleCase(string: string): string {
  return string.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
}
