import React from 'react';

import { useRouteMatch } from 'react-router-dom';

import { ConfigProvider } from './ConfigProvider';
import LanguageConfig, { Subpage } from './LanguageConfig';
import { LanguageControlProvider } from './LanguageProvider';

export default function LanguageConfigWrapper(): JSX.Element {
  const {
    params: { id }
  } = useRouteMatch<{ id: Subpage }>();

  return (
    <ConfigProvider>
      <LanguageControlProvider>
        <LanguageConfig subpage={id} />
      </LanguageControlProvider>
    </ConfigProvider>
  );
}
