export const NIST_FAMILY_MAPPINGS: Record<string, string> = {
  AC: 'Access Control',
  AT: 'Awareness and Training',
  AU: 'Audit and Accountability',
  CA: 'Assessment, Authorization, and Monitoring',
  CM: 'Configuration Management',
  CP: 'Contingency Planning',
  IA: 'Identification and Authentication',
  IR: 'Incident Response',
  MA: 'Maintenance',
  MP: 'Media Protection',
  PE: 'Physical and Environmental Protection',
  PL: 'Planning',
  PM: 'Program Management',
  PS: 'Personnel Security',
  PT: 'PII Processing and Transparency',
  RA: 'Risk Assessment',
  SA: 'System and Services Acquisition',
  SC: 'System and Communications Protection',
  SI: 'System and Information Integrity',
  SR: 'Supply Chain Risk Management'
};

export const DEMO_DEPLOY_NUMBERS = [
  [85, 85],
  [12, 11],
  [99, 99],
  [3, 1],
  [28, 27],
  [61, 59],
  [1, 1],
  [2, 0],
  [68, 50],
  [2, 1],
  [1, 1],
  [64, 16],
  [3, 0],
  [94, 36],
  [71, 71],
  [6, 6],
  [62, 62],
  [99, 95],
  [99, 99],
  [36, 21],
  [89, 85],
  [84, 84],
  [2, 0],
  [2, 0],
  [13, 12],
  [2, 0],
  [23, 8],
  [21, 19],
  [2, 1],
  [74, 71],
  [2, 1],
  [11, 8],
  [12, 9],
  [87, 86],
  [28, 27],
  [3, 2],
  [51, 47],
  [3, 1],
  [51, 47],
  [11, 11],
  [3, 0],
  [40, 40],
  [1, 0],
  [76, 73],
  [1, 1],
  [9, 7],
  [74, 74],
  [4, 2],
  [13, 7],
  [84, 82],
  [88, 87],
  [2, 1],
  [3, 2],
  [1, 1],
  [3, 1],
  [98, 96],
  [1, 1],
  [73, 69],
  [3, 0],
  [95, 82],
  [1, 0],
  [90, 87],
  [3, 0],
  [3, 2],
  [64, 61],
  [21, 19],
  [2, 1],
  [2, 1],
  [20, 20],
  [3, 0],
  [1, 0],
  [100, 97],
  [85, 81],
  [39, 37],
  [18, 17],
  [2, 2],
  [77, 74],
  [2, 0],
  [96, 93],
  [89, 86],
  [68, 64],
  [36, 34],
  [1, 1],
  [1, 1],
  [1, 1],
  [23, 19],
  [41, 41],
  [3, 0],
  [17, 17],
  [54, 54],
  [2, 2],
  [73, 69],
  [85, 83],
  [3, 1],
  [34, 34],
  [53, 51],
  [2, 2],
  [1, 1],
  [2, 0],
  [51, 50],
  [76, 20],
  [1, 1],
  [23, 19],
  [7, 4],
  [44, 42],
  [36, 5],
  [17, 14],
  [3, 0]
];
